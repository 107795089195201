import React, { useEffect, useMemo, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";

import { useLocation as useRouterLocation } from "react-router-dom";
import queryString from "query-string";

import { Space, Typography, Form, Row, Col } from "antd";
import { useForm } from "antd/lib/form/Form";
import {
  FormInput,
  FormInputArea,
  FormButton,
  FormPassword,
  FormSelect,
  FormPhoneNumber,
  FormDatePicker,
} from "components/FormItems/FlatFormItems";
import moment from "moment";
import Geocode from "react-geocode";

import { CloseCircleOutlined, CheckOutlined } from "@ant-design/icons";
import { openNotificationWithIcon } from "utils/Notification";

import {
  createUser,
  updateUser,
  externalRegister,
} from "services/auth.services";

import useLocation from "hooks/useLocation";
import LocationDropdown from "components/LocationDropDown/LocationDropdown";
import { getItem } from "utils/storage";
import { getStateAndCountryFromZip } from "services/locationCenter.services";

const COUNTRY_ID = 3;
function RegisterForm({ onSuccess, isUsernamePasswordRequired = true }) {
  const location = useRouterLocation();
  const qs = queryString.parse(location.search);

  const order = getItem("pendingOrder");

  const [basicInformationForm] = useForm();
  const { user, miscSettings } = useSelector((state) => ({
    user: state.user,
    intakeForms: state.intakeForms,
    miscSettings: state.misc.miscSettings,
  }));

  const { isRegisteringUser, isUpdatingUser, currentUser = {} } = user;
  const currentUserHasAccount =
    currentUser.email !== "" &&
    currentUser?.email !== null &&
    currentUser?.email !== undefined;
  const dispatch = useDispatch();

  const isLoadedRef = useRef(false);

  useEffect(() => {
    if (isLoadedRef.current === true) {
      return;
    }

    if (order?.serviceAddress) {
      basicInformationForm.setFieldsValue({
        streetAddress1: order.serviceAddress || "",
      });
    } else if (currentUser?.primaryAddress)
      basicInformationForm.setFieldsValue({
        streetAddress1: currentUser?.primaryAddress || "",
      });
    else {
      navigator.geolocation.getCurrentPosition(function (position) {
        Geocode.fromLatLng(
          position.coords.latitude,
          position.coords.longitude
        ).then((response) => {
          const serviceAddress = response.results[0].formatted_address;
          basicInformationForm.setFieldsValue({
            streetAddress1: serviceAddress || "",
          });
        });
      });
    }
    isLoadedRef.current = true;
  }, [order, currentUser]);

  const trafficSource = useMemo(() => {
    if (miscSettings) {
      const sourcesList = miscSettings.find((res) => {
        if (res.key == "MarketingSources") {
          return res;
        }
      });
      let sourceListString = decodeURI(sourcesList?.value || "");
      return JSON.parse(sourceListString || "[]");
    }
    return [];
  }, [miscSettings]);
  console.log("trafficSource", trafficSource);

  const handleBasicInformationSubmit = async (values) => {
    // set state, city and country of the user
    // values.city = cities.find((city) => city.locationId === values.cityId);
    // values.state = states.find((state) => state.locationId === values.stateId);
    // values.country = countries.find(
    //   (country) => country.locationId === values.countryId
    // );

    if (qs?.referrerCode) {
      values.referrerCode = qs.referrerCode;
    }
    values.userName = values.userName?.trim?.();
    values.dob = moment(values.dob).toISOString();

    const { state, country, zipCode } =
      (await getStateAndCountryFromZip(values.streetAddress1)) ?? {};

    values.state = state || "";
    values.country = country || "";
    values.zipCode = zipCode || "";

    if (!state || !country || !zipCode) {
      openNotificationWithIcon(
        "error",
        "Error!",
        "Please enter a valid street address"
      );
      return;
    }

    if (!currentUserHasAccount) {
      const cb = qs?.external ? externalRegister : createUser;

      await dispatch(cb(values, decodeURIComponent(qs?.token))).then((res) => {
        if (res) {
          openNotificationWithIcon(
            "success",
            "User created",
            "User has been created, you can login with credentials."
          );
          onSuccess();
        }
      });
    } else {
      values.mobileNo = values.phoneNo; // api name mapping...
      await dispatch(updateUser(currentUser, values)).then((res) => {
        if (res) {
          onSuccess();
        }
      });
    }
  };

  return (
    <Form
      autoComplete={"off"}
      layout="vertical"
      name="basicInformationForm"
      form={basicInformationForm}
      requiredMark={false}
      initialValues={{
        inviterId: qs.inviterId,
        inviterName: qs.inviterName,
        userName: currentUser?.userName || "",
        firstName: qs.firstName || currentUser?.firstName || "",
        lastName: qs.lastName || currentUser?.lastName || "",
        email: qs.email || currentUser?.email || "",
        phoneNo: currentUser?.phoneNumber || currentUser?.phoneNo || "",
        streetAddress1:
          currentUser?.streetAddress1 || order?.streetAddress1
            ? order?.streetAddress1
            : "",
        streetAddress2: currentUser?.streetAddress2 || "",
        countryId: currentUser?.countryId || COUNTRY_ID || undefined, // USA
        cityId: currentUser?.cityId || undefined,
        stateId: currentUser?.stateId || undefined,
        zipCode: currentUser?.zipCode || undefined,
        instructions: currentUser?.instructions || "",
        dob: currentUser?.dob ? moment(user.currentUser.dob) : undefined,
        password: "",
      }}
      onFinish={handleBasicInformationSubmit}
      onFinishFailed={({ values, errorFields, outOfDate }) => {
        basicInformationForm.scrollToField(errorFields[0].name, {
          scrollMode: "if-needed",
          block: "center",
          behavior: "smooth",
        });
      }}
    >
      <Row gutter={[24, 16]}>
        <Col xs={24} md={12} order={currentUserHasAccount ? 3 : 1}>
          <FormInput
            variant="underlined"
            name="firstName"
            label="First Name"
            placeholder="First Name"
            required={true}
            formItemStyles={{ marginBottom: 0 }}
          />
        </Col>
        <Col xs={24} md={12} order={currentUserHasAccount ? 4 : 1}>
          <FormInput
            variant="underlined"
            name="lastName"
            label="Last Name"
            placeholder="Last Name"
            required={true}
            formItemStyles={{ marginBottom: 0 }}
          />
        </Col>

        <Col xs={24} md={12} order={currentUserHasAccount ? 1 : 3}>
          <Row gutter={[0, 16]}>
            <Col span={24}>
              <FormInput
                readOnly={currentUserHasAccount}
                variant="underlined"
                name="email"
                label="Email Address"
                type="email"
                placeholder="Email Address"
                formItemStyles={{ marginBottom: 0 }}
                required={true}
                // addonAfter={
                //   <Space className="signupAddonAfterPrimaryText">
                //     <CheckOutlined />
                //     <Typography>Primary</Typography>
                //   </Space>
                // }
              />
            </Col>
            {/* <Form.List name="emails">
              {(fields, { add, remove }) => {
                return (
                  <>
                    {fields.map((field, index) => {
                      return (
                        <Col span={24}>
                          <FormInput
                            variant="underlined"
                            name={[index, "email"]}
                            type="email"
                            label={`Email Address ${index + 2}`}
                            placeholder="Email Address"
                            formItemStyles={{ marginBottom: 0 }}
                            addonAfter={
                              <CloseCircleOutlined
                                style={{ cursor: "pointer" }}
                                onClick={() => remove(index)}
                              />
                            }
                          />
                        </Col>
                      );
                    })}
                    <Typography.Link onClick={add}>
                      + Add New Email Address
                    </Typography.Link>
                  </>
                );
              }}
            </Form.List> */}
          </Row>
        </Col>
        {isUsernamePasswordRequired && (
          <Col xs={24} md={12} order={currentUserHasAccount ? 2 : 4}>
            <FormInput
              readOnly={currentUserHasAccount}
              variant="underlined"
              name="userName"
              label="Username"
              placeholder="Username"
              formItemStyles={{ marginBottom: 0 }}
              required={true}
              // addonAfter={
              //   <Space className="signupAddonAfterPrimaryText">
              //     <CheckOutlined />
              //     <Typography>Primary</Typography>
              //   </Space>
              // }
            />
          </Col>
        )}

        {!currentUserHasAccount && isUsernamePasswordRequired && (
          <Col xs={24} md={12} order={5}>
            <FormPassword
              variant="underlined"
              name="password"
              label="Create Password"
              placeholder="Password"
              required={true}
              formItemStyles={{ marginBottom: 0 }}
            />
          </Col>
        )}

        <Col xs={24} md={12} order={6}>
          <Row gutter={[0, 16]}>
            <Col span={24}>
              <FormPhoneNumber
                variant="underlined"
                name="phoneNo"
                label="Mobile Number"
                placeholder="Mobile Number"
                formName="basicInformationForm"
                required={true}
                formItemStyles={{ marginBottom: 0 }}
                country="us"
                // addonAfter={
                //   <Space className="signupAddonAfterPrimaryText">
                //     <CheckOutlined />
                //     <Typography>Primary</Typography>
                //   </Space>
                // }
              />
            </Col>
            {/* <Form.List name="mobiles">
              {(fields, { add, remove }) => {
                return (
                  <>
                    {fields.map((field, index) => {
                      return (
                        <Col span={24}>
                          <FormInput
                            variant="underlined"
                            name={[index, "mobile"]}
                            label={`Mobile Number ${index + 2}`}
                            placeholder="Mobile Number"
                            formItemStyles={{ marginBottom: 0 }}
                            addonAfter={
                              <CloseCircleOutlined
                                style={{ cursor: "pointer" }}
                                onClick={() => remove(index)}
                              />
                            }
                          />
                        </Col>
                      );
                    })}
                    <Typography.Link onClick={add}>
                      + Add New Email Address
                    </Typography.Link>
                  </>
                );
              }}
            </Form.List> */}
          </Row>
        </Col>

        <Col xs={24} md={12} order={7}>
          <FormDatePicker
            form={basicInformationForm}
            name="dob"
            label="Date of Birth"
            variant="underlined"
            formItemStyles={{ marginBottom: 0 }}
            required
            validateFirst
            // defaultValue={moment("01-01-1994", "DD-MM-YYYY")}
            rules={[
              {
                required: true,
                message: "*Required",
              },
              // {
              //   message:
              //     "Your age should be between 18 and 85. Kindly contact our support.",
              //   validator: (_, value) => {
              //     if (
              //       value.isSameOrBefore(moment().subtract(18, "years")) &&
              //       value.isAfter(moment().subtract(85, "years"))
              //     )
              //       return Promise.resolve();
              //     else return Promise.reject();
              //   },
              // },
            ]}
            disabledDate={(current) =>
              current.isSameOrAfter(
                moment().set({ hour: 0, minute: 0, second: 0, millisecond: 0 })
              )
            }
          />
        </Col>
        {/* <Col xs={0} md={12}></Col> */}

        <Col xs={24} md={12} order={8}>
          {/* <FormInput
            variant="underlined"
            name="streetAddress1"
            label="Street Address"
            placeholder="Street address"
            required={true}
            formItemStyles={{ marginBottom: 0 }}
          /> */}
          <LocationDropdown
            required={true}
            inputValue={currentUser?.primaryAddress ?? order?.serviceAddress}
            name={"streetAddress1"}
            form={basicInformationForm}
            onChange={(e) => {
              basicInformationForm.setFieldsValue({
                streetAddress1: e?.target?.value || "",
              });
            }}
            className={"flatFormItem underlined addressLocation"}
            label={"Street Address"}
          />
        </Col>
        {/* <Col xs={24} md={12}>
          <FormInput
            variant="underlined"
            name="streetAddress2"
            label="Street Address 2"
            placeholder="Street address"
            required={true}
            formItemStyles={{ marginBottom: 0 }}
          />
          <LocationDropdown
            name={"streetAddress2"}
            form={basicInformationForm}
            onChange={(facilityId, value) => {
              basicInformationForm.setFieldsValue({ streetAddress2: value });
            }}
            className={"flatFormItem underlined addressLocation"}
            label={"Street Address 2"}
          />
        </Col> */}
        {/* <Col xs={24} md={12}>
          <FormSelect
            variant="underlined"
            name="stateId"
            label="State"
            placeholder="State"
            required={true}
            formItemStyles={{ marginBottom: 0 }}
            options={states}
            renderLabel="locationName"
            renderValue="locationId"
            onChange={(value, option) => {
              basicInformationForm.setFieldsValue({ cityId: null });
              setState(value);
            }}
            showSearch={true}
            onSearch={(value) => { }}
            filterOption={(input, option) => {
              return option?.children
                ?.toLowerCase?.()
                .includes?.(input.toLowerCase());
            }}
          />
        </Col>
        <Col xs={24} md={12}>
          <FormSelect
            variant="underlined"
            name="cityId"
            label="City"
            placeholder="City"
            required={true}
            formItemStyles={{ marginBottom: 0 }}
            options={cities}
            renderLabel="locationName"
            renderValue="locationId"
            showSearch={true}
            onSearch={(value) => { }}
            filterOption={(input, option) => {
              console.log("option, input", option, input);
              return option?.children
                ?.toLowerCase?.()
                .includes?.(input.toLowerCase());
            }}
          />
        </Col>
        <Col xs={24} md={12}>
          <FormInput
            variant="underlined"
            name="zipCode"
            label="ZIP Code"
            placeholder="ZIP Code"
            required={true}
            normalize={(value) => {
              return (value || "").toUpperCase();
            }}
            formItemStyles={{ marginBottom: 0 }}
          />
        </Col> */}
        {/* <Col xs={0} md={12}></Col> */}

        {/* {!!currentUserHasAccount && <Col md={12} xs={0} order={9}></Col>} */}
        <Col span={12} order={9}>
          <FormInputArea
            initialValue={
              order?.serviceAddress ? currentUser?.instructions : ""
            }
            variant="underlined"
            rows={5}
            name="instructions"
            label="Address Instructions"
            placeholder="Add Instructions"
            formItemStyles={{ marginBottom: 0 }}
          />
        </Col>
        <Col xs={24} md={12} order={!!currentUserHasAccount ? 8 : 10}>
          <FormSelect
            options={trafficSource ?? []}
            label="How did you hear about us?"
            variant="underlined"
            name="marketingSource"
            placeholder="Select an option"
            renderLabel={"sourceName"}
            renderValue={"sourceName"}
            formItemStyles={{ marginBottom: 0 }}
            required={trafficSource?.length > 0}
          />
        </Col>
        <Col span={24} order={11}>
          <FormButton
            text={currentUserHasAccount ? "Update" : "Save"}
            type="submit"
            loading={isRegisteringUser || isUpdatingUser}
            style={{ padding: " 16px 50px", borderRadius: 50 }}
            // disabled={currentUserHasAccount}
          />
        </Col>
      </Row>
      <FormInput name="inviterId" hidden={true} />
      <FormInput hidden={true} name="countryId" initialValue={COUNTRY_ID} />
    </Form>
  );
}

export default RegisterForm;
