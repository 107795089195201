import React, { useState, useRef, useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";

import { getAllPaymentMethods } from "services/paymentMethod.service";

const useDefaultPayment = ({ fetch = false }) => {
  const dispatch = useDispatch();
  const { paymentMethod } = useSelector((state) => {
    return {
      user: state.user,
      paymentMethod: state.paymentMethod,
    };
  });
  const { paymentMethods } = paymentMethod;

  useEffect(() => {
    if (fetch) dispatch(getAllPaymentMethods(false));
  }, []);

  const primaryPaymentMethod = useMemo(() => {
    return paymentMethods.find((card) => card?.isPrimary === true);
  }, [paymentMethods]);

  return primaryPaymentMethod;
};

export default useDefaultPayment;
