export const actions = {
  SET_COUNTRIES: "SET_COUNTRIES",
  SET_STATES: "SET_STATES",
  SET_CITIES: "SET_CITIES",
};

export const setAllCountries = (data) => ({
  type: actions.SET_COUNTRIES,
  data,
});

export const setAllStates = (data) => ({
  type: actions.SET_STATES,
  data,
});

export const setAllCities = (data) => ({
  type: actions.SET_CITIES,
  data,
});
