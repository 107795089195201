import React, { useState, useRef, useEffect, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";

const useIntakeForms = () => {
  const dispatch = useDispatch();
  const { user, intakeForms } = useSelector((state) => {
    return {
      user: state.user,
      intakeForms: state.intakeForms,
    };
  });

  const { profiles } = user ?? {};
  const { consent, physicalHistory } = intakeForms;

  const {
    legacyConsentFormId,
    legacyIntakeFormId,
    consentFormId,
    intakeFormId,
    legacyPhysicalHistorySubmittedOn,
    physicalHistorySubmittedOn,
    consentSubmittedOn,
    legacyConsentSubmittedOn,
    isPhysicalHistoryExpired,
    isConsentExpired,
    isConsentDesignOutdated,
    isPhysicalHistoryDesignOutdated,
  } = profiles?.[0] ?? {};

  const isHistoryFormLegacy =
    profiles?.[0]?.legacyPhysicalHistoryId && !profiles?.[0]?.physicalHistoryId;
  const isConsentFormLegacy =
    profiles?.[0]?.legacyConsentId && !profiles?.[0]?.consentId;

  const isHistoryFormSubmitted =
    (profiles?.[0]?.physicalHistoryId !== undefined &&
      profiles?.[0]?.physicalHistoryId !== null &&
      profiles?.[0]?.physicalHistoryId !== 0) ||
    physicalHistory.isFilled;
  const isConsentFormSubmitted =
    (profiles?.[0]?.consentId !== undefined &&
      profiles?.[0]?.consentId !== null &&
      profiles?.[0]?.consentId !== 0) ||
    consent.isFilled;

  const consentSubmittedOnDate = isConsentFormLegacy
    ? legacyConsentSubmittedOn
    : consentSubmittedOn;
  const historyPhysicalSubmittedOn = isHistoryFormLegacy
    ? legacyPhysicalHistorySubmittedOn
    : physicalHistorySubmittedOn;

  return {
    isHistoryFormSubmitted,
    isConsentFormSubmitted,
    isHistoryFormLegacy,
    isConsentFormLegacy,
    consentSubmittedOnDate,
    historyPhysicalSubmittedOn,
    isPhysicalHistoryExpired,
    isConsentExpired,
    isConsentDesignOutdated,
    isPhysicalHistoryDesignOutdated,
  };
};

export default useIntakeForms;
