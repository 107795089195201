import { Layout } from "antd";
import "./AppFooter.scss";

import {
  FacebookFilled,
  InstagramOutlined,
  LinkedinFilled,
  LogoutOutlined,
  MenuFoldOutlined,
  MenuUnfoldOutlined,
  UserOutlined,
} from "@ant-design/icons";

import { useDispatch, useSelector } from "react-redux";
import { push } from "connected-react-router";
import moment from "moment";

const { Header, Sider, Content, Footer } = Layout;

const AppFooter = () => {
  const dispatch = useDispatch();
  const { isTokenVerified } = useSelector((state) => state.user);
  return (
    <Footer className="appFooter">
      <div className="footerLinkContainer">
        {!isTokenVerified && (
          <p className="link" onClick={() => dispatch(push("/login"))}>
            LOGIN
          </p>
        )}

        <a className="link" href="https://liquidmobileiv.com/benefits/" target={'_blank'}>
          BENEFITS
        </a>

        <a href="https://liquidmobileiv.com/process/" target={'_blank'} className="link">
          PROCESS
        </a>
        <a href="https://liquidmobileiv.com/services/" target={'_blank'} className="link">
          SERVICES
        </a>
        <a href="https://liquidmobileiv.com/contact/" target={'_blank'} className="link">
          CONTACT
        </a>
      </div>
      <div className="footerSocialIconsContainer">
        <a target={'_blank'} href="https://www.facebook.com/LiquidMobileIV">
          <FacebookFilled style={{ padding: 20 }} />
        </a>
        <a target={'_blank'} href="https://www.linkedin.com/company/liquid-mobile-iv/">
          <LinkedinFilled style={{ padding: 20 }} />
        </a>
        <a target={'_blank'} href="https://www.instagram.com/liquid_mobile_iv/">
          <InstagramOutlined style={{ padding: 20 }} />
        </a>
      </div>
      <img
        alt="decorator"
        src="https://liquidmobileiv.com/wp-content/uploads/2021/10/footer-divider.svg"
        width={"50%"}
      />
      <p style={{ fontWeight: "bold", marginTop: 24 }}>
        Copyright © {moment().year()} Liquid Mobile IV | All Rights Reserved |{" "}
        <a
          target={'_blank'}
          className="footerLink"
          href="https://liquidmobileiv.com/privacy-policy/"
          style={{ color: "#fff !important" }}
        >
          Privacy Policy
        </a>
      </p>
    </Footer>
  );
};

export default AppFooter;
