import React from "react";
import PropTypes from "prop-types";

import { Collapse, Avatar, Space, Typography } from "antd";

import "./Accordion.scss";

const { Panel: AntdPanel } = Collapse;

export const Panel = ({
  isActive,
  title,
  children,
  completedMeta,
  isCompleted,
  key,
  isClickable = true,
  className = "",
  collapsible = true,
  ...props
}) => {
  return (
    <>
      <AntdPanel
        {...props}
        forceRender={false}
        isActive={isActive}
        header={
          <Space align="start" className="accordionHeader">
            <Space className="accordionHeaderContent">
              <Avatar> {props.panelKey}</Avatar>
              <Typography>{title}</Typography>
            </Space>
            {!isActive && isCompleted && (
              <Space direction="vertical" className="accordionHeaderMeta">
                {completedMeta}
              </Space>
            )}
          </Space>
        }
        showArrow={false}
        key={key}
        className={`${!isClickable ? "unclickable" : ""} ${className}`}
      >
        <div
          className={`accordionContent ${isActive ? "opacity1" : "opacity0"}`}
        >
          {children}
        </div>
      </AntdPanel>
    </>
  );
};

Panel.propTypes = {
  isActive: PropTypes.bool,
  title: PropTypes.string,
  children: PropTypes.node,
  completedMeta: PropTypes.node,
  isCompleted: PropTypes.bool,
};

function Accordion({ panel, onChange, children, className = "" }) {
  return (
    <Collapse
      className={`accordion ${className}`}
      accordion
      bordered={false}
      activeKey={panel}
      onChange={(key) => {
        onChange(key);
      }}
    >
      {children}
    </Collapse>
  );
}

Accordion.propTypes = {};

export default Accordion;
