import React, { useState } from "react";
import Proptypes from "prop-types";
import { Row, Col, Space, Typography, Button, Modal } from "antd";
import PrimaryCheckMark from "components/PrimaryCheckMark/PrimaryCheckMark";

import { ReactComponent as EditIcon } from "assets/icons/editIco.svg";
import { ReactComponent as DeleteIcon } from "assets/icons/deleteIco.svg";

import StripeEditCardModal from "./StripeEditCardModal";

import { setLoading } from "redux/actions/loading.actions";
import { useDispatch } from "react-redux";
import {
  deletePaymentMethod,
  changePrimaryPaymentMethod,
} from "services/paymentMethod.service";

const { Title, Text } = Typography;
const { confirm, warning, success, error } = Modal;

function PaymentViewCard({
  paymentMethodId,
  brand,
  name,
  last4Digits,
  expiry,
  cvv,
  isPrimary,
  currentPrimaryCard,
}) {
  const [showEditCard, setShowEditCard] = useState(false);
  const dispatch = useDispatch();

  const deletePaymentMethodModal = {
    title: "Delete Payment Method",
    content: <>Are you sure that you want to delete the payment method?</>,
    centered: true,
    maskClosable: true,
    onOk: (close) => {
      // dispatch(setLoading(true));
      dispatch(deletePaymentMethod(paymentMethodId)).then((result) => {
        if (result?.status === "success") {
          // dispatch(getAllPaymentMethods());
          close();
        }
      });
    },
  };
  return (
    <>
      <div className="paymentViewCardContainer">
        <div
          className="flex justifySpaceBetween alignCenter"
          style={{ marginBottom: 12 }}
        >
          <div className={`cardChip issuer-${brand?.toLowerCase()}`}>
            &nbsp;
          </div>

          <Space>
            <Button shape="circle" ghost onClick={() => setShowEditCard(true)}>
              <EditIcon />
            </Button>
            <Button
              shape="circle"
              ghost
              onClick={() => {
                confirm(deletePaymentMethodModal);
              }}
            >
              <DeleteIcon />
            </Button>
          </Space>
        </div>
        <div style={{ marginBottom: 12 }}>
          <Title
            level={4}
            className="tradeGothic"
            style={{
              textTransform: "capitalize",
              color: "#15213B",
              marginBottom: 0,
            }}
          >
            {name} *{last4Digits}
          </Title>
          <Text style={{ color: "#15213B" }}>Expiration Date: {expiry}</Text>
        </div>

        <div className="flex justifySpaceBetween alignCenter">
          <Title
            level={5}
            className="tradeGothic"
            style={{
              marginBottom: 0,
              lineHeight: 1,
              fontSize: 17,
              textTransform: "capitalize",
            }}
          >
            {brand}
          </Title>
          {isPrimary ? (
            <PrimaryCheckMark />
          ) : (
            <Button
              type="text"
              onClick={() => {
                dispatch(
                  changePrimaryPaymentMethod(
                    paymentMethodId,
                    currentPrimaryCard?.paymentMethodId
                  )
                );
              }}
            >
              Set as primary
            </Button>
          )}
        </div>
      </div>
      <StripeEditCardModal
        id={paymentMethodId}
        visible={showEditCard}
        handleSave={() => setShowEditCard(false)}
        onCancel={() => setShowEditCard(false)}
      />
    </>
  );
}

PaymentViewCard.propTypes = {
  paymentMethodId: Proptypes.string,
  brand: Proptypes.string,
  name: Proptypes.string,
  expiry: Proptypes.string,
  last4Digits: Proptypes.string,
  isPrimary: Proptypes.bool,
};

export default PaymentViewCard;
