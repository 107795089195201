import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";

import "./Loader.scss";
import LogoImage from "assets/images/logo.png";

function Loader({ loading }) {
  const [hidden, setHidden] = useState(false);

  useEffect(() => {
    if (loading === false) {
      setTimeout(() => {
        setHidden(true);
      }, 350);
    }
  }, [loading]);

  return (
    <div
      className={`appLoader ${!loading ? "fade" : ""}
    ${hidden ? "hidden" : ""}
    `}
    >
      <img src={LogoImage} alt="logo" />
    </div>
  );
}

Loader.propTypes = {};

export default Loader;
