import { React, useEffect, useRef, useState } from "react";
import { Form, Tooltip } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { push } from "connected-react-router";
import RoundButton from "../../components/RoundButton/RoundButton";
import "./Dashboard.scss";

import GuestForm from "./GuestForm";

import { setCurrentBooking } from "redux/actions/booking.action";
import { setItem } from "utils/storage";

const GuestIframe = () => {
  const [form] = Form.useForm();
  const ref = useRef();
  const [timeZone, setTimeZone] = useState("");

  const [termsAndConditions, setTermsAndCondition] = useState("");
  const [goodFaithExamPolicy, setGoodFaithExamPolicy] = useState("");
  const { miscSettings } = useSelector((state) => ({
    miscSettings: state.misc.miscSettings,
  }));

  const dispatch = useDispatch();
  const { user } = useSelector((state) => {
    return { user: state.user };
  });
  const { currentUser, isTokenVerified } = user;

  const handleSubmit = (values) => {
    if (window.parent) {
      values.type = "widget";
      values.guests = [];
      values.guestsNo = 1;
      values.serviceAddress = values.serviceAddress ?? "";
      values.zipCode = values.zipCode ?? "";
      values.addOns = values.addOns ?? [];

      const params = new URLSearchParams(values).toString();
      // handle undefined and other stuff

      window.parent.location.href = window.location.origin + `?${params}`;
      return;
    }
    values.status = "pending";
    values.isBookingClicked = true;
    dispatch(savePendingOrder(values)).then(() => {
      if (currentUser && isTokenVerified) dispatch(push("/thankyou"));
      else dispatch(push("/login?redirect=thankyou"));
    });
  };

  const savePendingOrder = (data) => {
    return (dispatch) => {
      return new Promise((resolve, reject) => {
        dispatch(setCurrentBooking(data));
        setItem("pendingOrder", data);
        resolve();
      });
    };
  };

  useEffect(() => {
    if (miscSettings) {
      const TAndC = miscSettings.find((res) => {
        if (res.key == "TAndC") {
          return res;
        }
      });
      const goodFaithExamPolicy = miscSettings.find((res) => {
        if (res.key == "GoodFaithExamPolicy") {
          return res;
        }
      });
      setTermsAndCondition(TAndC?.value || "");
      setGoodFaithExamPolicy(goodFaithExamPolicy?.value || "");
    }
  }, [miscSettings]);

  return (
    <div className="dashGuestContainer">
      <GuestForm
        timeZone={timeZone}
        setTimeZone={setTimeZone}
        submitRef={ref}
        form={form}
        handleSubmit={handleSubmit}
        isMultiGuest={false}
      />
      <div
        style={{
          display: "flex",
          alignItems: "center",
          flexDirection: "column",
          padding: "0px 32px",
        }}
      >
        <RoundButton
          onClick={() => {
            ref.current.click();
            // dispatch(push("/thankyou")); // only for demo purpoe
          }}
        />
        <div className="policyLinksContainer">
          <Tooltip
            overlayStyle={{ maxWidth: 640 }}
            placement="bottom"
            title={termsAndConditions}
          >
            <p className="dashCancellationPolicyLink">Cancellation Policy</p>
          </Tooltip>
          <Tooltip
            overlayStyle={{ maxWidth: 640 }}
            placement="bottom"
            title={goodFaithExamPolicy}
          >
            <p className="dashCancellationPolicyLink">Good Faith Exam Policy</p>
          </Tooltip>
        </div>
      </div>
    </div>
  );
};

export default GuestIframe;
