import React, { useEffect, useMemo, useState } from "react";

import { List, Tabs, Skeleton, Empty } from "antd";

import "./Appointments.scss";
import AppointmentsCard from "./AppointmentsCard";
import { getAllBookings, getBookingsHistory } from "services/booking.service";
import { useDispatch, useSelector } from "react-redux";

import orderBy from "lodash/orderBy";
const { TabPane } = Tabs;

function Appointments(props) {
  const dispatch = useDispatch();
  const { bookings } = useSelector((state) => state.booking);
  const [tab, setTab] = useState("pending");
  useEffect(() => {
    loadContent("pending");
  }, []);

  const loadContent = (status) => {
    dispatch(getAllBookings(status));
  };

  const loadHistory = () => {
    dispatch(getBookingsHistory());
  };

  const sortedBookings = useMemo(() => {
    if (tab === "pending")
      return orderBy(bookings ?? [], [(item) => item.serviceDateUTC], ["asc"]);
    return bookings;
  }, [bookings]);

  const renderTab = () => {
    if (bookings.length) {
      return (
        <List
          className='appointmentsList'
          itemLayout='vertical'
          size='large'
          pagination={{
            onChange: (page) => {
              console.log(page);
            },
            pageSize: 10,
          }}
          dataSource={sortedBookings}
          renderItem={(appointment, index) => (
            <List.Item key={index}>
              <AppointmentsCard
                key={index}
                id={appointment.orderId}
                type={appointment.lineItems?.[0]?.productName}
                durationHours={appointment.lineItems?.[0]?.durationHours}
                date={appointment?.serviceDateUTC}
                status={
                  appointment.orderStatus?.toLowerCase?.() === "pending"
                    ? "Booked"
                    : appointment.orderStatus?.toLowerCase?.() === "cancel"
                    ? "Cancelled"
                    : appointment.orderStatus?.toLowerCase?.() === "noshow"
                    ? "No Show"
                    : appointment.orderStatus
                }
                data={appointment}
                meetingVendor={appointment?.meetingVendor}
              />
            </List.Item>
          )}
        />
      );
    } else {
      return (
        <List
          className='appointmentsList'
          itemLayout='vertical'
          size='large'
          pagination={{
            onChange: (page) => {
              console.log(page);
            },
            pageSize: 10,
          }}
          dataSource={sortedBookings}
          renderItem={(appointment, index) => (
            <List.Item key={index}>
              <AppointmentsCard
                key={index}
                id={appointment.orderId}
                type={appointment.lineItems?.[0]?.productName}
                duration={appointment.lineItems?.[0]?.duration}
                date={appointment?.serviceDateUTC}
                status={
                  appointment.orderStatus?.toLowerCase?.() === "pending"
                    ? "Booked"
                    : appointment.orderStatus?.toLowerCase?.() === "cancel"
                    ? "Cancelled"
                    : appointment.orderStatus?.toLowerCase?.() === "noshow"
                    ? "No Show"
                    : appointment.orderStatus
                }
                data={appointment}
                meetingVendor={appointment?.meetingVendor}
              />
            </List.Item>
          )}
        />
      );
      // return (
      //   <Empty
      //     description={
      //       tab === "pending"
      //         ? "No Upcoming Appointments"
      //         : "No Previous Appointments"
      //     }
      //   />
      // );
    }
  };

  return (
    <>
      <Tabs
        className='appointmentsTab'
        onChange={(key) => {
          setTab(key);
          if (key === "completed") loadHistory();
          else {
            loadContent(key);
          }
        }}
      >
        <TabPane tab='Upcoming Appointments' key='pending'>
          {renderTab()}
        </TabPane>
        <TabPane tab='Appointments History' key='completed'>
          {renderTab()}
        </TabPane>
      </Tabs>
    </>
  );
}

Appointments.propTypes = {};

export default Appointments;
