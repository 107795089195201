import { actions } from "../actions/addresses.action";

const INITIAL_STATE = {
  isSubmitting: false,
  addresses: [],
};

const reducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case actions.SET_ADDRESSES:
      return { ...state, addresses: action.data };
    case actions.ADD_ADDRESS:
      return { ...state, addresses: [...state.addresses, action.data] };
    case actions.UPDATE_ADDRESS:
      const updatedAddresses = state.addresses.map((address) => {
        if (address.addressId === action.data?.addressId) {
          return { ...address, ...action.data };
        }
        return address;
      });
      return { ...state, addresses: updatedAddresses };
    case actions.DELETE_ADDRESS:
      const filteredAddresses = state.addresses.filter(
        (address) => address.addressId !== action?.id
      );
      return { ...state, addresses: filteredAddresses };
    case actions.SET_PRIMARY:
      const updatedAddresses2 = state.addresses.map((address) => {
        return {
          ...address,
          isPrimary: address.addressId === action.addressId,
        };
      });
      return { ...state, addresses: updatedAddresses2 };
    case actions.SET_SUBMITTING:
      return { ...state, isSubmitting: action.isSubmitting };
    default:
      return state;
  }
};
export default reducer;
