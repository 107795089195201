import { Alert, Button } from "antd";
import "./Login.scss";
import { useDispatch, useSelector } from "react-redux";
import { push } from "connected-react-router";
import queryString from "query-string";
import { useLocation } from "react-router-dom";

import { loginUser, socialLogin } from "../../services/auth.services";
import {
  FormInput,
  FormCheckbox,
  FormButton,
  FormPassword,
} from "../../components/FormItems/FlatFormItems";

import { SocialButton } from "components/Buttons/Buttons";
import TestimonialCard from "./TestimonialCard";

import LogoImage from "assets/images/logo.png";

import AuthLayout from "components/AuthLayout/AuthLayout";

import { Typography, Space, Form, Divider } from "antd";
import { useEffect } from "react";
import { openNotificationWithIcon } from "utils/Notification";
import { InfoCircleOutlined } from "@ant-design/icons";

const { Title, Text } = Typography;

const Login = () => {
  const dispatch = useDispatch();
  const { user } = useSelector((state) => ({
    user: state.user,
  }));
  const location = useLocation();
  const qs = queryString.parse(location.search);

  const { isLoggingIn } = user;

  const [form] = Form.useForm();

  const handleSubmit = (data) => {
    dispatch(loginUser(data, qs.redirect ?? location?.state?.goTo));
  };

  const handleFacebookLogin = (response) => {
    if (!response?.accessToken) return;
    const { name, email, accessToken } = response;
    const [firstName = "", lastName = ""] = name.split(" ");
    dispatch(
      socialLogin(
        "facebook",
        accessToken,
        { firstName, lastName, email },
        qs.redirect ?? location?.state?.goTo
      )
    );
  };
  const handleGoogleLogin = (response) => {
    const { name, email } = response.profileObj;
    const [firstName = "", lastName = ""] = name.split(" ");
    dispatch(
      socialLogin(
        "google",
        response.tokenId,
        { firstName, lastName, email },
        qs.redirect ?? location?.state?.goTo
      )
    );
  };
  const handleAppleLogin = (response) => {
    if (response?.authorization?.id_token) {
      const idToken = response?.authorization?.id_token;

      const email = response?.user?.email;
      const name = response?.user?.name;
      let firstName = "";
      let lastName = "";
      if (name) {
        firstName = name?.firstName;
        lastName = name?.lastName;
      }
      dispatch(
        socialLogin(
          "apple",
          idToken,
          { firstName, lastName, email },
          qs.redirect ?? location?.state?.goTo
        )
      );
    }
  };

  return (
    <AuthLayout>
      <Space className="loginFormContainer" direction="vertical">
        {/* <img className="loginLogo" src={LogoImage} alt="logo" /> */}
        {qs.redirect ? (
          <Alert
            className="loginFormMessage"
            message="Please Login or Create an Account to complete your booking"
            type="info"
            icon={<div className="info">i</div>}
            showIcon
          />
        ) : (
          <></>
          // <div className="loginFormMessage">
          //   <InfoCircleOutlined
          //     style={{
          //       fontSize: 24,
          //       marginRight: 12,
          //     }}
          //   />
          //   <Text style={{ fontWeight: 700 }}>
          //     Please Login or Create an Account to complete your booking
          //   </Text>
          // </div>
          // <Title level={4} style={{ margin: 0, color: "#000" }}>
          //   Login
          // </Title>
        )}

        <Form
          name="loginForm"
          className="loginForm"
          layout="vertical"
          form={form}
          requiredMark={false}
          onFinishFailed={({ values, errorFields, outOfDate }) => {
            form.scrollToField(errorFields[0].name, {
              scrollMode: "if-needed",
              block: "center",
              behavior: "smooth",
            });
          }}
          onFinish={handleSubmit}
        >
          <FormInput
            placeholder="Username"
            name="username"
            label="Username"
            required={true}
          />
          <FormPassword
            placeholder="Password"
            name="password"
            label="Password"
            type="password"
            required={true}
          />
          <Space
            style={{
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <FormCheckbox formItemStyles={{ marginBottom: 0 }}>
              Stay logged in
            </FormCheckbox>
            <Typography.Link
              underline
              className="forgotPasswordText"
              onClick={() => dispatch(push("reset"))}
            >
              Forgot Username/Password?
            </Typography.Link>
          </Space>
          <FormButton
            text="Login"
            type="submit"
            variant="filled"
            loading={isLoggingIn}
            style={{
              width: "100%",
              marginBottom: 10,
            }}
          />
          <FormButton
            onClick={() => {
              dispatch(push(`/signup?${queryString.stringify(qs)}`));
            }}
            text="Create an account"
            variant="outlined"
            style={{
              width: "100%",
              fontSize: 17,
            }}
          />
        </Form>
      </Space>

      <Space
        direction="vertical"
        style={{
          display: "flex",
        }}
      >
        <Divider className="loginFormDivider" />
        <Title level={4} style={{ color: "#000" }}>
          Or continue with
        </Title>
        <Space direction="horizontal" size="middle" wrap>
          <SocialButton
            type="google"
            text="Google"
            onSuccess={handleGoogleLogin}
          />
          <SocialButton
            type="facebook"
            text="Facebook"
            onSuccess={handleFacebookLogin}
          />
          <SocialButton
            type="apple"
            text="Apple"
            onSuccess={handleAppleLogin}
          />
        </Space>
      </Space>
    </AuthLayout>
  );
};

export default Login;
