import { openNotificationWithIcon } from "utils/Notification";
import { request } from "./verb.services";

import {
  setFamilyMembers,
  addFamilyMember,
  deleteFamilyMember as deleteFamilyMemberAction,
  updateFamilyMember as updateFamilyMemberAction,
} from "redux/actions/familyMembers.action";
import { setLoading } from "redux/actions/loading.actions";

export const getAllFamilyMembers = () => {
  return (dispatch) => {
    dispatch(setLoading(true));
    return request("Customer/InvitedMembers", "get", null, true)
      .then((response) => {
        dispatch(setFamilyMembers(response.data));
        return response.data;
      })
      .catch((e) => {
        openNotificationWithIcon(
          "error",
          "Error!",
          e?.response?.data?.message ||
            e?.response?.data?.Message ||
            "Network error has occured"
        );
      })
      .finally(() => {
        dispatch(setLoading(false));
      });
  };
};

export const createFamilyMember = (data) => {
  return (dispatch) => {
    dispatch(setLoading(true));
    return request("Customer/Invite", "put", data, true)
      .then((response) => {
        return dispatch(getAllFamilyMembers(response.data)).then(() => {
          openNotificationWithIcon(
            "success",
            "Success!",
            "Family member added successfully."
          );

          return {
            status: "success",
          };
        });
      })
      .catch((e) => {
        openNotificationWithIcon(
          "error",
          "Error!",
          e?.response?.data?.message ||
            e?.response?.data?.Message ||
            "Network error has occured"
        );
      })
      .finally(() => {
        dispatch(setLoading(false));
      });
  };
};

export const updateFamilyMember = (invitedMemberId, data) => {
  return (dispatch) => {
    dispatch(setLoading(true));

    return request(
      `Customer/InvitedMembers/${invitedMemberId}?permissionToBill=${data.permissionToBill}`,
      "patch",
      null,
      true
    )
      .then((response) => {
        return dispatch(getAllFamilyMembers(response.data)).then(() => {
          openNotificationWithIcon(
            "success",
            "Success!",
            "Family member updated successfully."
          );

          return {
            status: "success",
          };
        });
      })
      .catch((e) => {
        openNotificationWithIcon(
          "error",
          "Error!",
          e?.response?.data?.message ||
            e?.response?.data?.Message ||
            "Network error has occured"
        );
      })
      .finally(() => {
        dispatch(setLoading(false));
      });
  };
};

export const deleteFamilyMember = (invitedMemberId) => {
  return (dispatch) => {
    dispatch(setLoading(true));

    return request(
      `Customer/InvitedMembers/${invitedMemberId}`,
      "delete",
      null,
      true
    )
      .then((response) => {
        dispatch(deleteFamilyMemberAction(invitedMemberId));
        openNotificationWithIcon(
          "success",
          "Success!",
          "Family member entry removed successfully"
        );
      })
      .catch((e) => {
        openNotificationWithIcon(
          "error",
          "Error!",
          e?.response?.data?.message ||
            e?.response?.data?.Message ||
            "Network error has occured"
        );
      })
      .finally(() => {
        dispatch(setLoading(false));
      });
  };
};
