export const actions = {
  SET_FAMILYMEMBERS: "SET_FAMILYMEMBERS",
  ADD_FAMILYMEMBER: "ADD_FAMILYMEMBER",
  UPDATE_FAMILYMEMBER: "UPDATE_FAMILYMEMBER",
  DELETE_FAMILYMEMBER: "DELETE_FAMILYMEMBER",
};

export const setFamilyMembers = (data) => ({
  type: actions.SET_FAMILYMEMBERS,
  data,
});

export const addFamilyMember = (data) => ({
  type: actions.ADD_FAMILYMEMBER,
  data,
});

export const updateFamilyMember = (data) => ({
  type: actions.UPDATE_FAMILYMEMBER,
  data,
});

export const deleteFamilyMember = (invitedMemberId) => ({
  type: actions.DELETE_FAMILYMEMBER,
  invitedMemberId,
});
