import { actions } from "../actions/membership.action";

const INITIAL_STATE = {
  membershipTypes: [],
  currentMembership: null,
  membershipDate: null, // need to be decided
};

const reducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case actions.SET_MEMBERSHIPS:
      return { ...state, membershipTypes: action.data };
    case actions.SET_CURRENT_MEMBERSHIP:
      return { ...state, currentMembership: action.data };
    case actions.CANCEL_MEMBERSHIP:
      return { ...state, currentMembership: null };
    default:
      return state;
  }
};
export default reducer;
