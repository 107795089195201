import React, { useMemo, useState } from "react";
import { Collapse, Grid } from "antd";
import uniqBy from "lodash/uniqBy";

import { CaretRightOutlined, SearchOutlined, UserOutlined } from "@ant-design/icons";
import { Input } from "antd";

import ProductItem from "components/ProductItem/ProductItem";

import { ReactComponent as SearchIcon } from "assets/icons/searchIco.svg";
import { ReactComponent as ChevronRightIcon } from "assets/icons/chevronRight.svg";

const { useBreakpoint } = Grid;
const { Panel } = Collapse;

export default function MobileServiceDropdown({
  initialValue,
  categories,
  categoryProducts,
  onServiceChange,
}) {
  const [search, setSearch] = useState("");
  const screens = useBreakpoint();
  const isSmallDevice = !screens.md;
  const [activeKey, setActiveKey] = useState([])

  const categoryProductsList = uniqBy(categoryProducts, "productName");
  const onChange = (key) => {
    console.log(key);
  };


  const filteredCategoryIds = useMemo(() => {
    const ids = [];
    for (let category of categories) {
      if (
        category?.name?.toLowerCase?.()?.includes?.(search?.toLowerCase?.())
      ) {
        ids.push(category.id);
      }
    }

    for (let product of categoryProducts) {
      if (
        product?.productName
          ?.toLowerCase?.()
          ?.includes?.(search?.toLowerCase?.())
      ) {
        ids.push(...product.productCategoryIds);
      }
    }
    return ids;
  }, [search, categories]);
  return (
    <div
      className="mobileServiceDropdown"
      style={{
        // display: "flex",
        // justifyContent: "space-between",
        width: isSmallDevice ? "calc(100vw - 16px - 16px)" : 450,
        background: "#fff",
        zIndex: 100,
        left: "-30%",
        boxShadow: "0px 5px 38px rgba(0,0,0,0.25)",
        borderRadius: 14,
      }}
    >
      <div className="mobileServiceDropdownSearchContainer">
        <Input
          allowClear
          value={search}
          onChange={(e) => {
            setSearch(e.target.value);
          }}
          className="mobileServiceDropdownSearch"
          size="small"
          placeholder="Search Services"
          prefix={<SearchIcon />}
        />
      </div>
      <div
        style={{
          height: isSmallDevice ? 400 : 400,
          overflowY: "scroll",
        }}
      >
        <Collapse
        className="mobileServiceDropdownCollapse"
          expandIcon={({ isActive }) => (
            <ChevronRightIcon style={{
              transform: `rotate(${isActive ? 90 : 0}deg)`,
              transition: 'transform 0.25s ease-out'
            }} />
          )}
          onChange={setActiveKey}
          activeKey={activeKey}
        >
          {categories
            ?.filter((c) => {
              return (
                c.name !== "All" && filteredCategoryIds?.includes(c.id)
              );
            })
            ?.map((c, index) => {
              return (
                <Panel header={c.name} key={index}>
                  {categoryProducts
                    ?.filter((cp) => {
                      return (
                        cp?.productCategoryIds?.includes(c.id) &&
                        cp?.productName
                          ?.toLowerCase?.()
                          ?.includes?.(search?.toLowerCase?.())
                      );
                    })
                    ?.map((cp) => {
                      return (
                        <ProductItem
                          styles={{
                            marginBottom: 20
                          }}
                          key={cp?.productId}
                          product={cp}
                          onClick={() => onServiceChange(cp.productId)}
                        />
                      );
                    })}
                </Panel>
              );
            })}
        </Collapse>
      </div>
    </div>
  );
}
