import React, { useRef } from "react";
import PropTypes from "prop-types";

import { Form, Row, Col } from "antd";
import { Button } from "components/Buttons/Buttons";

import Modal from "components/Modal/Modal";
import {
  FormInput,
  FormInputArea,
  FormButton,
  FormSelect,
  FormHiddenSubmitButton,
} from "components/FormItems/FlatFormItems";
import { shareWithFriend } from "services/addresses.service";
import { useDispatch, useSelector } from "react-redux";
import LocationDropdown from "components/LocationDropDown/LocationDropdown";
import SearchLocationInput from "components/LocationInput/SearchLocationInput";
import { openNotificationWithIcon } from "utils/Notification";
function ShareWithFriendModal({
  visible,
  title,
  handleSave,
  onCancel,
  type = "add",
  data = {},
}) {
  const [form] = Form.useForm();
  const submitBtnRef = useRef();

  const dispatch = useDispatch();
  const addresses = useSelector((state) => state.addresses);

  const { addressId, address } = data;

  const { isSubmitting } = addresses;
  return (
    <Modal
      destroyOnClose={true}
      headerBorder={false}
      width="420px"
      title={title}
      onCancel={onCancel}
      visible={visible}
      okText="Send"
      footer={[
        <Button
          rounded={true}
          loading={isSubmitting}
          onClick={() => submitBtnRef?.current?.click()}
        >
          {isSubmitting? "Sending": "Send"}
        </Button>,
      ]}
    >
      <Form
        preserve={false}
        form={form}
        requiredMark={false}
        layout="vertical"
        initialValues={{
          address: address,
        }}
        onFinish={async (values) => {
          if (values.email) {
            const result = await dispatch(shareWithFriend(values.email));
            if (result?.status === "success") handleSave?.();
          } else {
            openNotificationWithIcon(
              "warning",
              "Invalid Address",
              "Please enter a valid street address"
            );
          }
        }}
        onValuesChange={(changedValues, allValues) => {}}
      >
        <Row gutter={[24, 16]}>
          <Col xs={24}>
            <FormInput
              type="email"
              required={true}
              variant="underlined"
              rows={6}
              name="email"
              label="Email Address"
              placeholder="Email Address"
              formItemStyles={{ marginBottom: 0 }}
            />
          </Col>

          <Col xs={0} md={12}></Col>
        </Row>

        <FormHiddenSubmitButton submitRef={submitBtnRef} />
      </Form>
    </Modal>
  );
}

ShareWithFriendModal.propTypes = {
  visible: PropTypes.bool,
  onCancel: PropTypes.any,
  handleSave: PropTypes.any,
  title: PropTypes.string,
  type: PropTypes.oneOf(["add", "edit"]),
};

export default ShareWithFriendModal;
