import React from "react";
import PropTypes from "prop-types";
// import Img from '../../assets/images/error.svg'

const ErrorPage = ({ error, errorInfo }) => {
  return (
    <div className="ErrorPageContent">
      {/* <img src={Img} alt="" /> */}
      <h1>{error || "Error"}</h1>
      <p>{errorInfo || "OOPS! Something went wrong, please try again"}</p>
      <a href="/" type="button" className="ReportButton">
        RELOAD
      </a>
    </div>
  );
};

ErrorPage.propTypes = {
  onClick: PropTypes.func,
  onRefresh: PropTypes.func,
};

export default ErrorPage;
