import { setMisc, setMiscLoading } from "redux/actions/misc.action";
import { setLoading } from "redux/actions/loading.actions";
import { request } from "./verb.services";
import { openNotificationWithIcon } from "../utils/Notification";

export const setMiscSettings = (timer) => {
  return (dispatch) => {
    dispatch(setLoading(true));
    dispatch(setMiscLoading(true));
    return request(`Configurations`, "get", null, false)
      .then((res) => {
        dispatch(setMisc(res.data));
        return { mstatus: true, data: res.data };
      })
      .catch((e) => {
        openNotificationWithIcon(
          "error",
          "Error!",
          e?.response?.data?.[0] ||
            e?.response?.data?.message ||
            e?.response?.data?.Message ||
            e?.response?.message ||
            e?.message ||
            "Something went wrong"
        );
        return { mstatus: false };
      })
      .finally(() => {
        dispatch(setMiscLoading(false));
        dispatch(setLoading(false));
      });
  };
};

// export const setTimer = (timer) => {
//   return (dispatch) => {
//     return new Promise((_) => {
//       dispatch(setTimerAction(timer));
//       setItem("timer", timer.toString());
//     });
//   };
// };
