export const actions = {
  SET_MISC: "SET_MISC",
  SET_MISC_LOADING: "SET_MISC_LOADING"
};

export const setMisc = (data) => ({
  type: actions.SET_MISC,
  data,
});

export const setMiscLoading = (data) => ({
  type: actions.SET_MISC_LOADING,
  data
})
