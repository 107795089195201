import React from "react";
import PropTypes, { string } from "prop-types";

import { Avatar, Typography, Space } from "antd";

function TestimonialCard({ description, name, subTitle, avatarURL, style }) {
  return (
    <Space direction="vertical" className="testimonialContainer" style={style}>
      <Typography style={{ color: "#fff" }} className="testimonialDescription">
        {description}
      </Typography>
      <Space>
        <Avatar src={avatarURL} size="large" style={{ background: "#fff" }} />
        <Typography className="testimonialTitle" style={{ color: "#fff" }}>
          {name}
          {subTitle && (
            <>
              <span className="testimonialSubtitle"> , {subTitle}</span>
            </>
          )}
        </Typography>
      </Space>
    </Space>
  );
}

TestimonialCard.propTypes = {
  description: PropTypes.string.isRequired,
  name: PropTypes.string,
  subTitle: PropTypes.string,
  avatarURL: PropTypes.string,
  style: PropTypes.object,
};

export default TestimonialCard;
