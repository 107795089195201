import { openNotificationWithIcon } from "utils/Notification";
import { request } from "./verb.services";

import {
  setIntakeForms,
  setIsSubmitting,
} from "redux/actions/intakeForm.action";
import { setLoading } from "redux/actions/loading.actions";
import { getProfiles } from "./auth.services";

export const getFormByType = (type, showLoading) => {
  return (dispatch) => {
    if (showLoading) dispatch(setLoading(true));
    return request(`Forms?type=${type}`, "get", null, true)
      .then((response) => {
        dispatch(
          setIntakeForms(
            type === 3 ? "consent" : "physicalHistory",
            response.data
          )
        );
        return {
          data: response.data,
          status: "success",
        };
      })
      .catch((e) => {
        openNotificationWithIcon(
          "error",
          "Error!",
          e?.response?.data?.message ||
            e?.response?.data?.Message ||
            "Network error has occured"
        );
      })
      .finally(() => {
        if (showLoading) dispatch(setLoading(false));
      });
  };
};

export const getForm = (profileId, name) => {
  return (dispatch) => {
    return request(`Customers/profiles/${profileId}/${name}`, "get", null, true)
      .then((response) => {
        const nameLower = name.toLowerCase();

        dispatch(
          setIntakeForms(
            nameLower === "consent" ? "consent" : "physicalHistory",
            response.data
          )
        );
        return {
          data: response.data,
          status: "success",
        };
      })
      .catch((e) => {
        openNotificationWithIcon(
          "error",
          "Error!",
          e?.response?.data?.message ||
            e?.response?.data?.Message ||
            "Network error has occured"
        );
      });
  };
};
export const getLegacyForm = (profileId, name) => {
  return (dispatch) => {
    return request(`Customers/profiles/${profileId}/${name}`, "get", null, true)
      .then((response) => {
        const nameLower = name.toLowerCase();

        dispatch(
          setIntakeForms(
            nameLower === "legacyconsent" ? "consent" : "physicalHistory",
            response.data
          )
        );
        return response.data;
      })
      .catch((e) => {
        openNotificationWithIcon(
          "error",
          "Error!",
          e?.response?.data?.message ||
            e?.response?.data?.Message ||
            "Network error has occured"
        );
      });
  };
};

export const postForm = (formId, formVersionId, data, formName) => {
  return (dispatch) => {
    dispatch(setLoading(true));
    return request(
      `Forms/${formId}/Versions/${formVersionId}/Values`,
      "post",
      {
        valueJSON: JSON.stringify(data ?? "{}"),
      },
      true
    )
      .then(async (response) => {
        dispatch(
          setIntakeForms(formName, {
            ...data,
            isFilled: true,
          })
        );
        await dispatch(getProfiles());
        openNotificationWithIcon(
          "success",
          "Form submitted!",
          "Your form has been submitted successfully"
        );
        return {
          status: "success",
          data: response?.data,
        };
      })
      .catch((e) => {
        openNotificationWithIcon(
          "error",
          "Error!",
          e?.response?.data?.message ||
            e?.response?.data?.Message ||
            "Network error has occured"
        );
      })
      .finally(() => {
        dispatch(setLoading(false));
      });
  };
};

export const putForm = (formId, formVersionId, formValueId, data, formName) => {
  return (dispatch) => {
    dispatch(setLoading(true));
    return request(
      `Forms/${formId}/Versions/${formVersionId}/Values?formValueId=${formValueId}`,
      "put",
      {
        valueJSON: JSON.stringify(data ?? "{}"),
      },
      true
    )
      .then(async (response) => {
        dispatch(
          setIntakeForms(formName, {
            ...data,
            isFilled: true,
          })
        );
        await dispatch(getProfiles());
        openNotificationWithIcon(
          "success",
          "Form submitted!",
          "Your form has been submitted successfully"
        );
        return {
          status: "success",
          data: response?.data,
        };
      })
      .catch((e) => {
        openNotificationWithIcon(
          "error",
          "Error!",
          e?.response?.data?.message ||
            e?.response?.data?.Message ||
            "Network error has occured"
        );
      })
      .finally(() => {
        dispatch(setLoading(false));
      });
  };
};

export const getFormsAuditHistory = (profileId, formType) => {
  return (dispatch) => {
    return request(
      `Customers/Profiles/${profileId}/FormTypes/${formType}`,
      "get",
      null,
      true
    )
      .then((response) => {
        return {
          data: response.data,
          status: "success",
        };
      })
      .catch((e) => {
        openNotificationWithIcon(
          "error",
          "Error!",
          e?.response?.data?.message ||
            e?.response?.data?.Message ||
            "Network error has occured"
        );
      });
  };
};

export const getFormByValueId = (formValueId) => {
  return (dispatch) => {
    dispatch(setLoading(true));
    return request(`Forms/Values/${formValueId}`, "get", null, true)
      .then((res) => {
        return { status: "success", data: res.data };
      })
      .catch((e) => {
        openNotificationWithIcon(
          "error",
          "Error!",
          e?.response?.data?.[0] ||
            e?.response?.data?.message ||
            e?.response?.data?.Message ||
            e?.response?.message ||
            e?.message ||
            "Something went wrong"
        );
      })
      .finally(() => {
        dispatch(setLoading(false));
      });
  };
};
