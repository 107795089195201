export const actions = {
  SET_LOADING: "SET_LOADING",
  SET_SUBMITTING: "SET_SUBMITTING",
};

export const setLoading = (data) => ({
  type: actions.SET_LOADING,
  data,
});

export const setSubmitting = (data) => ({
  type: actions.SET_SUBMITTING,
  data,
});
