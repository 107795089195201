import React, { useEffect, useMemo, useRef, useState } from "react";
import {
  Checkbox,
  Form,
  Select,
  Dropdown,
  Button as AntdButton,
  Empty,
  Typography,
  Space,
} from "antd";
import { CloseOutlined } from "@ant-design/icons";
import { useDispatch, useSelector } from "react-redux";
import { getItem, setItem, removeItem } from "utils/storage";

import Modal from "components/Modal/Modal";
import { Button } from "components/Buttons/Buttons";
import { FormInput } from "components/FormItems/FlatFormItems";
import { FormHiddenSubmitButton } from "components/FormItems/RoundedFormItems";
import { checkIfUsernameExists } from "services/booking.service";

const { Text, Title, Link } = Typography;
export default function UsernamePopup({
  visible,
  username,
  onCancel,
  handleSave,
  continueWithoutUsername,
}) {
  const [form] = Form.useForm();
  const submitRef = useRef();

  const dispatch = useDispatch();

  return (
    <Modal
      destroyOnClose={true}
      className="usernamePopup"
      title="Provide Username"
      headerBorder={true}
      visible={visible}
      onCancel={onCancel}
      okText="Save & Order"
      onOk={handleSave}
      //   okButtonProps={{
      //     disabled: !initialValue || initialValue?.length <= 0,
      //   }}
      footer={
        <Space style={{ display: "flex", justifyContent: "space-between" }}>
          <AntdButton
            type="text"
            style={{ paddingTop: 8 }}
            onClick={onCancel}
            // disabled={initialValue?.length}
          >
            Cancel
          </AntdButton>
          <Button rounded={true} onClick={() => submitRef.current?.click?.()}>
            Save
          </Button>
        </Space>
      }
    >
      <Text className="tradeGothic">
        There are more than one guest in our system with matching first name,
        last name and email. Please provide the username of the guest you wish
        to book for.
      </Text>

      <Form
        requiredMark={false}
        form={form}
        layout="vertical"
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "flex-end",
          borderBottom: "2px solid #EFF1F4",
          paddingBottom: 12,
        }}
        initialValues={{
          username,
        }}
        preserve={false}
        onFinish={(values) => {
          dispatch(checkIfUsernameExists(values.username)).then((res) => {
            if (res?.isValid) {
              handleSave(values.username);
            }
          });
        }}
        onFinishFailed={({ values, errorFields, outOfDate }) => {
          form.scrollToField(errorFields[0].name, {
            scrollMode: "if-needed",
            block: "center",
            behavior: "smooth",
          });
        }}
      >
        <FormInput
          name="username"
          label={"Username"}
          required
          type="input"
          formItemStyles={{
            width: "100%",
          }}
        />

        <FormHiddenSubmitButton submitRef={submitRef} />
      </Form>
      {/* <Text
        className="tradeGothic"
        style={{
          marginTop: 16,
          display: "inline-block",
        }}
      >
        To continue without any existing guest.{" "}
        <Link onClick={continueWithoutUsername} style={{}}>
          Click here
        </Link>
      </Text> */}
    </Modal>
  );
}
