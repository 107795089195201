import { actions } from "../actions/familyMembers.action";

const INITIAL_STATE = {
  family: [],
};

const reducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case actions.SET_FAMILYMEMBERS:
      return { ...state, family: action.data };
    case actions.ADD_FAMILYMEMBER:
      return { ...state, family: [...state.family, action.data] };
    case actions.UPDATE_FAMILYMEMBER:
      const updatedFamily = state.family.map((family) => {
        if (family.id === action.data?.id) {
          return { ...family, ...action.data };
        }
        return family;
      });
      return { ...state, family: updatedFamily };
    case actions.DELETE_FAMILYMEMBER:
      const { invitedMemberId } = action;
      const filteredFamily = state.family.filter(
        (family) => family.invitedMemberId !== invitedMemberId
      );
      return { ...state, family: filteredFamily };
    default:
      return state;
  }
};
export default reducer;
