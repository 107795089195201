import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import "./IntakeForms.scss";

import { Form, List } from "antd";
import IntakeFormCard from "./components/IntakeFormCard";
import HistoryIntakeForm from "components/HistoryIntakeForm/HistoryIntakeForm";
import ConsentForm from "components/ConsentForm/ConsentForm";

import useIntakeForms from "hooks/useIntakeForms";
import LegacyHistoryIntakeForm from "components/HistoryIntakeForm/LegacyHistoryIntakeForm";
import LegacyConsentForm from "components/ConsentForm/LegacyConsentForm";
import { FormType } from "utils/enums";

const DUMMY_INTAKEFORMS = [
  {
    type: FormType.historyOrPhysical,
    title: "History/Physical",
    createdOn: "", // probably datetime
    isFilled: true, // probably some other field that needs to be fetched from
  },
  {
    type: FormType.consent,
    title: "Consents",
    createdOn: "",
    isFilled: false, // probably some other field that needs to be fetched from
  },
];

function IntakeForms({
  variant = "full",
  openOnLoad = false,
  showAudit = false,
}) {
  const [showHistoryForm, setShowHistoryForm] = React.useState({
    visible: false,
    viewOnly: true,
  });
  const [showConsentForm, setShowConsentForm] = React.useState({
    visible: false,
    viewOnly: true,
  });

  const { user, intakeForms } = useSelector((state) => {
    return {
      user: state.user,
      intakeForms: state.intakeForms,
    };
  });
  const { currentUser, profiles } = user;
  const { intakeFormSubmitted, consentFormSubmitted } = currentUser;
  const { isSubmitting, consent, physicalHistory } = intakeForms;

  const modalVisibilityHander = (type, visible, viewOnly, fill = false) => {
    switch (type) {
      case FormType.historyOrPhysical:
        setShowHistoryForm({
          visible,
          viewOnly,
          fill,
        });
        break;
      case FormType.consent:
        setShowConsentForm({
          visible,
          viewOnly,
          fill,
        });
        break;
      default:
        break;
    }
  };

  const {
    isConsentDesignOutdated,
    isPhysicalHistoryDesignOutdated,
    isHistoryFormSubmitted,
    isConsentFormSubmitted,
    isHistoryFormLegacy,
    isConsentFormLegacy,
    consentSubmittedOnDate,
    historyPhysicalSubmittedOn,
    isPhysicalHistoryExpired,
    isConsentExpired,
  } = useIntakeForms();

  useEffect(() => {
    if (openOnLoad && !isHistoryFormSubmitted) {
      setShowHistoryForm({
        visible: true,
        viewOnly: false,
      });
    } else if (openOnLoad && !isConsentFormSubmitted) {
      setShowConsentForm({
        visible: true,
        viewOnly: false,
      });
    }
  }, []);

  return (
    <>
      <List
        className={`intakeFormsList ${variant === "minimal" ? "minimal" : ""}`}
        itemLayout="vertical"
        size="large"
        dataSource={DUMMY_INTAKEFORMS}
        renderItem={(intakeForm, index) => (
          <List.Item key={index}>
            <IntakeFormCard
              key={index}
              type={intakeForm.type}
              title={intakeForm.title}
              date={
                intakeForm?.type === FormType.historyOrPhysical
                  ? historyPhysicalSubmittedOn
                  : consentSubmittedOnDate
              }
              isLegacy={
                intakeForm?.type === FormType.historyOrPhysical
                  ? isHistoryFormLegacy
                  : isConsentFormLegacy
              }
              isOutdated={
                intakeForm?.type === FormType.historyOrPhysical
                  ? isPhysicalHistoryDesignOutdated
                  : isConsentDesignOutdated
              }
              isExpired={
                intakeForm.type === FormType.historyOrPhysical
                  ? isPhysicalHistoryExpired
                  : isConsentExpired
              }
              isFilled={
                intakeForm.type === FormType.historyOrPhysical
                  ? isHistoryFormSubmitted || isHistoryFormLegacy
                  : isConsentFormSubmitted || isConsentFormLegacy
              }
              modalVisibilityHander={modalVisibilityHander}
              variant={variant}
            />
          </List.Item>
        )}
      />

      <HistoryIntakeForm
        isOutdated={
          isPhysicalHistoryDesignOutdated ||
          isPhysicalHistoryExpired ||
          showHistoryForm?.fill
        }
        viewOnly={showHistoryForm?.viewOnly}
        disabled={showHistoryForm?.viewOnly}
        visible={
          showHistoryForm?.visible &&
          (!isHistoryFormLegacy || showHistoryForm?.fill)
        }
        onSubmit={() => {
          setShowHistoryForm(false);
          if (openOnLoad && !isConsentFormSubmitted) {
            setShowConsentForm({
              visible: true,
              viewOnly: false,
            });
          }
        }}
        onClose={() => setShowHistoryForm(false)}
      />
      <ConsentForm
        isOutdated={
          isConsentDesignOutdated || isConsentExpired || showConsentForm?.fill
        }
        viewOnly={showConsentForm?.viewOnly}
        disabled={showConsentForm?.viewOnly}
        visible={
          showConsentForm?.visible &&
          (!isConsentFormLegacy || showConsentForm?.fill)
        }
        onSubmit={() => setShowConsentForm(false)}
        onClose={() => setShowConsentForm(false)}
      />
      <LegacyHistoryIntakeForm
        visible={
          showHistoryForm?.visible &&
          isHistoryFormLegacy &&
          !showHistoryForm?.fill
        }
        onSubmit={() => {
          setShowHistoryForm(false);
          if (openOnLoad && !isConsentFormSubmitted) {
            setShowConsentForm(true);
          }
        }}
        onClose={() => setShowHistoryForm(false)}
      />
      <LegacyConsentForm
        visible={
          showConsentForm?.visible &&
          isConsentFormLegacy &&
          !showConsentForm?.fill
        }
        onSubmit={() => setShowConsentForm(false)}
        onClose={() => setShowConsentForm(false)}
      />
    </>
  );
}

IntakeForms.propTypes = {
  variant: "full" | "minimal",
};

export default IntakeForms;
