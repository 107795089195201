import React, { useMemo, useState } from "react";
import PropTypes from "prop-types";

import moment from "moment-timezone";

import { Row, Col, Space, Button, Typography } from "antd";
import CheckCircleOutlined from "@ant-design/icons/CheckCircleOutlined";
import ExclamationCircleFilled from "@ant-design/icons/ExclamationCircleFilled";
import AuditList from "./AuditList";
const { Title, Text } = Typography;

function IntakeFormCard({
  title,
  date,
  isFilled,
  type,
  modalVisibilityHander,
  variant = "full",
  isExpired,
  isOutdated,
  isLegacy,
}) {
  const [showAudit, setShowAudit] = useState(false);

  const renderEditOrFill = () => {
    if (isOutdated || isExpired || isLegacy)
      return (
        <Button
          type="text"
          onClick={() => modalVisibilityHander(type, true, false, true)}
        >
          Fill
        </Button>
      );
    return (
      <Button
        type="text"
        onClick={() => modalVisibilityHander(type, true, false)}
      >
        Edit
      </Button>
    );
  };

  return (
    <Row
      gutter={[16, 16]}
      className={`intakeFormCardContainer ${
        variant === "minimal" ? "minimal" : ""
      }`}
      justify="space-between"
    >
      {variant === "full" && (
        <>
          <Col>
            <Title
              level={4}
              className="tradeGothic colorPrimary"
              style={{ margin: 0 }}
            >
              {title}
            </Title>
          </Col>
          <Col>
            {isFilled && (
              <Space size={16}>
                <Button
                  type="text"
                  onClick={() => modalVisibilityHander(type, true, true)}
                >
                  View
                </Button>
                {renderEditOrFill()}
                <Button type="text" onClick={() => setShowAudit(true)}>
                  History
                </Button>
              </Space>
            )}
          </Col>
        </>
      )}

      <Col span={24}>
        <Space>
          {isExpired ? (
            <ExclamationCircleFilled style={{ color: "#faad14" }} />
          ) : (
            <CheckCircleOutlined
              style={{ color: isFilled ? " #27CA2D" : "#dddddd" }}
            />
          )}

          <Title
            level={5}
            className="tradeGothic "
            style={{ marginBottom: 0, minWidth: 120 }}
          >
            {title}
          </Title>
          {isFilled &&
            (variant === "minimal" ? (
              renderEditOrFill()
            ) : (
              <Text className="tradeGothic " style={{ fontSize: 14 }}>
                {date ? (
                  isExpired ? (
                    <>Expired</>
                  ) : (
                    <>
                      {moment.utc(date).local().format("MMM DD, YYYY h:mm A")} (
                      {moment?.tz?.guess?.()})
                    </>
                  )
                ) : (
                  ""
                )}
              </Text>
            ))}
          {!isFilled && (
            <Button
              type="text"
              onClick={() => {
                modalVisibilityHander(type, true, false, true);
              }}
            >
              Fill Now
            </Button>
          )}
        </Space>
      </Col>
      <AuditList
        open={showAudit}
        onCancel={() => setShowAudit(false)}
        type={type}
        itemTitle={title}
      />
    </Row>
  );
}

IntakeFormCard.propTypes = {
  title: PropTypes.string,
  date: PropTypes.string | PropTypes.any, // prob datetime
  isFilled: PropTypes.bool,
  type: PropTypes.string,
  modalVisibilityHander: PropTypes.bool,
  variant: PropTypes.string,
};

export default IntakeFormCard;
