import { openNotificationWithIcon } from "utils/Notification";
import { request } from "./verb.services";

import {
  setIsSubmitting,
  setAddresses,
  addAddress,
  deleteAddress as deleteAddressAction,
  updateAddress as updateAddressAction,
  setPrimaryAddress as setPrimaryAddressAction,
} from "redux/actions/addresses.action";

import { setLoading } from "redux/actions/loading.actions";
import { getStateAndCountryFromZip } from "./locationCenter.services";

export const getAllAddresses = (showLoading = true) => {
  return (dispatch) => {
    if (showLoading) dispatch(setLoading(true, "Getting all addresses"));
    return request("Customer/Addresses", "get", null, true)
      .then((response) => {
        dispatch(setAddresses(response.data));
        return response.data;
      })
      .catch((e) => {
        openNotificationWithIcon(
          "error",
          "Error!",
          e?.response?.data?.message ||
            e?.response?.data?.Message ||
            "Network error has occured"
        );
      })
      .finally(() => {
        if (showLoading) dispatch(setLoading(false));
      });
  };
};

export const createAddress = (data) => {
  return async (dispatch) => {
    dispatch(setIsSubmitting(true));
    const { state, country, zipCode } = await getStateAndCountryFromZip(
      data.address
    );
    if (!state || !country || !zipCode) {
      dispatch(setIsSubmitting(false));
      openNotificationWithIcon(
        "error",
        "Error!",
        "Please enter a valid street address"
      );
      return;
    }
    return request(
      "Customer/Addresses",
      "post",
      {
        address: data.address,
        state: state,
        country: country,
        zipCode: zipCode,
      },
      {
        contentType: "application/json",
      }
    )
      .then((response) => {
        dispatch(getAllAddresses());
        openNotificationWithIcon(
          "success",
          "Success!",
          "Your address has been added successfully"
        );
        return { status: "success" };
      })
      .catch((e) => {
        openNotificationWithIcon(
          "error",
          "Error!",
          e?.response?.data?.message ||
            e?.response?.data?.Message ||
            "Network error has occured"
        );
      })
      .finally(() => dispatch(setIsSubmitting(false)));
  };
};

export const setPrimaryAddress = (addressId) => {
  return (dispatch) => {
    dispatch(setLoading(true, "Setting primary address"));
    return request(
      `Customer/Addresses/${addressId}/Primary`,
      "patch",
      null,
      true
    )
      .then((response) => {
        dispatch(setPrimaryAddressAction(addressId));
        openNotificationWithIcon(
          "success",
          "Success!",
          "Your primary address has been updated successfully"
        );
      })
      .catch((e) => {
        openNotificationWithIcon(
          "error",
          "Error!",
          e?.response?.data?.message ||
            e?.response?.data?.Message ||
            "Network error has occured"
        );
      })
      .finally(() => {
        dispatch(setLoading(false));
      });
  };
};

export const setAddressInstruction = (addressId, data) => {
  return (dispatch) => {
    dispatch(setIsSubmitting(true));
    return request(
      `Customer/Addresses/${addressId}/Instructions`,
      "patch",
      JSON.stringify(data.instructions),
      {
        contentType: "application/json",
      }
    )
      .then((response) => {
        dispatch(
          updateAddressAction({
            instructions: data.instructions,
            addressId: addressId,
          })
        );
        openNotificationWithIcon(
          "success",
          "Success!",
          "Instructions for the address have been updated successfully"
        );
        return { status: "success" };
      })
      .catch((e) => {
        openNotificationWithIcon(
          "error",
          "Error!",
          e?.response?.data?.message ||
            e?.response?.data?.Message ||
            "Network error has occured"
        );
      })
      .finally(() => {
        dispatch(setIsSubmitting(false));
      });
  };
};

export const updateAddress = (addressId, data) => {
  return async (dispatch) => {
    dispatch(setIsSubmitting(true));
    const { state, country, zipCode } = await getStateAndCountryFromZip(
      data.address
    );
    if (!state || !country || !zipCode) {
      dispatch(setIsSubmitting(false));
      openNotificationWithIcon(
        "error",
        "Error!",
        "Please enter a valid street address"
      );
      return;
    }
    return request(
      `Customer/Addresses/${addressId}`,
      "put",
      {
        address: data.address,
        state,
        country,
        zipCode,
      },
      true
    )
      .then((response) => {
        dispatch(
          updateAddressAction({
            address: data.address,
            addressId: addressId,
          })
        );
        openNotificationWithIcon(
          "success",
          "Success!",
          "Address has been updated successfully"
        );
        return { status: "success" };
      })
      .catch((e) => {
        openNotificationWithIcon(
          "error",
          "Error!",
          e?.response?.data?.message ||
            e?.response?.data?.Message ||
            "Network error has occured"
        );
      })
      .finally(() => {
        dispatch(setIsSubmitting(false));
      });
  };
};

export const deleteAddress = (id) => {
  return (dispatch) => {
    dispatch(setLoading(true));
    return request(`Customer/Addresses/${id}`, "delete", null, true)
      .then((response) => {
        dispatch(deleteAddressAction(id));
        openNotificationWithIcon(
          "success",
          "Success!",
          "Address removed successfully"
        );
        return { status: "success" };
      })
      .catch((e) => {
        openNotificationWithIcon(
          "error",
          "Error!",
          e?.response?.data?.message ||
            e?.response?.data?.Message ||
            "Network error has occured"
        );
      })
      .finally(() => dispatch(setLoading(false)));
  };
};

export const shareWithFriend = (email) => {
  return (dispatch) => {
    dispatch(setIsSubmitting(true));
    return request(
      `Customer/Refer?refreeEmailAddress=${email}`,
      "put",
      null,
      true
    )
      .then((response) => {
        openNotificationWithIcon(
          "success",
          "Success!",
          "Email has been sent successfully"
        );
        return { status: "success" };
      })
      .catch((e) => {
        openNotificationWithIcon(
          "error",
          "Error!",
          e?.response?.data?.message ||
            e?.response?.data?.Message ||
            "Network error has occured"
        );
      })
      .finally(() => {
        dispatch(setIsSubmitting(false));
      });
  };
};
