import { actions } from "../actions/locationCenter.action";

const INITIAL_STATE = {
  centers: [],
  operationTypes: [],
  products: [],
  addOns: [],
  isFetchingCenters: false,
  isFetchingOperationTypes: false,
  isFetchingProducts: false,
  isFetchingAddons: false,
};

const reducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case actions.SET_CENTERS:
      if (action.clearCascadingSelectors === true)
        return {
          ...state,
          centers: action.data,
        };
      return { ...state, centers: action.data };
    case actions.SET_OPERATIONTYPES:
      if (action.clearCascadingSelectors === true)
        return {
          ...state,
          operationTypes: action.data,
          products: [],
          addOns: [],
        };
      return { ...state, operationTypes: action.data };
    case actions.SET_PRODUCTS:
      if (action.clearCascadingSelectors === true)
        return {
          ...state,
          products: action.data,
          addOns: [],
        };
      return { ...state, products: action.data };
    case actions.SET_ADDONS:
      return { ...state, addOns: action.data };
    case actions.SET_FETCHING:
      return { ...state, [action.fetchingType]: action.status };
    case actions.CLEAR_ALL_DATA:
      return {
        ...state,
        centers: [],
        operationTypes: [],
        products: [],
        addOns: [],
      };
    default:
      return state;
  }
};
export default reducer;
