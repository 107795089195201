import React, { useEffect, useLayoutEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import Modal from "components/Modal/Modal";
import { Button } from "components/Buttons/Buttons";
import moment from "moment";
import {
  postForm,
  getForm,
  getFormByType,
  putForm,
} from "services/intakeForm.service";
import Signature, { useSignature } from "components/Signature/Signature";

import { openNotificationWithIcon } from "utils/Notification";

import { Form, Skeleton, Typography, Button as AntdButton } from "antd";
import {
  FormInputArea,
  FormCheckboxGroup,
  FormSlider,
  FormRadioGroup,
} from "components/FormItems/FlatFormItems";
import { setLoading } from "redux/actions/loading.actions";
import FormParsedUI from "components/FormParseUI/FormParseUi.index";
const { Text } = Typography;

const FORM_NAME = "PhysicalHistory";
const DATE_INDICATOR = "{{DATE}}";

function HistoryIntakeForm({
  isOutdated,
  viewOnly,
  visible,
  onSubmit,
  onClose,
  initialValues = {},
  disabled,
}) {
  const [form] = Form.useForm();

  const dispatch = useDispatch();
  const { user, intakeForms } = useSelector((state) => {
    return { user: state.user, intakeForms: state.intakeForms };
  });
  const { profiles } = user;
  const [signature, getSignatureValue, clearSignatureValue, setSignatureValue] =
    useSignature("historyForm", form);

  const { isSubmitting } = intakeForms;
  const isFormSubmitted =
    profiles?.[0]?.physicalHistoryId !== undefined &&
    profiles?.[0]?.physicalHistoryId !== null &&
    profiles?.[0]?.physicalHistoryId !== 0;
  const [formInformation, setformInformation] = useState();
  const [error, setError] = useState(false);

  const loadData = (renderDesign = true) => {
    // get data
    if ((isFormSubmitted && !isOutdated) || viewOnly) {
      dispatch(setLoading(true));
      dispatch(getForm(profiles[0].profileId, FORM_NAME))
        .then((res) => {
          if (res?.status === "success") {
            setError(false);

            const data = res?.data;
            const {
              formId,
              formVersionId,
              formValueId,
              designJSON,
              valueJSON,
            } = data;

            const json = JSON.parse(designJSON || "{}");
            const values = JSON.parse(valueJSON || "{}");
            let customerSignature = "";
            let transformedValues = {};
            const keys = Object.keys(values);
            for (let i = 0; i < keys.length; i++) {
              const key = keys[i];
              const value = values[key];
              if (value?.startsWith?.(DATE_INDICATOR)) {
                const dateString = value.split(DATE_INDICATOR).pop();
                const date = moment(dateString);
                transformedValues[key] = date;
              } else transformedValues[key] = value;
              if (key === "customerSignature") customerSignature = value;
            }

            setformInformation({
              formId,
              formVersionId,
              formValueId,
              design: json,
              values,
            });
            if (setSignatureValue && disabled) {
              setSignatureValue(customerSignature);
            }
            form.setFieldsValue(transformedValues);
          } else {
            setError(true);
          }
        })
        .catch(() => {
          setError(true);
        })
        .finally(() => dispatch(setLoading(false)));
    } else {
      dispatch(getFormByType(2, true))
        .then((res) => {
          if (res?.status === "success") {
            setError(false);

            const data = res?.data;
            const formData = data?.formVersions?.[0];
            const { formId, formJSON, formVersionId, versionNo } = formData;

            const design = JSON.parse(formJSON || "{}");
            setformInformation({
              formId,
              formVersionId,
              versionNo,
              design,
              values: null,
            });
          } else {
            setError(true);
          }
        })
        .catch(() => {
          setError(true);
        })
        .finally(() => dispatch(setLoading(false)));
    }
  };

  useEffect(() => {
    if (visible) loadData();
    else {
      form.resetFields();
      setformInformation();
      clearSignatureValue();
    }
  }, [profiles?.profileId, visible]);

  const getSubmitableData = (data) => {
    const transformedValues = {};
    const keys = Object.keys(data);
    for (let i = 0; i < keys.length; i++) {
      const key = keys[i];
      const value = data[key];
      if (moment.isMoment(value))
        transformedValues[key] = DATE_INDICATOR + value?.toString();
      else transformedValues[key] = value;
    }
    return transformedValues;
  };

  const handleSubmit = (values) => {
    const signature = getSignatureValue();
    if (signature === "") {
      openNotificationWithIcon(
        "error",
        "Error!",
        "Please sign the consent form"
      );
      return;
    }
    dispatch(setLoading(true));
    values.customerSignature = signature;

    const { formId, formVersionId, formValueId } = formInformation;

    const transformedValues = getSubmitableData(values);
    if (getSignatureValue)
      transformedValues.customerSignature = getSignatureValue();

    // we no longer need put flow
    if (isFormSubmitted && !isOutdated && false) {
      // put
      dispatch(
        putForm(
          formId,
          formVersionId,
          formValueId,
          transformedValues,
          "physicalHistory"
        )
      ).then(() => {
        if (onSubmit) onSubmit();
      });
    } else {
      // post
      dispatch(
        postForm(formId, formVersionId, transformedValues, "physicalHistory")
      ).then(() => {
        if (onSubmit) onSubmit();
      });
    }
  };

  // useEffect(() => {
  //   if (visible) {
  //     setTimeout(() => {
  //       const form = document.querySelector(".historyForm form");
  //       console.log("Form", form.clientHeight);
  //       form?.scrollIntoView({
  //         top: 0,
  //         left: 0,
  //         behavior: "smooth",
  //       });
  //     }, 500);
  //   }
  // }, [visible]);

  return (
    <Modal
      destroyOnClose={false}
      className="intakeForm historyForm "
      title="History/Physical and Consents"
      visible={visible}
      width={812}
      onCancel={onClose}
      footer={[]}
    >
      {error && (
        <div style={{ marginBottom: 16 }}>
          <Text>
            Error has occured while loading form.
            <AntdButton
              type="text"
              onClick={() => {
                loadData();
              }}
              disabled={false}
            >
              Retry
            </AntdButton>
          </Text>
        </div>
      )}
      <Form
        disabled={disabled || error}
        requiredMark={false}
        form={form}
        layout="vertical"
        className="intakeFormWrapper "
        onFinishFailed={({ values, errorFields, outOfDate }) => {
          form.scrollToField(errorFields[0].name, {
            scrollMode: "if-needed",
            block: "center",
            behavior: "smooth",
          });
        }}
        onFinish={handleSubmit}
      >
        {error ? (
          <></>
        ) : formInformation?.design ? (
          <FormParsedUI
            disabled={disabled}
            data={formInformation?.design}
            form={form}
            signature={signature}
            getSignatureValue={getSignatureValue}
            clearSignatureValue={clearSignatureValue}
            setSignatureValue={setSignatureValue}
            isSignatureDisabled={disabled}
          />
        ) : (
          <Skeleton active />
        )}
        <Button
          rounded={true}
          type="submit"
          style={{ paddingLeft: 60, paddingRight: 60 }}
          loading={isSubmitting}
        >
          Save
        </Button>
      </Form>
    </Modal>
  );
}

HistoryIntakeForm.propTypes = {};

export default HistoryIntakeForm;
