import React, { useState, useEffect, useMemo, useCallback } from "react";
import PropTypes from "prop-types";
import { ClockCircleOutlined, ShoppingCartOutlined } from "@ant-design/icons";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";

import { clearTimer, setTimer } from "services/timer.service";

import "./Timer.scss";
import { Space, Tooltip, Button as AntdButton } from "antd";
import Modal from "components/Modal/Modal";
import { Button } from "components/Buttons/Buttons";

export const useTimer = () => {
  const { timer } = useSelector((state) => state.timer);
  const misc = useSelector((state) => state.misc.miscSettings);
  const [countdown, setCountdown] = useState(undefined);
  const [showTimeWarning, setShowTimeWarning] = useState(false);
  const [showWarningOn, setShowWarningOn] = useState(300);

  const dispatch = useDispatch();

  useEffect(() => {
    const timeAdded = misc.find((res) => {
      if (res.key == "BookingSessionTimeout") {
        return res;
      }
    });
    setShowWarningOn((timeAdded?.value / 3) * 60);
  }, [misc]);

  useEffect(() => {
    let interval = undefined;
    if (timer) {
      interval = setInterval(() => {
        const duration = moment.duration(timer.diff(moment()));
        const secondsRemaining = Math.floor(duration.asSeconds());
        if (secondsRemaining >= 0) {
          if (secondsRemaining === Math.round(showWarningOn)) {
            setShowTimeWarning(true);
          }
          setCountdown({
            minutes: `${duration.minutes()}`,
            seconds: `${duration.seconds()}`,
          });
        } else if (secondsRemaining === 300) {
        } else {
          setCountdown(undefined);
          clearInterval(interval);
          dispatch(clearTimer());
        }
      }, 1000);
    } else {
      setCountdown(undefined);
    }

    return () => {
      if (interval) clearInterval(interval);
    };
  }, [timer]);

  return [countdown, showTimeWarning, setShowTimeWarning];
};

function Timer(props) {
  const [countdown, showTimeWarning, setShowTimeWarning] = useTimer();
  const dispatch = useDispatch();

  const timer = useSelector((state) => state.timer.timer);
  const miscSettings = useSelector((state) => state.misc.miscSettings);

  const time = useMemo(() => {
    let mtimer = {
      value: 0,
    };
    const onOff = miscSettings.find((res) => {
      if (res.key == "BookingSessionBit") {
        return res;
      }
    });

    if (onOff?.value == "1") {
      mtimer = miscSettings.find((res) => {
        if (res.key == "BookingSessionTimeout") {
          return res;
        }
      });
      // dispatch(setTimer(moment().add(parseInt(time.value), "minutes")));
    }
    return mtimer;
  }, [miscSettings]);

  if (!countdown) return <></>;

  return (
    <>
      <Tooltip
        // align={{ offset: [0, 31] }}
        overlayInnerStyle={{ background: "#161c32" }}
        title={`Timeslot will be reserved for ${time?.value} minutes`}
        placement='bottom'
      >
        <div
          style={{
            color: "#fff",
            fontSize: 16,
            display: "flex",
            alignItems: "center",
            cursor: "pointer",
          }}
        >
          <ShoppingCartOutlined style={{ fontSize: 18 }} />
          {/* <span
      style={{ lineHeight: 1, fontFamily: "tradeGothic", fontSize: 15 }}
    >
      (1)
    </span> */}
          &nbsp;&nbsp;
          <span
            style={{ lineHeight: 1, fontFamily: "tradeGothic", fontSize: 15 }}
          >
            Timeslot will be reserved for{" "}
            {countdown?.minutes?.padStart(2, "0") ?? "00"}:
            {countdown?.seconds?.padStart(2, "0") ?? "00"} minutes
          </span>
        </div>
      </Tooltip>

      <Modal
        className='boostPopup'
        title='Time is Running Out'
        headerBorder={true}
        visible={showTimeWarning}
        onCancel={() => setShowTimeWarning(false)}
        // onOk={handleSave}
        // okButtonProps={{
        //   disabled: !initialValue || initialValue?.length <= 0,
        // }}
        footer={
          <Space style={{ display: "flex", justifyContent: "space-between" }}>
            <AntdButton
              type='text'
              style={{ paddingTop: 8 }}
              onClick={() => setShowTimeWarning(false)}
              // disabled={initialValue?.length}
            >
              No
            </AntdButton>
            <Space size={12}>
              <Button
                type='text'
                variant='outlined'
                style={{ fontFamily: "tradeGothic" }}
                onClick={() => {
                  dispatch(setTimer(moment(timer).add(5, "minutes")));
                  setShowTimeWarning(false);
                }}
                // onClick={handleSave}
              >
                5 Minutes
                <ClockCircleOutlined />
              </Button>
              <Button
                type='text'
                onClick={() => {
                  dispatch(setTimer(moment(timer).add(10, "minutes")));
                  setShowTimeWarning(false);
                }}
                // onClick={handleSave}
              >
                10 Minutes <ClockCircleOutlined />
              </Button>
            </Space>
          </Space>
        }
      >
        <div style={{ height: 56 }}>
          Do you need more time to book your appointment?
        </div>
      </Modal>
    </>
  );
}

Timer.propTypes = {};

export default Timer;
