import { openNotificationWithIcon } from "utils/Notification";
import { request } from "./verb.services";
import { getItem, setItem, removeItem } from "../utils/storage";

import {
  setBookings,
  setCurrentBooking,
  addBooking,
  updateBooking as updateBookingAction,
  deleteBooking,
} from "redux/actions/booking.action";
import { setLoading } from "redux/actions/loading.actions";
import { clearTimer } from "./timer.service";
import {
  setFacilities,
  setFetchingFacilities,
} from "redux/actions/facilities.action";
import { getRewards } from "./rewards.service";
import { GIFTCARD_CONFIG_KEY } from "utils/constants";
import { injectRewards } from "utils/common";

export const getAllBookings = (status = "pending") => {
  return (dispatch) => {
    dispatch(setLoading(true));
    return request(`Orders/${status}`, "get", null, true)
      .then((response) => {
        dispatch(setBookings(response.data));
        return response.data;
      })
      .catch((e) => {
        openNotificationWithIcon(
          "error",
          "Error!",
          e?.response?.data?.message ||
            e?.response?.data?.Message ||
            e?.response?.data?.[0] ||
            "Network error has occured"
        );
      })
      .finally(() => dispatch(setLoading(false)));
  };
};

export const getBookingsHistory = () => {
  return (dispatch) => {
    dispatch(setLoading(true));
    return request(`Orders/History`, "get", null, true)
      .then((response) => {
        dispatch(setBookings(response.data));
        return response.data;
      })
      .catch((e) => {
        openNotificationWithIcon(
          "error",
          "Error!",
          e?.response?.data?.message ||
            e?.response?.data?.Message ||
            e?.response?.data?.[0] ||
            "Network error has occured"
        );
      })
      .finally(() => dispatch(setLoading(false)));
  };
};

export const createBooking = (data, user = {}) => {
  let postData = { ...data };
  delete postData.appointments;
  // TODO: Instead of user send profile Info
  const { email, firstName, lastName } = user;

  // covert array of adOn objects to array of adOnIds
  // for (let i = 0; i < Math.min(1, data.lineItems?.length); i++) {
  // postData.lineItems = data.lineItems
  //   ?.filter((item, index) => index < 1)
  //   .map((lineItem, index) => {
  //     // for the first line item, send the current profile info
  //     if (index === 0) {
  //       return {
  //         ...lineItem,
  //         email,
  //         firstName,
  //         lastName,
  //         addOns: lineItem.addOns.map((adOn) => adOn.productId),
  //       };
  //     }
  //     return {
  //       ...lineItem,
  //       addOns: lineItem.addOns.map((adOn) => {
  //         return adOn.productId;
  //       }),
  //     };
  //   });
  // }
  const isMultipleBooking = postData?.guestBookings?.length > 0;
  const apiUrl = isMultipleBooking ? "Orders/MultipleBookings" : "Orders";
  return async (dispatch) => {
    await dispatch(setLoading(true));
    const rewards = await dispatch(getRewards());
    if (rewards?.status === "success") {
      injectRewards(postData, rewards?.data ?? [], postData?.facilityId); // mutates the object with latest rewards
    }
    return request(apiUrl, "post", postData, true, null, null, {
      retry: 3,
      retryDelay: 3000,
    })
      .then((response) => {
        const responseData = response.data;
        let pendingOrder = getItem("pendingOrder");
        // // pendingOrder = { ...pendingOrder, ...data };
        setItem("pendingOrder", null);
        // update redux
        if (responseData?.orderId) {
          dispatch(getRewards());
          dispatch(getOrderById(responseData?.orderId, isMultipleBooking));
        } else dispatch(setCurrentBooking(responseData));
        openNotificationWithIcon(
          "success",
          "Booking created",
          "Your booking has been created. You can go to account/bookings page for more details."
        );
        dispatch(clearTimer());
        // dispatch(updatePendingOrder(newOrder))
        return { status: "success", data: responseData };
      })
      .catch((e) => {
        openNotificationWithIcon(
          "error",
          "Error!",
          e?.response?.data?.[0] ||
            e?.response?.data?.message ||
            e?.response?.data?.Message ||
            e?.response?.message ||
            e?.message ||
            "Something went wrong"
        );
      })
      .finally(() => {
        dispatch(setLoading(false));
      });
  };
};

export const updateBooking = (data) => {
  return (dispatch) => {
    return request("Bookings", "put", data, true)
      .then((response) => {
        dispatch(updateBookingAction(data));
      })
      .catch((e) => {
        openNotificationWithIcon(
          "error",
          "Error!",
          e?.response?.data?.message ||
            e?.response?.data?.Message ||
            e?.response?.data?.[0] ||
            "Network error has occured"
        );
      });
  };
};

export const deleteLineItem = (orderID, lineItemId, newOrder) => {
  return (dispatch) => {
    return request(
      `Orders/${orderID}/LineItems/${lineItemId}`,
      "delete",
      null,
      true
    )
      .then(({ data }) => {
        if (newOrder) dispatch(setCurrentBooking(newOrder));
      })
      .catch((e) => {
        openNotificationWithIcon(
          "error",
          "Error!",
          e?.response?.data?.message ||
            e?.response?.data?.Message ||
            e?.response?.data?.[0] ||
            "Network error has occured"
        );
      });
  };
};

export const getOrderById = (orderID, isMultipleBooking = false) => {
  return (dispatch) => {
    dispatch(setLoading(true));
    return request(
      `Orders/${orderID}/${isMultipleBooking ? "MultipleBookings" : ""}`,
      "get",
      null,
      true,
      null,
      null,
      {
        retry: 3,
      }
    )
      .then(({ data }) => {
        dispatch(setCurrentBooking(data));

        return data;
        // dispatch(updateBookingAction(data));
      })
      .catch((e) => {
        openNotificationWithIcon(
          "error",
          "Error!",
          e?.response?.data?.message ||
            e?.response?.data?.Message ||
            e?.response?.data?.[0] ||
            "Network error has occured"
        );
      });
  };
};
export const createAddOn = (
  data,
  orderId,
  lineItemId,
  updateBooking = true
) => {
  return (dispatch) => {
    return request(
      `Orders/${orderId}/LineItems/${lineItemId}`,
      "post",
      data,
      true
    )
      .then(({ data }) => {
        // if(updateBooking)
        // dispatch(setCurrentBooking(data));
      })
      .catch((e) => {
        openNotificationWithIcon(
          "error",
          "Error!",
          e?.response?.data?.message ||
            e?.response?.data?.Message ||
            e?.response?.data?.[0] ||
            "Network error has occured"
        );
      });
  };
};

export const syncPendingOrders = () => {
  return (dispatch) => {
    return new Promise((_) => {
      const pendingOrder = getItem("pendingOrder");
      dispatch(setCurrentBooking(pendingOrder));
    });
  };
};

export const addLineItme = (orderID, data) => {
  return (dispatch) => {
    return request(`Orders/${orderID}/LineItems/`, "post", data, true)
      .then(({ data }) => {
        if (data) {
          dispatch(setCurrentBooking(data));
        }
      })
      .catch((e) => {
        openNotificationWithIcon(
          "error",
          "Error!",
          e?.response?.data?.message ||
            e?.response?.data?.Message ||
            e?.response?.data?.[0] ||
            "Network error has occured"
        );
      });
  };
};

export const applyVoucher = (orderId, voucherCode) => {
  return (dispatch) => {
    return request(
      `Orders/${orderId}/Voucher/${voucherCode}`,
      "put",
      null,
      true
    )
      .then(({ data }) => {
        if (data) {
          dispatch(setCurrentBooking(data));
        }
      })
      .catch((e) => {
        openNotificationWithIcon(
          "error",
          "Error!",
          e?.response?.data?.message ||
            e?.response?.data?.Message ||
            e?.response?.data?.[0] ||
            "Invalid Code"
        );
      });
  };
};
export const removeVoucher = (orderId) => {
  return (dispatch) => {
    return request(`Orders/${orderId}/Voucher`, "delete", null, true)
      .then(({ data }) => {
        if (data) {
          dispatch(setCurrentBooking(data));
        }
      })
      .catch((e) => {
        openNotificationWithIcon(
          "error",
          "Error!",
          e?.response?.data?.message ||
            e?.response?.data?.Message ||
            e?.response?.data?.[0] ||
            "Network error has occured"
        );
      });
  };
};

export const cancelOrder = (orderId) => {
  return (dispatch) => {
    return request(`Orders/${orderId}/Cancel`, "delete", null, true)
      .then(({ data }) => {
        dispatch(deleteBooking(orderId));
        openNotificationWithIcon(
          "success",
          "Order Cancelled!",
          "Your order has been cancelled."
        );
        return { status: "success" };
      })
      .catch((e) => {
        openNotificationWithIcon(
          "error",
          "Error!",
          e?.response?.data?.message ||
            e?.response?.data?.Message ||
            e?.response?.data?.[0] ||
            "Network error has occured"
        );
      });
  };
};

export const getClinicFacilities = () => {
  return (dispatch) => {
    dispatch(setFetchingFacilities(true));
    return request(`Facilities/Clinics`, "get", null, true)
      .then((response) => {
        dispatch(setFacilities(response.data));
        return response.data;
      })
      .catch((e) => {
        openNotificationWithIcon(
          "error",
          "Error!",
          e?.response?.data?.message ||
            e?.response?.data?.Message ||
            e?.response?.data?.[0] ||
            "Network error has occured"
        );
      })
      .finally(() => dispatch(setFetchingFacilities(false)));
  };
};

export const deleteRewards = (order, lineItemId, callback) => {
  return (dispatch) => {
    dispatch(setLoading(true));
    return request(
      `Orders/${order?.orderId}/LineItems/${lineItemId}/Rewards`,
      "delete",
      null,
      true
    )
      .then(({ data }) => {
        const isMultipleBooking =
          order?.guestBookings?.length > 0 || order?.appointments?.length > 0;
        callback?.();
        openNotificationWithIcon(
          "success",
          "Success",
          "Reward removed successfully"
        );
        Promise.all([
          dispatch(getOrderById(order?.orderId, isMultipleBooking)),
          dispatch(getRewards()),
        ]).finally(() => {
          dispatch(setLoading(false));
        });
      })
      .catch((e) => {
        dispatch(setLoading(false));
        openNotificationWithIcon(
          "error",
          "Error!",
          e?.response?.data?.message ||
            e?.response?.data?.Message ||
            e?.response?.data?.[0] ||
            "Invalid Code"
        );
      });
  };
};

export const applyReward = (order, lineItemId, rewardId, callback) => {
  return (dispatch) => {
    dispatch(setLoading(true));
    return request(
      `Orders/${order?.orderId}/LineItems/${lineItemId}/Rewards/${rewardId}`,
      "put",
      null,
      true
    )
      .then(({ data }) => {
        const isMultipleBooking =
          order?.guestBookings?.length > 0 || order?.appointments?.length > 0;
        return Promise.all([
          dispatch(getOrderById(order?.orderId, isMultipleBooking)),
          dispatch(getRewards()),
        ])
          .then(() => {
            callback?.();
          })
          .finally(() => {
            dispatch(setLoading(false));
          });
      })
      .catch((e) => {
        dispatch(setLoading(false));
        openNotificationWithIcon(
          "error",
          "Error!",
          e?.response?.data?.message ||
            e?.response?.data?.Message ||
            e?.response?.data?.[0] ||
            "Invalid Code"
        );
      });
  };
};

export const cancelMembership = (customerProgramId, callback) => {
  return (dispatch) => {
    dispatch(setLoading(true));
    return request(
      `Orders/Programs/Cancel?customerProgramId=${customerProgramId}`,
      "patch",
      null,
      true
    )
      .then(({ data }) => {
        openNotificationWithIcon(
          "success",
          "Success!",
          "Membership cancelled successfully"
        );
        return {
          status: "success",
        };
      })
      .catch((e) => {
        dispatch(setLoading(false));
        openNotificationWithIcon(
          "error",
          "Error!",
          e?.response?.data?.message ||
            e?.response?.data?.Message ||
            e?.response?.data?.[0] ||
            "Network error has occured"
        );
      })
      .finally(() => {
        dispatch(setLoading(false));
      });
  };
};

// /api/
export const validateGuestAccount = (data) => {
  return (dispatch) => {
    return request(
      `Customer/Count?firstName=${data.firstName}&lastName=${data.lastName}&emailAddress=${data.emailAddress}`,
      "get",
      null,
      true
    )
      .then((res) => {
        return {
          status: "success",
          count: res.data,
        };
      })
      .catch((e) => {
        openNotificationWithIcon(
          "error",
          "Error!",
          e?.response?.data?.message ||
            e?.response?.data?.Message ||
            e?.response?.data?.[0] ||
            "Network error has occured"
        );
      });
  };
};

export const checkIfUsernameExists = (username) => {
  return (dispatch) => {
    dispatch(setLoading(true));
    return request(`Customer/Exists?username=${username}`, "get", null, true)
      .then((res) => {
        return {
          isValid: true,
        };
      })
      .catch((e) => {
        openNotificationWithIcon(
          "error",
          "Error!",
          e?.response?.data?.message ||
            e?.response?.data?.Message ||
            e?.response?.data?.[0] ||
            "Username not found."
        );
      })
      .finally(() => {
        dispatch(setLoading(false));
      });
  };
};

export const purchaseGiftCard = (data) => {
  return (dispatch) => {
    dispatch(setLoading(true));
    return request(`Orders/GiftCard`, "post", data, true)
      .then((res) => {
        openNotificationWithIcon(
          "success",
          "Gift Card Purchased",
          "Your gift card has been purchased successfully."
        );
        return {
          status: "success",
          data: res.data,
        };
      })
      .catch((e) => {
        openNotificationWithIcon(
          "error",
          "Error!",
          e?.response?.data?.message ||
            e?.response?.data?.Message ||
            e?.response?.data?.[0] ||
            "Network error has occured"
        );
        return {
          status: "error",
          message:
            e?.response?.data?.message ||
            e?.response?.data?.Message ||
            e?.response?.data?.[0] ||
            "Network error has occured",
        };
      })
      .finally(() => {
        dispatch(setLoading(false));
      });
  };
};

export const redeemGiftCard = (data) => {
  return (dispatch) => {
    dispatch(setLoading(true));
    return request(
      `GiftCards/Redeem?redeemCode=${data.redeemCode}`,
      "post",
      data,
      true
    )
      .then((res) => {
        openNotificationWithIcon(
          "success",
          "Gift Card Redeemed",
          "Your gift card has been redeemed successfully."
        );
        return {
          status: "success",
          data: res.data,
        };
      })
      .catch((e) => {
        openNotificationWithIcon(
          "error",
          "Error!",
          e?.response?.data?.[0] ||
            e?.response?.data?.message ||
            e?.response?.data?.Message ||
            e?.response?.message ||
            e?.message ||
            "Something went wrong"
        );
        return {
          status: "error",
          message:
            e?.response?.data?.message ||
            e?.response?.data?.Message ||
            "Network error has occured",
        };
      })
      .finally(() => {
        dispatch(setLoading(false));
      });
  };
};

// /api/Orders/{orderId}/ChargePreference

export const changeWalletPreferance = (order, useWallet, callback) => {
  return (dispatch) => {
    dispatch(setLoading(true));
    return request(
      `Orders/${order?.orderId}/ChargePreference?useWallet=${useWallet}`,
      "patch",
      null,
      true
    )
      .then(({ data }) => {
        const isMultipleBooking =
          order?.guestBookings?.length > 0 || order?.appointments?.length > 0;
        return Promise.all([
          dispatch(getOrderById(order?.orderId, isMultipleBooking)),
          dispatch(getRewards()),
        ])
          .then(() => {
            callback?.();
          })
          .finally(() => {
            dispatch(setLoading(false));
          });
      })
      .catch((e) => {
        dispatch(setLoading(false));
        openNotificationWithIcon(
          "error",
          "Error!",
          e?.response?.data?.message ||
            e?.response?.data?.Message ||
            e?.response?.data?.[0] ||
            "Network error has occured"
        );
      });
  };
};

export const getGiftCardDesign = () => {
  return (dispatch) => {
    return request(
      `Configurations/${GIFTCARD_CONFIG_KEY}`,
      "get",
      null,
      true,
      null
    )
      .then((res) => {
        console.log("res", res);
        let designValues = {};
        if (res?.data) {
          designValues = JSON.parse(decodeURI(res?.data?.value || "{}"));
        }
        return { status: "success", data: designValues };
      })
      .catch((e) => {
        openNotificationWithIcon(
          "error",
          "Error!",
          e?.response?.data?.[0] ||
            e?.response?.data?.message ||
            e?.response?.data?.Message ||
            e?.response?.message ||
            e?.message ||
            "Something went wrong"
        );
      });
  };
};

export const getAllGiftCards = () => {
  return (dispatch) => {
    return request(`MyGiftCards`, "get", null, true)
      .then((response) => {
        return {
          status: "success",
          data: response.data,
        };
      })
      .catch((e) => {
        openNotificationWithIcon(
          "error",
          "Error!",
          e?.response?.data?.[0] ||
            e?.response?.data?.message ||
            e?.response?.data?.Message ||
            e?.response?.message ||
            e?.message ||
            "Something went wrong"
        );
      });
  };
};

export const getGiftCardById = (giftCardId, callback) => {
  return (dispatch) => {
    dispatch(setLoading(true));
    return request(`GiftCards/${giftCardId}`, "get", null, true)
      .then((res) => {
        return { status: "success", data: res.data };
      })
      .catch((e) => {
        openNotificationWithIcon(
          "error",
          "Error!",
          e?.response?.data?.[0] ||
            e?.response?.data?.message ||
            e?.response?.data?.Message ||
            e?.response?.message ||
            e?.message ||
            "Something went wrong"
        );
      })
      .finally(() => {
        dispatch(setLoading(false));
      });
  };
};

export const updateGiftCard = (giftCardId, data) => {
  return (dispatch) => {
    dispatch(setLoading(true));
    return request(`GiftCards/${giftCardId}`, "patch", data, true)
      .then((res) => {
        openNotificationWithIcon(
          "success",
          "Gift Card Updated",
          "Your gift card information has been updated successfully."
        );
        return {
          status: "success",
          data: res.data,
        };
      })
      .catch((e) => {
        openNotificationWithIcon(
          "error",
          "Error!",
          e?.response?.data?.message ||
            e?.response?.data?.Message ||
            e?.response?.data?.[0] ||
            "Network error has occured"
        );
        return {
          status: "error",
          message:
            e?.response?.data?.message ||
            e?.response?.data?.Message ||
            e?.response?.data?.[0] ||
            "Network error has occured",
        };
      })
      .finally(() => {
        dispatch(setLoading(false));
      });
  };
};

export const purchaseAnonymousGiftCard = (data) => {
  return (dispatch) => {
    dispatch(setLoading(true));
    return request(`Orders/GiftCard/Anonymous`, "post", data, true)
      .then((res) => {
        openNotificationWithIcon(
          "success",
          "Gift Card Purchased",
          "Your gift card has been purchased successfully."
        );
        return {
          status: "success",
          data: res.data,
        };
      })
      .catch((e) => {
        openNotificationWithIcon(
          "error",
          "Error!",
          e?.response?.data?.message ||
            e?.response?.data?.Message ||
            "Network error has occured"
        );
        return {
          status: "error",
          message:
            e?.response?.data?.message ||
            e?.response?.data?.Message ||
            e?.response?.data?.[0] ||
            "Network error has occured",
        };
      })
      .finally(() => {
        dispatch(setLoading(false));
      });
  };
};
