import React from "react";
import PropTypes, { any } from "prop-types";
import { useDispatch, useSelector } from "react-redux";
import Modal from "components/Modal/Modal";

import { Radio, Input, Form, Typography } from "antd";
import {
  FormButton,
  FormCheckbox,
  FormInput,
  FormPhoneNumber,
} from "components/FormItems/FlatFormItems";

import { openNotificationWithIcon } from "utils/Notification";
import {
  createFamilyMember,
  updateFamilyMember,
} from "services/family.service";
function FamilyMemberModal({
  visible,
  handleSave,
  onCancel,
  type,
  title,
  data,
}) {
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const { submitting, family } = useSelector((state) => {
    return {
      submitting: state.loading.submitting,
      family: state.family.family,
    };
  });

  return (
    <Modal
      destroyOnClose={true}
      className="giftCardModal"
      width="500px"
      title={title}
      footer={null}
      onCancel={() => onCancel()}
      visible={visible}
      centered={true}
    >
      <Form
        preserve={false}
        form={form}
        requiredMark={false}
        layout="vertical"
        initialValues={{
          firstName: data?.firstName,
          lastName: data?.lastName,
          email: data?.email,
          mobileNo: data?.mobileNo || "",
          permissionToBill: data?.permissionToBill,
        }}
        onFinishFailed={({ values, errorFields, outOfDate }) => {
          form.scrollToField(errorFields[0].name, {
            scrollMode: "if-needed",
            block: "center",
            behavior: "smooth",
          });
        }}
        onFinish={async (values) => {
          const dupNameProfile = family.find((profile) => {
            if (
              profile.firstName?.trim?.()?.toLowerCase?.() ===
                values.firstName?.trim?.()?.toLowerCase?.() &&
              profile.lastName?.trim?.()?.toLowerCase?.() ===
                values.lastName?.trim?.()?.toLowerCase?.()
            )
              return profile;
          });
          if (
            (data?.invitedMemberId &&
              dupNameProfile?.invitedMemberId &&
              data?.invitedMemberId !== dupNameProfile?.invitedMemberId) ||
            (!data?.invitedMemberId && dupNameProfile?.invitedMemberId)
          ) {
            openNotificationWithIcon(
              "error",
              "Error!",
              `A family member with this name "${values.firstName} ${values.lastName}" already exists`
            );
            return;
          }

          const callback =
            type === "add"
              ? () => dispatch(createFamilyMember(values))
              : () =>
                  dispatch(updateFamilyMember(data.invitedMemberId, values));
          const result = await callback();
          if (result?.status === "success") {
            handleSave?.();
            form.resetFields();
          }
        }}
      >
        <FormInput
          readOnly={data?.invitedMemberId}
          name="firstName"
          label="First Name"
          variant="underlined"
          placeholder="First Name"
          required
          formItemStyles={{ marginBottom: 16 }}
        />
        <FormInput
          readOnly={data?.invitedMemberId}
          name="lastName"
          label="Last Name"
          variant="underlined"
          placeholder="Last Name"
          required
          formItemStyles={{ marginBottom: 16 }}
        />
        <FormInput
          readOnly={data?.invitedMemberId}
          name="email"
          label="Email Address"
          variant="underlined"
          placeholder="name@domain.com"
          rules={[
            {
              required: true,
              type: "email",
              message: "Enter a valid email",
            },
          ]}
          formItemStyles={{ marginBottom: 16 }}
        />
        <FormPhoneNumber
          readOnly={data?.invitedMemberId}
          name="mobileNo"
          label="Mobile Number"
          variant="underlined"
          placeholder="Mobile Number"
          formItemStyles={{ marginBottom: 16 }}
          country="us"
        />
        <FormCheckbox
          name="permissionToBill"
          className={"familyPermissionCheckbox"}
        >
          I would like to give permission for this family member to bill their
          payments to my account
        </FormCheckbox>

        <div className="flex justifySpaceBetween alignCenter">
          <FormButton
            type="submit"
            text="Save and Invite"
            rounded
            formItemStyles={{ marginBottom: 0 }}
            loading={submitting}
          />
          <Typography.Link onClick={() => onCancel()}>Cancel</Typography.Link>
        </div>
      </Form>
    </Modal>
  );
}

FamilyMemberModal.propTypes = {
  type: PropTypes.oneOf(["add", "edit"]),
  visible: PropTypes.bool,
  handleSave: PropTypes.any,
  onCancel: PropTypes.any,
  title: PropTypes.string,
  // visible: Pro, onCancel, type, title
};

export default FamilyMemberModal;
