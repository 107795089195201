import moment from "moment";
import { getItem, setItem, removeItem, clearStorage } from "../utils/storage";
import { setTimer as setTimerAction } from "redux/actions/timer.action";

export const syncTimer = () => {
  return (dispatch) => {
    const timer = moment(getItem("timer"));

    if (timer) {
      const duration = moment.duration(timer.diff(moment()));
      if (duration.asSeconds() >= 0) dispatch(setTimerAction(timer));
      else removeItem("timer");
    }
  };
};

export const setTimer = (timer) => {
  return (dispatch) => {
    return new Promise((_) => {
      dispatch(setTimerAction(timer));
      setItem("timer", timer.toString());
    });
  };
};

export const clearTimer = () => {
  return (dispatch) => {
    return new Promise((_) => {
      dispatch(setTimerAction(undefined));
      removeItem("timer");
    });
  };
};
