import {
  setCenters,
  setOperationTypes,
  setProducts,
  setAddons,
  setFetching,
} from "../redux/actions/locationCenter.action";
import { openNotificationWithIcon } from "utils/Notification";
import { request } from "./verb.services";
import { Result } from "antd";
import { getProductTypeFromOperation } from "utils/common";
import { OperationType } from "utils/enums";
import { GOOGLE_MAP_KEY } from "utils/constants";

/**
 *
 * @param {string} streetAddress
 * @param {boolean} clearCascadingSelectors
 * @param {object} signal
 * @returns
 */
export const getLocationsByStreetAddress = (
  streetAddress = "",
  clearCascadingSelectors = false,
  signal
) => {
  return (dispatch) => {
    const urlEncodedStreetAddress = escape(streetAddress).replace("/", "");
    dispatch(setFetching("isFetchingCenters", true));

    return fetch(
      "https://maps.googleapis.com/maps/api/geocode/json?address=" +
        encodeURIComponent(urlEncodedStreetAddress) +
        "&key=AIzaSyA0eoYnn4K0ShoUxhjYKPThufrjtRckOnA&fields=formatted_address",
      {
        signal,
      }
    )
      .then((response) => response.json())
      .then((data) => {
        const lat = data.results[0]?.geometry?.location?.lat;
        const lng = data.results[0]?.geometry?.location?.lng;
        return request(
          `Facilities?streetAddress=${urlEncodedStreetAddress}${
            lat ? `&lat=${escape(lat)}` : ""
          }${lng ? `&lng=${escape(lng)}` : ""}`,
          "get",
          null,
          false,
          null,
          {
            signal,
          }
        )
          .then((response) => {
            // TODO: contact backend and have it return array instead of object
            // then use it directly instead of array and object checks
            let data =
              response.data.constructor === Object
                ? [response.data]
                : response.data;
            dispatch(setCenters(data, clearCascadingSelectors));
            return data;
          })
          .catch((e) => {
            if (e.message !== "canceled")
              openNotificationWithIcon(
                "error",
                "Error!",
                e?.response?.data?.message ||
                  e?.response?.data?.Message ||
                  "Network error has occured"
              );
          })
          .finally(() => {
            dispatch(setFetching("isFetchingCenters", false));
          });
      })
      .catch((e) => {
        console.log("e fetch", e);
      });
  };
};

export const getOutOfZoneErrorMessage = (operationTypeId) => {
  return operationTypeId === OperationType?.Telehealth
    ? "The ZIP code is out of zone. Please contact Liquid Mobile IV to book."
    : "The address is out of zone. Please contact Liquid Mobile IV to book.";
};

export const getFaclityInZone = (
  operationTypeId,
  { lat, lng },
  clearCascadingSelectors,
  showError = true
) => {
  return (dispatch) => {
    dispatch(setFetching("isFetchingCenters", true));
    // TODO: Revert this later
    return request(
      `Facilities/InZone?operationTypeId=${operationTypeId}${
        lat ? `&latitude=${escape(lat)}` : ""
      }${lng ? `&longitude=${escape(lng)}` : ""}`,
      "get",
      null,
      false,
      null
    )
      .then((response) => {
        // TODO: contact backend and have it return array instead of object
        // then use it directly instead of array and object checks
        let data =
          response.data.constructor === Object
            ? [response.data]
            : response.data;
        if (data?.length > 0) {
          dispatch(setCenters(data, clearCascadingSelectors));
        } else {
          if (showError)
            openNotificationWithIcon(
              "error",
              "Error!",
              getOutOfZoneErrorMessage(operationTypeId),
              1
            );
        }
        return {
          status: data?.length > 0 ? "success" : "failure",
          data,
        };
      })
      .catch((e) => {
        if (e.message !== "canceled")
          openNotificationWithIcon(
            "error",
            "Error!",
            e?.response?.data?.message ||
              e?.response?.data?.Message ||
              getOutOfZoneErrorMessage(operationTypeId),
            "Out of zone",
            1
          );
      })
      .finally(() => {
        dispatch(setFetching("isFetchingCenters", false));
      });
  };
};

export const getOperationTypesByLocationCenter = (
  facilityId,
  clearCascadingSelectors = false
) => {
  return (dispatch) => {
    dispatch(setFetching("isFetchingOperationTypes", true));

    return request(
      `Facilities/${facilityId}/OperationTypes`,
      "get",
      null,
      false
    )
      .then((response) => {
        // TODO: contact backend and have it return array instead of object
        // then use it directly instead of array and object checks
        let data =
          response.data.constructor === Object
            ? [response.data]
            : response.data;
        dispatch(setOperationTypes(data, clearCascadingSelectors));
      })
      .catch((e) => {
        openNotificationWithIcon(
          "error",
          "Error!",
          e?.response?.data?.message ||
            e?.response?.data?.Message ||
            "Network error has occured"
        );
      })
      .finally(() => {
        dispatch(setFetching("isFetchingOperationTypes", false));
      });
  };
};

export const getProducts = (
  facilityId,
  operationTypeId,
  clearCascadingSelectors = false
) => {
  return (dispatch) => {
    dispatch(setFetching("isFetchingProducts", true));
    const productType = getProductTypeFromOperation(operationTypeId);

    return request(
      `Facilities/${facilityId}/OperationTypes/${operationTypeId}/Products?productType=${productType}`,
      "get",
      null,
      false
    )
      .then((response) => {
        if (response?.data?.sort)
          response.data.sort((a, b) => {
            return `${a.productCategoryName}`.localeCompare(
              b.productCategoryName
            );
          });

        dispatch(setProducts(response.data, clearCascadingSelectors));
      })
      .catch((e) => {
        openNotificationWithIcon(
          "error",
          "Error!",
          e?.response?.data?.message ||
            e?.response?.data?.Message ||
            "Network error has occured"
        );
      })
      .finally(() => {
        dispatch(setFetching("isFetchingProducts", false));
      });
  };
};

export const getAddons = (
  facilityId,
  operationTypeId,
  productId,
  setLoader
) => {
  return (dispatch) => {
    if (setLoader) dispatch(setFetching("isFetchingAddons", true));

    return (
      request(
        `Facilities/${facilityId}/OperationTypes/${operationTypeId}/Products/${productId}/AddOns`,
        "get",
        null,
        false
      )
        //   `Facilities/${facilityId}/OperationTypes/${operationTypeId}/Products/${productId}/AddOns`,
        //   "get",
        //   null,
        //   false,
        //   {
        //     facilityId,
        //     operationTypeId,
        //     productIds: [...productId],
        //   }
        .then((response) => {
          let data = response.data;
          // dispatch(setAddons(data));
          return data;
        })
        .catch((e) => {
          console.log("e", e);
          openNotificationWithIcon(
            "error",
            "Error!",
            e?.response?.data?.message ||
              e?.response?.data?.Message ||
              "Network error has occured"
          );
        })
        .finally(() => {
          if (setLoader) dispatch(setFetching("isFetchingAddons", false));
        })
    );
  };
};
export const getFacilityTimings = (scheduledDate, facilityId, callback) => {
  return (dispatch) => {
    return request(
      `Facilities/${facilityId}/OperationalTimings?serviceDate=${scheduledDate}`,
      "get",
      null,
      false
    )
      .then((response) => {
        let data = response.data;
        // dispatch(setAddons(data));
        return {
          status: "success",
          data,
        };
      })
      .catch((e) => {
        openNotificationWithIcon(
          "error",
          "Error!",
          e?.response?.data?.message ||
            e?.response?.data?.Message ||
            "Network error has occured while fetching shift timings."
        );
      })
      .finally(() => {
        callback?.();
      });
  };
};

export const getStateAndCountryFromZip = (address) => {
  return fetch(
    `https://maps.googleapis.com/maps/api/geocode/json?key=${GOOGLE_MAP_KEY}&fields=formatted_address&address=${encodeURIComponent(
      address
    )}`
  )
    .then((response) => response.json())
    .then((data) => {
      if (data?.length === 0) return {};
      else {
        let result = data.results[0];
        let state = "";
        let country = "US";
        let zipCode = "";
        for (let addressComponent of result?.["address_components"]) {
          if (
            addressComponent?.["types"]?.includes("administrative_area_level_1")
          )
            state = addressComponent?.["short_name"];
          else if (addressComponent?.["types"]?.includes("country"))
            country = addressComponent?.["short_name"];
          else if (addressComponent?.["types"]?.includes("postal_code"))
            zipCode = addressComponent?.["short_name"];
        }
        return {
          state,
          country,
          zipCode,
        };
      }
    })
    .catch(() => {
      return {};
    });
};
