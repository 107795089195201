import React, { useEffect, useMemo, useState } from "react";

import { Typography, Space, Form, Select } from "antd";
import { useDispatch } from "react-redux";
import { getAddons } from "services/locationCenter.services";
import BoostPopup from "components/BoostPopup/BoostPopup";
import FormItem from "antd/lib/form/FormItem";
import {
  createAddOn,
  deleteLineItem,
  getOrderById,
} from "services/booking.service";
import { setLoading } from "redux/actions/loading.actions";
import { openNotificationWithIcon } from "utils/Notification";

const { Text, Title, Link } = Typography;
const Option = Select.Option;

export default function OrderSummaryItem({ order, orderId, item }) {
  const [addOns, setAddOns] = useState({
    data: [],
    isLoading: true,
  });
  const [showBoost, setShowBoost] = useState(false);
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const pickedAddOns = Form.useWatch("addOns", form) ?? [];
  const selectedAddOns = useMemo(() => {
    return item?.addOns?.map?.((addOn) => addOn.productId) ?? [];
  }, [item]);

  useEffect(() => {
    if (order?.facilityId && order?.operationTypeId) {
      dispatch(
        getAddons(
          order?.facilityId,
          order?.operationTypeId,
          item?.productId,
          false
        )
      )
        .then((res) => {
          setAddOns({
            data: res ?? [],
            isLoading: false,
          });
        })
        .finally(() => {
          setAddOns((data) => ({
            ...data,
            isLoading: false,
          }));
        });
    } else {
      setAddOns({
        data: [],
        isLoading: false,
      });
    }
  }, []);

  useEffect(() => {
    form.resetFields();
  }, [order]);

  const handleAddonsSave = async () => {
    const addOns = form.getFieldValue("addOns") ?? [];

    if (addOns?.length > 0) {
      dispatch(setLoading(true));
      await dispatch(createAddOn(addOns, orderId, item.lineItemId, false));
      await dispatch(
        getOrderById(order?.orderId, order?.isMultipleGuestBooking)
      );
      dispatch(setLoading(false));
      setShowBoost(false);
    } else {
      openNotificationWithIcon("error", "Error", "No Add-ons Selected");
    }
  };

  const availableAddons =
    addOns?.data?.filter(
      (addon) => !selectedAddOns?.includes(addon.productId)
    ) ?? [];

  return (
    <div>
      <div
        className="flex alignCenter justifySpaceBetween"
        style={{ marginBottom: 4 }}
      >
        <Space size={"middle"}>
          <Title
            level={5}
            className="tradeGothic"
            style={{ marginBottom: 0, fontSize: 17 }}
          >
            {item.productName}{" "}
          </Title>
          {/* <Link onClick={() => setServiceModal(item.lineItemId)} underline disabled={orderId === undefined}>
    Change
  </Link> */}
        </Space>

        <Title
          level={5}
          className="tradeGothic"
          style={{ marginBottom: 0, fontSize: 17 }}
        >
          ${Number(item.sellingPrice ?? 0)?.toFixed?.(2)}{" "}
          {/* {item?.taxAmount > 0 && (
            <>+ ${Number(item?.taxAmount ?? 0)?.toFixed?.(2)}</>
          )} */}
          {!!item?.rewardId && (
            <span style={{ color: "#27ca2d", fontFamily: "tradeGothic" }}>
              - ${Number(item.discountValue ?? 0)?.toFixed?.(2)}
            </span>
          )}
        </Title>
      </div>
      {/* Additional Boosts Start */}
      <div style={{ marginBottom: 12 }}>
        <Title
          level={5}
          className="tradeGothic"
          style={{ marginBottom: 4, fontSize: 17 }}
        >
          Additional Add-ons
        </Title>
        {item?.addOns?.length ? (
          <div>
            {item?.addOns.map((addon) => {
              return (
                <div
                  className="flex alignCenter justifySpaceBetween"
                  style={{ paddingLeft: 16 }}
                >
                  <Space>
                    <Text
                      className="tradeGothic"
                      style={{ marginBottom: 0, fontSize: 17 }}
                    >
                      {addon?.productName}
                    </Text>
                    <Link
                      onClick={() => {
                        // async (id, pId) => {
                        //   let newOrder = { ...order };
                        //   newOrder.lineItems = newOrder?.lineItems?.map((item) => {
                        //     if (item.lineItemId === pId) {
                        //       item.addOns = item?.addOns?.filter((adon) => adon.lineItemId !== id);
                        //     }
                        //     return item;
                        //   });
                        dispatch(setLoading(true));
                        dispatch(deleteLineItem(orderId, addon.lineItemId))
                          .then(() => {
                            dispatch(getOrderById(order.orderId, true)).finally(
                              () => {
                                dispatch(setLoading(false));
                              }
                            );
                          })
                          .catch(() => {
                            dispatch(setLoading(false));
                          });
                        // }
                      }}
                      underline
                      disabled={orderId === undefined}
                    >
                      Remove
                    </Link>
                  </Space>
                  <Title
                    level={5}
                    className="tradeGothic"
                    style={{ marginBottom: 0, fontSize: 17 }}
                  >
                    ${Number(addon.sellingPrice ?? 0)?.toFixed?.(2)}{" "}
                    {/* {addon?.taxAmount > 0 && (
                      <>+ ${Number(addon?.taxAmount ?? 0)?.toFixed?.(2)}</>
                    )} */}
                    {!!addon?.rewardId && (
                      <span
                        style={{ color: "#27ca2d", fontFamily: "tradeGothic" }}
                      >
                        - ${Number(addon.discountValue ?? 0)?.toFixed?.(2)}
                      </span>
                    )}
                  </Title>
                </div>
              );
            })}
            {availableAddons?.length > 0 && (
              <Link onClick={() => setShowBoost(true)}>
                Click here to add Add-ons (Guest)
              </Link>
            )}
          </div>
        ) : (
          <div>
            {}
            <Text className="tradeGothic" style={{ fontSize: 17 }}>
              No additional add-ons selected.{" "}
              {availableAddons?.length > 0 && (
                <Link onClick={() => setShowBoost(true)}>
                  Click here to add Add-ons (Guest)
                </Link>
              )}
            </Text>
          </div>
        )}
      </div>
      {/* Additional Add-ons End */}
      <Form form={form}>
        <BoostPopup
          closeOnCancel={true}
          cancelText="Cancel"
          addOns={availableAddons}
          name="addOns"
          form={form}
          visible={showBoost}
          checkboxOnChange={(productId) => {
            const addons = form.getFieldValue("addOns") ?? [];
            if (addons?.includes?.(productId)) {
              form.setFieldsValue({
                addOns: [],
              });
            } else {
              form.setFieldsValue({
                addOns: [...addons, productId],
              });
            }
          }}
          onCancel={() => {
            setShowBoost(false);
          }}
          handleSave={() => {
            handleAddonsSave();
          }}
          selectedAddOns={selectedAddOns}
        />
        <FormItem
          style={{
            border: "2px solid #DBDFEA",
            borderRadius: 14,
            height: 84,
            margin: 0,
            position: "relative",
          }}
          label={"ADD-ONS"}
          className="dashboardSelector  noError roundedSelect"
          // initialValue={initialValue}
          name={"addOns"}
          hidden={true}
        >
          <Select
            mode="multiple"
            style={{
              border: "none",
              flexWrap: "nowrap",
              overflow: "auto",
              cursor: "pointer",
            }}
            placeholder={"Select Add-ons"}
            dropdownStyle={{ display: "none" }}
            className={`hideValue  ${addOns.length > 0 ? "showValue" : ""} `}
          >
            {addOns?.data?.map?.((addon) => {
              return (
                <Option key={addon.productId} value={addon.productId}>
                  {addon.productName}
                </Option>
              );
            })}
          </Select>
        </FormItem>
      </Form>
    </div>
  );
}
