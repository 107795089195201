import Dashboard from "containers/Dashboard/Dashboard";
import ThankYouForBooking from "containers/ThankYouForBooking/ThankYouForBooking";

// router configuration.
let routes = [
  {
    path: "/",
    name: "dashboard",
    component: Dashboard,
    exact: true,
  },
];

export default routes;
