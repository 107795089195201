import React, { useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";
import queryString from "query-string";

import { Checkbox, Form, Grid, Row, Col, Tooltip } from "antd";
import {
  FormDatePicker,
  FormHiddenSubmitButton,
  FormInput,
  FormSelect,
} from "../../components/FormItems/RoundedFormItems";

import useLocationCenter from "hooks/useLocationCenters";

import BoostsDropdown from "../../components/BoostsDropdown/BoostsDropdown";
import ServicesDropdown from "../../components/ServicesDropdown/ServicesDropdown";
import { useLocation } from "react-router";
import {
  LoadingOutlined,
  SettingOutlined,
  UserOutlined,
} from "@ant-design/icons";
import UsernamePopup from "./UsernamePopup";
import { validateGuestAccount } from "services/booking.service";
import { setItem } from "utils/storage";
import { openNotificationWithIcon } from "utils/Notification";
import { validateEmail } from "utils/common";

const { useBreakpoint } = Grid;

function GuestFormRow({
  field,
  index,
  remove,
  totalFields,
  form,
  locationId,
  operationTypeId,
  productId,
}) {
  const abortConRef = useRef();
  const screenSizes = useBreakpoint();
  const dispatch = useDispatch();
  const { currentUser } = useSelector((state) => state.user);
  const [showUsernamePopup, setShowUsernamePopup] = useState(false);

  const guests = Form.useWatch(`guests`, form);
  const { pendingOrder } = useSelector((state) => ({
    pendingOrder: state.pendingOrder,
  }));
  const { order } = pendingOrder ?? {};

  const [isValidating, setIsValidating] = useState(false);
  const {
    firstName,
    lastName,
    email,
    username,
    isValidated,
    hasMultipleGuests,
    count,
  } = form.getFieldValue(["guests", index]) ?? {};
  const [lastCheckedInfo, setLastCheckedInfo] = useState({
    email: "",
    firstName: "",
    lastName: "",
  });
  // const isEmailVerified =
  //   form.getFieldValue(["guests", index, "email"]) === lastCheckedEmail;

  useEffect(() => {
    const {
      firstName,
      lastName,
      email,
      username,
      isValidated,
      hasMultipleGuests,
    } = form.getFieldValue(["guests", index]);
    if (isValidated) {
      setLastCheckedInfo({
        firstName,
        lastName,
        email,
      });
    }
  }, []);
  useEffect(() => {
    setItem("pendingOrder", {
      ...form.getFieldsValue(),
      status: "pending",
    });
  }, [isValidated]);

  const { addOns, setProduct, isFetchingAddons } = useLocationCenter({
    initialFacilityId: locationId,
    initialOperationTypeId: operationTypeId,
    intialProductId: productId,
    addonOnly: true,
  });

  useEffect(() => {
    if (
      locationId !== undefined &&
      operationTypeId !== undefined &&
      productId !== undefined
    ) {
      setProduct(productId, operationTypeId, locationId);
    }
  }, [locationId, operationTypeId, productId]);

  // multiple guest bookings junaid

  const checkInformation = () => {
    if (!validateEmail(email?.trim?.() || "")) return;

    if (
      currentUser &&
      email?.trim?.()?.toLowerCase?.() ===
        currentUser.email?.trim?.()?.toLowerCase?.() &&
      firstName?.trim?.()?.toLowerCase?.() ===
        currentUser.firstName?.trim?.()?.toLowerCase?.() &&
      lastName?.trim?.()?.toLowerCase?.() ===
        currentUser.lastName?.trim?.()?.toLowerCase?.()
    ) {
      openNotificationWithIcon(
        "error",
        "Error",
        "You cannot book yourself as a guest.",
        1
      );
      return;
    }

    setIsValidating(true);
    if (abortConRef.current) abortConRef.current.abort();
    abortConRef.current = new AbortController();
    dispatch(
      validateGuestAccount({
        firstName,
        lastName,
        emailAddress: email,
      })
    )
      .then((res) => {
        if (res?.status !== "success") {
          return;
        }

        if (res?.count > 1) {
          setShowUsernamePopup(true);
        } else if (res?.count === 1) {
          form.setFields([
            {
              name: ["guests", index, "email"],
              errors: [],
            },
            {
              name: ["guests", index, "count"],
              value: 1,
            },
            {
              name: ["guests", index, "isValidated"],
              value: true,
            },
            {
              name: ["guests", index, "username"],
              value: "",
            },
            {
              name: ["guests", index, "hasMultipleGuests"],
              value: "no",
            },
          ]);
          setLastCheckedInfo({
            email,
            firstName,
            lastName,
          });
        } else {
          form.setFields([
            {
              name: ["guests", index, "email"],
              errors: [],
            },
            {
              name: ["guests", index, "count"],
              value: 0,
            },
            {
              name: ["guests", index, "isValidated"],
              value: true,
            },
            {
              name: ["guests", index, "username"],
              value: "",
            },
            {
              name: ["guests", index, "hasMultipleGuests"],
              value: "no",
            },
          ]);
          setLastCheckedInfo({
            email,
            firstName,
            lastName,
          });
        }
      })
      .finally(() => {
        setIsValidating(false);
      });
  };

  const renderVerificationStatus = () => {
    const hasAllFields =
      email === lastCheckedInfo?.email &&
      firstName === lastCheckedInfo.firstName &&
      lastName === lastCheckedInfo.lastName;
    if (count === 0 && hasAllFields)
      return <p className="verifiedText">NEW GUEST</p>;

    return (
      <>
        {hasAllFields ? (
          <p className="verifiedText">VERIFIED</p>
        ) : (
          <p className="verifyBtn" onClick={checkInformation}>
            VERIFY
          </p>
        )}
      </>
    );
  };

  return (
    <Row key={index} className="dashFormGuestRow" gutter={[12, 12]}>
      {(index === 0 || screenSizes.xs) && (
        <div>
          <p className="dashFormMakePaymentBtn">MAKE PAYMENT</p>
          <Tooltip title="Check Make Payment box if you will be paying for additional guest's service and leave unchecked if additional guests will be paying for their own service.">
            <div
              className="info"
              style={{
                position: "absolute",
                top: 14,
                left: 70,
                cursor: "pointer",
              }}
            >
              i
            </div>
          </Tooltip>
        </div>
      )}

      <Col xs={4} md={1}>
        <Form.Item name={[index, "checked"]} valuePropName="checked">
          <Checkbox />
        </Form.Item>
      </Col>
      <Col xs={20} md={4}>
        <ServicesDropdown
          form={form}
          listName="guests"
          name={[index, "productId"]}
          onChange={(value) => {
            const { operationTypeId, facilityId } = form.getFieldsValue();

            setProduct(value, operationTypeId, facilityId);

            let list = form.getFieldValue("guests");
            let updatedList = list.map((item, index2) => {
              if (index === index2) return { ...item, addOns: [] };
              return item;
            });
            form.setFieldsValue({ guests: updatedList });
          }}
        />
      </Col>
      <Col xs={24} md={4}>
        <BoostsDropdown
          form={form}
          listName="guests"
          name={[index, "addOns"]}
          addOns={addOns}
          isFetchingAddons={isFetchingAddons}
          selectedAddOns={guests?.[index]?.addOns ?? []}
        />
      </Col>
      <Col xs={24} md={4}>
        <FormInput
          required
          name={[index, "firstName"]}
          label="FIRST NAME"
          placeholder="First Name"
          onChange={(e) => {
            if (e.target.value !== lastCheckedInfo.firstName)
              form.setFields([
                {
                  name: ["guests", index, "isValidated"],
                  value: false,
                },
              ]);
          }}
        />
      </Col>
      <Col xs={24} md={4}>
        <FormInput
          required
          name={[index, "lastName"]}
          label="LAST NAME"
          placeholder="Last Name"
          onChange={(e) => {
            if (e.target.value !== lastCheckedInfo.lastName)
              form.setFields([
                {
                  name: ["guests", index, "isValidated"],
                  value: false,
                },
              ]);
          }}
        />
      </Col>
      <Col xs={24} md={5}>
        <FormInput
          required
          type="email"
          name={[index, "email"]}
          label="EMAIL ADDRESS"
          placeholder="Email Address"
          rules={[
            {
              required: true,
              message: "The name is required.",
            },
            {
              type: "email",
              message: "The input is not valid E-mail!",
            },
            {
              message: "username validation",
              validator: (_, value) => {
                if (
                  email &&
                  firstName &&
                  lastName &&
                  email?.trim?.()?.toLowerCase?.() ===
                    currentUser.email?.toLowerCase?.() &&
                  firstName?.trim?.()?.toLowerCase?.() ===
                    currentUser.firstName?.toLowerCase?.() &&
                  lastName?.trim?.()?.toLowerCase?.() ===
                    currentUser.lastName?.toLowerCase?.()
                ) {
                  return Promise.reject("You cannot yourself as a guest");
                }

                if (
                  email === lastCheckedInfo?.email &&
                  firstName === lastCheckedInfo.firstName &&
                  lastName === lastCheckedInfo.lastName
                )
                  return Promise.resolve();
                else return Promise.reject("Username not validated");
              },
            },
          ]}
          addonAfter={
            isValidating ? (
              <LoadingOutlined
                className="blueLoader"
                style={{
                  fontSize: 16,
                }}
                spin
              />
            ) : hasMultipleGuests === "yes" ? (
              <UserOutlined onClick={() => setShowUsernamePopup(true)} />
            ) : undefined
          }
          onChange={(e) => {
            if (e.target.value !== lastCheckedInfo.email)
              form.setFields([
                {
                  name: ["guests", index, "isValidated"],
                  value: false,
                },
              ]);
          }}
          onBlur={() => {
            if (
              email &&
              firstName &&
              lastName &&
              !(
                email === lastCheckedInfo?.email &&
                firstName === lastCheckedInfo.firstName &&
                lastName === lastCheckedInfo.lastName
              )
            ) {
              checkInformation();
            }
            // dispatch();
          }}
        />
        <FormInput hidden={true} rules={[]} name={[index, "username"]} />
        <FormInput hidden={true} rules={[]} name={[index, "count"]} />
        <FormInput
          hidden={true}
          rules={[]}
          name={[index, "hasMultipleGuests"]}
        />
        <FormInput hidden={true} rules={[]} name={[index, "isValidated"]} />
      </Col>
      <Col
        xs={24}
        md={2}
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        {email && firstName && lastName && renderVerificationStatus()}
        <p
          className="dashFormRemoveBtn"
          onClick={() => {
            form.setFieldsValue({ guestsNo: totalFields });
            remove(index);
          }}
        >
          REMOVE
        </p>
      </Col>
      <UsernamePopup
        username={username}
        visible={showUsernamePopup}
        onCancel={() => setShowUsernamePopup(false)}
        continueWithoutUsername={() => {
          form.setFields([
            {
              name: ["guests", index, "email"],
              errors: [],
            },
            {
              name: ["guests", index, "isValidated"],
              value: true,
            },
            {
              name: ["guests", index, "username"],
              value: "",
            },
            {
              name: ["guests", index, "backendStatus"],
              value: "",
            },
            {
              name: ["guests", index, "hasMultipleGuests"],
              value: "no",
            },
          ]);
          setLastCheckedInfo({
            email,
            firstName,
            lastName,
          });
          setShowUsernamePopup(false);
        }}
        handleSave={(username) => {
          form.setFields([
            {
              name: ["guests", index, "email"],
              errors: [],
            },
            {
              name: ["guests", index, "count"],
              value: 2,
            },
            {
              name: ["guests", index, "isValidated"],
              value: true,
            },
            {
              name: ["guests", index, "username"],
              value: username,
            },
            {
              name: ["guests", index, "hasMultipleGuests"],
              value: "yes",
            },
          ]);
          setLastCheckedInfo({
            email,
            firstName,
            lastName,
          });
          setShowUsernamePopup(false);
        }}
      />
    </Row>
  );
}

GuestFormRow.propTypes = {};

export default GuestFormRow;
