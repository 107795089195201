import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import { Provider } from "react-redux";
import { ConnectedRouter } from "connected-react-router";
import { Switch } from "react-router";
import reportWebVitals from "./reportWebVitals";
import configureStore, { history } from "./redux/store/configureStore";

// import './App.less';

import AppRoot from "./AppRoot";
import { Route } from "react-router-dom";
import GuestIframe from "containers/Dashboard/GuestIframe";

const store = configureStore();

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <ConnectedRouter history={history}>
        <Switch>
          <Route path={"/guestIframe"} component={GuestIframe} />
          <Route path={"/"} component={AppRoot} />
        </Switch>
      </ConnectedRouter>
    </Provider>
  </React.StrictMode>,
  document.getElementById("root")
);

reportWebVitals();
