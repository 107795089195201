export const actions = {
  SET_CENTERS: "SET_CENTERS",
  SET_OPERATIONTYPES: "SET_OPERATIONTYPES",
  SET_PRODUCTS: "SET_PRODUCTS",
  SET_ADDONS: "SET_ADDONS",
  SET_FETCHING: "SET_FETCHING",
  CLEAR_ALL_DATA: "CLEAR_ALL_DATA",
};

export const setCenters = (data, clearCascadingSelectors) => ({
  type: actions.SET_CENTERS,
  data,
  clearCascadingSelectors,
});

export const setOperationTypes = (data, clearCascadingSelectors) => ({
  type: actions.SET_OPERATIONTYPES,
  data,
  clearCascadingSelectors,
});

export const setProducts = (data, clearCascadingSelectors) => ({
  type: actions.SET_PRODUCTS,
  data,
  clearCascadingSelectors,
});

export const setAddons = (data, clearCascadingSelectors) => ({
  type: actions.SET_ADDONS,
  data,
  clearCascadingSelectors,
});

export const setFetching = (fetchingType, status) => ({
  type: actions.SET_FETCHING,
  fetchingType,
  status,
});

export const clearAllData = () => ({
  type: actions.CLEAR_ALL_DATA,
});
