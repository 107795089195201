import React from "react";
import PropTypes from "prop-types";

import { Badge, Typography } from "antd";

import "./Memberships.scss";
import { RewardType } from "utils/enums";
const { Title, Text } = Typography;

function MembershipCard({ data = {}, onClick = () => {} }) {
  const { programName, programType, sellingPrice, notes } = data;
  const renderContent = () => {
    return (
      <div className="membershipCard" onClick={onClick}>
        <div className="content">
          <Title level={4} className="tradeGothic" style={{ color: "#4A6AAF" }}>
            {programName}
          </Title>
          <div className="innerContent">
            <>
              {notes &&
                notes.split("\n").map((ele) => {
                  return <Text>{ele}</Text>;
                })}
            </>
          </div>
          {/* <Text
        style={{
          fontFamily: "tradeGothic",
          color: "#8E94AA",
        }}
      >
        * NAD not included
      </Text> */}
        </div>
        <div>
          <Title level={5} className="tradeGothic" style={{ marginBottom: 0 }}>
            ${sellingPrice}
            {programType === RewardType.Membership ? "/Month" : ""}
          </Title>
        </div>
      </div>
    );
  };
  if (data?.isPurchased && data?.isCancelled === false)
    return (
      <Badge.Ribbon text="Purchased" className="membershipRibbon" color="green">
        {renderContent()}
      </Badge.Ribbon>
    );
  else if (data?.isPurchased && data?.isCancelled === true) {
    return (
      <Badge.Ribbon
        text="Cancelled"
        className="membershipRibbon"
        color="orange"
      >
        {renderContent()}
      </Badge.Ribbon>
    );
  }
  return renderContent();
}

MembershipCard.propTypes = {};

export default MembershipCard;
