import React, { useMemo, useState } from "react";

import { Button, Modal, Space, Typography } from "antd";
import { BenefitType } from "utils/enums";
import { getRewardId, getRewardsText } from "utils/common";
import { useDispatch, useSelector } from "react-redux";
import { CrownOutlined, DeleteOutlined } from "@ant-design/icons";
import RewardPopup from "components/RewardPopup/RewardPopup";
import { applyReward } from "services/booking.service";
import { openNotificationWithIcon } from "utils/Notification";

const { Text, Link, Title } = Typography;

export default function AddonItem({
  addon,
  deleteReward,
  removeAddon,
  lineItemId,
  order,
  disabled,
}) {
  const { rewards } = useSelector((state) => ({
    rewards: state.rewards,
  }));
  const dispatch = useDispatch();

  const [showAddReward, setShowAddReward] = useState(false);

  const hasAvailableReward = useMemo(() => {
    if (!addon?.rewardId) {
      return getRewardId(rewards?.rewards ?? [], addon, []);
    }
    return false;
  }, [rewards, addon]);

  const deleteConfirmation = (id) => ({
    title: "Remove Reward",
    content: <>Are you sure that you want to remove the reward?</>,
    centered: true,
    maskClosable: true,
    onOk: (close) => {
      deleteReward(id);
      close();
    },
  });

  return (
    <>
      <div
        className="flex alignCenter justifySpaceBetween"
        style={{ paddingLeft: 16 }}
      >
        <Space>
          <Text
            className="tradeGothic"
            style={{ marginBottom: 0, fontSize: 17 }}
          >
            {addon?.productName}
          </Text>
          {hasAvailableReward && (
            <Button
              hidden={disabled}
              disabled={order?.orderId === undefined || disabled}
              onClick={() => setShowAddReward(true)}
              size="small"
              type="primary"
              shape="circle"
              style={{
                backgroundColor: "#2abb2a",
                border: "#295b29",
              }}
              className=""
              icon={<CrownOutlined />}
            />
          )}
          <Link
            onClick={() => removeAddon(addon.lineItemId, lineItemId)}
            underline
            disabled={order?.orderId === undefined || disabled}
            hidden={disabled}
          >
            Remove
          </Link>
        </Space>
        <Title
          level={5}
          className="tradeGothic"
          style={{ marginBottom: 0, fontSize: 17 }}
        >
          ${Number(addon.sellingPrice ?? 0)?.toFixed?.(2)}{" "}
          {/* {addon?.taxAmount > 0 && (
            <>+ ${Number(addon?.taxAmount ?? 0)?.toFixed?.(2)}</>
          )} */}
          {/* {!!addon?.rewardId && (
            <span style={{ color: "#27ca2d", fontFamily: "tradeGothic" }}>
              - ${Number(addon.discountValue ?? 0)?.toFixed?.(2)}
            </span>
          )} */}
        </Title>
      </div>
      {addon.rewardId && (
        <div
          className="flex alignCenter justifySpaceBetween"
          style={{
            marginBottom: 14,
            marginLeft: 20,
          }}
        >
          <Space size={"middle"}>
            <Title
              level={5}
              className="tradeGothic"
              style={{
                marginBottom: 0,
                fontSize: 17,
                color: "#27ca2d",
              }}
            >
              Rewards : {getRewardsText(addon)}
            </Title>
            {/* <Link onClick={() => setServiceModal(item.lineItemId)} underline disabled={orderId === undefined}>
                Change 
              </Link> */}
          </Space>
          <Space>
            {/* <Link
              onClick={() => deleteReward(addon.lineItemId)}
              underline
              style={{
                color: "#ea5961",
              }}
            >
              Remove
            </Link> */}
            <Button
              size="small"
              shape="circle"
              danger
              hidden={disabled}
              disabled={order?.orderId === undefined || disabled}
              icon={<DeleteOutlined style={{ color: "#ea5961" }} />}
              onClick={() =>
                Modal.warning(deleteConfirmation(addon.lineItemId))
              }
            />
            {!!addon?.rewardId && (
              <span
                style={{
                  color: "#27ca2d",
                  fontSize: 18,
                  fontFamily: "tradeGothic",
                  fontWeight: "bold",
                }}
              >
                -${Number(addon.discountValue ?? 0)?.toFixed?.(2)}
              </span>
            )}
          </Space>
        </div>
      )}
      <RewardPopup
        values={addon}
        availedRewards={[]}
        visible={showAddReward}
        onCancel={() => setShowAddReward(false)}
        onRedeem={(rewardId) => {
          dispatch(
            applyReward(order, addon?.lineItemId, rewardId, () => {
              openNotificationWithIcon(
                "success",
                "Success",
                "Reward applied successfully"
              );
              setShowAddReward(false);
            })
          );
        }}
        facilityId={order?.facilityId}
      />
    </>
  );
}
