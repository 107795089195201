export const actions = {
  SET_INTAKEFORMS: "SET_INTAKEFORMS",
  IS_SUBMITTING: "IS_SUBMITTING",
  RESET: "RESET",
};

export const setIntakeForms = (name, data) => ({
  type: actions.SET_INTAKEFORMS,
  data,
  name: name,
});

export const setIsSubmitting = (status) => ({
  type: actions.IS_SUBMITTING,
  isSubmitting: status,
});

export const resetIntakeForms = () => ({
  type: actions.RESET,
});
