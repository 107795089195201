export const actions = {
  UPDATE_CURRENT_USER: "UPDATE_CURRENT_USER",
  SET_CURRENT_USER: "SET_CURRENT_USER",
  LOG_OUT_USER: "LOG_OUT_USER",
  GUEST_SESSION: "GUEST_SESSION",
  // LOADING
  LOGGING_IN_USER: "LOGGING_IN_USER",
  REGISTERING_USER: "REGISTERING_USER",
  UPDATING_USER: "UPDATING_USER",

  // PROFILES
  SET_CURRENT_PROFILEID: "SET_CURRENT_PROFILEID", // just the id
  SET_PROFILES: "SET_PROFILES",
  SET_COMMUNICATION_PREFERENCES: "SET_COMMUNICATION_PREFERENCES",
  CREATE_PROFILE: "CREATE_PROFILE",
  DELETE_PROFILE: "DELETE_PROFILE",
  UPDATE_PROFILE: "UPDATE_PROFILE",

  SHOW_PROFILE_SELECTION: "SHOW_PROFILE_SELECTION",
};

export const clearUser = () => ({
  type: actions.LOG_OUT_USER,
});
export const loginUser = (status) => ({
  type: actions.LOGGING_IN_USER,
  isLoggingIn: status,
});

export const logoutUser = () => ({
  type: actions.LOG_OUT_USER,
});

export const setUser = (data) => ({
  type: actions.SET_CURRENT_USER,
  data,
});

export const updateUser = (data) => ({
  type: actions.UPDATE_CURRENT_USER,
  data,
});

export const setRegisteringUser = (status) => ({
  type: actions.REGISTERING_USER,
  isRegisteringUser: status,
});

export const guestSession = (data) => ({
  type: actions.GUEST_SESSION,
  data,
});

export const setUpdatingUser = (status) => ({
  type: actions.UPDATING_USER,
  isUpdatingUser: status,
});

export const setProfiles = (data) => ({
  type: actions.SET_PROFILES,
  data,
});

export const setCurrentProfileId = (id) => ({
  type: actions.SET_CURRENT_PROFILEID,
  id,
});

export const setCommunicationPreference = (profileId, data) => ({
  type: actions.SET_COMMUNICATION_PREFERENCES,
  profileId,
  data,
});

export const createProfile = (data) => ({
  type: actions.CREATE_PROFILE,
  data,
});

export const deleteProfile = (profileId) => ({
  type: actions.DELETE_PROFILE,
  profileId,
});

export const updateProfile = (profileId, data) => ({
  type: actions.UPDATE_PROFILE,
  profileId,
  data,
});

export const setShowProfileScreen = (status) => ({
  type: actions.SHOW_PROFILE_SELECTION,
  status,
});
