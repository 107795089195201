import { actions } from "../actions/location.actions";

const INITIAL_STATE = {
  countries: [],
  states: [],
  cities: [],
};

const productsReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case actions.SET_COUNTRIES:
      return { ...state, countries: action.data };
    case actions.SET_STATES:
      return { ...state, states: action.data };
    case actions.SET_CITIES:
      return { ...state, cities: action.data };
    default:
      return state;
  }
};

export default productsReducer;
