import "./App.scss";
import "./App.less";

import React, { Component } from "react";
import { connect } from "react-redux";
import { Route, Switch, withRouter } from "react-router-dom";
import routes from "utils/routes";
import App from "containers/App/App";
import NotFound from "components/ErrorPage/ErrorPage";
import { clearStorage, getItem } from "./utils/storage";
import { replace } from "connected-react-router";
import ErrorPage from "components/ErrorPage/ErrorPage";
import { setUser } from "redux/actions/user.actions";
import {
  verifyUser,
  logoutUser,
  guestSessionStart,
} from "services/auth.services";
import { syncPendingOrders } from "services/booking.service";
import { syncTimer } from "services/timer.service";

import { PublicRoute } from "components/PublicRoute/PublicRoute";
import { PrivateRoute } from "components/PrivateRoute/PrivateRoute";

// export default AppRoot;

import Login from "containers/Login/Login";
import Reset from "containers/Reset/Reset";
import Signup from "containers/Signup/Signup";
import Account from "containers/Account/Account";
import Loader from "components/Loader/Loader";
import ThankyouForBooking from "containers/ThankYouForBooking/ThankYouForBooking";
import Invite from "containers/Invite/Invite";
import { setMiscSettings } from "services/misc.service";
import Giftcard from "containers/GiftcardAnonymous/GiftcardAnonymous";

const RouteWithSubRoutes = (route) => (
  <Route
    path={route.path}
    render={(props) => (
      // pass the sub-routes down to keep nesting
      <route.component {...props} routes={route.routes} />
    )}
  />
);

class AppRoot extends Component {
  state = {
    error: null,
    errorInfo: null,
  };

  componentDidMount() {
    this.verifyUser();
    this.props.syncPendingOrders();
    this.props.setMiscSettings();

    const googleAnaltyicsInlineScript = document.createElement("script");
    googleAnaltyicsInlineScript.id = "googleAnalyticsInlineScript2";
    googleAnaltyicsInlineScript.innerHTML = `window.dataLayer = window.dataLayer || [];
    (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
})(window,document,'script','dataLayer','GTM-TQ2DXZG');`;
    document.body.append(googleAnaltyicsInlineScript);

    const googleAnalyticsExternalScript = document.createElement("script");
    googleAnalyticsExternalScript.id = "googleAnalyticsExternalScript";
    googleAnalyticsExternalScript.src =
      "https://www.googletagmanager.com/gtag/js?id=AW-1081008824";
    googleAnalyticsExternalScript.async = true;
    document.head.append(googleAnalyticsExternalScript);

    const googleAnalyticsInlineScript2 = document.createElement("script");
    googleAnalyticsInlineScript2.id = "googleAnalyticsInlineScript";
    googleAnalyticsInlineScript2.innerHTML = `window.dataLayer = window.dataLayer || [];
    function gtag() {
      dataLayer.push(arguments);
    }
    gtag("js", new Date());
    gtag("config", "AW-1081008824");`;
    document.body.append(googleAnalyticsInlineScript2);

    const clarityScript = document.createElement("script");
    clarityScript.id = "microsoftClarity";
    clarityScript.async = true;
    clarityScript.innerHTML = `(function(c,l,a,r,i,t,y){
      c[a]=c[a]||function(){(c[a].q=c[a].q||[]).push(arguments)};
      t=l.createElement(r);t.async=1;t.src="https://www.clarity.ms/tag/"+i;
      y=l.getElementsByTagName(r)[0];y.parentNode.insertBefore(t,y);
      })(window, document, "clarity", "script", "extk57x2pd");`;
    document.head.append(clarityScript);
  }

  componentWillUnmount() {
    const googleAnalyticsExternalScript = document.querySelector(
      "#googleAnalyticsExternalScript"
    );

    const googleAnalyticsInlineScript = document.querySelector(
      "#googleAnalyticsInlineScript"
    );

    const googleAnalyticsInlineScript2 = document.querySelector(
      "#googleAnalyticsInlineScript2"
    );
    googleAnalyticsExternalScript?.remove?.();
    googleAnalyticsInlineScript?.remove?.();
    googleAnalyticsInlineScript2?.remove?.();
  }

  verifyUser = async () => {
    const token = getItem("tokenContainer");
    if (token) {
      this.props.verifyUser();
    } else {
      this.props.guestSessionStart();
    }
  };

  componentDidCatch(error, errorInfo) {
    console.log('exception', error, errorInfo)
    this.setState({ error, errorInfo });
    // this.props.logoutUser();
  }
  render() {
    let { error, errorInfo } = this.state;

    if (error) {
      return <ErrorPage error={error} errorInfo={errorInfo} />;
    }

    return (
      <App>
        <Loader loading={this.props.isTokenVerifying} />
        {!this.props.isTokenVerifying && (
          <Switch>
            {routes.map((route, i) => {
              return <RouteWithSubRoutes key={i} {...route} />;
            })}
            <PrivateRoute path="/account" component={Account} />
            <PrivateRoute path="/thankyou" component={ThankyouForBooking} />
            <PublicRoute path="/signup" component={Signup} exact strict />
            <PublicRoute path="/login" component={Login} exact strict  />
            <PublicRoute path="/reset" component={Reset} exact strict />
            <PublicRoute path="/giftcards" component={Giftcard} exact strict />
            <PublicRoute
              path="/complete-registration"
              component={Invite}
              exact
              strict
            />
            <Route path="*" component={NotFound} />
          </Switch>
        )}
        <noscript>
          <iframe
            title="Google Analytics"
            src="https://www.googletagmanager.com/ns.html?id=GTM-TQ2DXZG"
            height="0"
            width="0"
            style={{
              display: "none",
              visibility: "hidden",
            }}
          ></iframe>
        </noscript>
      </App>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    isTokenVerifying: state.user.isTokenVerifying,
  };
};

export default withRouter(
  connect(mapStateToProps, {
    replace,
    setUser,
    verifyUser,
    logoutUser,
    guestSessionStart,
    syncPendingOrders,
    syncTimer,
    setMiscSettings,
  })(AppRoot)
);
