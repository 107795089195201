import { Space, Button as AntdButton, Typography, Divider } from "antd";
import { Button } from "components/Buttons/Buttons";
import Modal from "components/Modal/Modal";
import React, { useMemo } from "react";
import { useSelector } from "react-redux";
import { BenefitType, ExcludeType, RewardType } from "utils/enums";
import moment from "moment";

import "./RewardPopup.scss";
import { getFirstRewardIdFromRewards } from "utils/common";

const { Text, Title } = Typography;

export default function RewardPopup({
  values,
  visible,
  onCancel,
  handleSave,
  availedRewards,
  onRedeem,
  facilityId
}) {
  const { rewards } = useSelector((state) => ({
    rewards: state.rewards,
  }));

  const [firstAvailableRewardId, availableRewards] = useMemo(() => {
    const firstAvailableRewardIds = getFirstRewardIdFromRewards(
      rewards?.rewards ?? [],
      values,
      facilityId,
      availedRewards
    );
    const filteredRewards = rewards?.rewards?.filter((reward) => {
      for (let customerRewardId of reward?.customerRewardIds) {
        if (firstAvailableRewardIds?.includes(customerRewardId)) return true;
        else continue;
      }
      return false;
    });
    return [firstAvailableRewardIds, filteredRewards];
  }, [rewards, availedRewards, values, facilityId]);

  // const getRewardDescription = (reward) => {
  //   switch (reward.programType) {
  //     case RewardType.Program:
  //       return "Program";
  //     case RewardType.Membership:
  //       return "Membership";
  //     case RewardType["Voucher Code"]:
  //       return "Voucher Code";
  //     case RewardType.Referral:
  //       return "Referral";
  //     case RewardType["Gift Card"]:
  //       return "Gift Card";
  //     default:
  //       return "Reward";
  //   }
  // };

  const getBenefitType = (reward) => {
    switch (reward.benefitType) {
      case BenefitType["Discount Percentage"]:
        return "Discount Percentage";
      case BenefitType["Discount Amount"]:
        return "Discount Amount";
      case BenefitType["Bonus Service"]:
        return "Bonus Service";
      case BenefitType["Referrals"]:
        return "Referral Discount";
      default:
        return "Reward";
    }
  };

  const renderReward = (reward, index) => {
    const description = "";
    const amount = "";
    const expiry = "";
    const reedemableCount = "";

    return (
      <div className="rewardItem">
        <div className="rewardItemHead">
          <Title style={{ fontFamily: "tradeGothic", fontSize: 19, margin: 0 }}>
            {getBenefitType(reward)}
          </Title>
          <Button
            rounded={true}
            onClick={() => onRedeem(firstAvailableRewardId[index])}
            style={{
              padding: "8px 16px",
              fontSize: 15,
            }}
          >
            Redeem
          </Button>
        </div>
        <div className="rewardItemBody">
          {/* <span>Can be used before: {moment(reward)}</span>   */}
          {/* <span>Redemption Count {reward.redemptionLimit}</span> */}
          <Divider type="vertical" />
          <span>
            Expires on:{" "}
            {moment
              .utc(reward.expiryDateUtc)
              .local()
              .format("MMMM DD, yyyy - h:mmA")}
          </span>
        </div>
      </div>
    );
  };

  return (
    <Modal
      destroyOnClose={true}
      className="boostPopup"
      title="Rewards List"
      headerBorder={true}
      visible={visible}
      onCancel={onCancel}
      okText="Save & Order"
      onOk={handleSave}
      //   okButtonProps={{
      //     disabled: !initialValue || initialValue?.length <= 0,
      //   }}
      footer={<></>}
    >
      <Text>Available rewards:</Text>
      <div className="availableRewardsList">
        {availableRewards?.map((reward, index) => {
          return <div key={index}>{renderReward(reward, index)}</div>;
        })}
      </div>
    </Modal>
  );
}
