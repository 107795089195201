import React, { useRef, useMemo } from "react";
import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";

import { Form, Row, Col } from "antd";
import { Button } from "components/Buttons/Buttons";

import moment from "moment";

import {
  FormInput,
  FormSelect,
  FormHiddenSubmitButton,
} from "components/FormItems/FlatFormItems";

import Modal from "components/Modal/Modal";

import {
  getAllPaymentMethods,
  updatePaymentMethod,
} from "services/paymentMethod.service";

function EditCardModal({ visible, handleSave, onCancel, id }) {
  const [form] = Form.useForm();
  const submitBtnRef = useRef();

  const dispatch = useDispatch();
  const isSubmitting = useSelector((state) => state.loading.submitting);

  const years = useMemo(() => {
    const year = Number(moment().year());
    const years = [];
    for (let i = year; i < year + 50; i++) {
      years.push({ value: i, label: i });
    }
    return years;
  }, []);

  return (
    <Modal
      destroyOnClose={true}
      headerBorder={false}
      width="460px"
      title="Edit Card"
      onCancel={onCancel}
      visible={visible}
      onOk={() => handleSave()}
      okText="Save"
      footer={[
        <Button
          rounded={true}
          onClick={() => submitBtnRef?.current?.click()}
          loading={isSubmitting}
        >
          Save
        </Button>,
      ]}
    >
      <Form
        form={form}
        requiredMark={false}
        layout="vertical"
        onFinish={(values) => {
          dispatch(updatePaymentMethod(id, values)).then((result) => {
            if (result?.status === "success") {
              dispatch(getAllPaymentMethods());
              handleSave?.();
            }
          });
        }}
        onFinishFailed={({ values, errorFields, outOfDate }) => {
          form.scrollToField(errorFields[0].name, {
            scrollMode: "if-needed",
            block: "center",
            behavior: "smooth",
          });
        }}
      >
        <Row gutter={[16, 16]}>
          <Col span={12}>
            <FormSelect
              required
              variant="underlined"
              name="expiryMonth"
              label="Expiry Month"
              renderValue="value"
              renderLabel="label"
              placeholder={"Expiry Month"}
              formItemStyles={{ marginBottom: 0 }}
              options={[1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12].map((item) => ({
                key: item,
                label: item,
                value: item,
              }))}
            />
          </Col>
          <Col span={12}>
            <FormSelect
              required
              variant="underlined"
              name="expiryYear"
              label="Expiry Year"
              renderValue="value"
              renderLabel="label"
              placeholder={"Expiry Year"}
              formItemStyles={{ marginBottom: 0 }}
              options={years}
            />
          </Col>
          {/* <Col span={24}>
            <FormInput
              required
              name="name"
              placeholder="Card Holder Name"
              l abel="Card Holder Name"
              variant="underlined"
              formItemStyles={{ marginBottom: 0 }}
            />
          </Col> */}
        </Row>

        <FormHiddenSubmitButton submitRef={submitBtnRef} />
      </Form>
    </Modal>
  );
}

EditCardModal.propTypes = {};

export default EditCardModal;
