import { actions } from "../actions/rewards.action";

const INITIAL_STATE = {
  purchaseableRewards: [],
  rewards: [],
  isLoading: false,
};

const reducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case actions.IS_LOADING:
      return { ...state, isLoading: action.isLoading };
    case actions.SET_REWARDS:
      return { ...state, rewards: action.data };
    case actions.SET_PURCHASEABLE_REWARDS:
      return { ...state, purchaseableRewards: action.data };

    default:
      return state;
  }
};
export default reducer;
