export const actions = {
  SET_REWARDS: "SET_REWARDS",
  SET_PURCHASEABLE_REWARDS: "SET_PURCHASEABLE_REWARDS",
  IS_LOADING: "IS_LOADING",
  RESET: "RESET",
};

export const setRewards = (data) => ({
  type: actions.SET_REWARDS,
  data,
});

export const setLoading = (status) => ({
  type: actions.IS_LOADING,
  isLoading: status,
});

export const resetRewards = () => ({
  type: actions.RESET,
});

export const setPurchaseableRewards = (data) => ({
  type: actions.SET_PURCHASEABLE_REWARDS,
  data,
});
