import React, { useRef } from "react";
import PropTypes from "prop-types";

import { Form, Row, Col } from "antd";
import { Button } from "components/Buttons/Buttons";

import Modal from "components/Modal/Modal";
import {
  FormInput,
  FormInputArea,
  FormButton,
  FormSelect,
  FormPhoneNumber,
  FormHiddenSubmitButton,
  FormDatePicker,
} from "components/FormItems/FlatFormItems";
import { updateUser } from "services/auth.services";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";

import useLocation from "hooks/useLocation";

const COUNTRY_ID = 3;
function PersonalInfoUpdateModal({ visible, handleSave, onCancel }) {
  const [form] = Form.useForm();
  const submitBtnRef = useRef();

  const dispatch = useDispatch();
  const { user } = useSelector((state) => ({
    user: state.user,
  }));
  const { isUpdatingUser } = user;

  // const { countries, states, cities, setState, setCountry } = useLocation({
  //   countryId: COUNTRY_ID,
  //   cityId: user?.currentUser?.cityId || undefined,
  //   stateId: user?.currentUser?.stateId || undefined,
  // });

  return (
    <Modal
      headerBorder={false}
      width="620px"
      title="Update Personal Information"
      onCancel={onCancel}
      visible={visible}
      onOk={() => handleSave()}
      okText="Save"
      footer={[
        <Button
          rounded={true}
          loading={isUpdatingUser}
          onClick={() => submitBtnRef?.current?.click()}
        >
          Save
        </Button>,
      ]}
    >
      <Form
        form={form}
        requiredMark={false}
        layout="vertical"
        initialValues={{
          firstName: user?.currentUser?.firstName || "",
          lastName: user?.currentUser?.lastName || "",
          mobileNo: user?.currentUser?.phoneNumber || "",
          dob: user?.currentUser?.dob
            ? moment(user.currentUser.dob)
            : undefined,
          instruction: "test",
          // streetAddress1: user?.currentUser?.streetAddress1 || "",
          // streetAddress2: user?.currentUser?.streetAddress2 || "",
          // countryId: user?.currentUser?.countryId || COUNTRY_ID || undefined, // USA
          // cityId: user?.currentUser?.cityId || undefined,
          // stateId: user?.currentUser?.stateId || undefined,
          // zipCode: user?.currentUser?.zipCode || undefined,
        }}
        onFinish={(values) => {
          values.dob = moment(values.dob).toISOString();
          dispatch(updateUser(user, values)).then((result) => {
            if (result?.status === "success") handleSave?.();
          });
        }}
        onValuesChange={(changedValues, allValues) => {
          console.log(changedValues, allValues);
        }}
      >
        <Row gutter={[24, 16]}>
          <Col xs={24} md={12}>
            <FormInput
              variant="underlined"
              name="firstName"
              label="First Name"
              placeholder="First Name"
              required={true}
              formItemStyles={{ marginBottom: 0 }}
            />
          </Col>
          <Col xs={24} md={12}>
            <FormInput
              variant="underlined"
              name="lastName"
              label="Last Name"
              placeholder="Last Name"
              required={true}
              formItemStyles={{ marginBottom: 0 }}
            />
          </Col>
          <Col xs={24} md={12}>
            <FormPhoneNumber
              variant="underlined"
              name="mobileNo"
              label="Mobile Number"
              placeholder="Mobile Number"
              required={true}
              formItemStyles={{ marginBottom: 0 }}
              country="us"
              // addonAfter={
              //   <Space className="signupAddonAfterPrimaryText">
              //     <CheckOutlined />
              //     <Typography>Primary</Typography>
              //   </Space>
              // }
            />
          </Col>
          <Col xs={24} md={12}>
            <FormDatePicker
              form={form}
              name="dob"
              label="Date of Birth"
              variant="underlined"
              formItemStyles={{ marginBottom: 0 }}
              required
              validateFirst
              // defaultValue={moment("01-01-1994", "DD-MM-YYYY")}
              rules={[
                {
                  required: true,
                  message: "*Required",
                },
                // {
                //   message:
                //     "Your age should be between 18 and 85. Kindly contact our support.",
                //   validator: (_, value) => {
                //     if (
                //       value.isSameOrBefore(moment().subtract(18, "years")) &&
                //       value.isAfter(moment().subtract(85, "years"))
                //     )
                //       return Promise.resolve();
                //     else return Promise.reject();
                //   },
                // },
              ]}
              disabledDate={(current) =>
                current.isSameOrAfter(
                  moment().set({
                    hour: 0,
                    minute: 0,
                    second: 0,
                    millisecond: 0,
                  })
                )
              }
            />
          </Col>
          {/* <Col xs={24} md={12}>
            <FormInput
              variant="underlined"
              name="streetAddress1"
              label="Street Address"
              placeholder="Street address"
              required={true}
              formItemStyles={{ marginBottom: 0 }}
            />
          </Col>
          <Col xs={24} md={12}>
            <FormInput
              variant="underlined"
              name="streetAddress2"
              label="Street Address 2"
              placeholder="Street address 2"
              formItemStyles={{ marginBottom: 0 }}
            />
          </Col>
          <Col xs={24} md={12}>
            <FormSelect
              variant="underlined"
              name="stateId"
              label="State"
              placeholder="State"
              required={true}
              formItemStyles={{ marginBottom: 0 }}
              options={states}
              renderLabel="locationName"
              renderValue="locationId"
              onChange={(value, option) => {
                setState(4);
              }}
            />
          </Col>
          <Col xs={24} md={12}>
            <FormSelect
              variant="underlined"
              name="cityId"
              label="City"
              placeholder="City"
              required={true}
              formItemStyles={{ marginBottom: 0 }}
              options={cities}
              renderLabel="locationName"
              renderValue="locationId"
            />
          </Col>
          <Col xs={24} md={12}>
            <FormInput
              variant="underlined"
              name="zipCode"
              label="Zip Code"
              placeholder="Zip Code"
              required={true}
              normalize={(value) => {
                return (value || "").toUpperCase();
              }}
              formItemStyles={{ marginBottom: 0 }}
            />
          </Col>
          <Col xs={0} md={12}></Col> */}
          {/* <Col span={24}>
            <FormInputArea
              variant="underlined"
              rows={6}
              name="instructions"
              label="Instructions"
              placeholder="General Instructions if any!"
              formItemStyles={{ marginBottom: 0 }}
            />
          </Col> */}
        </Row>
        {/* <FormInput hidden={true} name="mobileNo" initialVa lue={""}/> */}
        <FormInput hidden={true} name="instruction" initialValue={"test"} />

        {/* <FormInput hidden={true} name="countryId" initialValue={COUNTRY_ID} /> */}

        <FormHiddenSubmitButton submitRef={submitBtnRef} />
      </Form>
    </Modal>
  );
}

PersonalInfoUpdateModal.propTypes = {};

export default PersonalInfoUpdateModal;
