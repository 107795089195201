import { React, useEffect, useRef, useState } from "react";
import { Space, Checkbox, Form, Grid, Tooltip } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { push, replace } from "connected-react-router";

import RoundButton from "../../components/RoundButton/RoundButton";
import "./Dashboard.scss";

import GuestForm from "./GuestForm";

import { setCurrentBooking } from "redux/actions/booking.action";
import { getItem, setItem, removeItem } from "utils/storage";
import { validateRestriction } from "services/validate.services";
import { useLocation } from "react-router";
import queryString from "query-string";

import moment from "moment";

const { useBreakpoint } = Grid;

const Dashboard = () => {
  const [form] = Form.useForm();
  const ref = useRef();
  const screenSizes = useBreakpoint();
  const [timeZone, setTimeZone] = useState("");
  const location = useLocation();
  const qs = queryString.parse(location.search);

  const mainTime = Form.useWatch("time", form);
  const mainDate = Form.useWatch("date", form);

  const [termsAndConditions, setTermsAndCondition] = useState("");
  const [goodFaithExamPolicy, setGoodFaithExamPolicy] = useState("");
  const dispatch = useDispatch();
  const { user } = useSelector((state) => {
    return { user: state.user };
  });
  const { miscSettings } = useSelector((state) => ({
    miscSettings: state.misc.miscSettings,
  }));

  const { currentUser, isTokenVerified } = user;

  const handleSubmit = async (values) => {
    // const dateTime = mainDate?.format("YYYY-MM-DD") + " " + mainTime;

    let dateTime = "";
    if (mainDate && mainTime) {
      dateTime = moment(mainDate?.format("L") + " " + mainTime ?? "").format();
    }

    const { facilityId } = form.getFieldsValue();
    let valuesToValidate = [
      {
        lineItems: [
          {
            productId: values.productId,
            addOns:
              values?.addOns?.map?.((addOn) => ({
                productId: addOn,
                productType: 2,
              })) ?? [],
          },
        ],
        serviceDate: dateTime,
        customerId: currentUser?.customerId,
      },
    ];

    for (let guest of values?.guests ?? []) {
      valuesToValidate.push({
        firstName: guest.firstName,
        lastName: guest.lastName,
        email: guest.email,
        lineItems: [
          {
            productId: guest.productId,
            firstName: guest.firstName,
            lastName: guest.lastName,
            email: guest.email,
            addOns:
              guest?.addOns?.map?.((addOn) => ({
                productId: addOn,
                productType: 2,
              })) ?? [],
          },
        ],
        // facilityId: facilityId,
        // timeZone: timeZone,
        serviceDate: dateTime,
      });
    }

    if (isTokenVerified) {
      // const allAddons = values.addOns ? values.addOns : [];
      dispatch(
        validateRestriction(facilityId, valuesToValidate, dateTime)
      ).then((result) => {
        if (!result?.mstatus) {
          // form.setFieldsValue({
          //   productId: undefined,
          // });
        } else {
          values.status = "pending";
          values.isBookingClicked = true;
          dispatch(savePendingOrder(values)).then(() => {
            if (currentUser && isTokenVerified) handleRedirect("/thankyou");
            // else dispatch(push("/login?redirect=thankyou"));
          });
        }
      });
    } else {
      values.status = "pending";
      values.isBookingClicked = true;
      dispatch(savePendingOrder(values)).then(() => {
        handleRedirect("/login?redirect=thankyou");
      });
    }
  };

  function handleRedirect(url) {
    if (qs.type === "widget") {
      dispatch(replace(url));
    } else {
      dispatch(push(url));
    }
  }

  useEffect(() => {
    if (miscSettings) {
      const TAndC = miscSettings.find((res) => {
        if (res.key == "TAndC") {
          return res;
        }
      });
      const goodFaithExamPolicy = miscSettings.find((res) => {
        if (res.key == "GoodFaithExamPolicy") {
          return res;
        }
      });
      setTermsAndCondition(TAndC?.value || "");
      setGoodFaithExamPolicy(goodFaithExamPolicy?.value || "");
    }
  }, [miscSettings]);

  const savePendingOrder = (data) => {
    return (dispatch) => {
      return new Promise((resolve, reject) => {
        dispatch(setCurrentBooking(data));
        setItem("pendingOrder", data);
        resolve();
      });
    };
  };

  return (
    <div className="dashContainer">
      <div className="dashGuestContainer">
        <p className="dashHeaderTitle hendersonSemiBold">
          Your performance. Our nutrients.
        </p>
        <GuestForm
          submitRef={ref}
          form={form}
          handleSubmit={handleSubmit}
          timeZone={timeZone}
          setTimeZone={setTimeZone}
        />
        <div
          style={{
            display: "flex",
            alignItems: "center",
            flexDirection: "column",
            padding: "0px 32px",
          }}
        >
          <RoundButton
            onClick={() => {
              ref.current.click();
              // dispatch(push("/thankyou")); // only for demo purpoe
            }}
          />
          <div className="policyLinksContainer">
            <Tooltip
              overlayStyle={{ maxWidth: 640 }}
              placement="bottom"
              title={termsAndConditions}
            >
              <p className="dashCancellationPolicyLink">Cancellation Policy</p>
            </Tooltip>
            <Tooltip
              overlayStyle={{ maxWidth: 640 }}
              placement="bottom"
              title={goodFaithExamPolicy}
            >
              <p className="dashCancellationPolicyLink">
                Good Faith Exam Policy
              </p>
            </Tooltip>
          </div>
        </div>
      </div>
      <div className="dashFooterContainer">
        <p className="dashFooterBoldText">Can’t find a time?</p>
        {screenSizes.xs && <br />}
        <p className="dashFooterBoldText">Call us to book:</p>
        <a className="dashFooterBlueText" href="tel:+18559547843">
          (855) 954-7843{" "}
        </a>
        {screenSizes.xs && <br />}
        <p className="dashFooterBoldText"> Or Email: </p>
        <a
          className="dashFooterBlueText"
          href="mailto:scheduling@liquidmobileiv.com"
        >
          scheduling@liquidmobileiv.com
        </a>
      </div>
    </div>
  );
};

export default Dashboard;
