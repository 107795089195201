import React, { useEffect, useRef, useState, useMemo } from "react";
import { useSelector, useDispatch } from "react-redux";

import { Checkbox, Form, Select, Dropdown, Empty, Grid } from "antd";

import Glutathione from "assets/images/Glutathione.png";
import "./ServicesDropdown.scss";

import Spinner from "components/Spinner/Spinner";

import uniqBy from "lodash/uniqBy";
import MobileServiceDropdown from "./MobileServiceDropdown";

const FormItem = Form.Item;
const Option = Select.Option;

const { useBreakpoint } = Grid;

function ServicesDropdownNew({
  form,
  listName,
  name,
  initialValue,
  disabled,
  onChange,
}) {
  const [visible, setVisible] = useState(false);
  const [categoryId, setCategoryId] = useState(undefined);
  const screens = useBreakpoint();
  const isSmallDevice = !screens.md;

  const dispatch = useDispatch();
  const handleVisibleChange = (flag) => {
    setVisible(flag);
  };

  const { products, isFetchingProducts } = useSelector((state) => {
    return state.locationCenter;
  });

  const categories = useMemo(() => {
    function getUniqueListBy(arr, key) {
      const cat = [...new Map(arr.map((item) => [item[key], item])).values()];
      return cat.map((item) => ({
        name: item.productCategoryName,
        id: item.productCategoryId,
      }));
    }

    const categoryObjects = [];
    for (let i = 0; i < products?.length; i++) {
      const product = products[i];
      if (product.productCategoryNames) {
        for (let j = 0; j < product.productCategoryNames.length; j++) {
          const id = product?.productCategoryIds?.[j];
          const name = product?.productCategoryNames?.[j];
          categoryObjects.push({
            productCategoryId: id,
            productCategoryName: name,
          });
        }
      }
    }
    const categorySet = getUniqueListBy(categoryObjects, "productCategoryName");
    const categoriesList = [...categorySet].sort((a, b) => {
      if (a.name?.toLowerCase?.() < b.name?.toLowerCase?.()) return -1;
      if (a.name?.toLowerCase?.() > b.name?.toLowerCase?.()) return 1;
      return 0;
    });
    // if (categoriesList.length > 0) setCategoryId(categoriesList?.[0]?.id);
    setCategoryId();
    return [{ name: "All", value: null }, ...categoriesList];
  }, [products]);

  const categoryProducts = useMemo(() => {
    let categoryProducts = products.filter((product) => {
      if (categoryId)
        return (
          product.productCategoryId === categoryId ||
          product?.productCategoryIds?.includes(categoryId)
        );
      else return true;
    });
    categoryProducts = categoryProducts.sort((a, b) => {
      if (a.productName < b.productName) return -1;
      if (a.productName > b.productName) return 1;
      return 0;
    });
    return categoryProducts;
  }, [products, categoryId]);

  // TODO: Update this function in case multiple services are allowed
  // const onServiceChangeMultiple = (value) => {
  //   if (listName) {
  //     let list = form.getFieldValue(listName);
  //     let previousValue = list[name[0]][name[1]];
  //     list[name[0]][name[1]] = [...previousValue, value];
  //     form.setFieldsValue({ names: list });
  //   } else {
  //     const services = form.getFieldValue(name);
  //     if (services) {
  //       if (services.includes(value)) {
  //         const updatedservices = services.filter((b) => b !== value);
  //         form.setFieldsValue({ [name]: updatedservices });
  //       } else {
  //         form.setFieldsValue({ [name]: [...services, value] });
  //       }
  //     } else {
  //       form.setFieldsValue({ [name]: [value] });
  //     }
  //   }
  //   setVisible(false);
  // };

  const onServiceChangeSingle = (value) => {
    if (onChange) onChange(value);
    if (listName) {
      let list = form.getFieldValue(listName);
      let updatedList = list.map((item, index) => {
        if (index === name[0]) return { ...item, [name[1]]: value };
        return item;
      });
      form.setFieldsValue({ [listName]: updatedList });
      setVisible(false);
    } else {
      form.setFieldsValue({ [name]: value });
      setVisible(false);
    }
  };

  const dropdownContent = () => {
    if (categoryProducts.length === 0) {
      return (
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            background: "#fff",
            boxShadow: "0px 5px 38px rgba(0,0,0,0.25)",
            borderRadius: 14,
            padding: 20,
          }}
        >
          <Empty
            description='No Service Available'
            style={{
              margin: "0 auto",
            }}
          />
        </div>
      );
    }
    return (
      <MobileServiceDropdown
        initialValue={initialValue}
        categories={categories?.filter((c) => c.name !== "All")}
        categoryProducts={categoryProducts}
        onServiceChange={onServiceChangeSingle}
      />
    );
  };

  return (
    <div className='dashboardDropdown'>
      <Dropdown
        placement='bottomLeft'
        overlay={dropdownContent}
        trigger={["click"]}
        onVisibleChange={handleVisibleChange}
        visible={visible}
        getPopupContainer={(triggerNode) => triggerNode?.parentNode}
      >
        <FormItem
          style={{
            border: "2px solid #DBDFEA",
            borderRadius: 14,
            height: 84,
            margin: 0,
            position: "relative",
            overflow: "hidden",
          }}
          label={"SERVICES"}
          initialValue={initialValue}
          name={name}
          rules={[
            {
              required: true,
            },
          ]}
          className={`dashboardSelector
          noError roundedSelect
          hideValue ${categoryProducts.length > 0 ? "showValue" : ""} `}
          placeholder={"Select Service"}
          dropdownStyle={{ display: "none" }}
        >
          <Select
            getPopupContainer={(trigger) => trigger.parentNode}
            removeIcon={null}
            disabled={disabled}
            // mode="multiple"
            style={{
              border: "none",
              flexWrap: "nowrap",
              overflow: "auto",
              cursor: "pointer",
            }}
            className={`hideValue ${
              categoryProducts.length > 0 ? "showValue" : ""
            } `}
            placeholder={"Select Service"}
            dropdownStyle={{ display: "none" }}
            suffixIcon={isFetchingProducts ? <Spinner /> : undefined}
          >
            {categoryProducts.map((product) => {
              return (
                <Option key={product.productId} value={product.productId}>
                  {product.productName}
                  {/* {product.durationHours !== undefined &&
                    Number(product.durationHours) >= 1 &&
                    ` (${Number(product.durationHours).toFixed(0)} hr)`} */}
                </Option>
              );
            })}
          </Select>
        </FormItem>
      </Dropdown>
    </div>
  );
}

ServicesDropdownNew.propTypes = {};

export default ServicesDropdownNew;
