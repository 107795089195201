import React from "react";
import { Col, Form, Row, Space, Spin, Typography } from "antd";
import { FormButton, FormCheckbox } from "components/FormItems/FlatFormItems";
import InfoText from "components/InfoText/InfoText";

import "./MiniPaymentRow.scss";

const { Title, Text } = Typography;
export default function MiniPaymentRow({
  loading,
  onSuccess,
  paymentMethod,
  saveText,
  submitting,
}) {
  const { paymentMethodId, brand, last4Digits } = paymentMethod;

  return (
    <Row gutter={[24, 16]}>
      <Col span={11}>
        <Space style={{ width: "100%", justifyContent: "space-between" }}>
          <Title
            level={4}
            style={{
              fontFamily: "tradeGothic",
              fontSize: 17,
              color: "#000",
              margin: 0,
            }}
          >
            Payment
          </Title>
          {loading ? (
            <Spin style={{ marginLeft: 28 }} />
          ) : (
            <>
              {paymentMethod && (
                <div className="cardDetails" key={paymentMethodId}>
                  <span
                    style={{ width: 30, height: 26 }}
                    className={`cardChip issuer-${brand}`}
                  >
                    &nbsp;
                  </span>
                  <Text className="cardBrandName">{brand}</Text>
                  <Text style={{}}>ending in {last4Digits}</Text>
                </div>
              )}
            </>
          )}
        </Space>
      </Col>
      <Col span={13}>
        <InfoText>
          Your card will not be charged until after the service is complete.
        </InfoText>
      </Col>
      <Form
        initialValues={{
          optNewsletter: false,
        }}
        onFinish={onSuccess}
      >
        <FormCheckbox name="optNewsletter" className="stripeFormCheckbox">
          I agree to receive customer support, appointment, newsletter,
          educational and promotional messages. Typically messages are sent by
          Liquid with each appointment, in addition to a couple of times per
          month if you don’t have an appointment. Messages and data rates may
          apply. Reply STOP to no longer receive.{" "}
          <Typography.Link target="_blank" href="https://liquidmobileiv.com/privacy-policy/">
            Privacy Policy
          </Typography.Link>
          .
        </FormCheckbox>
        <div className="flex alignCenter" style={{ marginTop: 16 }}>
          <FormButton
            text={saveText}
            type="submit"
            loading={submitting}
            formItemStyles={{ marginBottom: 0 }}
            // onClick={e=>{
            //   e.preventDefault();
            //   onSuccess()
            // }}
            style={{
              margin: "0px 16px 0px 0px",
              padding: " 16px 50px",
              borderRadius: 50,
              fontFamily: "tradeGothicBold",
            }}
          />
          {/* <Button type="text">Or Proceed</Button> */}
        </div>
      </Form>
    </Row>
  );
}
