import React, { useEffect, useState, useMemo } from "react";
import PropTypes from "prop-types";

import "./Account.scss";

import MenuSidebar from "components/MenuSidebar/MenuSidebar";
import { useDispatch } from "react-redux";
import { push } from "connected-react-router";

import { useHistory } from "react-router-dom";

import accountRoutes from "utils/accountRoutes";

function AccountSidebar(props) {
  const dispatch = useDispatch();
  const history = useHistory();

  const { location } = history;
  const [activeKey, setActiveKey] = useState(0);

  const getSelectedItemIndex = () => {
    if (location.pathname === "/account") {
      return 0;
    } else {
      const index = sortedAccountRoutes?.findIndex((item) => {
        return location.pathname === `/account/${item.urlName}`;
      });
      if (index >= 0) {
        return index;
      } else {
        return 0;
      }
    }
  };

  const sortedAccountRoutes = useMemo(() => {
    return [...accountRoutes]?.sort((a, b) => {
      if (a.order === undefined && b.order === undefined) {
        return 0;
      }
      return a.order ?? -1 < b.order ?? -1 ? 1 : -1;
    });
  }, []);


  useEffect(() => {
    const index = getSelectedItemIndex();
    setActiveKey(index);
  }, [location.pathname, sortedAccountRoutes]);

  return (
    <>
      <MenuSidebar
        activeKey={activeKey}
        items={sortedAccountRoutes}
        onClick={(url, key) => {
          setActiveKey(key);
          dispatch(push(`/account/${url}`));
        }}
      />
    </>
  );
}

AccountSidebar.propTypes = {};

export default AccountSidebar;
