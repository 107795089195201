import React, { useRef } from "react";
import PropTypes from "prop-types";

import { Form } from "antd";
import { Button } from "components/Buttons/Buttons";

import {
  FormPassword,
  FormHiddenSubmitButton,
} from "components/FormItems/FlatFormItems";

import Modal from "components/Modal/Modal";

import { changePassword } from "services/auth.services";
import { useDispatch, useSelector } from "react-redux";

function ChangePasswordModal({ visible, handleSave, onCancel }) {
  const [form] = Form.useForm();
  const submitBtnRef = useRef();

  const dispatch = useDispatch();
  const { submitting } = useSelector((state) => state.loading);

  return (
    <Modal
      destryOnClose={true}
      headerBorder={false}
      width="460px"
      title="Change Password"
      onCancel={onCancel}
      visible={visible}
      onOk={() => handleSave()}
      okText="Save"
      footer={[
        <Button
          rounded={true}
          loading={submitting}
          onClick={() => submitBtnRef?.current?.click()}
        >
          Save
        </Button>,
      ]}
    >
      <Form
        preserve={false}
        form={form}
        requiredMark={false}
        layout="vertical"
        onFinish={async (values) => {
          const { currentPassword, newPassword } = values;
          const result = await dispatch(
            changePassword({ currentPassword, newPassword })
          );
          if (result?.status === "success") {
            handleSave?.();
            form.resetFields();
          }
        }}
        onFinishFailed={({ values, errorFields, outOfDate }) => {
          form.scrollToField(errorFields[0].name, {
            scrollMode: "if-needed",
            block: "center",
            behavior: "smooth",
          });
        }}
      >
        <FormPassword
          required
          variant="underlined"
          name="currentPassword"
          label="Current Password"
          placeholder=""
          formItemStyles={{ marginBottom: 12 }}
        />

        <FormPassword
          required
          variant="underlined"
          name="newPassword"
          label="New Password"
          placeholder=""
          formItemStyles={{ marginBottom: 12 }}
          rules={[
            {
              required: true,
              message: "*Required",
            },
            {
              message: "Password cannot be same as previous password",
              validator: (_, value) => {
                const password = form.getFieldValue("currentPassword");
                const newPassword = form.getFieldValue("newPassword");
                if (password === newPassword) {
                  return Promise.reject();
                }
                return Promise.resolve();
              },
            },
          ]}
        />
        <FormPassword
          required
          variant="underlined"
          name="confirmPassword"
          label="Confirm New Password"
          placeholder=""
          dependencies={["newPassword"]}
          formItemStyles={{ marginBottom: 12 }}
          rules={[
            {
              required: true,
              message: "*Required",
            },
            {
              message: "New and Confirm passwords don't match",
              validator: (_, value) => {
                const newPassword = form.getFieldValue("newPassword");
                const confirmPassword = form.getFieldValue("confirmPassword");
                if (newPassword !== confirmPassword) {
                  return Promise.reject();
                }
                return Promise.resolve();
              },
            },
          ]}
        />
        <FormHiddenSubmitButton submitRef={submitBtnRef} />
      </Form>
    </Modal>
  );
}

ChangePasswordModal.propTypes = {};

export default ChangePasswordModal;
