export const actions = {
  SET_BOOKINGS: "SET_BOOKINGS",
  ADD_BOOKING: "ADD_BOOKING",
  UPDATE_BOOKING: "UPDATE_BOOKING",
  DELETE_BOOKING: "DELETE_BOOKING",
  SET_CURRENT_BOOKING: "SET_CURRENT_BOOKING",
  RESET: "RESET",
};

export const setCurrentBooking = (data) => ({
  type: actions.SET_CURRENT_BOOKING,
  data,
});

export const setBookings = (data) => ({
  type: actions.SET_BOOKINGS,
  data,
});

export const addBooking = (data) => ({
  type: actions.ADD_BOOKING,
  data,
});

export const updateBooking = (data) => ({
  type: actions.UPDATE_BOOKING,
  data,
});

export const deleteBooking = (orderId) => ({
  type: actions.DELETE_BOOKING,
  orderId,
});

export const resetBooking = () => ({
  type: actions.RESET,
});
