import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";

import { List } from "antd";
import { Button } from "components/Buttons/Buttons";

import AddressCard from "./AddressCard";
import "./Addresses.scss";

import { getAllAddresses } from "services/addresses.service";
import AddAddressModal from "./components/AddAddressModal";

const DUMMY_ADDRESSES = [
  {
    streetAddress: "15208 West 119th Street",
    state: "Cedar Basehor", // probably datetime
    city: "Kansas City",
    zipCode: "KS 66103", // probably int
    instructions: "",
  },
  {
    streetAddress: "1234 East 4th Street",
    state: "Cedar Basehor", // probably datetime
    city: "Kansas City",
    zipCode: "KS 66103", // probably int
    instructions: "",
  },
];

export default function Addresses() {
  const { addresses } = useSelector((state) => {
    return {
      addresses: state.addresses.addresses,
    };
  });
  const dispatch = useDispatch();
  const [showAddressAdd, setShowAddressAdd] = useState(false);

  useEffect(() => {
    dispatch(getAllAddresses());
  }, []);

  return (
    <>
      <List
        className="addressesList"
        itemLayout="vertical"
        size="large"
        dataSource={addresses}
        renderItem={(address, index) => (
          <List.Item key={index}>
            <AddressCard
              key={address.addressId}
              // streetAddress={address.streetAddress}
              // state={address.state}
              // city={address.city}
              // zipCode={address.zipCode}
              addressId={address.addressId}
              isPrimary={address.isPrimary}
              address={address.address}
              instructions={address.instructions}
            />
          </List.Item>
        )}
      />
      <Button
        onClick={() => setShowAddressAdd(true)}
        variant="filled"
        style={{
          borderRadius: 50,
          width: "100%",
          maxWidth: 228,
          marginTop: 12,
        }}
      >
        Add New Address
      </Button>
      {showAddressAdd && (
        <AddAddressModal
          visible={showAddressAdd}
          onCancel={() => setShowAddressAdd(false)}
          handleSave={() => {
            setShowAddressAdd(false);
          }}
          title={"Add New Address"}
          type="add"
        />
      )}
    </>
  );
}
