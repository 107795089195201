import React from "react";
import PropTypes from "prop-types";

import "./Buttons.scss";

import { Button as AntdButton } from "antd";

import { ReactComponent as GoogleLogo } from "assets/icons/googleLogo.svg";
import { ReactComponent as FacebookLogo } from "assets/icons/facebookLogo.svg";
import { ReactComponent as AppleLogo } from "assets/icons/appleLogo.svg";

import FacebookLogin from "react-facebook-login";
import GoogleLogin from "react-google-login";
import AppleLogin from "react-apple-login";

export const Button = ({
  type,
  children,
  size,
  style,
  onClick,
  loading,
  disabled,
  variant = "filled",
  className,
  rounded = false,
  icon = null,
}) => {
  return (
    <AntdButton
      icon={icon}
      size={size}
      onClick={onClick ? onClick : () => {}}
      style={style}
      loading={loading}
      disabled={disabled}
      htmlType={type ? type : "button"}
      className={`
      button
      ${variant === "filled" ? "flatButtonFilled" : "flatButtonOutlined"} 
      ${rounded ? "rounded" : ""}
      ${className}`}
    >
      {children}
    </AntdButton>
  );
};

Button.propTypes = {
  type: PropTypes.string,
  children: PropTypes.node.isRequired,
  size: PropTypes.oneOf(["small", "medium", "large"]),
  style: PropTypes.object,
  onClick: PropTypes.func,
  loading: PropTypes.bool,
  disabled: PropTypes.bool,
  variant: PropTypes.oneOf(["filled", "outlined"]),
  className: PropTypes.string,
  rounded: PropTypes.bool,
};

export const SocialButton = ({
  type,
  text,
  size,
  style,
  onClick,
  disabled,
  onSuccess,
}) => {
  const getEnv = () => {
    const appEnv = window.location.host.split(".")[0];
    switch (appEnv) {
      case "gentle-grass-09eed8a10":
        return "predev";
      case "purple-sand-066809e10":
        return "test";
      case "nice-meadow-08f429810":
        return "uat";
      case "black-pebble-06b1a7010":
        return "prod";
      case "elevate":
        return "prodelevate";
      default:
        return "local";
    }
  };

  const redirectUrl = () => {
    switch (getEnv()) {
      case "uat":
        return "https://nice-meadow-08f429810.1.azurestaticapps.net";
      case "prod":
        return "https://black-pebble-06b1a7010.1.azurestaticapps.net";
      case "prodelevate":
        return "https://elevate.liquidmobileiv.com";
      case "predev":
        return "https://gentle-grass-09eed8a10.2.azurestaticapps.net";
      default:
        return "https://purple-sand-066809e10.1.azurestaticapps.net";
    }
  };

  if (type === "facebook") {
    return (
      <FacebookLogin
        appId="1428592220917096"
        autoLoad={false}
        fields="first_name,last_name,name, email"
        callback={(response) => onSuccess(response)}
        icon={<FacebookLogo className="socialButtonIcon" />}
        textButton={text}
        cssClass="socialButton btn-facebook"
        scope="public_profile,email"
      />
    );
  } else if (type === "google") {
    return (
      <GoogleLogin
        redirectUri="postmessage"
        className="socialButton btn-google"
        clientId="344231112126-j03526l04l32r3lrugs15jsvlej7b6ha.apps.googleusercontent.com"
        onSuccess={(response) => {
          onSuccess(response);
        }}
        onFailure={(error) => {
          console.log("Google error", error);
        }}
        buttonText={text}
        icon={true}
      />
    );
  } else if (type === "apple") {
    return (
      <AppleLogin
        usePopup={true}
        clientId="49c6-182-185-82-98.in.ngrok.io"
        redirectURI={redirectUrl()}
        callback={(response) => {
          onSuccess(response);
          // console.log("Apple response", response)
        }}
        buttonText="Google"
        scope={"name email"}
        responseType={"id_token"}
        responseMode={"query"}
        render={(props) => {
          return (
            <AntdButton
              className="socialButton btn-apple"
              type="apple"
              {...props}
              icon={<AppleLogo className="socialButtonIcon" />}
            >
              {text}
            </AntdButton>
          );
        }}
      />
    );
  }

  return (
    <AntdButton onClick={onClick} disabled={disabled}>
      <span>{text}</span>
    </AntdButton>
  );
};

SocialButton.propTypes = {
  type: PropTypes.oneOf(["google", "facebook", "apple"]).isRequired,
  children: PropTypes.node.isRequired,
  size: PropTypes.oneOf(["small", "medium", "large"]),
  style: PropTypes.object,
  onClick: PropTypes.func,
};
