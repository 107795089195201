import React, {
  useRef,
  useMemo,
  useState,
  useEffect,
  useLayoutEffect,
  useCallback,
} from "react";
import { useDispatch, useSelector } from "react-redux";

import { Checkbox, Form, Grid, Row, Col } from "antd";
import moment from "moment";
import {
  FormDatePicker,
  FormHiddenSubmitButton,
  FormInput,
  FormSelect,
} from "../../components/FormItems/RoundedFormItems";

import GuestFormRow from "./GuestFormRow";

import BoostsDropdown from "../../components/BoostsDropdown/BoostsDropdown";
import ServicesDropdown from "../../components/ServicesDropdown/ServicesDropdown";

import useLocationCenter from "hooks/useLocationCenters";
import {
  generateHourlySlots,
  generateHourlytimeslotsAfterNHours,
  getRewardId,
} from "utils/common";
import LocationDropdown from "components/LocationDropDown/LocationDropdown";
import { getItem, setItem, removeItem } from "utils/storage";
import Spinner from "components/Spinner/Spinner";
import BoostPopup from "components/BoostPopup/BoostPopup";

import StripeAddCardModal from "components/StripeAddCard/StripeAddCardModal";

import { setTimer } from "services/timer.service";
import Geocode from "react-geocode";
import {
  getLocationsByStreetAddress,
  getFacilityTimings,
} from "services/locationCenter.services";
import { openNotificationWithIcon } from "utils/Notification";

import { setCurrentBooking } from "redux/actions/booking.action";
import Modal from "components/Modal/Modal";
import IntakeForms from "containers/Account/Screens/IntakeForms/IntakeForms";
import { useForm } from "antd/lib/form/Form";
import { getClinicFacilities } from "services/booking.service";

import queryString from "query-string";
import { useLocation } from "react-router";
import { setMiscSettings } from "services/misc.service";
import useIntakeForms from "hooks/useIntakeForms";
import { validateRestriction } from "services/validate.services";
import { ExcludeType } from "utils/enums";

Geocode.setApiKey("AIzaSyA0eoYnn4K0ShoUxhjYKPThufrjtRckOnA");
const APPOINTMENT_HOURS_DIFF = 2;

const GuestCounts = [
  { label: "1 Person", value: 1 },
  { label: "2 Person", value: 2 },
  { label: "3 Person", value: 3 },
  { label: "4 Person", value: 4 },
  { label: "5 Person", value: 5 },
  { label: "6 Person", value: 6 },
  { label: "7 Person", value: 7 },
  { label: "8 Person", value: 8 },
  { label: "9 Person", value: 9 },
  { label: "10 Person", value: 10 },
];

const DEFAULT_FORM_VALUES = {
  operationTypeId: undefined,
  serviceAddress: undefined,
  facilityId: undefined,
  productId: undefined,
  date: undefined,
  time: undefined,
};

export default function GuestForm({
  form,
  handleSubmit,
  submitRef,
  timeZone,
  setTimeZone,
  isMultiGuest = true,
}) {
  const location = useLocation();
  const qs = queryString.parse(location.search);

  const addItemFunctionRef = useRef();
  const [showBoost, setShowBoost] = useState(false);
  const [showIntakeForms, setShowIntakeForms] = useState(false);
  const [continueWithoutBoosts, setContinueWithoutBoosts] = useState(
    qs.type == "widget" ? true : false
  );
  const [shouldSubmit, setShouldSubmit] = useState(false);
  const [showAddCard, setShowAddCard] = useState(false);
  const [timeslots, setTimeslots] = useState({
    isClosed: false,
    isLoading: false,
    data: [],
  });
  const dispatch = useDispatch();
  const {
    profiles,
    user,
    booking,
    paymentMethod: { paymentMethods },
    isTokenVerified,
    timer,
    addresses,
    intakeForms,
    facilities,
    rewards,
    miscSettings,
  } = useSelector((state) => ({
    profiles: state.user.profiles,
    user: state.user.currentUser,
    isTokenVerified: state.user.isTokenVerified,
    booking: state.booking,
    locationCenter: state.locationCenter,
    paymentMethod: state.paymentMethod,
    timer: state.timer.timer,
    addresses: state.addresses.addresses,
    intakeForms: state.intakeForms,
    facilities: state.facilities.facilities,
    rewards: state.rewards,
    miscSettings: state.misc.miscSettings,
  }));

  const [isAddressChanged, setIsAddressChanged] = useState(false);

  const { currentBooking: order } = booking ?? {};
  const isOrderPending =
    order?.status === "pending" && order?.productId !== undefined
      ? true
      : false;
  const currentUser = useSelector((state) => state.user.currentUser);
  const {
    locationCenters,
    setStreetAddress,
    setProduct,
    addOns,
    error: streetAddressError,
    isFetchingCoords,
    isFetchingAddons,
    clearLocationCenterData,
    setClinic,
  } = useLocationCenter({
    initialOperationTypeId: qs?.operationTypeId
      ? Number(qs.operationTypeId)
      : isOrderPending
      ? order?.operationTypeId
      : undefined,
    initialStreetAddress: qs?.serviceAddress
      ? qs.serviceAddress
      : isOrderPending
      ? order?.serviceAddress
      : undefined,
    initialFacilityId: qs?.facilityId
      ? Number(qs.facilityId)
      : isOrderPending
      ? order?.facilityId
      : undefined,
    intialProductId: qs?.productId
      ? Number(qs.productId)
      : isOrderPending
      ? order?.productId
      : undefined,
  });

  const { centers, operationTypes, products, isFetchingCenters } =
    locationCenters;

  const [scheduledDate, setScheduledDate] = useState(
    timer && moment().isSameOrBefore(moment(timer)) && isOrderPending
      ? moment(order.date)
      : undefined
  );

  const {
    isHistoryFormSubmitted,
    isConsentFormSubmitted,
    isHistoryFormLegacy,
    isConsentFormLegacy,
    isPhysicalHistoryExpired,
    isConsentExpired,
  } = useIntakeForms();

  const intakeFormsExpired = isPhysicalHistoryExpired || isConsentExpired;
  const intakeFormsSubmitted =
    (isHistoryFormSubmitted || isHistoryFormLegacy) &&
    (isConsentFormSubmitted || isConsentFormLegacy);

  const selectedAddOns = Form.useWatch("addOns", form) ?? [];
  const operationTypeId = Form.useWatch("operationTypeId", form);
  const facilityId = Form.useWatch("facilityId", form);
  const savePendingOrder = (data) => {
    return (dispatch) => {
      return new Promise((resolve, reject) => {
        dispatch(setCurrentBooking(data));
        setItem("pendingOrder", data);
        resolve();
      });
    };
  };

  const mainProductId = Form.useWatch("productId", form);
  const mainTime = Form.useWatch("time", form);
  const mainDate = Form.useWatch("date", form);

  // useEffect(() => {
  //   if (mainTime) {
  //     validateSelection(mainProductId);
  //   }
  // }, [mainTime]);

  useEffect(() => {
    if (mainProductId && mainTime) {
      validateSelection(mainProductId);
    }
  }, [mainTime]);

  // useEffect(() => {
  //   if (addOnChange && mainProductId) {
  // validateSelection(mainProductId);
  //     setAddOnChange(false);
  //   }
  // }, [addOnChange]);

  useEffect(() => {
    const { operationTypeId, facilityId, productId } = form.getFieldsValue();
    if (operationTypeId === 1 && productId === undefined) {
      populateAddressField(operationTypeId);
    }
  }, [currentUser]);

  const validateSelection = (productID) => {
    let dateTime = "";
    if (mainDate && mainTime) {
      dateTime = moment(mainDate?.format("L") + " " + mainTime ?? "").format();
    }

    const { operationTypeId, facilityId } = form.getFieldsValue();
    // [
    //   {
    //     "lineItems": [
    //       {
    //         "productId": 0,
    //         "addOns": [
    //           0
    //         ]
    //       }
    //     ],
    //     "serviceDate": "2023-05-10T16:11:24.524Z",
    //     "customerId": 0,
    //     "firstName": "string",
    //     "lastName": "string",
    //     "emailAddress": "string"
    //   }
    // ]
    let valuesToValidate = [
      {
        lineItems: [
          {
            productId: productID,
            addOns:
              selectedAddOns?.map?.((addOn) => ({
                productId: addOn,
                productType: 2,
              })) ?? [],
          },
        ],
        serviceDate: dateTime,
        customerId: currentUser?.customerId,
      },
    ];

    const { guests = [] } = form.getFieldsValue();

    for (let guest of guests) {
      valuesToValidate.push({
        lineItems: [
          {
            productId: guest.productId,
            firstName: guest.firstName,
            lastName: guest.lastName,
            email: guest.email,
            addOns:
              guest?.addOns?.map?.((addOn) => ({
                productId: addOn,
                productType: 2,
              })) ?? [],
          },
        ],
        facilityId: facilityId,
        timeZone: timeZone,
        serviceDate: dateTime,
      });
    }

    if (currentUser?.customerId) {
      dispatch(
        validateRestriction(facilityId, valuesToValidate, dateTime)
      ).then((result) => {
        if (!result?.mstatus) {
          form.setFieldsValue({
            time: undefined,
          });
        } else {
          setProduct(productID, operationTypeId, facilityId);
        }
      });
    } else {
      form.setFieldsValue({ productId: productID });
      setProduct(productID, operationTypeId, facilityId);
    }
  };

  const populateAddressField = (operationTypeId) => {
    if (currentUser?.streetAddress1 || currentUser?.primaryAddress) {
      const fallbackAddress = currentUser.streetAddress1;
      const primaryAddress = addresses?.find?.(
        (address) => address.isPrimary === true
      )?.address;
      const formAddress = primaryAddress ?? fallbackAddress;
      setStreetAddress(formAddress, operationTypeId, (facilityId) => {
        const { operationTypeId: formOperationTypeId } = form.getFieldsValue();
        if (formOperationTypeId !== 1) {
          return;
        }
        setClinic(facilityId, operationTypeId);

        form.setFieldsValue({
          serviceAddress: formAddress,
          facilityId,
        });
        dispatch(
          savePendingOrder({
            ...form.getFieldsValue(),
            serviceAddress: formAddress,
            // operationTypeId: orderOperationTypeId,
            facilityId: facilityId,
            status: "pending",
          })
        );
      });
    } else {
      navigator.geolocation.getCurrentPosition(function (position) {
        Geocode.fromLatLng(
          position.coords.latitude,
          position.coords.longitude
        ).then(
          (response) => {
            const serviceAddress = response.results[0].formatted_address;
            setStreetAddress(serviceAddress, operationTypeId, (facilityId) => {
              const { operationTypeId: formOperationTypeId } =
                form.getFieldsValue();
              if (formOperationTypeId !== 1) {
                return;
              }
              setClinic(facilityId, operationTypeId);

              form.setFieldsValue({
                serviceAddress,
                facilityId,
              });
              dispatch(
                savePendingOrder({
                  ...form.getFieldsValue(),
                  serviceAddress,
                  facilityId,
                  status: "pending",
                })
              );
            });
          },
          (error) => {
            console.error(error);
          }
        );
      });
    }
  };

  useEffect(async () => {
    if (qs.type == "widget") {
      const mSetting = await dispatch(setMiscSettings());
      dispatch(
        savePendingOrder({
          operationTypeId: qs.operationTypeId
            ? Number(qs.operationTypeId)
            : undefined,
          serviceAddress: qs.serviceAddress || undefined,
          zipCode: qs.zipCode ? qs.zipCode : undefined,
          facilityId: qs.facilityId ? Number(qs.facilityId) : undefined,
          productId: qs.productId ? Number(qs.productId) : undefined,
          addOns: qs.addOns
            ? qs.addOns?.split?.(",").map((item) => Number(item))
            : undefined,
          date: qs.date ? moment(qs.date) : undefined,
          time: qs.time ? qs.time : undefined,
        })
      );
      form.setFieldsValue({
        ...qs,
        zipCode: qs.zipCode ? qs.zipCode : undefined,
        operationTypeId: qs.operationTypeId
          ? Number(qs.operationTypeId)
          : undefined,
        serviceAddress: qs.serviceAddress || undefined,
        facilityId: qs.facilityId ? Number(qs.facilityId) : undefined,
        productId: qs.productId ? Number(qs.productId) : undefined,
        addOns: qs.addOns
          ? qs.addOns?.split?.(",").map((item) => Number(item))
          : undefined,
        date: qs.date ? moment(qs.date) : undefined,
        time: qs.time ? qs.time : undefined,
      });
      // dispatch(setCurrentBooking(qs));
      // dispatch(setTimer(moment().add(15, "minutes")));

      checkTimer(mSetting.data);

      if (qs.date !== undefined) {
        setScheduledDate(moment(qs.date));
      }
      // handleSubmit(Object.fromEntries(params))
      submitRef?.current?.click();
    }
  }, []);

  useEffect(() => {
    dispatch(getClinicFacilities());
    dispatch(setMiscSettings());
  }, []);

  if (!!streetAddressError) {
    form.setFields([
      {
        name: "serviceAddress", // required
        errors: ["Invalid Address"],
      },
    ]);
  } else {
    form.setFields([
      {
        name: "serviceAddress", // required
        errors: [],
      },
    ]);
  }

  // clear date and time on logout
  useEffect(() => {
    if (order === null && qs.time === undefined) {
      form.setFieldsValue({
        time: undefined,
        date: undefined,
      });
      setScheduledDate(undefined);
    }
  }, [order]);

  useEffect(() => {
    let isMobile = operationTypeId === 1 ?? false;
    if (!!scheduledDate && facilityId !== undefined) {
      setTimeslots({
        isLoading: true,
        isClosed: false,
        data: [],
      });
      dispatch(
        getFacilityTimings(scheduledDate?.format("MM/DD/YYYY"), facilityId)
      )
        .then((res) => {
          let serviceLength = 0;
          if (products?.length > 0 && mainProductId) {
            const p = products?.find((it) => it?.productId === mainProductId);
            serviceLength = p?.durationHours;
          }

          if (res?.status === "success") {
            setTimeZone(res?.data?.timeZone);
            const data = generateHourlySlots(
              scheduledDate,
              res?.data?.startTime,
              res?.data?.endTime,
              res?.data,
              isMobile,
              serviceLength,
              operationTypeId
            );
            setTimeslots({
              isLoading: false,
              isClosed: res?.data?.isClosed,
              data,
            });
          }
        })
        .catch(() => {
          setTimeslots({
            isLoading: false,
            isClosed: false,
            data: [],
          });
        })
        .finally(() => {
          setTimeslots((state) => ({
            ...state,
            isLoading: false,
          }));
        });
      // return generateHourlyTimeSlots(
      // )
    }
  }, [scheduledDate, operationTypeId, facilityId, products, mainProductId]);

  const onFinish = (values) => {
    if (user?.dob) {
      const dobMoment = moment(user?.dob);
      if (
        !(
          dobMoment.isSameOrBefore(moment().subtract(18, "years")) &&
          dobMoment.isAfter(moment().subtract(85, "years"))
        )
      ) {
        openNotificationWithIcon(
          "error",
          "Error!",
          "Unable to book order. Your age must be between 18 and 85. Kindly consult support for more details."
        );
        return;
      }
    }
    // return;

    // let availedRewards = [];
    // const mainServiceRewardId = getRewardId(
    //   rewards?.rewards ?? [],
    //   values,
    //   availedRewards
    // );
    // if (mainServiceRewardId) availedRewards.push(mainServiceRewardId);

    // generate values
    values.facility = centers.find(
      (center) => center.facilityId === values?.facilityId
    );
    values.operationType = operationTypes.find(
      (operationType) =>
        operationType.operationTypeId === values.operationTypeId
    );
    values.serviceDateUTC = moment(values.date.format("L") + " " + values.time)
      .utc()
      .format();
    values.serviceDate = moment(values.date.format("L") + " " + values.time)
      .local()
      .format("YYYY-MM-DDTHH:mm:ss[Z]");

    const guestAppointments = [
      ...(values.guests?.map?.((guest, index) => {
        return {
          firstName: guest.firstName,
          lastName: guest.lastName,
          emailAddress: guest.email,
          username:
            guest.hasMultipleGuests === "yes" ? guest.username : undefined,
          productId: guest.productId,
          willHostPay: guest.checked ? true : false,
          ...(products.find(
            (product) => product.productId === guest.productId
          ) ?? {}),
          addOns: guest.addOns?.map((addon) => {
            return (
              addOns.find((addonItem) => addonItem.productId === addon) ?? {
                productId: addon,
              }
            );
          }),
        };
      }) ?? []),
    ];
    values.lineItems = [
      {
        productId: values.productId,
        // rewardId: mainServiceRewardId,
        addOns:
          values.addOns?.map((addon) => {
            // const rewardId = getRewardId(
            //   rewards?.rewards ?? [],
            //   { productId: addon },
            //   availedRewards
            // );
            // if (rewardId) availedRewards.push(rewardId);

            return {
              ...(addOns.find((addonItem) => addonItem.productId === addon) ?? {
                productId: addon,
              }),
              // rewardId,
              productType: 2,
            };
          }) ?? [],
        ...(products.find(
          (product) => product.productId === values.productId
        ) ?? {}),
      },
    ];

    values.appointments =
      values?.guests?.map?.((guest, index) => {
        // const rewardId = getRewardId(
        //   rewards?.rewards ?? [],
        //   {
        //     facilityId,
        //     productId: guest.productId,
        //   },
        //   availedRewards
        // );
        // if (rewardId) availedRewards.push(rewardId);
        return {
          customerFullName: guest.firstName + " " + guest.lastName,
          firstName: guest.firstName,
          lastName: guest.lastName,
          emailAddress: guest.email,
          username:
            guest.hasMultipleGuests === "yes" ? guest.username : undefined,
          // productId: guest.productId,
          willHostPay: guest.checked ? true : false,
          lineItems: [
            {
              productId: products.find(
                (product) => product.productId === guest.productId
              ).productId,
              addOns:
                guest.addOns?.map((addon) => {
                  return {
                    ...(addOns.find(
                      (addonItem) => addonItem.productId === addon
                    ) ?? {
                      productId: addon,
                    }),
                    productType: 2,
                  };
                }) ?? [],
            },
          ],
        };
      }) ?? [];

    values.guestBookings = values.appointments;
    values.isMultipleGuestBooking = values?.guestBookings?.length > 0;
    handleSubmit(values);
  };

  useLayoutEffect(() => {
    const add = addItemFunctionRef.current;
    if (
      add &&
      isOrderPending &&
      order?.productId !== undefined &&
      order?.guestsNo > 1
    ) {
      add(order.guestsNo, order.guests);
    }
  }, []);

  const checkTimer = (data = null) => {
    const mSetting = data ? data : miscSettings;
    const onOff = mSetting.find((res) => {
      if (res.key == "BookingSessionBit") {
        return res;
      }
    });

    if (onOff?.value == "1") {
      const time = mSetting.find((res) => {
        if (res.key == "BookingSessionTimeout") {
          return res;
        }
      });
      dispatch(setTimer(moment().add(parseInt(time.value), "minutes")));
    }
  };
  return (
    <div className="dashFormContainer">
      <Form
        requiredMark={false}
        form={form}
        layout="vertical"
        className="dashForm"
        preserve={false}
        onValuesChange={(params) => {
          const isOperationTypeIdChanged =
            params?.operationTypeId !== undefined;
          if (isOperationTypeIdChanged) {
            dispatch(
              savePendingOrder({
                ...DEFAULT_FORM_VALUES,
                ...params,
                status: "pending",
              })
            );
          } else {
            dispatch(
              savePendingOrder({
                ...form.getFieldsValue(),
                status: "pending",
              })
            );
          }
        }}
        onFinishFailed={({ values, errorFields, outOfDate }) => {
          console.log("errorFields", errorFields);
          form.scrollToField(errorFields[0].name, {
            scrollMode: "if-needed",
            block: "center",
            behavior: "smooth",
          });
        }}
        onFinish={(values) => {
          const onOff = miscSettings?.find?.((res) => {
            if (res.key == "BookingSessionBit") {
              return res;
            }
          });
          if (onOff?.value !== "0" && timer === undefined) {
            setScheduledDate();
            form.setFieldsValue({
              date: undefined,
              time: undefined,
            });
            openNotificationWithIcon(
              "error",
              "Error!",
              "Your time has expired. Please select a new date and time."
            );
            return;
          }

          let isMobile = operationTypeId == 1 ? true : false;
          let isTelehealth = operationTypeId == 3 ? true : false;

          if (
            addOns?.length > 0 &&
            continueWithoutBoosts === false &&
            (!values?.addOns || values?.addOns?.length === 0)
          )
            setShowBoost(true);
          else if (
            isTokenVerified &&
            (!intakeFormsSubmitted || intakeFormsExpired)
          ) {
            setShowIntakeForms(true);
          } else if (
            isTokenVerified &&
            // (isMobile || isTelehealth) &&
            paymentMethods?.length < 1 &&
            shouldSubmit === false
          ) {
            setShowAddCard(true);
          } else onFinish(values);
        }}
        initialValues={{
          zipCode: qs.zipCode
            ? qs.zipCode
            : isOrderPending
            ? order?.zipCode
            : undefined,
          serviceAddress: qs.serviceAddress
            ? qs.serviceAddress
            : isOrderPending
            ? order?.serviceAddress
            : undefined,
          facilityId: qs.facilityId
            ? Number(qs.facilityId)
            : isOrderPending
            ? order?.facilityId
            : undefined,
          operationTypeId: qs.operationTypeId
            ? Number(qs.operationTypeId)
            : isOrderPending
            ? order?.operationTypeId
            : undefined,
          productId: qs.productId
            ? Number(qs.productId)
            : isOrderPending
            ? order?.productId
            : undefined,
          addOns: qs.addOns
            ? qs.addOns?.split?.(",").map((item) => Number(item))
            : isOrderPending
            ? order?.addOns
            : undefined,
          date:
            (timer &&
              moment().isSameOrBefore(moment(timer)) &&
              isOrderPending) ||
            qs.date
              ? moment(qs.date ? qs.date : order.date)
              : undefined,
          time:
            (timer &&
              moment().isSameOrBefore(moment(timer)) &&
              isOrderPending) ||
            qs.time
              ? qs.time
                ? qs.time
                : order.time
              : undefined,
          guestsNo: isOrderPending ? order.guestsNo : undefined,
        }}
      >
        <Row className="dashFormMainRow" gutter={[12, 12]}>
          <Col xs={24} md={8} lg={isMultiGuest ? 4 : 5}>
            <FormSelect
              required
              // disabled={isFetchingCoords}
              // suffixIcon={isFetchingOperationTypes ? <Spinner /> : undefined}
              name={"operationTypeId"}
              options={[
                { operationTypeId: 2, operationTypeName: "Clinic" },
                { operationTypeId: 1, operationTypeName: "Mobile" },
                { operationTypeId: 3, operationTypeName: "Telehealth" },
              ]}
              label={"SERVICE TYPE"}
              placeholder={"Select Type"}
              renderLabel="operationTypeName"
              renderValue="operationTypeId"
              // loading={isFetchingCenters}
              onChange={(value) => {
                setIsAddressChanged(false);
                clearLocationCenterData();
                setScheduledDate();
                form.setFieldsValue({
                  facilityId: undefined,
                  serviceAddress: undefined,
                  zipCode: undefined,
                  productId: undefined,
                  addOns: undefined,
                  guestsNo: undefined,
                  date: undefined,
                  time: undefined,
                });
                // const add = addItemFunctionRef.current;
                // add(0);
                if (value === 1) {
                  // call the local set street address function and pass operationTypeId
                  populateAddressField(value);
                }
              }}
            />
          </Col>

          <Col xs={24} md={8} lg={isMultiGuest ? 4 : 5}>
            <FormInput
              label="ZIP CODE"
              name="zipCode"
              max={5}
              hidden={operationTypeId !== 3}
              placeholder="Enter ZIP Code"
              className="zipCodeInput"
              onChange={async (e) => {
                await clearLocationCenterData();
                await form.setFieldsValue({
                  // facilityId: undefined,
                  // operationTypeId: undefined,
                  productId: undefined,
                  addOns: undefined,
                  guestsNo: undefined,
                  time: undefined,
                });
                const { operationTypeId } = form.getFieldsValue();
                setStreetAddress(
                  e.target.value,
                  operationTypeId,
                  (facilityId) => {
                    const { operationTypeId: formOperationTypeId } =
                      form.getFieldsValue();
                    if (
                      formOperationTypeId !== 1 &&
                      formOperationTypeId !== 3
                    ) {
                      return;
                    }
                    setClinic(facilityId, formOperationTypeId);

                    form.setFieldsValue({
                      facilityId,
                    });

                    dispatch(
                      savePendingOrder({
                        ...form.getFieldsValue(),
                        facilityId,
                        status: "pending",
                      })
                    );
                  }
                );
              }}
            />
            <LocationDropdown
              hidden={operationTypeId !== 1}
              required={operationTypeId === 1}
              name={"serviceAddress"}
              form={form}
              guests
              loading={isFetchingCoords}
              onChange={async (e) => {
                setIsAddressChanged(true);
                await clearLocationCenterData();
                await form.setFieldsValue({
                  // facilityId: undefined,
                  // operationTypeId: undefined,
                  productId: undefined,
                  addOns: undefined,
                  guestsNo: undefined,
                  time: undefined,
                });
              }}
              onSelectChange={async (e) => {
                setIsAddressChanged(true);
                await clearLocationCenterData();
                await form.setFieldsValue({
                  // facilityId: undefined,
                  // operationTypeId: undefined,
                  productId: undefined,
                  addOns: undefined,
                  guestsNo: undefined,
                  time: undefined,
                });
                const { operationTypeId } = form.getFieldsValue();
                setStreetAddress(
                  e?.target?.value,
                  operationTypeId,
                  (facilityId) => {
                    const { operationTypeId: formOperationTypeId } =
                      form.getFieldsValue();
                    if (formOperationTypeId !== 1) {
                      return;
                    }
                    setClinic(facilityId, formOperationTypeId);

                    form.setFieldsValue({
                      facilityId,
                    });

                    dispatch(
                      savePendingOrder({
                        ...form.getFieldsValue(),
                        facilityId,
                        status: "pending",
                      })
                    );
                  }
                );
                setIsAddressChanged(false);
              }}
              onBlur={(e) => {
                if (isAddressChanged) {
                  const { operationTypeId } = form.getFieldsValue();
                  setStreetAddress(
                    e.target.value,
                    operationTypeId,
                    (facilityId) => {
                      const { operationTypeId: formOperationTypeId } =
                        form.getFieldsValue();
                      if (formOperationTypeId !== 1) {
                        return;
                      }
                      setClinic(facilityId, formOperationTypeId);

                      form.setFieldsValue({
                        facilityId,
                      });

                      dispatch(
                        savePendingOrder({
                          ...form.getFieldsValue(),
                          facilityId,
                          status: "pending",
                        })
                      );
                    }
                  );
                }
                setIsAddressChanged(false);
              }}
              rules={[
                {
                  required: operationTypeId === 1,
                  message: "*Required",
                },
                {
                  message: "Invalid Address",
                  validator: () => {
                    if (operationTypeId === 1 && streetAddressError) {
                      return Promise.reject();
                    }
                    return Promise.resolve();
                  },
                },
              ]}
              round
              label={"LOCATION"}
            />

            <FormSelect
              hidden={operationTypeId === 1 || operationTypeId === 3}
              required
              name={"facilityId"}
              renderLabel="facilityName"
              renderValue={"facilityId"}
              dropdownClassName={
                timeslots?.data?.length > 0 ? "timeDropdown" : ""
              }
              options={facilities ?? []}
              label={"FACILITY"}
              placeholder={"Select Facility"}
              emptyMessage="No Facility Available"
              onChange={(value) => {
                clearLocationCenterData();
                form.setFieldsValue({
                  // facilityId: undefined,
                  // operationTypeId: undefined,
                  productId: undefined,
                  addOns: undefined,
                  guestsNo: undefined,
                  time: undefined,
                });
                const { operationTypeId, facilityId, productId } =
                  form.getFieldsValue();
                setClinic(value, operationTypeId, productId);
                // dispatch(setTimer(moment().add(15, "minutes")));
              }}
              rules={[
                {
                  required: true,
                  message: false,
                },
              ]}
            />
          </Col>

          <Col xs={24} md={8} lg={isMultiGuest ? 4 : 4}>
            <ServicesDropdown
              required
              name={"productId"}
              form={form}
              initialValue={mainProductId}
              onChange={(value) => {
                form.setFieldsValue({
                  addOns: undefined,
                });

                const { operationTypeId, facilityId } = form.getFieldsValue();
                setProduct(value, operationTypeId, facilityId);
                dispatch(
                  savePendingOrder({
                    ...form.getFieldsValue(),
                    productId: value,
                    status: "pending",
                  })
                );
              }}
            />
          </Col>

          <Col xs={24} md={16} lg={isMultiGuest ? 3 : 4}>
            <BoostsDropdown
              name={"addOns"}
              form={form}
              addOns={addOns}
              // disabled={operationTypeId === 3}
              isFetchingAddons={isFetchingAddons}
              selectedAddOns={selectedAddOns}
              onChange={(value) => {
                dispatch(
                  savePendingOrder({
                    ...form.getFieldsValue(),
                    addOns: value,
                    status: "pending",
                  })
                );
              }}
            />
          </Col>

          <Col xs={12} md={4} lg={3}>
            <FormDatePicker
              form={form}
              required
              name={"date"}
              label={"DATE"}
              placeholder={"Select Date"}
              rules={[
                {
                  required: true,
                  message: "Please pick an item!",
                },
              ]}
              disabledDate={(current) =>
                current.isBefore(moment().subtract(1, "day"))
              }
              onChange={(value, date) => {
                setScheduledDate(value);
                form.setFieldsValue({
                  time: undefined,
                });
              }}
            />
          </Col>

          <Col xs={12} md={4} lg={3}>
            <FormSelect
              required
              name={"time"}
              renderLabel="label"
              renderValue={"value"}
              loading={timeslots?.isLoading}
              dropdownClassName={
                timeslots?.data?.length > 0 ? "timeDropdown" : ""
              }
              options={timeslots?.isClosed ? [] : timeslots?.data ?? []}
              label={"TIME"}
              placeholder={"Select Time"}
              emptyMessage={
                timeslots?.isClosed ? (
                  <>
                    Facility is closed on selected date.
                    <br />
                    Call to book.
                  </>
                ) : (
                  "No Time Available In Selected Date"
                )
              }
              disabled={operationTypeId === undefined}
              onChange={() => {
                checkTimer();
              }}
              rules={[
                {
                  required: true,
                  message: false,
                },
              ]}
            />
          </Col>

          <Col xs={12} md={isMultiGuest ? 3 : 0}>
            <FormSelect
              onChange={(val) => {
                const add = addItemFunctionRef.current;
                add(val);
              }}
              name={"guestsNo"}
              options={GuestCounts}
              label={"GUESTS"}
              placeholder={"EG: 1"}
              renderLabel="label"
              renderValue="value"
            />
          </Col>
        </Row>
        <Form.List name="guests">
          {(fields, { add, remove, move }, { errors, warnings }) => {
            addItemFunctionRef.current = (val, data) => {
              if (fields.length < val) {
                let difference = val - fields.length;
                for (let i = 0; i < difference - 1; i++) {
                  let guest = data?.[i];
                  add({
                    checked: guest?.checked || false,
                    firstName: guest?.firstName,
                    lastName: guest?.lastName,
                    email: guest?.email,
                    addOns: guest?.addOns || [],
                    productId: guest?.productId || undefined,
                    username: guest?.username || undefined,
                    hasMultipleGuests: guest?.hasMultipleGuests || undefined,
                    isValidated: guest?.isValidated || undefined,
                    count: guest?.count ?? undefined,
                  });
                }
              } else {
                let difference = fields.length - val;
                for (let i = 0; i <= difference + 1; i++) {
                  remove(fields.length - i);
                }
              }
            };

            return (
              fields.length > 0 && (
                <>
                  <div className="dashFormGuestRows" style={{}}>
                    {fields.map((field, index) => {
                      const productId =
                        form.getFieldValue("guests")?.[index]?.productId;
                      return (
                        <GuestFormRow
                          key={`${field.name}.${index}`}
                          field={field}
                          index={index}
                          remove={remove}
                          totalFields={fields.length}
                          form={form}
                          productId={productId}
                          locationId={facilityId}
                          operationTypeId={operationTypeId}
                        />
                      );
                    })}
                  </div>
                </>
              )
            );
          }}
        </Form.List>
        <FormHiddenSubmitButton submitRef={submitRef} />
        <BoostPopup
          addOns={addOns}
          name="addOns"
          form={form}
          visible={showBoost}
          onChange={() => {}}
          onCancel={() => {
            setShowBoost(false);
            setContinueWithoutBoosts(true);
            submitRef?.current?.click();
          }}
          handleSave={() => {
            setShowBoost(false);
            setContinueWithoutBoosts(true);
            submitRef?.current?.click();
          }}
          selectedAddOns={selectedAddOns}
        />
        <Modal
          className="intakeFormsPopup"
          title="Intake Forms"
          headerBorder={true}
          visible={showIntakeForms}
          onCancel={() => setShowIntakeForms(false)}
          okButtonProps={{
            disabled: !intakeFormsSubmitted,
          }}
          onOk={() => {
            setShowIntakeForms(false);
            submitRef?.current?.click();
          }}

          // footer={[]}
        >
          <p style={{ fontFamily: "tradeGothic", fontSize: 17 }}>
            Intake forms are mandatory to book an appointment.
          </p>
          <IntakeForms variant="minimal" />
        </Modal>
        <StripeAddCardModal
          message="Payment method is mandatory for booking a service."
          saveText={"Save and Order"}
          forcePrimary={true}
          visible={showAddCard}
          handleSave={() => {
            setShowAddCard(false);
            setShouldSubmit(true);
            submitRef?.current?.click();
          }}
          onCancel={() => setShowAddCard(false)}
        />
      </Form>
    </div>
  );
}
