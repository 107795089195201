import React from "react";
import PropTypes from "prop-types";

import ReferalImage from "assets/images/referral.png";

import { Space, Typography } from "antd";
import { Button } from "components/Buttons/Buttons";

const { Title, Text } = Typography;

function InviteFriendsCard(props) {
  return (
    <div className="inviteFriendCard">
      <img src={ReferalImage} className="inviteFriendCardImage" />
      <div className="inviteFriendCardContent">
        <Space direction="vertical" size={0}>
          <Title
            level={4}
            className="tradeGothic"
            style={{ color: "#4C6DB0", marginBottom: 2 }}
          >
            Invite your friends and Earn
          </Title>
          <Text className="tradeGothic" style={{ color: "#444D62" }}>
            Ask your friends to signup with your referal code and on first
            payment, you will get $50 each!
          </Text>
        </Space>
        <Button rounded>Invite</Button>
      </div>
    </div>
  );
}

InviteFriendsCard.propTypes = {};

export default InviteFriendsCard;
