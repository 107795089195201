export const actions = {
  SET_MEMBERSHIPS: "SET_MEMBERSHIPS",
  SET_CURRENT_MEMBERSHIP: "SET_CURRENT_MEMBERSHIP",
  CANCEL_MEMBERSHIP: "CANCEL_MEMBERSHIP",
};

export const setMembershipTypes = (data) => ({
  type: actions.SET_MEMBERSHIPS,
  data,
});

export const setCurrentMembership = (data) => ({
  type: actions.SET_CURRENT_MEMBERSHIP,
  data,
});

export const cancelMembership = () => ({
  type: actions.CANCEL_MEMBERSHIP,
});
