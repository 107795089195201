import React, {
  useState,
  useEffect,
  useRef,
  useCallback,
  useMemo,
} from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import queryString from "query-string";
import "./Signup.scss";

import { Form, Layout, Space, Typography } from "antd";
import Accordion, { Panel } from "components/Accordion/Accordion";

import StripePayment from "./components/StripePayment";
import IntakeForms from "containers/Account/Screens/IntakeForms/IntakeForms";
import InfoText from "components/InfoText/InfoText";
import RegisterForm from "components/RegisterForm/RegisterForm";

import { setNewsletter } from "services/auth.services";

import { verifyUser } from "services/auth.services";
import useIntakeForms from "hooks/useIntakeForms";
import MiniPaymentRow from "components/MiniPaymentRow/MiniPaymentRow";

// const { Panel } = Collapse;
const { Content } = Layout;
const { Text } = Typography;

export default function Signup() {
  const [panel, setPanel] = useState("1"); // "1"
  const [completed, setCompleted] = useState([""]); // no value
  const isFirstLoad = useRef(true);

  const dispatch = useDispatch();
  const {
    booking,
    user,
    loading: { submitting },
    paymentMethods,
  } = useSelector((state) => ({
    user: state.user,
    loading: state.loading,
    booking: state.booking,
    profiles: state.profiles,
    paymentMethods: state.paymentMethod?.paymentMethods,
  }));
  const { currentBooking } = booking ?? {};
  const isOrderPending = currentBooking?.status === "pending" ? true : false;
  const isOrderMobile = currentBooking?.operationTypeId === 1 ? true : false;

  const location = useLocation();
  const qs = queryString.parse(location.search);

  const { profiles } = user ?? {};
  const { isHistoryFormSubmitted, isConsentFormSubmitted } = useIntakeForms();

  const { firstName, lastName, city, state, zipCode, streetAddress1 } =
    user.currentUser ?? {};
  const checkUserBasicInfoSubmitted = useCallback(() => {
    const userInfo = user.currentUser;
    return (
      userInfo &&
      userInfo.email &&
      userInfo.userName &&
      userInfo.firstName &&
      userInfo.lastName &&
      (userInfo.phoneNumber || userInfo.phoneNo || userInfo.mobileNumber) &&
      userInfo.dob &&
      userInfo.streetAddress1
    );
  }, [user.currentUser]);

  useEffect(() => {
    const completedSet = new Set([...completed]);
    const isUserInfoSubmitted = checkUserBasicInfoSubmitted();
    // if we have a user
    if (
      isUserInfoSubmitted &&
      (qs.from !== "complete-registration" ||
        (qs.from === "complete-registration" && isFirstLoad.current === false))
    ) {
      completedSet.add("1");
      completedSet.add("2");
      setCompleted([...completedSet]);
      setPanel(-1);
    }
    isFirstLoad.current = false;
    // if we have both intake forms filled
    // if we have a payment method
  }, [user.currentUser]);

  useEffect(() => {
    if (isHistoryFormSubmitted && isConsentFormSubmitted) setPanel("3");
  }, [isHistoryFormSubmitted, isConsentFormSubmitted]);

  const currentPrimaryCard = useMemo(
    () => paymentMethods.find((card) => card?.isPrimary === true),
    [paymentMethods]
  );

  const handleStepCompletion = async (step, data) => {
    switch (step) {
      case 1:
        // setPanel("2");
        setPanel(-1);
        break;
      case 2:
        break;
      case 3:
        await Promise.all([dispatch(setNewsletter(data.optNewsletter))]);
        await dispatch(verifyUser());
        break;
      default:
        break;
    }
  };

  return (
    <Content className="signupContainer">
      <Space direction="vertical" className="signupWrapper">
        <Typography.Title level={3}>Welcome!</Typography.Title>
        <Accordion
          panel={panel}
          onChange={(key) => {
            if (key === "2") {
              // we dont want to open Tab Panel 2 (Intake Forms)
              return;
            } else if (
              key === "3" &&
              (!isConsentFormSubmitted || !isHistoryFormSubmitted)
            ) {
              return;
            }

            setPanel(key);
          }}
          completed={completed}
        >
          <Panel
            key={1}
            title="Profile Information"
            isCompleted={completed.includes("1")}
            completedMeta={
              <Space direction="vertical" size={0} className="signupUserInfo">
                <Text>
                  {firstName} {lastName}
                </Text>
                <Text>{streetAddress1} </Text>
                {/* <Text>
                  {city?.locationName ?? "NA"}, {state?.locationName ?? "NA"}{" "}
                  {zipCode ?? "NA"}
                </Text> */}
              </Space>
            }
          >
            <RegisterForm
              isUsernamePasswordRequired={!qs?.external}
              onSuccess={() => handleStepCompletion(1)}
            />
          </Panel>
          <Panel
            key={2}
            title={
              <span
                className="flex alignCenter"
                style={{ fontFamily: "inherit", fontSize: "inherit" }}
              >
                <span
                  style={{
                    marginRight: 16,
                    fontFamily: "inherit",
                    fontSize: "inherit",
                  }}
                >
                  Intake Forms
                </span>
                <InfoText tooltip={true}>
                  Intake Forms are mandatory to book an appointment.
                </InfoText>
              </span>
            }
            isCompleted={completed.includes("2")}
            completedMeta={<IntakeForms variant="minimal" openOnLoad={true} />}
            isClickable={false}
          >
            &nbsp;
          </Panel>
          <Panel
            key={3}
            title={
              <span
                className="flex alignCenter"
                style={{ fontFamily: "inherit", fontSize: "inherit" }}
              >
                <span
                  style={{
                    marginRight: 46,
                    fontFamily: "inherit",
                    fontSize: "inherit",
                  }}
                >
                  Payments
                </span>
                {panel !== "3" && (
                  <InfoText tooltip={true}>
                    Your card will not be charged until after the service is
                    complete.
                  </InfoText>
                )}
              </span>
            }
            isCompleted={completed.includes("3")}
            isClickable={isHistoryFormSubmitted && isConsentFormSubmitted}
          >
            {currentPrimaryCard ? (
              <MiniPaymentRow
                saveText={qs.redirect === "thankyou" ? "Add & Order" : "Add"}
                submitting={submitting}
                paymentMethod={currentPrimaryCard}
                onSuccess={(data) => {
                  handleStepCompletion(3, data);
                }}
              />
            ) : (
              <StripePayment
                isRequired={true}
                saveText={qs.redirect === "thankyou" ? "Add & Order" : "Add"}
                submitting={submitting}
                onSuccess={(data) => {
                  handleStepCompletion(3, data);
                }}
              />
            )}
          </Panel>
        </Accordion>
      </Space>
    </Content>
  );
}
