export const getItem = (key) => {
  const value = localStorage.getItem(key);
  if (value) {
    const data = JSON.parse(value);
    return data;
  }
  return null;
};

export const setItem = (key, data) => {
  let filteredData = {};
  if (Array.isArray(data)) {
    filteredData = data;
  }
  if (typeof data === "object") {
    const entries = Object.entries(data ?? {});
    for (let i = 0; i < entries.length; i++) {
      let [key, value] = entries?.[i];
      if (value === undefined || value === null) {
        continue;
      } else {
        filteredData[key] = value;
      }
    }
  } else {
    filteredData = data;
  }

  return localStorage.setItem(key, JSON.stringify(filteredData));
};

export const removeItem = (key) => {
  return localStorage.removeItem(key);
};

export const clearStorage = () => {
  const pendingOrder = localStorage.getItem("pendingOrder");
  localStorage.clear();
  localStorage.setItem("pendingOrder", pendingOrder);
};
