import React, { useEffect, useMemo, useRef, useState } from "react";
import { Checkbox, Form, Select, Dropdown, Empty } from "antd";
import { useDispatch, useSelector } from "react-redux";
import Glutathione from "../../assets/images/Glutathione.png";
import { CloseOutlined } from "@ant-design/icons";
import Spinner from "components/Spinner/Spinner";
import { getItem, setItem, removeItem } from "utils/storage";
import { setCurrentBooking } from "redux/actions/booking.action";
import { disableOptionBasedOnSelection } from "utils/common";
import ProductItem from "components/ProductItem/ProductItem";

const FormItem = Form.Item;
const Option = Select.Option;

export default function BoostsDropdown({
  form,
  name,
  listName,
  addOns = [],
  isFetchingAddons = false,
  selectedAddOns = [],
  onChange,
  selectedMultipleAddons,
  setSelectedMultipleAddons,
  disabled,
}) {
  // Disabling option for selection code starts here ~ Junaid
  let disabledAdonsList = disableOptionBasedOnSelection(selectedAddOns, addOns);
  // Disabling option for selection code ends here ~ Junaid

  const [
    isTriImmuneSelected,
    isGlutathioneSelected,
    isZincSelected,
    isB12Selected,
    isSkinnySelected,
  ] = useMemo(() => {
    let isTriImmuneSelected = false;
    let isGlutathioneSelected = false;
    let isZincSelected = false;
    let isB12Selected = false;
    let isSkinnySelected = false;

    selectedAddOns?.forEach?.((addOnId) => {
      const addOn = addOns.find((addon) => addOnId === addon.productId);
      if (addOn?.productName === "Tri-Immune") isTriImmuneSelected = true;
      if (addOn?.productName === "Glutathione") isGlutathioneSelected = true;
      if (addOn?.productName === "Zinc") isZincSelected = true;
      if (addOn?.productName === "B-12") isB12Selected = true;
      if (addOn?.productName === "Skinny") isSkinnySelected = true;
    });
    return [
      isTriImmuneSelected,
      isGlutathioneSelected,
      isZincSelected,
      isB12Selected,
      isSkinnySelected,
    ];
  }, [selectedAddOns]);

  const checkIfAddonIsDisabled = (addOn) => {
    if (addOn?.productName === "Tri-Immune" && isGlutathioneSelected)
      return true;
    if (addOn?.productName === "Glutathione" && isTriImmuneSelected)
      return true;
    if (addOn?.productName === "Tri-Immune" && isZincSelected) return true;
    if (addOn?.productName === "Zinc" && isTriImmuneSelected) return true;
    if (addOn?.productName === "B-12" && isSkinnySelected) return true;
    if (addOn?.productName === "Skinny" && isB12Selected) return true;
    return false;
  };

  const dispatch = useDispatch();
  const [visible, setVisible] = React.useState(false);

  const handleVisibleChange = (flag) => {
    setVisible(flag);
  };
  const nameValue = Form.useWatch(name, form);
  const initialValue = listName
    ? form.getFieldValue(listName)?.[name?.[0]]?.[name?.[1]]
    : form.getFieldValue(name);

  const savePendingOrder = (data) => {
    return (dispatch) => {
      return new Promise((resolve, reject) => {
        dispatch(setCurrentBooking(data));
        setItem("pendingOrder", data);
        resolve();
      });
    };
  };

  const onBoostChange = (value) => {
    if (listName) {
      let list = form.getFieldValue(listName);
      // let previousValue = list[name[0]][name[1]];
      // list[name[0]][name[1]] = [...previousValue, value];
      let updatedList = list.map((item, index) => {
        if (index === name[0]) {
          let previousValue = list[name[0]][name[1]];
          let updatedBoosts = [];
          if (previousValue.includes(value)) {
            updatedBoosts = previousValue.filter((b) => b !== value);
          } else {
            updatedBoosts = [...previousValue, value];
          }
          return { ...item, [name[1]]: updatedBoosts };
        }
        return item;
      });
      form.setFieldsValue({ [listName]: updatedList });
      dispatch(
        savePendingOrder({
          ...form.getFieldsValue(),
          [listName]: updatedList,
          status: "pending",
        })
      );
    } else {
      const boosts = form.getFieldValue(name);
      if (boosts) {
        if (boosts.includes(value)) {
          const updatedBoosts = boosts.filter((b) => b !== value);
          form.setFieldsValue({ [name]: updatedBoosts });
          onChange?.(updatedBoosts);
        } else {
          form.setFieldsValue({ [name]: [...boosts, value] });
          dispatch(
            savePendingOrder({
              ...form.getFieldsValue(),
              [name]: [...boosts, value],
              status: "pending",
            })
          );
        }
      } else {
        form.setFieldsValue({ [name]: [value] });
        onChange?.([value]);
      }
    }
  };

  const dropdownContent = () => {
    if (addOns.length === 0) {
      return (
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            background: "#fff",
            boxShadow: "0px 5px 38px rgba(0,0,0,0.25)",
            borderRadius: 14,
            padding: 20,
          }}
        >
          <Empty description={"No Add-ons Available"} />
        </div>
      );
    }

    return (
      <div
        style={{
          width: 400,

          background: "#fff",
          boxShadow: "0px 5px 38px rgba(0,0,0,0.25)",
          borderRadius: 14,
          overflow: "hidden",
        }}
      >
        <div
          className='showScrollbar'
          style={{
            padding: 12,
            position: "relative",
            overflow: "scroll",
            height: 350,
          }}
        >
          {/* <CloseOutlined
            style={{ position: "absolute", right: 0, padding: 4 }}
            // onClick={() => setBoostVisble(false)}
          /> */}
          <p
            style={{
              fontWeight: "bold",
              fontSize: 18,
              margin: 0,
              fontFamily: "hendersonSemiBold",
            }}
          >
            Additional Add-ons
          </p>
          <p style={{ fontFamily: "tradeGothic", fontSize: 16 }}>
            Elevate your wellness even more with add-ons.
          </p>
          <div>
            {addOns.map((addon) => {
              return (
                <div
                  className={`${
                    checkIfAddonIsDisabled(addon) ? "addondisabled" : ""
                  }`}
                  key={addon.productId}
                  style={{
                    display: "flex",
                    alignItems: "center",
                    marginBottom: 20,
                  }}
                >
                  <Checkbox
                    disabled={
                      disabledAdonsList?.includes?.(addon?.productId) ?? false
                    }
                    style={{
                      marginRight: 16,
                    }}
                    checked={
                      initialValue?.includes?.(addon?.productId) ?? false
                    }
                    onChange={() => {
                      onBoostChange(addon.productId);
                    }}
                  />
                  <ProductItem key={addon.productId} product={addon} />
                </div>
              );
            })}
          </div>
        </div>
      </div>
    );
  };

  return (
    <div className='dashboardDropdown'>
      <Dropdown
        disabled={disabled}
        placement='bottomLeft'
        overlay={dropdownContent}
        trigger={["click"]}
        onVisibleChange={handleVisibleChange}
        visible={visible}
        getPopupContainer={(triggerNode) => triggerNode?.parentNode}
      >
        <FormItem
          style={{
            border: "2px solid #DBDFEA",
            borderRadius: 14,
            height: 84,
            margin: 0,
            position: "relative",
          }}
          label={"ADD-ONS"}
          className='dashboardSelector  noError roundedSelect'
          // initialValue={initialValue}
          name={name}
          disabled={disabled}
          rules={[
            {
              required: false,
            },
          ]}
        >
          <Select
            showSearch={false}
            removeIcon={null}
            loading={isFetchingAddons}
            disabled={disabled}
            mode='multiple'
            style={{
              border: "none",
              flexWrap: "nowrap",
              overflow: "auto",
              cursor: "pointer",
            }}
            placeholder={"Select Add-ons"}
            dropdownStyle={{ display: "none" }}
            className={`hideValue  ${addOns.length > 0 ? "showValue" : ""} `}
          >
            {addOns.map((addon) => {
              return (
                <Option key={addon.productId} value={addon.productId}>
                  {addon.productName}
                </Option>
              );
            })}
          </Select>
        </FormItem>
      </Dropdown>
    </div>
  );
}
