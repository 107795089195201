import { actions } from "../actions/intakeForm.action";

const INITIAL_STATE = {
  isSubmitting: false,
  physicalHistory: {
    type: "history",
    isFilled: false, // for frontend only
    chiefComplaint: "",
    contraindications: [],
    prescriptionsLast60Days: "",
    sensitivies: "",
    diseases: [],
    diseasesExplanation: "",
    energyLevelWeekly: null, // integer number
    exerciseFrequencyWeekly: null, // integer number
    hasDifficultyLosingWeight: null, // boolean
    hasHeadaches: null,
    hasSkinConditions: null,
    hasConfusions: null,
    hasSleeplessFatigue: null,
    description: "",
  },
  consent: {
    type: "consent",
    isFilled: false, // for frontend only
    isCancellationMarked: null, // boolean
    isPrivacyMarked: null, // boolean
    isCovidMarked: null, // boolean
    isProviderMarked: null, // boolean
    isTreatmentMarked: null, // boolean
    isMedicalInformationMarked: null, // boolean
    isAbove18: null, // "Above18", "hasGuardian", "No"
    isPhotographyMarked: null, // boolean
  },
};

//  TODO: Calculate if the form is filled or not;
const reducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case actions.IS_SUBMITTING:
      return { ...state, isSubmitting: action.isSubmitting };
    case actions.SET_INTAKEFORMS:
      return { ...state, [action.name]: action.data };
    case actions.RESET:
      return INITIAL_STATE;
    default:
      return state;
  }
};
export default reducer;

// Booking Object
// {
//     "id": 0,
//     "location": "string",
//     "serviceLocation": "string",
//     "serviceId": 0,
//     "boosts": "string",
//     "dateTime": "2022-05-10T15:08:17.296Z",
//     "guests": 0,
//     "bookingGuests": [
//       {
//         "id": 0,
//         "firstName": "string",
//         "lastName": "string",
//         "boosts": "string",
//         "email": "user@example.com",
//         "serviceId": 0,
//         "makePayment": true,
//         "bookingId": 0
//       }
//     ]
//   }
