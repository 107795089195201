import React, { useState, useRef, useEffect } from "react";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import { CardNumberElement } from "@stripe/react-stripe-js";
import { useStripe, useElements } from "@stripe/react-stripe-js";

import { Row, Col, Form, Typography, Space, Button } from "antd";
import {
  FormHiddenSubmitButton,
  FormInput,
  FormButton,
  FormCheckbox,
} from "components/FormItems/FlatFormItems";

import Stripe from "components/Stripe/Stripe";

import InfoText from "components/InfoText/InfoText";

import { ReactComponent as CheckMarkIcon } from "assets/icons/checkMarkIco.svg";
import { ReactComponent as CloseIcon } from "assets/icons/closeIco.svg";
import { useDispatch } from "react-redux";
import { push } from "connected-react-router";
import { applyVoucher, removeVoucher } from "services/booking.service";
import { setLoading } from "redux/actions/loading.actions";
import useDefaultPayment from "hooks/useDefaultPayment";

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PK);

const { Title, Text, Link } = Typography;

// Shows Issuer Card Icon and Card Number
const MiniCard = ({ brand, last4Digits }) => {
  return (
    <div className="miniPaymentCard">
      <span className={`cardChip issuer-${brand}`}>&nbsp;</span>
      <Text>
        <span className="issuer"> {brand} </span> ending in {last4Digits}
      </Text>
    </div>
  );
};

const BookingPayment = ({ order, paymentMethods }) => {
  const [form] = Form.useForm();
  const submitBtnRef = useRef();

  useEffect(() => {
    form.setFieldsValue({ voucher: order?.voucherCode });
  }, [order]);
  const dispatch = useDispatch();

  let paymentMethod = useDefaultPayment({
    fetch: order?.orderId ? false : true,
    // orderId: order?.orderId,
  });

  if (order?.orderId !== undefined || order?.orderId !== null) {
    paymentMethod = paymentMethods?.find(
      (paymentMethod) =>
        paymentMethod?.paymentMethodId === order?.paymentMethodId
    );
  }

  return (
    <>
      <Row gutter={[16, 0]}>
        <Col span={10}>
          <MiniCard
            brand={paymentMethod?.brand ?? "NA"}
            last4Digits={paymentMethod?.last4Digits ?? "0000"}
          />
        </Col>
        <Col span={12}>
          <div className="flex" style={{}}>
            <div className="info">i</div>
            <Text
              className="tradeGothic"
              style={{ fontSize: 14, color: "#7F889A" }}
            >
              Your card will not be charged until after the service is complete.
            </Text>
          </div>
        </Col>
        <Col span={2} className="flex justifyEnd">
          <Link
            underline
            style={{ padding: 0 }}
            onClick={() => dispatch(push("account/payments"))}
          >
            Change
          </Link>
        </Col>
      </Row>
      <Row gutter={[16, 16]} className="couponsRow">
        <Col span={10}>
          <Form
            name="voucherForm"
            layout="vertical"
            form={form}
            requiredMark={false}
            onFinishFailed={({ values, errorFields, outOfDate }) => {
              form.scrollToField(errorFields[0].name, {
                scrollMode: "if-needed",
                block: "center",
                behavior: "smooth",
              });
            }}
            onFinish={async (values) => {
              dispatch(setLoading(true));
              await dispatch(applyVoucher(order.orderId, values.voucher));
              dispatch(setLoading(false));
            }}
          >
            <FormInput
              name="voucher"
              disabled={order?.voucherCode ? true : false}
              label="Apply Coupon"
              variant="underlined"
              placeholder="Eg: Code"
              formItemStyles={{ marginBottom: 0 }}
              initialValue={order?.voucherCode}
            />
            <FormHiddenSubmitButton submitRef={submitBtnRef}>
              submit
            </FormHiddenSubmitButton>
          </Form>
        </Col>
        <Col span={14}>
          {order?.voucherCode ? (
            <Space className="voucherAppliedContainer">
              <div className="checkMarkOutlined">
                <CheckMarkIcon />
              </div>
              <Title className="tradeGothic availedText">Applied</Title>
              <Button
                shape="circle"
                className="voucherCloseBtn"
                hidden={
                  order?.orderStatus === "Completed" ||
                  order?.orderStatus === "Cancel" ||
                  (!!order?.voucherCode && order?.voucherDiscount < 0)
                }
                onClick={async () => {
                  dispatch(setLoading(true));
                  await dispatch(removeVoucher(order?.orderId));
                  dispatch(setLoading(false));
                }}
              >
                <CloseIcon />
              </Button>
            </Space>
          ) : (
            <Link
              underline
              style={{ padding: 0 }}
              className="couponsRowApplyBtn"
              onClick={() => {
                if (submitBtnRef.current && !order?.voucherCode)
                  submitBtnRef.current.click();
              }}
              disabled={
                order?.orderStatus === "Completed" ||
                order?.status === "Cancelled"
              }
            >
              Apply Now
            </Link>
          )}
        </Col>
      </Row>
    </>
  );
};

BookingPayment.propTypes = {};

export default BookingPayment;
