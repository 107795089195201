import React from "react";
import PropTypes from "prop-types";

import PhoneInput from "react-phone-input-2";

import {
  Input,
  Form,
  DatePicker,
  Select,
  Switch,
  Button as AntdButton,
  TimePicker,
  InputNumber,
  Checkbox,
  Radio,
  Slider,
} from "antd";
import "react-phone-input-2/lib/style.css";
import "./FlatFormItem.scss";
import IMask from "imask";
import moment from "moment";

import { Button } from "components/Buttons/Buttons";
import myxss from "components/FormParseUI/myxss";

const FormItem = Form.Item;
const { Option } = Select;
const { TextArea } = Input;

const DATE_FORMAT = "MM/DD/YYYY";

const MASKED = IMask.createMask({
  blocks: {
    DD: { from: 1, mask: IMask.MaskedRange, to: 31 },
    MM: { from: 1, mask: IMask.MaskedRange, to: 12 },
    YYYY: { from: 1900, mask: IMask.MaskedRange, to: Number.MAX_VALUE },
  },
  format: (date) => moment(date).format(DATE_FORMAT),
  mask: Date,
  parse: (date) => moment(date, DATE_FORMAT),
  pattern: DATE_FORMAT,
});

export const FormInput = ({
  name,
  required,
  type = "text",
  label,
  placeholder,
  initialValue,
  formItemStyles,
  styles,
  disabled,
  max,
  addonAfter,
  variant = "filled",
  readOnly,
  hidden = false,
  rules,
  normalize = (e) => e,
  validateFirst,
  onBlur,
  onChange,
}) => {
  const hasLabelValue = label && label?.length > 0;

  return (
    <FormItem
      normalize={normalize}
      initialValue={initialValue}
      label={
        <span
          dangerouslySetInnerHTML={{
            __html: myxss.process(label),
          }}
        ></span>
      }
      name={name}
      rules={
        rules ?? [
          {
            required,
            message: "*Required",
          },
          {
            type: type,
            message: `Please Enter a valid ${label}`,
          },
        ]
      }
      style={formItemStyles}
      className={`flatFormItem ${
        variant === "underlined" ? "underlined" : ""
      } ${readOnly ? "readOnly" : ""}
      ${hasLabelValue ? "" : "label-d-none"}
      `}
      hidden={hidden}
      onChange={onChange}
      onBlur={onBlur}
      validateFirst={validateFirst}
    >
      <Input
        readOnly={readOnly}
        defaultValue={initialValue}
        placeholder={placeholder}
        style={{ padding: 12, ...styles }}
        type={type === "email" ? "text" : type}
        disabled={disabled}
        maxLength={max}
        addonAfter={addonAfter}
        autoComplete="do-not-autofill"
      />
    </FormItem>
  );
};

export const FormInputNumber = ({
  name,
  required,
  type,
  label,
  placeholder,
  initialValue,
  styles,
  disabled,
  max,
  maxlength,
}) => {
  const hasLabelValue = label && label?.length > 0;

  return (
    <FormItem
      initialValue={initialValue}
      label={
        <span
          dangerouslySetInnerHTML={{
            __html: myxss.process(label),
          }}
        ></span>
      }
      name={name}
      rules={[
        {
          required,
          message: "*Required",
        },
      ]}
      className={`flatFormItem ${hasLabelValue ? "" : "label-d-none"}`}
    >
      <InputNumber
        placeholder={placeholder}
        style={styles}
        disabled={disabled}
        max={max}
        maxLength={maxlength}
      />
    </FormItem>
  );
};

export const FormPhoneNumber = ({
  name,
  required,
  type,
  label,
  placeholder,
  initialValue,
  formItemStyles,
  styles,
  disabled,
  max,
  addonAfter,
  variant = "filled",
  readOnly,
  hidden = false,
  normalize,
  country = "",
  formName,
}) => {
  const hasLabelValue = label && label?.length > 0;

  return (
    <FormItem
      initialValue={initialValue}
      label={
        <span
          dangerouslySetInnerHTML={{
            __html: myxss.process(label),
          }}
        ></span>
      }
      name={name}
      validateFirst
      rules={[
        {
          required,
          message: "*Required",
        },
        {
          message: "Please enter a valid phone number ",
          validateTrigger: ["onBlur", "onChange", "onSubmit"],
          validator: (e, value) => {
            if (value.length !== 0 && value.length < 10) {
              return Promise.reject();
            }
            return Promise.resolve();
          },
        },
      ]}
      style={formItemStyles}
      className={`flatFormItem ${
        variant === "underlined" ? "underlined" : ""
      } ${hasLabelValue ? "" : "label-d-none"}`}
      hidden={hidden}
    >
      <PhoneInput
        onlyCountries={["us"]}
        inputProps={{
          name: name,
          id: `${formName}_${name}`, // used for scrolling on error
        }}
        country={country}
      />
    </FormItem>
  );
};

export const FormDatePicker = ({
  name,
  required,
  label,
  initialValue,
  styles,
  onClick,
  variant,
  formItemStyles,
  disabledDate,
  rules,
  defaultValue,
  validateFirst,
  form,
  onChange,
  placeholder,
  disabled,
  readOnly,
}) => {
  const hasLabelValue = label && label?.length > 0;

  return (
    <FormItem
      disabled={disabled}
      readOnly={readOnly}
      initialValue={initialValue}
      label={
        <span
          dangerouslySetInnerHTML={{
            __html: myxss.process(label),
          }}
        ></span>
      }
      name={name}
      validateFirst={validateFirst}
      className={`flatFormItem ${
        variant === "underlined" ? "underlinedDatePicker" : ""
      } ${hasLabelValue ? "" : "label-d-none"}`}
      rules={
        rules ?? [
          {
            required,
            message: "Please select date",
          },
        ]
      }
      trigger="onSelect" // <<<< THIS ONE
      style={formItemStyles}
      onKeyUp={(e) => {
        if (
          e.target.value === "" ||
          e.target.value === null ||
          e.target.value === undefined
        ) {
          form.setFieldsValue({
            [name]: undefined,
          });
          onChange?.(undefined);
        }
      }}
    >
      <DatePicker
        disabled={disabled}
        readOnly={readOnly}
        format={DATE_FORMAT}
        onKeyDown={(event) => {
          let inputValue = event.target.value;
          let inputValueWithKey = inputValue + event.key;
          const target = event.target;
          const regex = /^\d{2}\/\d{2}\/\d{4}$/g;

          let newValue = MASKED.resolve(inputValue);
          let newValueWithKey = newValue + event.key;

          target.value = newValue;

          if (newValueWithKey.match(regex) && form) {
            const date = moment(newValueWithKey, DATE_FORMAT);
            if (!(disabledDate && disabledDate(date))) {
              form.setFieldsValue({
                [name]: date,
              });
              onChange?.(date);
              event.preventDefault();
            }
          }
        }}
        onChange={(date, dateString) => {
          form.setFieldsValue({
            [name]: date,
          });
          onChange?.(date);
        }}
        onClick={onClick}
        placeholder={placeholder ?? DATE_FORMAT.toLowerCase()}
        disabledDate={disabledDate}
        // onClick={onClick && onClick}
        defaultValue={defaultValue}
        className="home_select"
        style={styles}
      />
    </FormItem>
  );
};
export const FormTimePicker = ({
  name,
  required,
  label,
  initialValue,
  styles,
}) => {
  const hasLabelValue = label && label?.length > 0;

  return (
    <FormItem
      initialValue={initialValue}
      label={
        <span
          dangerouslySetInnerHTML={{
            __html: myxss.process(label),
          }}
        ></span>
      }
      name={name}
      rules={[
        {
          required,
          message: "Please input task",
        },
      ]}
      className={`flatFormItem ${hasLabelValue ? "" : "label-d-none"}`}
    >
      <TimePicker format={"HH:mm"} style={styles} />
    </FormItem>
  );
};

export const FormSelect = ({
  name,
  required,
  label,
  placeholder,
  options,
  initialValue,
  styles,
  onChange,
  disabled,
  dropdownStyle,
  mode,
  onClick,
  variant,
  formItemStyles,
  renderLabel,
  renderValue,
  shouldUpdate = [],
  onSearch,
  filterOption,
  showSearch,
}) => {
  const hasLabelValue = label && label?.length > 0;

  return (
    <FormItem
      // hasFeedback
      label={
        <span
          dangerouslySetInnerHTML={{
            __html: myxss.process(label),
          }}
        ></span>
      }
      initialValue={initialValue ?? undefined}
      name={name}
      rules={[
        {
          required,
          message: "*Required",
        },
      ]}
      className={`flatFormItem select ${
        variant === "underlined" ? "underlined" : ""
      } ${hasLabelValue ? "" : "label-d-none"}`}
      style={formItemStyles}
      shouldUpdate={shouldUpdate}
    >
      <Select
        showSearch={showSearch}
        value={undefined}
        onClick={onClick && onClick}
        mode={mode && mode}
        dropdownStyle={{ ...dropdownStyle }}
        className="home_select"
        placeholder={placeholder}
        style={styles}
        onChange={onChange}
        disabled={disabled}
        onSearch={onSearch}
        filterOption={filterOption}
      >
        {options?.map((opt) => (
          <Option key={opt[renderValue]} value={opt[renderValue]}>
            {opt[renderLabel]}
          </Option>
        ))}
      </Select>
    </FormItem>
  );
};

export const FormSwitch = ({ name, required, label, initialValue }) => {
  return (
    <FormItem
      initialValue={initialValue}
      label={
        <span
          dangerouslySetInnerHTML={{
            __html: myxss.process(label),
          }}
        ></span>
      }
      name={name}
      valuePropName="checked"
      rules={[
        {
          required,
          message: "Please input task",
        },
      ]}
    >
      <Switch />
    </FormItem>
  );
};

// variant can be primary | secondary
export const FormButton = ({
  type,
  text,
  size,
  style,
  onClick,
  variant = "filled",
  disabled,
  loading,
  className,
  rounded = false,
  formItemStyles = {},
}) => {
  return (
    <FormItem style={formItemStyles}>
      <Button
        htmlType={type}
        loading={loading}
        disabled={disabled}
        size={size}
        onClick={onClick ? onClick : () => {}}
        style={style}
        type={type ? type : "button"}
        variant={variant}
        className={className}
        rounded={rounded}
      >
        {text}
      </Button>
    </FormItem>
  );
};

FormButton.propTypes = {
  loading: PropTypes.bool,
  disabled: PropTypes.bool,
  type: PropTypes.string,
  text: PropTypes.string,
  size: PropTypes.oneOf(["small", "medium", "large"]),
  onClick: PropTypes.func,
  variant: PropTypes.oneOf(["filled", "outlined"]),
  className: PropTypes.string,
  rounded: PropTypes.bool,
  formItemStyles: PropTypes.object,
};

export const FormHiddenSubmitButton = ({ submitRef }) => {
  return (
    <Form.Item
      style={{ display: "none" }}
      wrapperCol={{
        offset: 8,
        span: 16,
      }}
    >
      <AntdButton ref={submitRef} type="primary" htmlType="submit"></AntdButton>
    </Form.Item>
  );
};

export const FormInputArea = ({
  name,
  required,
  label,
  placeholder,
  message,
  initialValue,
  styles,
  formItemStyles,
  rows,
  variant = "filled",
  disabled,
}) => {
  const hasLabelValue = label && label?.length > 0;

  return (
    <FormItem
      initialValue={initialValue}
      label={
        <span
          dangerouslySetInnerHTML={{
            __html: myxss.process(label),
          }}
        ></span>
      }
      name={name}
      style={formItemStyles}
      rules={[
        {
          required,
          message: "*Required",
        },
      ]}
      className={`flatFormItem ${
        variant === "underlined" ? "underlined" : ""
      } ${hasLabelValue ? "" : "label-d-none"}`}
      disabled={disabled}
    >
      <TextArea
        placeholder={placeholder}
        style={styles}
        rows={rows || 4}
        disabled={disabled}
      />
    </FormItem>
  );
};

export const FormRadioGroup = ({
  name,
  label,
  style,
  options,
  required,
  className,
}) => {
  return (
    <FormItem
      name={name}
      label={
        <span
          dangerouslySetInnerHTML={{
            __html: myxss.process(label),
          }}
        ></span>
      }
      className={`flatFormItem flatFormItemRadioGroup ${className ?? ""}`}
      style={style}
      rules={[
        {
          required,
          message: "*Required",
        },
      ]}
    >
      <Radio.Group options={options} />
    </FormItem>
  );
};

export const FormCheckboxGroup = ({
  name,
  label,
  formItemStyles,
  style,
  options,
  required,
  className,
  initialValue,
  disabled,
  ...props
}) => {
  return (
    <FormItem
      {...props}
      initialValue={initialValue}
      name={name}
      label={
        <span
          dangerouslySetInnerHTML={{
            __html: myxss.process(label),
          }}
        ></span>
      }
      disabled={disabled}
      className={`flatFormItem flatFormItemCheckboxGroup ${className ?? ""}`}
      style={formItemStyles}
      rules={[
        {
          required,
          message: "*Required",
        },
      ]}
    >
      <Checkbox.Group options={options} style={style} disabled={disabled} />
    </FormItem>
  );
};

export const FormCheckbox = ({
  name,
  formItemStyles,
  style,
  children,
  required,
  className,
  disabled,
  onChange = () => {},
}) => {
  return (
    <FormItem
      name={name}
      disabled={disabled}
      className={`flatFormItemCheckbox ${className ?? ""}`}
      style={formItemStyles}
      rules={[
        {
          required,
          message: "*Required",
        },
      ]}
      valuePropName="checked"
    >
      <Checkbox style={style} onChange={onChange} disabled={disabled}>
        {children}
      </Checkbox>
    </FormItem>
  );
};

export const FormRadio = ({
  name,
  formItemStyles,
  style,
  children,
  required,
  className,
  options,
}) => {
  return (
    <FormItem
      name={name}
      className={`flatFormItemCheckbox ${className ?? ""}`}
      style={formItemStyles}
      rules={[
        {
          required,
          message: "*Required",
        },
      ]}
    >
      <Checkbox style={style}>{children}</Checkbox>
    </FormItem>
  );
};

export const FormPassword = ({
  name,
  required,
  label,
  placeholder,
  formItemStyles,
  styles,
  disabled,
  max,
  addonAfter,
  variant = "filled",
  type = "text",
  rules,
  dependencies = [],
}) => {
  const hasLabelValue = label && label?.length > 0;

  return (
    <FormItem
      label={
        <span
          dangerouslySetInnerHTML={{
            __html: myxss.process(label),
          }}
        ></span>
      }
      name={name}
      rules={
        rules ?? [
          {
            required,
            message: "*Required",
          },
          // {
          //   pattern: /^(?=.*[A-Z])(?=.*[a-z])(?=.*[0-9]).{8,}$/,
          //   message:
          //     "Enter atleast one uppercase, lowercase and a number; 8 characters ",
          // },
        ]
      }
      dependencies={dependencies}
      style={formItemStyles}
      className={`flatFormItem ${
        variant === "underlined" ? "underlined" : ""
      } ${hasLabelValue ? "" : "label-d-none"}`}
    >
      <Input
        placeholder={placeholder}
        style={{ padding: 12, ...styles }}
        disabled={disabled}
        maxLength={max}
        addonAfter={addonAfter}
        type={type}
      />
    </FormItem>
  );
};

export const FormSlider = ({
  label,
  name,
  step,
  marks,
  className,
  formItemStyles,
  styles,
  required,
  ...rest
}) => {
  const hasLabelValue = label && label?.length > 0;

  return (
    <FormItem
      className={`flatFormItem ${className ? className : ""} ${
        hasLabelValue ? "" : "label-d-none"
      }`}
      style={formItemStyles ?? {}}
      label={
        <span
          dangerouslySetInnerHTML={{
            __html: myxss.process(label),
          }}
        ></span>
      }
      name={name}
      rules={[
        {
          required,
          message: "*Required",
        },
      ]}
    >
      <Slider step={step} marks={marks} style={styles ?? {}} {...rest} />
    </FormItem>
  );
};

export const FlatFormItem = ({
  initialValue,
  name,
  required,
  type,
  label,
  formItemStyles,
  children,
  className,
}) => {
  return (
    <FormItem
      className={`flatFormItem ${className ? className : ""} `}
      initialValue={initialValue}
      label={
        <span
          dangerouslySetInnerHTML={{
            __html: myxss.process(label),
          }}
        ></span>
      }
      name={name}
      rules={[
        {
          required,
          message: "*Required",
        },

        {
          type: type === "email" ? "email" : null,
          message: "The input is not valid E-mail!",
        },
      ]}
      style={formItemStyles}
    >
      <span
        dangerouslySetInnerHTML={{
          __html: myxss.process(children),
        }}
      ></span>
    </FormItem>
  );
};
