import React, { useState, useEffect, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Space, Typography, Form, Radio, List, Avatar } from "antd";
import AuthLayout from "components/AuthLayout/AuthLayout";

import LogoImage from "assets/images/logo.png";
import "./Invite.scss";

import {
  FormHiddenSubmitButton,
  FormInput,
  FormPassword,
} from "components/FormItems/FlatFormItems";
import { Button } from "components/Buttons/Buttons";

import {
  checkIsRegistrationComplete,
  forgotPassword,
  forgotUsername,
  guestInviteReset,
  resetPassword,
} from "services/auth.services";
import Modal from "components/Modal/Modal";
import { push } from "connected-react-router";
import { useLocation } from "react-router";

import queryString from "query-string";

const { Title, Text } = Typography;
const COLORS = ["#58379E", "#88C3FD", "#FF8E00", "#48A4FE", "#88ebc2"];

export default function Invite(params) {
  const [form] = Form.useForm();

  const location = useLocation();
  const qs = queryString.parse(location.search);

  const dispatch = useDispatch();

  return (
    <AuthLayout>
      <Space className="loginFormContainer" direction="vertical">
        <Title level={4} style={{ margin: 0, color: "#000" }}>
          Welcome {qs.firstName}, please finish setting up your account.
        </Title>
        <Form
          form={form}
          requiredMark={false}
          layout="vertical"
          initialValues={
            {
              // userName: qs.userName,
            }
          }
          onFinish={async (values) => {
            const requestData = {
              newPassword: values.password,
              resetToken: window.location.search.split("token=")[1],
              newUserName: values.userName,
              emailAsUsername: qs?.userName,
            };

            const res = await dispatch(
              checkIsRegistrationComplete(qs?.userName)
            );
            if (res?.status === "success") {
              dispatch(guestInviteReset(requestData));
            }
          }}
          onFinishFailed={({ values, errorFields, outOfDate }) => {
            form.scrollToField(errorFields[0].name, {
              scrollMode: "if-needed",
              block: "center",
              behavior: "smooth",
            });
          }}
        >
          {/* <Text style={{ display: "inline-block", marginBottom: 12 }}>
            A reset token has been sent to your email.
          </Text> */}
          {/* <FormInput
            required
            variant="underlined"
            name="resetToken"
            label="Reset Token"
            placeholder=""
            formItemStyles={{ marginBottom: 12 }}
          /> */}
          <FormInput
            placeholder="Username"
            name="userName"
            label="Username"
            required={true}
            formItemStyles={{
              paddingTop: 10,
              marginBottom: 12,
            }}
          />
          <FormPassword
            required={true}
            name="password"
            label="New Password"
            placeholder=""
            formItemStyles={{ marginBottom: 12 }}
          />
          <FormPassword
            name="confirmPassword"
            label="Confirm Password"
            placeholder=""
            formItemStyles={{ marginBottom: 12 }}
            rules={[
              {
                required: true,
                message: "*Required",
              },
              {
                message: "Password and Confirm Password don't match.",
                validator: (_, value) => {
                  const password = form.getFieldValue("password");
                  const confirmPassword = form.getFieldValue("confirmPassword");
                  if (password !== confirmPassword) {
                    return Promise.reject();
                  }
                  return Promise.resolve();
                },
              },
            ]}
          />
          <Button
            style={{ width: "100%", marginTop: 20 }}
            type="submit"
            // loading={submitting}
          >
            Save
          </Button>
        </Form>
      </Space>
    </AuthLayout>
  );
}
