import { setLoading } from "redux/actions/loading.actions";
import {
  setPurchaseableRewards,
  setRewards,
} from "redux/actions/rewards.action";
import { openNotificationWithIcon } from "utils/Notification";
import { request } from "./verb.services";

export const getRewards = () => {
  return (dispatch) => {
    //   dispatch(setSubmitting(true));
    return request("CustomerRewards/RewardGroups", "get", null, true)
      .then((response) => {
        const data = response.data;
        dispatch(setRewards(data));
        return { status: "success", data };
      })
      .catch((e, f) => {
        openNotificationWithIcon(
          "error",
          "Error!",
          e?.response?.message ||
            e?.response?.data?.[0]?.description ||
            "Error while fetching rewards."
        );
      });
    // .finally(() => dispatch(setSubmitting(false)));
  };
};

export const getCustomerPrograms = () => {
  return (dispatch) => {
    dispatch(setLoading(true));
    return request("Customer/Programs", "get", null, true)
      .then((response) => {
        const data = response.data;
        return { status: "success", data };
      })
      .catch((e, f) => {
        openNotificationWithIcon(
          "error",
          "Error!",
          e?.response?.message ||
            e?.response?.data?.[0]?.description ||
            "Network error has occured"
        );
      })
      .finally(() => dispatch(setLoading(false)));
  };
};

export const getPurchaseableRewards = () => {
  return (dispatch) => {
    dispatch(setLoading(true));
    return request("Programs", "get", null, true)
      .then((response) => {
        const data = response.data;
        dispatch(setPurchaseableRewards(data));
        return { status: "success", data };
      })
      .catch((e, f) => {
        openNotificationWithIcon(
          "error",
          "Error!",
          e?.response?.message ||
            e?.response?.data?.[0]?.description ||
            "Error has occurred"
        );
      })
      .finally(() => dispatch(setLoading(false)));
  };
};

export const purchaseReward = (data) => {
  return (dispatch) => {
    dispatch(setLoading(true));
    return request("orders/program", "post", data, true)
      .then((res) => {
        openNotificationWithIcon(
          "success",
          "Success!",
          "Program purchased successfully"
        );
        return {
          status: "success",
          data: res.data,
        };
      })
      .catch((e) => {
        openNotificationWithIcon(
          "error",
          "Error!",
          e?.response?.data?.[0] ||
            e?.response?.data?.message ||
            e?.response?.data?.Message ||
            e?.response?.message ||
            e?.message ||
            "Something went wrong"
        );
      })
      .finally(() => {
        dispatch(setLoading(false));
      });
  };
};

export const applyRewardToLineItem = () => {};

export const applyVoucherToLineItem = () => {};

export const removeRewardFromLineItem = () => {};

export const removeVoucherFromLineItem = () => {};
