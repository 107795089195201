import moment from "moment";
import {actions} from "redux/actions/misc.action"
const INITIAL_STATE = {
  miscSettings: [],
  isLoading: true,
};
const reducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case actions.SET_MISC:
      return { ...state, miscSettings: action.data };
    case actions.SET_MISC_LOADING:
      return { ...state, isLoading: action.data };
    default:
      return state;
  }
};

export default reducer;
