import React from "react";
import PropTypes from "prop-types";
import { Avatar as AntdAvtar } from "antd";

function Avatar({ children, url, ...props }) {
  return (
    <AntdAvtar {...props} size="large" className="headerAvatar">
      {children}
    </AntdAvtar>
  );
}

Avatar.propTypes = {};

export default Avatar;
