import React, { useRef, useState, useEffect, useLayoutEffect } from "react";
import PropTypes from "prop-types";

import SignatureCanvas from "react-signature-canvas";
import "./Signature.scss";
import { FontSizeOutlined, HighlightOutlined } from "@ant-design/icons";
import { Tabs, Input, Form, Grid } from "antd";
const FormItem = Form.Item;
const { TabPane } = Tabs;
const { useBreakpoint } = Grid;

export const useSignature = (className, form, name = "signature") => {
  let signatureRef = useRef();
  let inputRef = useRef();
  const [tab, setTab] = useState("1");

  const getSignatureValue = () => {
    let value = "";
    if (tab === "1") {
      value = signatureRef?.current?.toDataURL() || "";
      if (signatureRef?.current?.isEmpty()) {
        value = "";
      }
    } else {
      value = inputRef?.current?.input?.value || "";
    }

    if (value === "") {
      let element = document.querySelector(`.${className} .ant-modal-body`);
      element?.scrollBy({ top: element.scrollHeight, behavior: "smooth" });
    }
    return value;
  };

  const setSignatureValue = (value = "") => {
    if (value?.trim()?.match?.("data:image")) {
      setTab("1");
      signatureRef.current?.fromDataURL(value);
    } else if (value) {
      setTab("2");
      form.setFieldsValue({
        [name]: value,
      });
    }
  };

  const clearSignatureValue = () => {
    signatureRef?.current?.clear?.();
    if (!!inputRef?.current?.input?.value) {
      form.setFieldsValue({
        [name]: "",
      });
      // inputRef.current.input.value = "xxxxxxxx";
    }
  };

  return [
    {
      signatureRef,
      inputRef,
      tab,
      setTab,
    },
    getSignatureValue,
    clearSignatureValue,
    setSignatureValue,
  ];
};

function Signature({ signature, disabled, name = "signature" } = {}) {
  useEffect(() => {
    if (Object.keys(signature).length === 0) {
      throw new Error("Signature Hook is required");
    }
  }, []);

  const { signatureRef, inputRef, tab, setTab } = signature;
  const screens = useBreakpoint();

  return (
    <div className="signatureContainer" id="signatureContainer">
      <Tabs activeKey={tab} onChange={setTab} destroyInactiveTabPane={false}>
        <TabPane
          disabled={disabled}
          tab={
            <span>
              <HighlightOutlined />
              Signature
            </span>
          }
          key="1"
        >
          <div style={{ display: "flex", position: "relative" }}>
            {disabled && (
              <div
                style={{
                  width: screens.md ? 367 : 300,
                  height: 150,
                  position: "absolute",
                  background: "#000",
                  left: 0,
                  zIndex: 100,
                  opacity: "0.1",
                }}
              ></div>
            )}
            <SignatureCanvas
              ref={(ref) => {
                if (signatureRef) signatureRef.current = ref;
              }}
              className="canvas"
              canvasProps={{
                width: screens.md ? 320 : 255,
                height: 150,
                className: "sigCanvas",
              }}
            />
            <button
              onClick={() => {
                signatureRef?.current?.clear?.();
              }}
              type="button"
              style={{
                fontFamily: "tradeGothic",
                borderLeft: 0,
                borderWidth: 1,
                cursor: "pointer",
              }}
            >
              Clear
            </button>
          </div>
        </TabPane>
        <TabPane
          disabled={disabled}
          tab={
            <span>
              <FontSizeOutlined />
              Text
            </span>
          }
          key="2"
        >
          <FormItem noStyle name={name} disabled={disabled}>
            <Input
              ref={inputRef}
              name={name}
              placeholder="Signature"
              disabled={disabled}
              style={{ maxWidth: 320 }}
              // readOnly={readOnly}
              // defaultValue={initialValue}
              // placeholder={placeholder}
              // style={{ padding: 12, ...styles }}
              // type={type === "email" ? "text" : type}
              // disabled={disabled}
              // maxLength={max}
              // addonAfter={addonAfter}
            />
          </FormItem>
        </TabPane>
      </Tabs>
    </div>
  );
}

Signature.propTypes = {};

export default Signature;
