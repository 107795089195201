import React from "react";
import PropTypes from "prop-types";

import { Typography, Tooltip } from "antd";

function InfoText({ children, tooltip = false, className = "", style = {} }) {
  return (
    <Tooltip title={children} visible={tooltip === false ? false : undefined}>
      <div className={`flex ${className}`} style={style}>
        <div className="info">i</div>
        {!tooltip && (
          <Typography.Text
            className="tradeGothic"
            style={{ fontSize: 14, color: "#7F889A" }}
          >
            {children}
          </Typography.Text>
        )}
      </div>
    </Tooltip>
  );
}

InfoText.propTypes = {
  chiildren: PropTypes.any,
  tooltop: PropTypes.bool,
  className: PropTypes.string,
  style: PropTypes.object,
};

export default InfoText;
