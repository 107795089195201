import React, { useRef } from "react";
import PropTypes from "prop-types";

import { Form, Row, Col } from "antd";
import { Button } from "components/Buttons/Buttons";

import Modal from "components/Modal/Modal";
import {
  FormInput,
  FormInputArea,
  FormButton,
  FormSelect,
  FormHiddenSubmitButton,
} from "components/FormItems/FlatFormItems";
import {
  setAddressInstruction,
  updateAddress,
} from "services/addresses.service";
import { useDispatch, useSelector } from "react-redux";

function AddInstructionsModal({ data = {}, visible, handleSave, onCancel }) {
  const [form] = Form.useForm();
  const submitBtnRef = useRef();

  const dispatch = useDispatch();
  const addresses = useSelector((state) => state.addresses);

  const { addressId, instructions } = data;
  const { isSubmitting } = addresses;
  return (
    <Modal
      destroyOnClose={true}
      headerBorder={false}
      width="520px"
      title={"Address Instructions"}
      onCancel={() => {
        onCancel();
        form.resetFields();
      }}
      visible={visible}
      okText="Save"
      footer={[
        <Button
          rounded={true}
          loading={isSubmitting}
          onClick={() => submitBtnRef?.current?.click()}
        >
          Save
        </Button>,
      ]}
    >
      <Form
        preserve={false}
        form={form}
        requiredMark={false}
        layout="vertical"
        initialValues={{
          instructions: instructions || "",
        }}
        onFinish={(values) => {
          dispatch(setAddressInstruction(addressId, values)).then((data) => {
            if (data) handleSave?.();
          });
        }}
        onValuesChange={(changedValues, allValues) => {
          console.log(changedValues, allValues);
        }}
      >
        <Row gutter={[24, 16]}>
          <Col xs={24}>
            <FormInputArea
              variant="underlined"
              rows={6}
              name="instructions"
              label="Instructions"
              placeholder="Instructions"
              formItemStyles={{ marginBottom: 0 }}
            />
          </Col>
        </Row>

        <FormHiddenSubmitButton submitRef={submitBtnRef} />
      </Form>
    </Modal>
  );
}

AddInstructionsModal.propTypes = {
  data: PropTypes.any,
  visible: PropTypes.bool,
  onCancel: PropTypes.any,
  handleSave: PropTypes.any,
};

export default AddInstructionsModal;
