import React, { useEffect, useMemo, useRef, useState } from "react";
import {
  Checkbox,
  Form,
  Select,
  Dropdown,
  Button as AntdButton,
  Empty,
  Typography,
  Space,
} from "antd";
import Glutathione from "../../assets/images/Glutathione.png";
import { CloseOutlined } from "@ant-design/icons";
import { useSelector } from "react-redux";
import { getItem, setItem, removeItem } from "utils/storage";

import Modal from "components/Modal/Modal";
import { Button } from "components/Buttons/Buttons";
import { disableOptionBasedOnSelection } from "utils/common";
import ProductItem from "components/ProductItem/ProductItem";

const { Text } = Typography;

export default function BoostPopup({
  handleSave,
  onCancel,
  visible,
  form,
  name,
  listName,
  addOns = [],
  selectedAddOns = [],
  onChange,
  cancelText = "Continue without adding add-ons.",
  closeOnCancel,
  checkboxOnChange
}) {
  // Disabling option for selection code starts here ~ Junaid
  let disabledAdonsList = disableOptionBasedOnSelection(selectedAddOns, addOns);
  // Disabling option for selection code ends here ~ Junaid

  const [
    isTriImmuneSelected,
    isGlutathioneSelected,
    isZincSelected,
    isB12Selected,
    isSkinnySelected,
  ] = useMemo(() => {
    let isTriImmuneSelected = false;
    let isGlutathioneSelected = false;
    let isZincSelected = false;
    let isB12Selected = false;
    let isSkinnySelected = false;

    selectedAddOns?.forEach?.((addOnId) => {
      const addOn = addOns.find((addon) => addOnId === addon.productId);
      if (addOn?.productName === "Tri-Immune") isTriImmuneSelected = true;
      if (addOn?.productName === "Glutathione") isGlutathioneSelected = true;
      if (addOn?.productName === "Zinc") isZincSelected = true;
      if (addOn?.productName === "B-12") isB12Selected = true;
      if (addOn?.productName === "Skinny") isSkinnySelected = true;
    });
    return [
      isTriImmuneSelected,
      isGlutathioneSelected,
      isZincSelected,
      isB12Selected,
      isSkinnySelected,
    ];
  }, [selectedAddOns]);

  const checkIfAddonIsDisabled = (addOn) => {
    if (addOn?.productName === "Tri-Immune" && isGlutathioneSelected)
      return true;
    if (addOn?.productName === "Glutathione" && isTriImmuneSelected)
      return true;
    if (addOn?.productName === "Tri-Immune" && isZincSelected) return true;
    if (addOn?.productName === "Zinc" && isTriImmuneSelected) return true;
    if (addOn?.productName === "B-12" && isSkinnySelected) return true;
    if (addOn?.productName === "Skinny" && isB12Selected) return true;
    return false;
  };

  const nameValue = Form.useWatch(name, form);
  const initialValue = listName
    ? form.getFieldValue(listName)?.[name?.[0]]?.[name?.[1]]
    : form.getFieldValue(name);

  const onBoostChange = (value) => {
    if (listName) {
      let list = form.getFieldValue(listName);
      // let previousValue = list[name[0]][name[1]];
      // list[name[0]][name[1]] = [...previousValue, value];
      let updatedList = list.map((item, index) => {
        if (index === name[0]) {
          let previousValue = list[name[0]][name[1]];
          let updatedBoosts = [];
          if (previousValue.includes(value)) {
            updatedBoosts = previousValue.filter((b) => b !== value);
          } else {
            updatedBoosts = [...previousValue, value];
          }
          return { ...item, [name[1]]: updatedBoosts };
        }
        return item;
      });
      form.setFieldsValue({ [listName]: updatedList });
      setItem("pendingOrder", {
        ...form.getFieldsValue(),
        [listName]: updatedList,
        status: "pending",
      });
    } else {
      const boosts = form.getFieldValue(name);
      if (boosts) {
        if (boosts.includes(value)) {
          const updatedBoosts = boosts.filter((b) => b !== value);
          form.setFieldsValue({ [name]: updatedBoosts });
          setItem("pendingOrder", {
            ...form.getFieldsValue(),
            [name]: updatedBoosts,
            status: "pending",
          });
        } else {
          form.setFieldsValue({ [name]: [...boosts, value] });
          setItem("pendingOrder", {
            ...form.getFieldsValue(),
            [name]: [...boosts, value],
            status: "pending",
          });
        }
      } else {
        form.setFieldsValue({ [name]: [value] });
        setItem("pendingOrder", {
          ...form.getFieldsValue(),
          [name]: [value],
          status: "pending",
        });
      }
    }
  };

  const dropdownContent = () => {
    if (addOns.length === 0) {
      return (
        <div style={{}}>
          <Empty />
        </div>
      );
    }

    return (
      <div>
        <p style={{ fontFamily: "tradeGothic", fontSize: 17 }}>
          Elevate your wellness even more with add-ons.
        </p>
        <div
          className="showScrollbar"
          style={{
            overflow: "scroll",
            height: 300,
          }}
        >
          {/* <CloseOutlined
            style={{ position: "absolute", right: 0, padding: 4 }}
            // onClick={() => setBoostVisble(false)}
          /> */}

          <div>
            {addOns.map((addon) => {
              return (
                <div
                  className={`${
                    checkIfAddonIsDisabled(addon) ? "addondisabled" : ""
                  }`}
                  key={addon.productId}
                  style={{
                    display: "flex",
                    alignItems: "center",
                    marginBottom: 20,
                  }}
                >
                  <Checkbox
                    disabled={
                      disabledAdonsList?.includes?.(addon?.productId) ?? false
                    }
                    style={{
                      marginRight: 16,
                    }}
                    checked={
                      initialValue?.includes?.(addon?.productId) ?? false
                    }
                    onChange={() => {
                      if (checkboxOnChange) {
                        checkboxOnChange(addon.productId);
                      } else {
                        onChange(addon.productId);
                        onBoostChange(addon.productId);
                      }
                      // onBoostChange(addon.productId)
                    }}
                  />
                  <ProductItem key={addon.productId} product={addon} />
                </div>
              );
            })}
          </div>
        </div>
        {/* <Button type="text" style={{ paddingTop: 8 }} onClick={handleSave}>
          Continue without adding boosts.
        </Button> */}
      </div>
    );
  };
  return (
    <Modal
      destroyOnClose={true}
      className="boostPopup"
      title="Additional Add-ons"
      headerBorder={true}
      visible={visible}
      onCancel={onCancel}
      okText="Save & Order"
      onOk={handleSave}
      okButtonProps={{
        disabled: !initialValue || initialValue?.length <= 0,
      }}
      footer={
        <Space style={{ display: "flex", justifyContent: "space-between" }}>
          <AntdButton
            type="text"
            style={{ paddingTop: 8 }}
            onClick={closeOnCancel ? onCancel : handleSave}
            disabled={initialValue?.length}
          >
            {cancelText}
          </AntdButton>
          <Button rounded={true} onClick={handleSave}>
            Save
          </Button>
        </Space>
      }
    >
      {dropdownContent()}
    </Modal>
  );
}
