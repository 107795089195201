import { actions } from "../actions/document.action";

const INITIAL_STATE = {
  documents: [],
};

const reducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case actions.SET_DOCUMENTS:
      return { ...state, documents: action.data };
    case actions.ADD_DOCUMENT:
      return { ...state, documents: [...state.documents, action.data] };
    case actions.UPDATE_DOCUMENT:
      const updatedDocuments = state.documents.map((document) => {
        if (document.id === action.data?.id) {
          return { ...document, ...action.data };
        }
        return document;
      });
      return { ...state, documents: updatedDocuments };
    case actions.DELETE_DOCUMENT:
      const filteredDocuments = state.documents.filter(
        (document) => document.id !== action.data?.id
      );
      return { ...state, documents: filteredDocuments };
    default:
      return state;
  }
};
export default reducer;
