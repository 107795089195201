import React from "react";
import PropTypes from "prop-types";
import { useSelector, useDispatch } from "react-redux";
import Modal from "components/Modal/Modal";

import { List, Typography, Button } from "antd";
import Avatar from "components/Avatar/Avatar";

import {
  setCurrentProfileId,
  setShowProfileScreen,
} from "redux/actions/user.actions";

const { Text } = Typography;

const COLORS = ["#58379E", "#88C3FD", "#FF8E00", "#48A4FE", "#88ebc2"];

function ProfileSelection({ visible }) {
  const { showProfileSelectionPrompt, profiles, currentProfileId } =
    useSelector((state) => state.user);
  const dispatch = useDispatch();

  return (
    <Modal
      width={480}
      centered
      title="Select Your Profile"
      headerBorder={false}
      visible={showProfileSelectionPrompt}
      footer={null}
      onCancel={() => {
        dispatch(setShowProfileScreen(false));
      }}
    >
      <List
        itemLayout="horizontal"
        dataSource={profiles}
        renderItem={(profile, index) => {
          const isProfileSelected =
          currentProfileId === profile.profileId ||
            (!Boolean(currentProfileId) && index === 0);
          return (
            <List.Item>
              <List.Item.Meta
                avatar={
                  <Avatar
                    style={{
                      color: "#f56a00",
                      backgroundColor:
                        COLORS[(Math.random() * COLORS.length) | 0],
                    }}
                  >
                    {profile.firstName[0]} {profile.lastName[0]}
                  </Avatar>
                }
                title={`${profile.firstName} ${profile.lastName}`}
                description={profile.email}
              />
              {!isProfileSelected && (
                <Button
                  type="text"
                  disabled={isProfileSelected}
                  onClick={() => {
                    dispatch(setShowProfileScreen(false));
                    dispatch(setCurrentProfileId(profile.profileId));
                  }}
                >
                  {isProfileSelected ? "Selected" : "Select"}
                </Button>
              )}
            </List.Item>
          );
        }}
      />
      {/* {profiles.map((profile) => {
        return (
          <div style={{ display: "flex", alignItems: "center" }}>
            <Avatar>
              {profile?.firstName?.[0]} {profile?.lastName?.[0]}
            </Avatar>
            <Text>
              {profile.firstName} {profile.lastName}
            </Text>
          </div>
        );
      })} */}
    </Modal>
  );
}

ProfileSelection.propTypes = {};

export default ProfileSelection;
