/* eslint-disable no-unused-expressions */
/* eslint-disable no-param-reassign */

import axios from "axios";
import apiObj from "../utils/api";
import { getItem } from "../utils/storage";

const api = apiObj.url;

export const fetchToken = () => {
  const tokenContainer = getItem("tokenContainer");
  return `Bearer ${tokenContainer?.token}`;
};

// export const fetchToken = () => JSON.parse(localStorage.getItem('idToken'));

axios.interceptors.response.use(undefined, (err) => {
  const { config, message } = err;
  if (!config || !config.retry) {
    return Promise.reject(err);
  }
  // retry while Network timeout or Network Error
  if (!(message.includes("timeout") || message.includes("Network Error"))) {
    return Promise.reject(err);
  }
  config.retry -= 1;
  const delayRetryRequest = new Promise((resolve) => {
    setTimeout(() => {
      resolve();
    }, config.retryDelay || 1000);
  });
  return delayRetryRequest.then(() => axios(config));
});

export const request = (
  url,
  type,
  data,
  headers,
  params,
  extraOptions = null,
  retryOptions = null
) => {
  return new Promise((resolve, reject) => {
    const request = {
      method: type,
      url: api + url,
      headers: {
        accept: "*/*",
        "Access-Control-Allow-Origin": "*",
        "Content-Type": "application/json",
        "ngrok-skip-browser-warning": "any",
      },
      ...extraOptions,
    };
    if (headers) {
      request.headers = {
        Authorization: fetchToken(),
        "ngrok-skip-browser-warning": "any",
      };
      if (headers.contentType) {
        request.headers["content-type"] = headers.contentType;
      }
    }

    type !== "get" && (request.data = data);
    params && (request.params = params);
    retryOptions && (request.retry = retryOptions?.retry);
    if (!request.url.includes("refresh")) {
      axios(request, retryOptions).then(resolve, reject);
    }
  });
};
