import { combineReducers } from "redux";
import { connectRouter } from "connected-react-router";
import user from "./user.reducer";
import loading from "./loading.reducer";
import booking from "./booking.reducer";
import location from "./location.reducers";
import addresses from "./addresses.reducer";
import document from "./document.reducer";
import family from "./family.reducer";
import intakeForms from "./intakeForms.reducer";
import paymentMethod from "./paymentMethod.reducer";
import membership from "./membership.reducer";
import locationCenter from "./locationCenter.reducer";
import timer from "./timer.reducer";
import facilities from "./facilities.reducer";
import rewards from "./rewards.reducer";
import misc from "./misc.reducer";

// Root Reducer.
const reducer = (history) => {
  return combineReducers({
    router: connectRouter(history),
    user,
    loading,
    booking,
    location,
    addresses,
    document,
    family,
    intakeForms,
    paymentMethod,
    membership,
    locationCenter,
    timer,
    rewards,
    misc,
    facilities,
  });
};

export default reducer;
