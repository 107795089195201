import moment from "moment";
import { BenefitType, ExcludeType, OperationType, ProductType } from "./enums";

export const generateHourlyTimeslotsAfterNHours = (
  n,
  date,
  isMobile,
  facilityId
) => {
  let offsetDate = moment();
  offsetDate.set({ hour: 0, minute: 0, second: 0, millisecond: 0 });

  let scheduledDate = moment(date);
  const isScheduledDateToday =
    scheduledDate.format("DD MM YYYY") === moment().format("DD MM YYYY");
  if (!isScheduledDateToday) {
    offsetDate = moment(scheduledDate);
    offsetDate.set({ hour: 0, minute: 0, second: 0, millisecond: 0 });
    scheduledDate.set({ hour: 0, minute: 0, second: 0, millisecond: 0 });
  }

  const hoursPlusNMoment = moment().add(n, "hours");
  const currentHr24Plus2 =
    Math.ceil(offsetDate.local().hour()) + (isScheduledDateToday ? n : 0);
  const slots = [];
  for (let i = 0; i < 24; i++) {
    let hr = currentHr24Plus2 + i;
    let date = moment(offsetDate);
    date.hour(hr);

    if (
      date >= scheduledDate &&
      !(date.format("DD") !== scheduledDate.format("DD")) &&
      date.isAfter(hoursPlusNMoment)
    ) {
      let disabled = false;
      let preDate = moment(date).set({
        hours: getPreHour(date, isMobile, facilityId),
        minutes: 0,
        seconds: 0,
        milliseconds: 0,
      });
      let postDate = moment(date).set({
        hours: getPostHour(date, isMobile, facilityId),
        minutes: 0,
        seconds: 0,
        milliseconds: 0,
      });

      let preCallToBook = moment(date).set({
        hours: 7,
        minutes: 0,
        seconds: 0,
        milliseconds: 0,
      });
      if (date.isAfter(postDate)) {
        disabled = true;
      } else if (
        !isMobile &&
        date.isSameOrAfter(preCallToBook) &&
        date.isBefore(preDate)
      ) {
        disabled = true;
      } else if (date.isBefore(preDate)) {
        continue;
      }
      slots.push({
        label: `${moment(hr, "hh").format("LT")}${
          disabled ? " (Call to Book)" : ""
        }`,
        value: moment(hr, "hh").format("LT"),
        disabled: disabled,
      });
    }
  }
  return slots;
};

const WORK_DAYS = [1, 2, 3, 4, 5]; // Monday - Friday
const WEEK_END = [0, 6]; // Sunday, Saturday
const getPreHour = (date, isMobile, facilityId) => {
  const day = date.day();
  if (!isMobile) {
    // chiefs fit- overland park
    if (facilityId === 1) {
      // if monday - thursday
      if ([1, 2, 3, 4].includes(day)) return 10;
      // if friday
      if ([5].includes(day)) return 10;
      // if satuday- sunday
      if ([0, 6].includes(day)) return 9;
    }
    // chiefs fit - plaza
    if (facilityId === 3) {
      // if monday - thursday
      if ([1, 2, 3, 4].includes(day)) return 10;
      // if friday
      if ([5].includes(day)) return 10;
      // if satuday- sunday
      if ([0, 6].includes(day)) return 9;
    }
    // phoenix - optima baltimore towers
    if (facilityId === 2) {
      // if monday - thursday
      if ([1, 2, 3, 4].includes(day)) return 10;
      // if satuday- sunday
      if ([0, 5, 6].includes(day)) return 9;
    }
  }
  return 8;
};

const getPostHour = (date, isMobile, facilityId) => {
  const day = date.day();
  if (!isMobile) {
    // chiefs fit- overland park
    if (facilityId === 1) {
      // if monday - thursday
      if ([1, 2, 3, 4].includes(day)) return 19;
      // if friday
      if ([5].includes(day)) return 17;
      // if satuday- sunday
      if ([0, 6].includes(day)) return 16;
    }
    // chiefs fit - plaza
    if (facilityId === 3) {
      // if monday - thursday
      if ([1, 2, 3, 4].includes(day)) return 19;
      // if friday
      if ([5].includes(day)) return 17;
      // if satuday- sunday
      if ([0, 6].includes(day)) return 16;
    }
    // phoenix - optima baltimore towers
    if (facilityId === 2) {
      // if monday - thursday
      if ([1, 2, 3, 4].includes(day)) return 18;
      // if satuday- sunday
      if ([0, 5, 6].includes(day)) return 18;
    }
  }
  return isMobile ? 20 : 19;
};

export const addonsTextReducer = (prev, curr, currentIndex, array) => {
  let seperator = ",";
  if (!prev) seperator = "";
  if (currentIndex === array.length - 1) seperator = " and ";
  if (array?.length <= 1) seperator = "";
  return prev + `${seperator} ` + curr?.productName;
};

export const disableOptionBasedOnSelection = (
  selectedOptions,
  listOfAllOptions
) => {
  let disabledList = [];
  selectedOptions.forEach((res) => {
    listOfAllOptions.forEach((res2) => {
      if (res2.productId == res) {
        disabledList.push(res2.restrictedProductIds);
      }
    });
  });
  return disabledList.flat(Infinity);
};

export const generateHourlySlots = (
  selectedDate,
  startTime = "09:00",
  endTime = "18:00",
  fullData = {},
  isMobile = false,
  durationHours = 0,
  operationTypeId
) => {
  const {
    clinicLeadTime = 0,
    mobileLeadTime = 0,
    telehealthLeadTime = 0,
    callToBook = false,
    postHoursOfOperationStartTime,
    postHoursOfOperationEndTime,
    preHoursOfOperationStartTime,
    preHoursOfOperationEndTime,
  } = fullData;

  const leadTime =
    operationTypeId == 1
      ? mobileLeadTime
      : operationTypeId == 3
      ? telehealthLeadTime
      : clinicLeadTime;

  let currentTime = moment();
  var leadDuration = moment.duration(leadTime, "hours");
  var leadMS = leadDuration.asMilliseconds();
  currentTime = moment(currentTime).add(leadMS, "ms");

  const startDate = moment(selectedDate);
  startDate.set({
    hours: Number(startTime.split(":")[0]),
    minutes: startTime.split(":")[1],
    seconds: 0,
    milliseconds: 0,
  });

  let endDate = moment(selectedDate);
  endDate.set({
    hours: endTime.split(":")[0],
    minutes: endTime.split(":")[1],
    seconds: 0,
    milliseconds: 0,
  });

  var duration = moment.duration(durationHours, "hours");
  var ms = duration.asMilliseconds();
  endDate = moment(endDate).subtract(ms, "ms");

  let iterator = moment(startDate);
  const slots = [];
  while (iterator <= endDate) {
    if (iterator > currentTime)
      slots.push({
        label: iterator.format("LT"),
        value: iterator.format("LT"),
        disabled: false,
      });
    iterator = iterator.add(30, "minutes");
  }

  const preCallToBookHours = [];
  const postCallToBookHours = [];
  if (callToBook) {
    if (preHoursOfOperationStartTime && preHoursOfOperationEndTime) {
      const preHoursOfOperationStartTimeMoment = moment(selectedDate);
      preHoursOfOperationStartTimeMoment.set({
        hours: preHoursOfOperationStartTime.split(":")[0],
        minutes: preHoursOfOperationStartTime.split(":")[1],
        seconds: 0,
        milliseconds: 0,
      });
      const preHoursOfOperationEndTimeMoment = moment(selectedDate);
      preHoursOfOperationEndTimeMoment.set({
        hours: preHoursOfOperationEndTime.split(":")[0],
        minutes: preHoursOfOperationEndTime.split(":")[1],
        seconds: 0,
        milliseconds: 0,
      });

      let iterator = moment(preHoursOfOperationStartTimeMoment);
      while (
        iterator.format("LT") !==
          preHoursOfOperationEndTimeMoment.format("LT") &&
        iterator <= preHoursOfOperationEndTimeMoment &&
        iterator <= startDate
      ) {
        preCallToBookHours.push({
          label: `${iterator.format("LT")} (Call To Book)`,
          value: iterator.format("LT"),
          disabled: true,
        });
        iterator = iterator.add(30, "minutes");
      }
    }

    if (postHoursOfOperationStartTime && postHoursOfOperationEndTime) {
      const postHoursOfOperationStartTimeMoment = moment(selectedDate);
      postHoursOfOperationStartTimeMoment.set({
        hours: postHoursOfOperationStartTime.split(":")[0],
        minutes: postHoursOfOperationStartTime.split(":")[1],
        seconds: 0,
        milliseconds: 0,
      });
      const postHoursOfOperationEndTimeMoment = moment(selectedDate);
      postHoursOfOperationEndTimeMoment.set({
        hours: postHoursOfOperationEndTime.split(":")[0],
        minutes: postHoursOfOperationEndTime.split(":")[1],
        seconds: 0,
        milliseconds: 0,
      });
      let iterator = moment(postHoursOfOperationStartTimeMoment);
      while (
        iterator.format("LT") !==
          postHoursOfOperationEndTimeMoment.format("LT") &&
        iterator <= postHoursOfOperationEndTimeMoment &&
        iterator > endDate
      ) {
        postCallToBookHours.push({
          label: `${iterator.format("LT")} (Call To Book)`,
          value: iterator.format("LT"),
          disabled: true,
        });
        iterator = iterator.add(30, "minutes");
      }
    }
  }

  if (callToBook)
    return [...preCallToBookHours, ...slots, ...postCallToBookHours];
  else return slots;
};

export const getProductTypeFromOperation = (operationTypeId) => {
  return operationTypeId === OperationType.Telehealth
    ? ProductType.Telehealth
    : ProductType.Service;
};

export const checkForProductExcludeType = (
  myreward = {},
  values = {},
  excludeRewards = []
) => {
  let availableCustomerRewardIds = [];
  if (myreward.productExcludeType === ExcludeType["All Included"]) {
    // saari products pe ye reward apply krdo
    availableCustomerRewardIds = myreward?.customerRewardIds;
  } else if (myreward.productExcludeType === ExcludeType["Only Included"]) {
    // jo productIds ki collection mai ho sirf unhi pe apply krdo
    if (myreward.productIds.includes(values.productId)) {
      availableCustomerRewardIds = myreward?.customerRewardIds;
    }
  } else if (
    myreward.productExcludeType === ExcludeType["All Included Except"]
  ) {
    // jo productIds ki collection mai ho unke elawa sb pe apply krdo
    if (!myreward.productIds.includes(values.productId)) {
      availableCustomerRewardIds = myreward?.customerRewardIds;
    }
  }

  for (let customerRewardId of availableCustomerRewardIds) {
    // const redemableRewardIds = excludeRewards.filter(
    //   (rewardId) => rewardId !== customerRewardId
    // ).length;

    if (
      !excludeRewards.includes(customerRewardId) ||
      !!myreward?.redemptionLimit
    ) {
      return customerRewardId;
    }
  }

  return;
};

export const getRewardId = (rewards, values, facilityId, excludeRewards) => {
  let rewardId = undefined;
  for (let myreward of rewards) {
    const dateIsBefore = moment().isBefore(moment(myreward.expiryDateUtc));

    if (
      !dateIsBefore ||
      (!myreward?.applications?.includes(1) &&
        myreward?.benefitType !== BenefitType?.Referrals)
    ) {
      // if includes 1 (guest) and 2 (backoffice) then it means it is applicable for both
      continue;
    }
    if (myreward.facilityExcludeType === ExcludeType["All Included"]) {
      // koi b facility ho us pe apply hoga reward   AllIncluded = 1,
      rewardId = checkForProductExcludeType(myreward, values, excludeRewards);
    } else if (myreward.facilityExcludeType === ExcludeType["Only Included"]) {
      // jo facility ho us pe apply hoga reward OnlyInclude = 2,
      if (myreward?.facilityIds?.includes(facilityId)) {
        // return myreward?.customerRewardIds[0];
        rewardId = checkForProductExcludeType(myreward, values, excludeRewards);
      }
    } else if (
      myreward.facilityExcludeType === ExcludeType["All Included Except"]
    ) {
      // all include except facility list jo aarhi ho AllIncludeExcept = 3
      if (!myreward?.facilityIds.includes(facilityId)) {
        // return myreward?.customerRewardIds[0];
        rewardId = checkForProductExcludeType(myreward, values, excludeRewards);
      }
    }
    if (rewardId) return rewardId;
  }
  return;
  // customerRewardIds se koi b ID utha k replace krna hoga order mai
};

// returns first available rewardId from each reward
export const getFirstRewardIdFromRewards = (
  rewards,
  values,
  facilityId,
  excludeRewards
) => {
  let rewardIds = [];
  for (let myreward of rewards) {
    const dateIsBefore = moment().isBefore(moment(myreward.expiryDateUtc));
    let reward = null;
    if (
      !dateIsBefore ||
      (!myreward?.applications?.includes(1) &&
        myreward?.benefitType !== BenefitType?.Referrals)
    ) {
      // if includes 1 (guest) and 2 (backoffice) then it means it is applicable for both
      continue;
    } else if (myreward.facilityExcludeType === ExcludeType["All Included"]) {
      // koi b facility ho us pe apply hoga reward   AllIncluded = 1,
      reward = checkForProductExcludeType(myreward, values, excludeRewards);
    } else if (myreward.facilityExcludeType === ExcludeType["Only Included"]) {
      // jo facility ho us pe apply hoga reward OnlyInclude = 2,
      if (myreward?.facilityIds?.includes(facilityId)) {
        // return myreward?.customerRewardIds[0];
        reward = checkForProductExcludeType(myreward, values, excludeRewards);
      }
    } else if (
      myreward.facilityExcludeType === ExcludeType["All Included Except"]
    ) {
      // all include except facility list jo aarhi ho AllIncludeExcept = 3
      if (!myreward?.facilityIds.includes(facilityId)) {
        // return myreward?.customerRewardIds[0];
        reward = checkForProductExcludeType(myreward, values, excludeRewards);
      }
    }
    if (!!reward) rewardIds.push(reward);
  }
  return rewardIds;
  // customerRewardIds se koi b ID utha k replace krna hoga order mai
};
export const validateEmail = (email) => {
  return String(email)
    .toLowerCase()
    .match(
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    );
};

export const injectRewards = (data = {}, rewards = [], facilityId) => {
  let availedRewards = [];

  for (let lineItem of data.lineItems) {
    const rewardId = getRewardId(rewards, lineItem, facilityId, availedRewards);
    if (rewardId) {
      lineItem.rewardId = rewardId;
      availedRewards.push(rewardId);
    }
    lineItem.addOns = lineItem.addOns.map((addOn) => {
      const rewardId = getRewardId(rewards, addOn, facilityId, availedRewards);
      if (rewardId) {
        addOn.rewardId = rewardId;
        availedRewards.push(rewardId);
      }
      return addOn;
    });
  }
};

export const getRewardsText = (item) => {
  let text = "";
  if (item.benefitType == BenefitType["Discount Percentage"]) {
    text = `Discount applied  ${item.benefitValue}% `;
  } else if (item.benefitType == BenefitType["Discount Amount"]) {
    text = `Discount applied fixed discount of $${Number(
      item.discountValue ?? 0
    ).toFixed(2)}`;
  } else if (item.benefitType == BenefitType["Bonus Service"]) {
    text = `Bonus Service`;
  } else if (item.benefitType == BenefitType["Referrals"]) {
    text = `Referral bonus of $${Number(item.discountValue ?? 0).toFixed(2)}`;
  }
  return text;
};

export const getRestrictionValues = (values, dateTime, customerId) => {
  let valuesToValidate = [
    {
      lineItems: [
        {
          productId: values.productId,
          addOns:
            values?.addOns?.map?.((addOn) => ({
              productId: addOn,
              productType: 2,
            })) ?? [],
        },
      ],
      serviceDate: dateTime,
      customerId: customerId,
    },
  ];

  for (let guest of values?.appointments ?? []) {
    valuesToValidate.push({
      firstName: guest.firstName,
      lastName: guest.lastName,
      email: guest.email,
      lineItems: [
        {
          productId: guest.productId,
          firstName: guest.firstName,
          lastName: guest.lastName,
          email: guest.email,
          addOns:
            guest?.addOns?.map?.((addOn) => ({
              productId: addOn,
              productType: 2,
            })) ?? [],
        },
      ],
      // facilityId: facilityId,
      // timeZone: timeZone,
      serviceDate: dateTime,
    });
  }
  return valuesToValidate ?? {};
};
