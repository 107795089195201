import React from "react";
import PropTypes from "prop-types";

import { Row, Col, Menu, Select } from "antd";
import { ReactComponent as DropdownIcon } from "assets/icons/dropdownIco.svg";

import "./MenuSidebar.scss";

function Sidebar({ items, onClick, activeKey }) {
  return (
    <Row>
      <Col xs={0} sm={0} md={24}>
        <Menu className="sidebarWrapper" selectedKeys={[String(activeKey)]}>
          {items.map((item, index) => {
            return (
              <Menu.Item
                tabIndex={0}
                icon={item.icon}
                key={index}
                onClick={() => onClick(item.urlName, index)}
              >
                {item.label}
              </Menu.Item>
            );
          })}
        </Menu>
      </Col>
      <Col xs={24} sm={24} md={0}>
        <Select
          value={items[activeKey ?? 0]}
          className="menuDropdown"
          suffixIcon={<DropdownIcon />}
          onChange={(value, option) => {
            onClick(option.value, Number(option.key));
          }}
        >
          {items.map((item, index) => {
            return (
              <Select.Option key={index} value={item.urlName}>
                {item.label}
              </Select.Option>
            );
          })}
        </Select>
      </Col>
    </Row>
  );
}

Sidebar.propTypes = {};

export default Sidebar;
