import React, { useReducer } from "react";
import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";

import { Checkbox, Space } from "antd";
import { updateCommunicationPreference } from "services/auth.services";

function CommunicationPreferences(props) {
  const dispatch = useDispatch();
  const { currentUser, profiles } = useSelector((state) => {
    return state.user;
  });

  const selectedProfile = profiles?.[0] ?? {};
  const { sendPushNotification, sendSmsNotification } = selectedProfile;

  const updateSetting = (name, value) => {
    dispatch(
      updateCommunicationPreference(selectedProfile?.profileId, {
        sendSmsNotification,
        sendPushNotification,
        [name]: value,
      })
    );
  };

  return (
    <Space direction="vertical">
      <Checkbox
        disabled={!Boolean(selectedProfile?.profileId)}
        className="checkboxRegular"
        checked={sendPushNotification}
        onChange={(v) => {
          updateSetting("sendPushNotification", v.target.checked);
        }}
      >
        Push Notification
      </Checkbox>
      <Checkbox
        disabled={!Boolean(selectedProfile?.profileId)}
        className="checkboxRegular"
        checked={sendSmsNotification}
        onChange={(v) => {
          updateSetting("sendSmsNotification", v.target.checked);
        }}
      >
        Text Messages
      </Checkbox>
    </Space>
  );
}

CommunicationPreferences.propTypes = {};

export default CommunicationPreferences;
