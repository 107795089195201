import React, { useState, useEffect, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Space, Typography, Form, Radio, List, Avatar } from "antd";
import AuthLayout from "components/AuthLayout/AuthLayout";

import LogoImage from "assets/images/logo.png";
import "./Reset.scss";

import {
  FormHiddenSubmitButton,
  FormInput,
  FormPassword,
} from "components/FormItems/FlatFormItems";
import { Button } from "components/Buttons/Buttons";

import {
  forgotPassword,
  forgotUsername,
  resetPassword,
} from "services/auth.services";
import Modal from "components/Modal/Modal";
import { push } from "connected-react-router";

const { Title, Text } = Typography;
const COLORS = ["#58379E", "#88C3FD", "#FF8E00", "#48A4FE", "#88ebc2"];

export default function Reset(params) {
  const [userNameResetForm] = Form.useForm(); // bhai backend se userName hi ha
  const [passwordResetForm] = Form.useForm();
  const [changePasswordForm] = Form.useForm();

  const [usernames, setUsernames] = useState([]);
  const [resetCode, setResetCode] = useState({ username: "", code: "" });
  const [showUsernames, setShowUsernames] = useState(false);
  const submitBtnRef = useRef();

  const [showResetModal, setShowResetModal] = useState(false);

  const [tab, setTab] = useState(0);
  const dispatch = useDispatch();

  useEffect(() => {
    if (window.location.search) {
      const code = window.location.search.split("token=")[1];
      const username = window.location.search
        .split("userName=")[1]
        .split("&")[0];
      setResetCode({ username, code });
      setTab(1);
      setShowResetModal(true);
    }
  }, []);
  return (
    <AuthLayout>
      <Space className="loginFormContainer" direction="vertical">
        {/* <img className="loginLogo" src={LogoImage} alt="logo" /> */}
        <Title level={4} style={{ paddingTop: 8, margin: 0, color: "#000" }}>
          Forgot Username/ Password?
        </Title>
        <Radio.Group
          className="radio"
          onChange={(e, v) => {
            setTab(e.target.value);
          }}
          value={tab}
        >
          <Radio value={0}> Username</Radio>
          <Radio value={1}> Password</Radio>
        </Radio.Group>
        {tab === 0 ? (
          <Form
            form={userNameResetForm}
            requiredMark={false}
            layout="vertical"
            onFinish={async (values) => {
              const usernames = await dispatch(forgotUsername(values));
              if (usernames) {
                setUsernames([usernames]);
                setShowUsernames(true);
              }
            }}
            onFinishFailed={({ values, errorFields, outOfDate }) => {
              userNameResetForm.scrollToField(errorFields[0].name, {
                scrollMode: "if-needed",
                block: "center",
                behavior: "smooth",
              });
            }}
          >
            <FormInput
              required
              //   variant="underlined"
              name="email"
              label="Email"
              placeholder="Enter Email"
              formItemStyles={{ marginBottom: 12 }}
            />
            <FormInput
              required
              //   variant="underlined"
              name="password"
              label="Password"
              placeholder="Enter Password"
              formItemStyles={{ marginBottom: 16 }}
            />

            <Button
              type="submit"
              style={{
                fontFamily: "tradeGothicBold",
                FontSize: 18,
                width: "100%",
                marginBottom: 16
              }}
            >
              Find Username
            </Button>
            <Text>Forgot both? Contact Liquid Mobile IV.</Text>
          </Form>
        ) : (
          <Form
            form={passwordResetForm}
            requiredMark={false}
            layout="vertical"
            onFinish={async (values) => {
              const value = await dispatch(forgotPassword(values));
              // if (value) setShowResetModal(values.userName);
            }}
            onFinishFailed={({ values, errorFields, outOfDate }) => {
              passwordResetForm.scrollToField(errorFields[0].name, {
                scrollMode: "if-needed",
                block: "center",
                behavior: "smooth",
              });
            }}
          >
            {/* <FormInput
              required
              //   variant="underlined"
              name="email"
              label="Email"
              placeholder="Enter Email"
              formItemStyles={{ marginBottom: 12 }}
            /> */}
            <FormInput
              required
              //   variant="underlined"
              name="userName"
              label="Username"
              placeholder="Enter Username"
              formItemStyles={{ marginBottom: 16 }}
            />

            <Button
              type="submit"
              style={{
                fontFamily: "tradeGothicBold",
                FontSize: 18,
                width: "100%",
              }}
            >
              Reset Password
            </Button>
          </Form>
        )}
      </Space>

      <Modal
        width={480}
        centered
        title="Your Username"
        headerBorder={false}
        visible={showUsernames}
        footer={null}
        onCancel={() => {
          setShowUsernames(false);
        }}
      >
        <List
          itemLayout="horizontal"
          dataSource={usernames}
          renderItem={(username, index) => {
            return (
              <List.Item>
                <List.Item.Meta
                  avatar={
                    <Avatar>{username[0]}</Avatar>
                    // <Avatar
                    //   src="https://joeschmoe.io/api/v1/random"
                    // // style={{
                    // //   color: "#f56a00",
                    // //   backgroundColor:
                    // //     COLORS[(Math.random() * COLORS.length) | 0],
                    // // }}
                    // />
                  }
                  title={
                    <p
                      style={{
                        margin: 0,
                        fontSize: 16,
                        fontFamily: "tradeGothic",
                        fontWeight: "bold",
                      }}
                    >
                      {username}
                    </p>
                  }
                />
              </List.Item>
            );
          }}
        />
        {/* {profiles.map((profile) => {
        return (
          <div style={{ display: "flex", alignItems: "center" }}>
            <Avatar>
              {profile?.firstName?.[0]} {profile?.lastName?.[0]}
            </Avatar>
            <Text>
              {profile.firstName} {profile.lastName}
            </Text>
          </div>
        );
      })} */}
      </Modal>

      <Modal
        headerBorder={false}
        width="460px"
        title="Reset Password"
        onCancel={() => setShowResetModal(false)}
        visible={showResetModal}
        // onOk={() => handleSave()}
        okText="Save"
        footer={[
          <Button rounded={true} onClick={() => submitBtnRef?.current?.click()}>
            Save
          </Button>,
        ]}
      >
        <Form
          form={changePasswordForm}
          requiredMark={false}
          layout="vertical"
          onFinish={async (values) => {
            values.userName = showResetModal;

            dispatch(
              resetPassword({
                ...values,
                resetToken: resetCode.code,
                userName: resetCode.username,
              })
            ).then((response) => {
              if (response?.status === "success") {
                setShowResetModal(false);
                dispatch(push("/login"));
              }
            });
            // const { currentPassword, newPassword } = values;
            // const result = await dispatch(
            //   changePassword({ currentPassword, newPassword })
            // );
            // if (result?.status === "success") handleSave?.();
          }}
          onFinishFailed={({ values, errorFields, outOfDate }) => {
            changePasswordForm.scrollToField(errorFields[0].name, {
              scrollMode: "if-needed",
              block: "center",
              behavior: "smooth",
            });
          }}
        >
          {/* <Text style={{ display: "inline-block", marginBottom: 12 }}>
            A reset token has been sent to your email.
          </Text> */}
          {/* <FormInput
            required
            variant="underlined"
            name="resetToken"
            label="Reset Token"
            placeholder=""
            formItemStyles={{ marginBottom: 12 }}
          /> */}

          <FormPassword
            required
            variant="underlined"
            name="newPassword"
            label="New Password"
            placeholder=""
            formItemStyles={{ marginBottom: 12 }}
          />
          <FormPassword
            required
            variant="underlined"
            name="confirmPassword"
            label="Confirm New Password"
            placeholder=""
            dependencies={["newPassword"]}
            formItemStyles={{ marginBottom: 12 }}
            rules={[
              {
                required: true,
                message: "*Required",
              },
              {
                message: "New and confirm passwords don't match",
                validator: (_, value) => {
                  const newPassword =
                    changePasswordForm.getFieldValue("newPassword");
                  const confirmPassword =
                    changePasswordForm.getFieldValue("confirmPassword");
                  if (newPassword !== confirmPassword) {
                    return Promise.reject();
                  }
                  return Promise.resolve();
                },
              },
            ]}
          />
          <FormHiddenSubmitButton submitRef={submitBtnRef} />
        </Form>
      </Modal>
    </AuthLayout>
  );
}
