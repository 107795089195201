import React, { useState } from "react";
import { Button, Popover, Typography } from "antd";
import moment from "moment";
import { EyeOutlined, InfoCircleOutlined } from "@ant-design/icons";
import GiftCardModal from "./GiftCardModal";
import { useSelector } from "react-redux";

const { Text, Title } = Typography;

export default function GiftCardItem({ giftCard = {}, refreshData }) {
  const {
    senderEmail,
    amount,
    createdOn,
    senderName,
    isDelivered,
    personalNotes,
    recipientEmail,
    giftCardId,
    hasInvalidEmail,
  } = giftCard;

  const {
    paymentMethod: { paymentMethods, wallets },
  } = useSelector((state) => ({
    paymentMethod: state.paymentMethod,
  }));
  const primaryPaymentMethod =
    paymentMethods?.find?.((card) => card?.isPrimary === true) ??
    paymentMethods?.[0];

  const [showEdit, setShowEdit] = useState(false);
  return (
    <div className="giftCardItem">
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <Title
          style={{
            fontFamily: "tradeGothic",
            fontSize: 19,
            color: "#000",
            marginBottom: 0,
          }}
        >
          Sender: {senderName} for ${giftCard.amount}
        </Title>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            gap: 8,
          }}
        >
          {hasInvalidEmail && (
            <Popover content={"Invalid Receiver Email Provided."}>
              <InfoCircleOutlined
                style={{
                  color: "red",
                  fontSize: 18,
                }}
              />
            </Popover>
          )}
          <Button
            size="small"
            shape="circle"
            icon={<EyeOutlined />}
            onClick={() => setShowEdit(giftCard)}
          />
        </div>
      </div>
      <Text
        style={{
          fontFamily: "tradeGothic",
        }}
      >
        Recipient: {recipientEmail ?? "Self"}
      </Text>
      <br />
      <Text
        style={{
          fontFamily: "tradeGothic",
        }}
      >
        Sent On: {moment(createdOn).format("ll")}
      </Text>
      <GiftCardModal
        primaryPaymentMethod={primaryPaymentMethod}
        visible={showEdit}
        onCancel={() => setShowEdit(false)}
        onSave={() => {
          setShowEdit(false);
          refreshData();
        }}
      />
    </div>
  );
}
