import moment from "moment";
const INITIAL_STATE = {
  timer: undefined, // undefined or moment object to calculate diff from
};

const reducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case "SET_TIMER":
      return { ...state, timer: action.data };
    default:
      return state;
  }
};

export default reducer;
