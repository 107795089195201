import React from "react";
import PropTypes from "prop-types";

import { Row, Col } from "antd";
import LoginImage from "assets/images/loginImage.JPG";

function AuthLayout(props) {
  const { children } = props;
  return (
    <Row className="loginContainer">
      <Col
        xs={0}
        md={11}
        className="loginLeftSide"
        style={{
          maxWidth: "620px",
          backgroundImage: `url(${LoginImage})`,
        }}
      >
        {/* <TestimonialCard
          style={{ position: "absolute", right: 40, bottom: 40 }}
          description="“Lorem ipsum dolor sit amet, consectetur adipiscing elit. Duis tincidunt et odio et consequat. Vestibulum urna turpis, molestie in laoreet eget, ultricies vitae lectus.”"
          name="Guy Hawkins"
          subTitle="Pro Skater"
        /> */}
      </Col>
      <Col
        xs={24}
        md={13}
        className="loginRightSide"
        style={{
          maxWidth: "none",
          flex: 1,
        }}
      >
        {children}
      </Col>
    </Row>
  );
}

AuthLayout.propTypes = {};

export default AuthLayout;
