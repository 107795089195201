export const actions = {
  SET_DOCUMENTS: "SET_DOCUMENTS",
  ADD_DOCUMENT: "ADD_DOCUMENT",
  UPDATE_DOCUMENT: "UPDATE_DOCUMENT",
  DELETE_DOCUMENT: "DELETE_DOCUMENT",
};

export const setDocument = (data) => ({
  type: actions.SET_DOCUMENTS,
  data,
});

export const addDocument = (data) => ({
  type: actions.ADD_DOCUMENT,
  data,
});

export const updateDocument = (data) => ({
  type: actions.UPDATE_DOCUMENT,
  data,
});

export const deleteDocument = (data) => ({
  type: actions.DELETE_DOCUMENT,
  data,
});
