import React, { useEffect, useMemo, useState } from "react";
import PropTypes from "prop-types";

import { Row, Col, Space, Typography, Form, Statistic } from "antd";
import { FormInput, FormButton } from "components/FormItems/FlatFormItems";

import "./GiftcardsAndPromotions.scss";
import GiftCardModal from "./GiftCardModal";
import { useDispatch, useSelector } from "react-redux";
import StripeAddCardModalWrapper from "components/StripeAddCard/StripeAddCardModal";
import { redeemGiftCard } from "services/booking.service";
import { getAllPaymentMethods } from "services/paymentMethod.service";
import { getPurchaseableRewards } from "services/rewards.service";
import GiftCardsList from "./GiftCardsList";
import { getCustomerWallet } from "services/auth.services";
import { GIFTCARD_CONFIG_KEY } from "utils/constants";
import Modal from "components/Modal/Modal";
import { useLocation } from "react-router";
import queryString from "query-string";

const { Title, Text } = Typography;

const DUMMY_PROMOTIONS = [];

function GiftcardAndPromotions(props) {
  const [form] = Form.useForm();
  const [showSendGiftCardModal, setShowSendGiftCardModal] = useState(false);
  const location = useLocation();
  const qs = queryString.parse(location.search);

  const {
    paymentMethod: { paymentMethods, wallets },
    credit: { balance, isLoading: isCreditLoading },
  } = useSelector((state) => ({
    paymentMethod: state.paymentMethod,
    credit: state.paymentMethod.credit,
  }));
  const primaryPaymentMethod =
    paymentMethods?.find?.((card) => card?.isPrimary === true) ??
    paymentMethods?.[0];
  const wallet = wallets?.[0];

  const [showAddCard, setShowAddCard] = useState(false);
  const [showViewAll, setShowViewAll] = useState(false);
  const [showTCs, setShowTCs] = useState(false);

  const dispatch = useDispatch();

  const { miscSettings, miscLoading } = useSelector((state) => ({
    miscSettings: state.misc.miscSettings,
    miscLoading: state.misc.isLoading,
  }));

  const design = useMemo(() => {
    try {
      const giftCardConfig = miscSettings?.find(
        (setting) => setting.key === GIFTCARD_CONFIG_KEY
      );
      if (giftCardConfig)
        return JSON.parse(decodeURI(giftCardConfig?.value || "{}"));
    } catch (e) {
      console.log(e);
      return;
    }
  }, [miscSettings, miscLoading]);

  const sendCardEvent = (paymentAdded) => {
    if (paymentMethods?.length < 1 && !paymentAdded) {
      setShowAddCard(true);
      return;
    }
    setShowSendGiftCardModal(true);
  };

  useEffect(() => {
    dispatch(getCustomerWallet());
  }, []);

  useEffect(() => {
    if (qs.id) {
      setShowViewAll(true);
    }
  }, []);

  return (
    <div>
      <Row gutter={[20, 16]}>
        <Col
          xs={{
            span: 24,
            order: 1,
          }}
          md={{ span: 16, order: 0 }}
          className="giftcardsAndPromotionsLeftSideWrapper"
        >
          <Title level={4} className="tradeGothic">
            Gift Cards
          </Title>
          <Form
            form={form}
            layout="vertical"
            onFinish={(values) => {
              dispatch(redeemGiftCard(values)).then((res) => {
                if (res?.status === "success") {
                  form.resetFields();
                  dispatch(getCustomerWallet());
                }
              });
            }}
            onFinishFailed={({ values, errorFields, outOfDate }) => {
              form.scrollToField(errorFields[0].name, {
                scrollMode: "if-needed",
                block: "center",
                behavior: "smooth",
              });
            }}
          >
            <FormInput
              variant="underlined"
              name={"redeemCode"}
              label="Promo or Gift Card Code"
              placeholder="Eg: 3453 3456 2345 6235"
              styles={{ width: "100%" }}
              rules={[
                {
                  required: true,
                  message: "Enter a valid gift card code",
                },
              ]}
            />
            <div
              style={{
                display: "flex",
                gap: 8,
              }}
            >
              <FormButton
                type="submit"
                text="Redeem"
                className="tradeGothicBold"
                rounded={true}
              />
              <FormButton
                text="View Gift Cards"
                className="tradeGothicBold"
                rounded={true}
                onClick={() => setShowViewAll(true)}
              />
            </div>
          </Form>

          <Title level={4} className="tradeGothic" style={{ marginBottom: 16 }}>
            Or gift your friends the goodness of wellness!
          </Title>
          <FormButton
            onClick={() => sendCardEvent()}
            text="Send a Gift Card"
            className="tradeGothicBold"
            rounded={true}
            formItemStyles={{ marginBottom: 16 }}
          />
          <Typography.Link
            underline
            disabled={!design?.notes}
            onClick={() => setShowTCs(true)}
          >
            Gift Card Terms & Conditions
          </Typography.Link>
          {/* <Title
            level={4}
            className="tradeGothic"
            style={{ marginBottom: 8, paddingTop: 4 }}
          >
            Promitions
          </Title>

          <Text style={{ fontSize: 14 }}>You have no active promotions</Text> */}
        </Col>
        <Col xs={24} md={8} className="flex justifyEnd alignStart">
          <Statistic
            loading={isCreditLoading}
            className="creditsWidget"
            title="Available Credits"
            value={balance ?? 0}
            precision={2}
            prefix="$"
          />
        </Col>
      </Row>
      <GiftCardModal
        primaryPaymentMethod={primaryPaymentMethod}
        visible={showSendGiftCardModal}
        onCancel={() => setShowSendGiftCardModal(false)}
        onSave={() => setShowSendGiftCardModal(false)}
        design={design}
        miscLoading={miscLoading}
      />
      <StripeAddCardModalWrapper
        message="Payment method is mandatory for purchasing a gift card."
        saveText={"Save and Order"}
        closable={false}
        forcePrimary={true}
        visible={showAddCard}
        handleSave={(data = []) => {
          const primaryPaymentMethod =
            data.find((card) => card?.isPrimary === true) ?? data?.[0];
          setShowAddCard(false);
          sendCardEvent(primaryPaymentMethod?.paymentMethodId);
        }}

        // onCancel={() => setShowAddCard(false)}
      />
      <GiftCardsList
        // onView={(giftCard) => setShowAdd(giftCard)}
        visible={showViewAll}
        onCancel={() => {
          setShowViewAll(false);
        }}
        // loading={loading}
        // giftCards={giftCards}
        // refreshGiftCards={refreshGiftCards}
      />
      <Modal
        title="Gift Card Terms & Conditions"
        visible={showTCs}
        onCancel={() => setShowTCs(false)}
        footer={null}
      >
        <Typography.Text level={4} className="tradeGothic whiteSpacePreWrap">
          {design?.notes}
        </Typography.Text>
      </Modal>
    </div>
  );
}

GiftcardAndPromotions.propTypes = {};

export default GiftcardAndPromotions;
