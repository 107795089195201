import React from "react";
import { Tag } from "antd";
import { ReactComponent as CheckMarkIcon } from "assets/icons/checkMarkIco.svg";

export default function PrimaryCheckMark() {
  return (
    <Tag icon={<CheckMarkIcon />} className="primaryTag">
      Primary
    </Tag>
  );
}
