import { request } from "./verb.services";
import { openNotificationWithIcon } from "../utils/Notification";
import { setLoading, setSubmitting } from "redux/actions/loading.actions";

export const validateRestriction = (facilityId, values, dateTime, callback) => {
  return (dispatch) => {
    dispatch(setLoading(true));
    return request(
      `Orders/Validate?facilityId=${facilityId}`,
      // `Orders/Validate?orderServiceDate=${dateTime}`,
      "post",
      values,
      true
    )
      .then((res) => {
        return { mstatus: true };
      })
      .catch((e) => {
        openNotificationWithIcon(
          "error",
          "Error!",
          e?.response?.data?.[0] ||
            e?.response?.data?.message ||
            e?.response?.data?.Message ||
            e?.response?.message ||
            e?.message ||
            "Something went wrong"
        );
        return { mstatus: false };
      })
      .finally(() => {
        dispatch(setLoading(false));
      });
  };
};
