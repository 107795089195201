import React from "react";
import { Row, Col, Typography, Layout, Space } from "antd";
import { Switch, Route, Redirect } from "react-router-dom";

import "./Account.scss";
import AccountSidebar from "./AccountSidebar";

import accountRoutes from "utils/accountRoutes";

const { Content } = Layout;
const { Title } = Typography;

const AccountSubpagesLayout = ({ title, children }) => {
  return (
    <Content>
      <Title level={3} className="tradeGothic accountsCommonTitle">
        {title}
      </Title>
      {children}
    </Content>
  );
};

export default function Account() {
  return (
    <Content className="accountsContainer">
      <Row className="accountsWrapper" gutter={50}>
        <Col span={24}>
          <Title level={2}> My Account </Title>
        </Col>

        <Col xs={24} sm={24} md={6}>
          <AccountSidebar />
        </Col>
        <Col xs={24} md={18}>
          <Switch>
            {accountRoutes?.map((route, index) => {
              return (
                <Route
                  key={index}
                  path={route.path}
                  render={(props) => (
                    <AccountSubpagesLayout title={route.label}> 
                      <route.component {...props} />
                    </AccountSubpagesLayout>
                  )}
                />
              );
            })}
          </Switch>
        </Col>
      </Row>
    </Content>
  );
}
