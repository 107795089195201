import { request } from "./verb.services";
import { openNotificationWithIcon } from "../utils/Notification";

export const uploadFile = (file) => {
    return (dispatch) => {
    //   return new Promise((request, response) => {
    //     return request("Account/Upload", "post",)
    //       .then((response) => {
    //         dispatch((false));
    //         return response.data;
    //       })
    //       .catch((e) => {
    //         console.log(e);
    //         openNotificationWithIcon(
    //           "error",
    //           "Error!",
    //           e?.response?.data?.message || "Network error has occured"
    //         );
    //       });
    //   });
    };
  };
  