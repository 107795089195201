import React from "react";
import PropTypes from "prop-types";
import Glutathione from "assets/images/Glutathione.png";

function ProductItem({product, onClick, styles={}}) {
  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        cursor: "pointer",
        ...styles
      }}
      onClick={onClick}
    >
      <div style={{ display: "flex" }}>
        <img
          src={product?.url ?? Glutathione}
          width={68}
          height={56}
          alt="product icon"
          style={{ borderRadius: 10 }}
        />
        <div style={{ marginLeft: 12 }}>
          <p
            style={{
              margin: 0,
              fontSize: 20,
              fontWeight: "bold",
              color: "#486BAF",
              fontFamily: "hendersonBasic",
            }}
          >
            {product.productName}
            {/* {product.durationHours !== undefined &&
              Number(product.durationHours) >= 1 &&
              ` (${Number(product.durationHours).toFixed(0)} hr)`} */}
          </p>
          {!!product?.sellingPrice && (
            <p
              style={{
                margin: 0,
                fontFamily: "hendersonBasic",
                fontSize: 16,
                fontWeight: "bold",
              }}
            >
              ${product.sellingPrice}
            </p>
          )}
        </div>
      </div>
    </div>
  );
}

ProductItem.propTypes = {};

export default ProductItem;
