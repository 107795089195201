import { actions } from "../actions/user.actions";

const INITIAL_STATE = {
  isTokenVerified: false,
  isTokenVerifying: true,
  isRegisteringUser: false,
  isLoggingIn: false,
  isUpdatingUser: false,
  showProfileSelectionPrompt: false,
  currentProfileId: null,
  profiles: [],
  currentUser: {
    firstName: "",
    lastName: "",
    email: "",
    mobile: "",
    password: "",
    streetAddress1: "",
    city: "",
    state: "",
    zipCode: "",
  },
  historyIntakeFormCompleted: false,
  consentIntakeFormCompleted: false,
};

const reducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case actions.LOG_OUT_USER:
      return {
        ...INITIAL_STATE,
        isTokenVerified: false,
        isTokenVerifying: false,
      };
    case actions.UPDATING_USER:
      return { ...state, isUpdatingUser: action.isUpdatingUser };
    case actions.LOGGING_IN_USER:
      return { ...state, isLoggingIn: action.isLoggingIn };
    case actions.REGISTERING_USER:
      return { ...state, isRegisteringUser: action.isRegisteringUser };
    case actions.UPDATE_CURRENT_USER:
      return {
        ...state,
        currentUser: action.data,
      };
    case actions.SET_CURRENT_USER:
      console.log("SET_CURRENT_USER", action.data)
      return {
        ...state,
        currentUser: action.data,
        isLoggingIn: false,
        isTokenVerified: true,
        isTokenVerifying: false,
      };
    case actions.GUEST_SESSION:
      return {
        ...state,
        currentUser: { ...state.currentUser, ...action.data },
        isTokenVerifying: false,
      };
    case actions.SET_PROFILES:
      return {
        ...state,
        profiles: action.data,
      };
    case actions.SET_COMMUNICATION_PREFERENCES:
      const updatedProfiles = state.profiles.map((profile) => {
        if (profile.profileId === action.profileId) {
          return { ...profile, ...action.data };
        }
        return profile;
      });
      return { ...state, profiles: updatedProfiles };
    case actions.CREATE_PROFILE:
      return { ...state, profiles: [...state.profiles, action.data] };
    case actions.DELETE_PROFILE:
      return {
        ...state,
        profiles: state.profiles.filter(
          (profile) => profile.profileId !== action.profileId
        ),
      };
    case actions.UPDATE_PROFILE:
      const profiles = state.profiles.map((profile) => {
        if (profile.profileId === action.profileId)
          return { ...profile, ...action.data };

        return profile;
      });
      return {
        ...state,
        profiles,
      };
    case actions.SET_CURRENT_PROFILEID:
      return {...state, currentProfileId: action.id};
    case actions.SHOW_PROFILE_SELECTION:
      return {
        ...state,
        showProfileSelectionPrompt: action.status,
      };
    default:
      return state;
  }
};

export default reducer;
