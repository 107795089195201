export const actions = {
  SET_ADDRESSES: "SET_ADDRESSES",
  ADD_ADDRESS: "ADD_ADDRESS",
  UPDATE_ADDRESS: "UPDATE_ADDRESS",
  DELETE_ADDRESS: "DELETE_ADDRESS",
  SET_PRIMARY: "SET_PRIMARY",
  SET_SUBMITTING: "SET_SUBMITTING"
};

export const setAddresses = (data) => ({
  type: actions.SET_ADDRESSES,
  data,
});

export const addAddress = (data) => ({
  type: actions.ADD_ADDRESS,
  data,
});

export const setPrimaryAddress = (addressId) => ({
  type: actions.SET_PRIMARY,
  addressId,
});

export const updateAddress = (data) => ({
  type: actions.UPDATE_ADDRESS,
  data,
});

export const deleteAddress = (id) => ({
  type: actions.DELETE_ADDRESS,
  id,
});

export const setIsSubmitting = (status) => ({
  type: actions.SET_SUBMITTING,
  isSubmitting: status,
});
