import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";

import { Row, Col, Upload, Input, List, Typography } from "antd";
import { Button } from "components/Buttons/Buttons";

import { uploadFile } from "services/common.services";
import { useDispatch, useSelector } from "react-redux";

import SearchOutlined from "@ant-design/icons/SearchOutlined";
import moment from 'moment'

import "./Documents.scss";
import { getCustomerFiles } from "services/auth.services";
import { setLoading } from "redux/actions/loading.actions";

const { Title, Text } = Typography;

function Documents(props) {

  const [filesList, setFilesList] = useState([])

  const user = useSelector(state => state.user.currentUser)

  const dispatch = useDispatch();

  useEffect(() => {
    getData()
  }, [])

  const getData = async () => {
    dispatch(setLoading(true))
    const { data } = await dispatch(getCustomerFiles(user?.customerId))
    setFilesList(data)
    dispatch(setLoading(false))
  }


  const fileProps = {
    // action: 'https://www.mocky.io/v2/5cc8019d300000980a055e76',
    onChange: (info) => {
      if (info.file) {
        const { file } = info;
        dispatch(uploadFile(file));
      }
    },
    multiple: true,
  };

  return (
    <>
      <Row>
        <Col xs={24} md={14}>
          <Row gutter={[16, 16]}>
            <Col xs={14} md={18}>
              <Input
                className="documentsSearchbar"
                placeholder="Search Files"
                prefix={<SearchOutlined />}
              />
            </Col>
            {/* <Col xs={10} md={6}>
              <Upload {...fileProps} fileList={[]}>
                <Button className="tradeGothicBold">Upload File</Button>
              </Upload>
            </Col> */}

            <Col span={24}>
              <List
                className="documentsList"
                header={
                  <div className="flex justifySpaceBetween alignCenter">
                    <Title
                      level={5}
                      className="tradeGothic colorPrimary"
                      style={{ margin: 0 }}
                    >
                      File Name
                    </Title>
                    <Title
                      level={5}
                      className="tradeGothic colorPrimary"
                      style={{ margin: 0 }}
                    >
                      Upload Date
                    </Title>
                  </div>
                }
                dataSource={filesList}
                renderItem={(item, index) => {
                  return (
                    <div className="documentListItem" onClick={() => window.open(item.documentUrl, '_blank')}>
                      <Text style={{ fontSize: 17, cursor: 'pointer' }}> {item.documentName}</Text>
                      <Text style={{ fontSize: 14, color: "#7F889A" }}>
                        {moment(item.uploadDate).format('DD MMMM YYYY')}
                      </Text>
                    </div>
                  );
                }}
              />
            </Col>
          </Row>
        </Col>
      </Row>
    </>
  );
}

Documents.propTypes = {};

export default Documents;
