import React, { useRef, useEffect, useState } from "react";
import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";
import { Elements } from "@stripe/react-stripe-js";

import Stripe from "components/Stripe/Stripe";
import { loadStripe } from "@stripe/stripe-js";

import { CardNumberElement, CardExpiryElement } from "@stripe/react-stripe-js";
import { useStripe, useElements } from "@stripe/react-stripe-js";

import { Checkbox, Form, Typography } from "antd";
import { Button } from "components/Buttons/Buttons";
import {
  FormCheckbox,
  FormHiddenSubmitButton,
} from "components/FormItems/FlatFormItems";
import Modal from "components/Modal/Modal";

import {
  getAllPaymentMethods,
  getSetupIntent,
  changePrimaryPaymentMethod,
  postPaymentMethodId,
} from "services/paymentMethod.service";
import { openNotificationWithIcon } from "utils/Notification";
import { setSubmitting } from "redux/actions/loading.actions";

const { Text } = Typography;

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PK);
function StripeAddCardModal({
  message,
  visible,
  handleSave = () => {},
  onCancel = () => {},
  footer,
  forcePrimary,
  currentPrimaryCard,
  saveText = "Save",
  closable = true,
}) {
  const stripe = useStripe();
  const elements = useElements();
  const [form] = Form.useForm();

  const dispatch = useDispatch();

  const submitBtnRef = useRef();
  const [setupIntent, setSetupIntent] = useState(undefined);

  const { submitting, currentUser } = useSelector((state) => {
    return {
      currentUser: state.user.currentUser,
      submitting: state.loading.submitting,
    };
  });

  useEffect(() => {
    if (visible) {
      dispatch(getSetupIntent()).then((token) => {
        setSetupIntent(token);
      });
    }
  }, [visible]);

  const handleSubmit = async (data) => {
    // We don't want to let default form submission happen here,
    // which would refresh the page.
    // event.preventDefault();

    if (!stripe || !elements) {
      console.log("Stripe or elements not loaded");
      // Stripe.js has not yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      return;
    }
    dispatch(setSubmitting(true));
    const isPrimary = forcePrimary ? true : data?.isPrimary ?? false;
    const result = await stripe.confirmCardSetup(setupIntent, {
      payment_method: {
        card: elements.getElement(CardNumberElement),
        billing_details: {
          name: data.name,
          email: currentUser?.email,
        },
        metadata: {
          isPrimary,
        },
      },
    });
    if (result?.error) {
      // Show error to your customer (for example, insufficient funds)
      openNotificationWithIcon(
        "error",
        "Error!",
        result?.error?.message || "Network error has occured"
      );
    } else {
      // The payment has been processed!
      if (result.setupIntent.status === "succeeded") {
        // if a primary card already exists then
        // then update
        let backendResponse = await dispatch(
          postPaymentMethodId(result.setupIntent.payment_method)
        );
        if (backendResponse?.status === "success") {
          if (isPrimary) {
            await dispatch(
              changePrimaryPaymentMethod(
                backendResponse?.response?.paymentMethodId,
                currentPrimaryCard?.paymentMethodId,
                false
              )
            );
          }
        }

        openNotificationWithIcon(
          "success",
          "Success!",
          "Your payment method has been added successfully"
        );
        dispatch(getAllPaymentMethods()).then((res) => {
          if (handleSave) handleSave(res?.data);
          form.resetFields();
        });

        // Show a success message to your customer
        // There's a risk of the customer closing the window before callback
        // execution. Set up a webhook or plugin to listen for the
        // payment_intent.succeeded event that handles any business critical
        // post-payment actions.
      }
    }
    dispatch(setSubmitting(false));
  };

  return (
    <Modal
      closable={closable}
      destroyOnClose={true}
      className='stripeAddCardModal'
      headerBorder={false}
      width='460px'
      title='Add New Card'
      onCancel={() => onCancel()}
      visible={visible}
      onOk={() => console.log("ok")}
      okText='Add'
      centered={true}
      footer={[
        <Button
          rounded={true}
          onClick={() => submitBtnRef?.current?.click()}
          disabled={!Boolean(setupIntent)}
          loading={submitting}
        >
          {saveText}
        </Button>,
      ]}
      //   cancelText="No"
    >
      {message && <Text>{message}</Text>}

      <Form
        layout='vertical'
        form={form}
        requiredMark={false}
        onFinish={(values) => {
          handleSubmit(values);
        }}
        onFinishFailed={({ values, errorFields, outOfDate }) => {
          form.scrollToField(errorFields[0].name, {
            scrollMode: "if-needed",
            block: "center",
            behavior: "smooth",
          });
        }}
      >
        <Stripe>
          {forcePrimary ? (
            <Checkbox
              name='isPrimary'
              className='checkboxRegular'
              checked={true}
              style={{ marginBottom: 18, width: 0, height: 0, display: "none" }}
            >
              {/* Set as Primary */}
            </Checkbox>
          ) : (
            <FormCheckbox
              name='isPrimary'
              className='checkboxRegular'
              formItemStyles={{ marginBottom: 14 }}
            >
              Set as Primary
            </FormCheckbox>
          )}

          <FormHiddenSubmitButton submitRef={submitBtnRef} />
        </Stripe>
      </Form>
    </Modal>
  );
}

StripeAddCardModal.propTypes = {};

const StripeAddCardModalWrapper = (props) => {
  return (
    <Elements stripe={stripePromise}>
      <StripeAddCardModal {...props} />
    </Elements>
  );
};

export default StripeAddCardModalWrapper;
