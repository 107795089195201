import React, { useState, useEffect, useMemo } from "react";
import PaymentViewCard from "./PaymentViewCard";
import { useSelector, useDispatch } from "react-redux";

import { Row, Col, Typography } from "antd";
import StripeAddCardModal from "components/StripeAddCard/StripeAddCardModal";

import "./Payments.scss";

import { getAllPaymentMethods } from "services/paymentMethod.service";

const { Title } = Typography;

export default function Payments() {
  const [showAddCard, setShowAddCard] = useState(false);
  const dispatch = useDispatch();
  const { paymentMethods } = useSelector((state) => state.paymentMethod);

  useEffect(() => {
    dispatch(getAllPaymentMethods());
  }, []);

  const currentPrimaryCard = useMemo(
    () => paymentMethods.find((card) => card?.isPrimary === true),
    [paymentMethods]
  );

  return (
    <div>
      <div className="paymentsContainer">
        {paymentMethods.map((card, index) => {
          return (
            <PaymentViewCard
              key={index}
              paymentMethodId={card.paymentMethodId}
              brand={card.brand}
              name={card.cardName}
              last4Digits={card.last4Digits}
              expiry={card.expiry}
              currentPrimaryCard={currentPrimaryCard}
              isPrimary={card?.isPrimary ? true : false}
            />
          );
        })}
        <div
          className="addNewCardContainer"
          onClick={() => setShowAddCard(true)}
        >
          <Title level={5} className="tradeGothic">
            +
          </Title>
          <Title level={5} className="tradeGothic">
            Add a New Card
          </Title>
        </div>
      </div>

      <StripeAddCardModal
        forcePrimary={paymentMethods.length === 0}
        currentPrimaryCard={currentPrimaryCard}
        visible={showAddCard}
        handleSave={() => {
          setShowAddCard(false);
        }}
        onCancel={() => setShowAddCard(false)}
      />
    </div>
  );
}
