import { Button } from "antd";
import React from "react";

export default function RoundButton({ htmlType, onClick }) {
  return (
    <Button
      onClick={onClick}
      type='primary'
      htmlType={htmlType}
      style={{
        height: 60,
        width: "100%",
        maxWidth: 360,
        borderRadius: 60,
        display: "flex",
        justifyItems: "center",
        alignItems: "center",
        color: "white",
        margin: 20,
      }}
    >
      <p
        style={{
          margin: 0,
          width: "100%",
          textAlign: "center",
          fontFamily: "hendersonBasic",
          fontSize: 18,
          fontWeight: "bold",
        }}
      >
        BOOK NOW
      </p>
    </Button>
  );
}
