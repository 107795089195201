import React from "react";
import PropTypes from "prop-types";

import { Typography } from "antd";

import DripImage from "assets/images/drip.png";
import "./ProductCard.scss";

const { Title, Link } = Typography;

function ProductCard({ title, action, className = "", style = {}, disabled }) {
  return (
    <div className={`productCard ${className}`} style={style}>
      <img src={DripImage} alt="service" />
      <Title level={5}>{title}</Title>
      <Link onClick={action.onClick} underline={true} disabled={disabled}>
        {action.text}
      </Link>
    </div>
  );
}

ProductCard.propTypes = {};

export default ProductCard;
