import React from "react";
import PropTypes from "prop-types";

import Modal from "components/Modal/Modal";
import RegisterForm from "components/RegisterForm/RegisterForm";

import { Button } from "components/Buttons/Buttons";

function RegisterModal({ visible, onSuccess, onClose }) {
  return (
    <Modal
      visible={visible}
      title="User Information"
      width={812}
      onCancel={onClose}
      footer={null}
      destroyOnClose={true} // we dont want to keep partial edit info
    >
      {/* TODO: Update Register Form for new requirements */}
      <RegisterForm onSuccess={onSuccess} />
    </Modal>
  );
}

RegisterModal.propTypes = {};

export default RegisterModal;
