import React, { useState } from "react";
import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";

import { setLoading } from "redux/actions/loading.actions";

import { Typography, Button, Row, Col, Modal, Space } from "antd";

import FamilyMemberModal from "./FamilyMemberModal";
import { deleteFamilyMember, getAllFamilyMembers } from "services/family.service";

const { Text, Title } = Typography;
const { confirm, warning, success, error } = Modal;

function FamilyMemberCard({
  invitedMemberId,
  firstName,
  lastName,
  email,
  mobileNo,
  permissionToBill,
}) {
  const dispatch = useDispatch();
  const [showEditModal, setshowEditModal] = useState(false);

  const deleteAddressModal = {
    title: "Delete Family Member",
    content: <>Are you sure that you want to delete the family member?</>,
    centered: true,
    maskClosable: true,
    onOk: async (close) => {
      const result = await dispatch(deleteFamilyMember(invitedMemberId));
      dispatch(setLoading(true));
      if (result?.status === "success") {
        const res2 = await dispatch(getAllFamilyMembers());
        close();
      }
      dispatch(setLoading(false));
    },
  };

  return (
    <Row className="famileMemberCard">
      <Col span={20}>
        <Title level={4} className="tradeGothic" style={{ marginBottom: 0 }}>
          {firstName} {lastName}
        </Title>
      </Col>
      <Col span={4} className="flex justifyEnd">
        <Space size="large">
          <Button type="text" onClick={() => setshowEditModal(true)}>
            Edit
          </Button>
          <Button
            type="text"
            onClick={() => {
              confirm(deleteAddressModal);
            }}
          >
            Remove
          </Button>
        </Space>
      </Col>
      <FamilyMemberModal
        type="edit"
        title="Edit Family Member"
        data={{
          firstName,
          lastName,
          email,
          invitedMemberId,
          mobileNo,
          permissionToBill,
        }}
        visible={showEditModal}
        handleSave={() => {
          setshowEditModal(false);
        }}
        onCancel={() => setshowEditModal(false)}
      />
    </Row>
  );
}

FamilyMemberCard.propTypes = {};

export default FamilyMemberCard;
