import React, { useEffect, useMemo, useState } from "react";
import PropTypes from "prop-types";
import Modal from "components/Modal/Modal";
import moment from "moment";
import { List, Skeleton, Space } from "antd";
import AuditListItem from "./AuditListItem";
import { useDispatch, useSelector } from "react-redux";
import { FormType } from "utils/enums";

import LegacyHistoryIntakeForm from "components/HistoryIntakeForm/LegacyHistoryIntakeForm";
import LegacyConsentForm from "components/ConsentForm/LegacyConsentForm";
import { getFormsAuditHistory } from "services/intakeForm.service";
import IntakeFormModal from "./IntakeFormModal";
import { getProfiles } from "services/auth.services";
import useIntakeForms from "hooks/useIntakeForms";

const INITIAL_STATE = {
  data: [],
  isLoading: true,
};
const INITIAL_MODAL_STATE = {
  open: false,
  viewOnly: true,
  formType: undefined,
  isLegacy: false,
  formValueId: undefined,
  isSubmitted: true,
  isDesignOutdated: false,
};
function AuditList({
  profile,
  type,
  open,
  onCancel,
  title = "History",
  itemTitle,
  afterClose,
}) {
  const [data, setData] = useState(INITIAL_STATE);
  const [showModal, setShowModal] = useState(INITIAL_MODAL_STATE);
  const dispatch = useDispatch();
  const { user } = useSelector((state) => {
    return {
      user: state.user,
    };
  });
  const { currentUser, profiles } = user;

  useEffect(() => {
    if (open) {
      loadData();
    }
  }, [open]);

  const { legacyConsendId, legacyPhysicalHistoryId } = useMemo(() => {
    if (profiles?.length > 0) {
      return {
        legacyConsendId: profiles?.[0]?.legacyConsentId,
        legacyPhysicalHistoryId: profiles?.[0]?.legacyPhysicalHistoryId,
      };
    }
    return {
      legacyConsendId: null,
      legacyPhysicalHistoryId: null,
    };
  }, [profiles]);

  const appendLegacyForm = (data) => {
    if (type === FormType?.consent) {
      if (legacyConsendId !== null && legacyConsendId !== undefined) {
        const expiryDate = moment(profiles?.[0]?.legacyConsentSubmittedOn)?.add(
          365,
          "days"
        );
        const isExpired = moment() >= expiryDate;
        return [
          ...data,
          {
            isLegacy: true,
            legacyConsendId,
            createdOn: profiles?.[0]?.legacyConsentSubmittedOn,
            isExpired,
          },
        ];
      }
    } else if (type === FormType?.historyOrPhysical) {
      if (
        legacyPhysicalHistoryId !== null &&
        legacyPhysicalHistoryId !== undefined
      ) {
        const expiryDate = moment(
          profiles?.[0]?.legacyPhysicalHistorySubmittedOn
        )?.add(365, "days");
        const isExpired = moment() >= expiryDate;
        return [
          ...data,
          {
            isLegacy: true,
            legacyPhysicalHistoryId,
            createdOn: profiles?.[0]?.legacyPhysicalHistorySubmittedOn,
            isExpired,
          },
        ];
      }
    }
    return data;
  };

  const loadData = () => {
    setData(INITIAL_STATE);
    dispatch(getProfiles());
    dispatch(getFormsAuditHistory(profiles?.[0]?.profileId, type))
      .then((res) => {
        if (res?.status === "success") {
          const data = appendLegacyForm(res?.data);
          setData({
            data: data?.filter((it, index) => index !== 0),
            isLoading: false,
          });
        }
      })
      .finally(() => {
        setData((prev) => ({
          ...prev,
          isLoading: false,
        }));
      });
  };

  const renderLoading = () => {
    return (
      <>
        <Skeleton.Button block size="small" active />
        <br />
        <br />
        <Skeleton.Button block size="small" active />
        <br />
        <br />
        <Skeleton.Button block size="small" active />
        <br />
        <br />
        <Skeleton.Button block size="small" active />
      </>
    );
  };

  return (
    <Modal
      visible={open}
      onCancel={onCancel}
      destroyOnClose={true}
      headerBorder={true}
      width={420}
      className="auditsModal"
      centered={true}
      title={title}
      footer={<></>}
    >
      {data?.isLoading ? (
        renderLoading()
      ) : (
        <List
          // loading={data?.isLoading}
          dataSource={data?.data ?? []}
          renderItem={(it, index) => {
            return (
              <List.Item key={it?.id}>
                <AuditListItem
                  data={it}
                  title={itemTitle}
                  onClick={(viewOnly) => {
                    setShowModal({
                      open: true,
                      isDesignOutdated: it?.isDesignOutdated,
                      formType: type,
                      isLegacy: it?.isLegacy,
                      formValueId: it?.formValueId,
                      isSubmitted: true,
                      viewOnly,
                    });
                  }}
                />
              </List.Item>
            );
          }}
        />
      )}
      <IntakeFormModal
        disabled={showModal?.viewOnly}
        isOutDated={showModal?.isDesignOutdated}
        type={showModal?.formType}
        visible={showModal?.open && !showModal?.isLegacy}
        title={
          showModal?.formType === FormType?.historyOrPhysical
            ? "History/Physical and Consents"
            : "Consents"
        }
        onSubmit={() => {
          setShowModal(INITIAL_MODAL_STATE);
          loadData();
        }}
        onClose={() => setShowModal(INITIAL_MODAL_STATE)}
        isSubmitted={showModal?.isSubmitted}
        formId={showModal?.formValueId}
      />
      <LegacyHistoryIntakeForm
        className="legacyPhysicalFormAudit"
        visible={
          showModal?.open &&
          showModal?.isLegacy &&
          showModal?.formType === FormType.historyOrPhysical
        }
        onSubmit={() => {
          //   setShowHistoryForm(false);
          //   if (openOnLoad && !isConsentFormSubmitted) {
          //     setShowConsentForm(true);
          //   }
        }}
        onClose={() => setShowModal(INITIAL_MODAL_STATE)}
      />
      <LegacyConsentForm
        className="legacyConsentFormAudit"
        visible={
          showModal?.open &&
          showModal?.isLegacy &&
          showModal?.formType === FormType.consent
        }
        onSubmit={() => {
          //   setShowHistoryForm(false);
          //   if (openOnLoad && !isConsentFormSubmitted) {
          //     setShowConsentForm(true);
          //   }
        }}
        onClose={() => setShowModal(INITIAL_MODAL_STATE)}
      />
    </Modal>
  );
}

AuditList.propTypes = {};

export default AuditList;
