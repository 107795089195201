import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";

import { Typography, Button as AntdButton, List, Tag } from "antd";
import { FormInput } from "components/FormItems/FlatFormItems";

import { Button } from "components/Buttons/Buttons";
import { useDispatch, useSelector } from "react-redux";
import ShareWithFriendModal from "./ShareWithFriendModal";
import { openNotificationWithIcon } from "utils/Notification";
import {
  getCustomerReferrals,
  getReferralPoliciesTC,
} from "services/auth.services";

import moment from "moment";
import Modal from "components/Modal/Modal";
import Spinner from "components/Spinner/Spinner";

const { Text, Title } = Typography;

const Referlink = "https://lmiv.com/23sd32d";

function ReferAndEarn(props) {
  const { user } = useSelector((state) => {
    return { user: state.user };
  });
  const [isModalOpen, setIsModalOpen] = useState(false);

  const [referrals, setReferrals] = useState({
    data: [],
    isLoading: true,
  });

  const [referalPolicyTC, setReferalPolicyTC] = useState({
    data: "",
    isLoading: true,
  });
  const [showTCs, setShowTCs] = useState(false);

  const dispatch = useDispatch();

  const { currentUser } = user;
  const getEnv = () => {
    const appEnv = window.location.host.split(".")[0];
    switch (appEnv) {
      case "gentle-grass-09eed8a10":
        return "predev";
      case "purple-sand-066809e10":
        return "test";
      case "nice-meadow-08f429810":
        return "uat";
      case "black-pebble-06b1a7010":
        return "prod";
      case "elevate":
        return "prodelevate";
      default:
        return "local";
    }
  };

  const redirectUrl = () => {
    switch (getEnv()) {
      case "uat":
        return `https://nice-meadow-08f429810.1.azurestaticapps.net/signup?referrerCode=${currentUser?.referralCode}`;
      case "prod":
        return `https://black-pebble-06b1a7010.1.azurestaticapps.net/signup?referrerCode=${currentUser?.referralCode}`;
      case "prodelevate":
        return `https://elevate.liquidmobileiv.com/signup?referrerCode=${currentUser?.referralCode}`;
      case "predev":
        return `https://gentle-grass-09eed8a10.2.azurestaticapps.net/signup?referrerCode=${currentUser?.referralCode}`;
      default:
        return `https://purple-sand-066809e10.1.azurestaticapps.net/signup?referrerCode=${currentUser?.referralCode}`;
    }
  };

  useEffect(() => {
    setReferrals((state) => ({
      ...state,
      isLoading: true,
    }));
    dispatch(getCustomerReferrals())
      .then((res) => {
        if (res?.status === "success") {
          setReferrals((state) => ({
            ...state,
            data: res?.data ?? [],
          }));
        }
      })
      .finally(() => {
        setReferrals((state) => ({
          ...state,
          isLoading: false,
        }));
      });
    setReferalPolicyTC((state) => ({
      ...state,
      isLoading: true,
    }));
    dispatch(getReferralPoliciesTC())
      .then((res) => {
        if (res?.status === "success") {
          setReferalPolicyTC((state) => ({
            ...state,
            data: res?.data ?? [],
          }));
        }
      })
      .finally(() => {
        setReferalPolicyTC((state) => ({
          ...state,
          isLoading: false,
        }));
      });
  }, []);

  const showModal = () => {
    setIsModalOpen(true);
  };

  return (
    <div>
      {isModalOpen && (
        <ShareWithFriendModal
          visible={isModalOpen}
          onCancel={() => setIsModalOpen(false)}
          handleSave={() => {
            setIsModalOpen(false);
          }}
          title={"Share with friend"}
          type="add"
        />
      )}
      <Text className="tradeGothic">
        Invite your friends using the unique code and win exciting discounts and
        rewards!
      </Text>
      <div
        className="flex alignCenter"
        style={{
          marginTop: 12,
          marginBottom: 16,
        }}
      >
        <FormInput
          initialValue={redirectUrl()}
          readOnly={true}
          className="label-d-none"
          variant="underlined"
          formItemStyles={{
            width: "100%",
            maxWidth: 280,
            margin: 0,
            marginRight: 16,
          }}
        />
        <AntdButton
          type="text"
          onClick={() => {
            navigator.clipboard.writeText(redirectUrl());
            openNotificationWithIcon(
              "success",
              "Success",
              "Copied to clipboard"
            );
          }}
        >
          Copy
        </AntdButton>
      </div>

      <Button
        rounded={true}
        className="tradeGothicBold"
        style={{ marginBottom: 32, marginRight: 12 }}
        onClick={showModal}
        type="primary"
      >
        Share with friends
      </Button>
      <Typography.Link
        underline
        disabled={!referalPolicyTC?.data || referalPolicyTC?.isLoading}
        onClick={() => setShowTCs(true)}
      >
        Terms & Conditions
      </Typography.Link>

      <Title level={4} className="tradeGothic">
        My Referrals
      </Title>
      {referrals?.isLoading ? (
        <div
          style={{
            width: 120,
            display: "flex",
            justifyContent: "center",
          }}
        >
          <Spinner />
        </div>
      ) : referrals?.data?.length > 0 ? (
        <div style={{ maxWidth: 440 }}>
          <List
            dataSource={referrals?.data}
            renderItem={(item) => {
              return (
                <div
                  style={{
                    borderBottom: "1px solid #cddfd5",
                  }}
                >
                  <List.Item>
                    <div>
                      <Text style={{ fontSize: 16, fontFamily: "tradeGothic" }}>
                        {item?.refreeEmailAddress}
                      </Text>
                      {item?.createdOn ? (
                        <>
                          <br />
                          <Text
                            style={{
                              fontSize: 14,
                              fontFamily: "tradeGothic",
                              paddingRight: 12,
                            }}
                          >
                            {moment(item?.createdOn).format()}
                          </Text>
                        </>
                      ) : (
                        <></>
                      )}
                      {item?.isAvailed !== undefined &&
                        (item?.isAvailed ? (
                          <Tag color="green">Availed</Tag>
                        ) : (
                          <Tag color="yellow">Not Availed</Tag>
                        ))}
                    </div>
                  </List.Item>
                </div>
              );
            }}
            pagination={{
              pageSize: 10,
            }}
          />
        </div>
      ) : (
        <Text style={{ fontSize: 14 }}>You have no referrals</Text>
      )}

      <Modal
        title=" Terms & Conditions"
        visible={showTCs}
        onCancel={() => setShowTCs(false)}
        footer={null}
      >
        <Typography.Text level={4} className="tradeGothic">
          {referalPolicyTC?.data}
        </Typography.Text>
      </Modal>
    </div>
  );
}

ReferAndEarn.propTypes = {};

export default ReferAndEarn;
