import React, { useState, useEffect, useMemo } from "react";

import { Row, Col, Typography, Space, Checkbox, Button } from "antd";
import { useDispatch, useSelector } from "react-redux";

import PrimaryCheckMark from "components/PrimaryCheckMark/PrimaryCheckMark";
import ChangePasswordModal from "./components/ChangePasswordModal";
import PersonalInfoUpdateModal from "./components/PersonalInfoEditModal";
import "./AccountSettings.scss";

import { getUser } from "services/auth.services";
import CommunicationPreferences from "./components/CommunicationPreferences";
import parsePhoneNumber from "libphonenumber-js";
import moment from "moment";

const { Title, Text } = Typography;

export default function AccountSettings() {
  const { user } = useSelector((state) => {
    return { user: state.user.currentUser };
  });

  const [showPasswordEdit, setShowPasswordEdit] = useState(false);
  const [showPersonalInfoEdit, setShowPersonalInfoEdit] = useState(false);

  const {
    firstName,
    lastName,
    streetAddress1,
    streetAddress2,
    city,
    state,
    zipCode,
    email,
    phoneNumber,
    dob,
  } = user ?? {};

  const DUMMY_SECONDARY_EMAILS = [];
  const DUMMY_SECONDARY_PHOHES = [];

  const dispatch = useDispatch();
  const { isTokenVerified } = useSelector((state) => state.user);

  useEffect(() => {
    if (isTokenVerified) {
      dispatch(getUser());
    }
  }, [isTokenVerified]);

  const phoneNumberFormatted = useMemo(() => {
    if (user.phoneNumber) {
      const phoneNumber = parsePhoneNumber(`+${user.phoneNumber}`);
      return phoneNumber?.formatInternational?.() ?? user.phoneNumber ?? "NA";
    }
  }, [user]);

  return (
    <>
      <Title level={4} className="tradeGothic" style={{ marginTop: 16 }}>
        Account information
      </Title>

      <Row
        gutter={[16, DUMMY_SECONDARY_EMAILS.length > 0 ? 24 : 16]}
        style={{
          paddingBottom: 16,
          borderBottom: "1px solid #CDDFD5",
        }}
      >
        {/* Emails Section Start */}

        <Col span={24}>
          <Row gutter={[16, 12]}>
            <Col span={6}>
              <Text className="tradeGothic ">Email</Text>
            </Col>
            <Col span={9}>
              <Space size={12}>
                <Text className="tradeGothic bold">{email}</Text>
                {/* <PrimaryCheckMark /> */}
              </Space>
            </Col>
            <Col span={9}>
              <Space size={16} className="flex justifyEnd">
                {/* <Button type="text">Add New Email Address</Button>
                <Button type="text">Change</Button> */}
              </Space>
            </Col>

            {/* {DUMMY_SECONDARY_EMAILS.map((email, index) => {
              return (
                <React.Fragment key={index}>
                  <Col span={6}></Col>
                  <Col span={9}>
                    <Text className="tradeGothic bold">{email}</Text>
                  </Col>
                  <Col span={9}>
                    <Space size={16} className="flex justifyEnd">
                      <Button type="text">Set as Primary</Button>
                      <Button type="text">Delete</Button>
                    </Space>
                  </Col>
                </React.Fragment>
              );
            })} */}
          </Row>
        </Col>
        {/* Emails Section End */}

        {/* Passwords Section Start */}
        <Col span={6}>
          <Text className="tradeGothic">Password</Text>
        </Col>
        <Col span={9}>
          <Text className="tradeGothic bold">************</Text>
        </Col>
        <Col span={9} className="flex justifyEnd">
          <Button type="text" onClick={() => setShowPasswordEdit(true)}>
            Change
          </Button>
        </Col>
        {/* Password Section End */}
      </Row>

      <Title level={4} className="tradeGothic" style={{ marginTop: 16 }}>
        Profile Information
      </Title>
      <Row
        gutter={[16, 16]}
        style={{
          paddingBottom: 16,
          borderBottom: "1px solid #CDDFD5",
        }}
      >
        <Col span={6}>
          <Text className="tradeGothic ">First Name</Text>
        </Col>
        <Col span={9}>
          <Text className="tradeGothic bold">{firstName}</Text>
        </Col>
        <Col span={9} className="flex justifyEnd">
          <Button type="text" onClick={() => setShowPersonalInfoEdit(true)}>
            Change
          </Button>
        </Col>

        <Col span={6}>
          <Text className="tradeGothic ">Last Name</Text>
        </Col>
        <Col span={18}>
          <Text className="tradeGothic bold">{lastName}</Text>
        </Col>

        {/* Mobile Numbers Section Start */}

        <Col span={6}>
          <Text className="tradeGothic ">Mobile Number</Text>
        </Col>
        <Col span={9}>
          {<Text className="tradeGothic bold">{phoneNumberFormatted}</Text> ||
            (DUMMY_SECONDARY_PHOHES && (
              <Space size={12}>
                <Text className="tradeGothic bold">{phoneNumber ?? "NA"}</Text>
                {/* <PrimaryCheckMark /> */}
              </Space>
            ))}
        </Col>
        <Col span={9}>
          <Space className="flex justifyEnd">
            {/* <Button type="text">Add New Mobile Number</Button> */}
            {/* <Button type="text">Change</Button> */}
          </Space>
        </Col>
        {DUMMY_SECONDARY_PHOHES.map((mobile, index) => {
          return (
            <React.Fragment key={index}>
              <Col span={6}></Col>
              <Col span={9}>
                <Text className="tradeGothic bold">{mobile}</Text>
              </Col>
              <Col span={9}>
                <Space className="flex justifyEnd">
                  <Button type="text">Set as Primary</Button>
                  <Button type="text">Delete</Button>
                </Space>
              </Col>
            </React.Fragment>
          );
        })}
        {/* Mobile Numbers Section End */}
        <Col span={6}>
          <Text className="tradeGothic ">Date of birth</Text>
        </Col>
        <Col span={18}>
          <Text className="tradeGothic bold">
            {moment(dob).format("DD MMMM YYYY")}
          </Text>
        </Col>
      </Row>
      <Title level={4} className="tradeGothic" style={{ marginTop: 16 }}>
        Marketing Update
      </Title>
      <CommunicationPreferences />

      {/* Modals */}
      {/* Add/Edit Email Modal Start */}
      {/* <Modal
        visible={true}
        title="Add New Email Address"
        okText="Save"
        cancelText="Cancel"
        content={
          <form>
            <input name="asdsad" /> <p>asdsds </p>
          </form>
        }
        centered
      >
        sadasdasdasd
      </Modal> */}
      {/* Add/Edit Email Modal End */}
      <ChangePasswordModal
        visible={showPasswordEdit}
        handleSave={() => setShowPasswordEdit(false)}
        onCancel={() => setShowPasswordEdit(false)}
      />
      <PersonalInfoUpdateModal
        visible={showPersonalInfoEdit}
        handleSave={() => setShowPersonalInfoEdit(false)}
        onCancel={() => setShowPersonalInfoEdit(false)}
      />
    </>
  );
}
