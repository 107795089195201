import React, { useEffect, useState } from "react";

import "./App.scss";
import { useDispatch, useSelector } from "react-redux";
import { push, replace } from "connected-react-router";
import { removeItem } from "../../utils/storage";

import { Layout, Spin } from "antd";
import AppHeader from "components/AppHeader/AppHeader";
import AppFooter from "components/AppFooter/AppFooter";
import ProfileSelection from "components/ProfileSelection/ProfileSelection";
import useScript from "hooks/useScript";

const { Content } = Layout;

const App = (props) => {
  // state variables start
  const [collapsed, setCollapsed] = useState(false);
  // state variables end

  //  hooks start
  const dispatch = useDispatch();

  const { currentUser } = useSelector((state) => state.user);
  const { loading, message } = useSelector((state) => state.loading);
  const hubspotChat = useScript(
    "//js-na1.hs-scripts.com/21858029.js",
    "lmiv-hubspot-script"
  );
  // hooks end

  // useEffects start
  // useEffects end

  const toggle = () => {
    setCollapsed(!collapsed);
  };
  const logout = () => {
    removeItem("user");
    dispatch(replace("/"));
  };
  return (
    <div className="reactRoot">
      <Layout style={{ height: "100vh", overflow: "auto" }}>
        <div
          style={{
            height: "100%",
            background: "#0000001f",
            position: "absolute",
            width: "100%",
            zIndex: 10000000,
            display: loading ? "flex" : "none",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "column",
          }}
        >
          <Spin spinning={loading} size="large" style={{ color: "#D4008E" }} />
          <h1 style={{ color: "#D4008E", marginLeft: 8, marginBottom: 2 }}>
            {message}
          </h1>
        </div>
        <AppHeader
          collapsed={collapsed}
          toggle={toggle}
          logout={logout}
          props={props}
          user={currentUser}
        />
        <Content style={{ minHeight: 280, height: "100%" }}>
          {props.children}
          <AppFooter />
        </Content>
      </Layout>
      <ProfileSelection />
    </div>
  );
};

export default App;
