import React, { useState } from "react";
import PropTypes from "prop-types";

import { Row, Col, Modal, Button, Space } from "antd";
import PrimaryCheckMark from "components/PrimaryCheckMark/PrimaryCheckMark";
import { useDispatch } from "react-redux";

import { setPrimaryAddress, deleteAddress } from "services/addresses.service";
import { setLoading } from "redux/actions/loading.actions";

import AddInstructionsModal from "./components/AddInstructionsModal";
import AddAddressModal from "./components/AddAddressModal";

const { confirm, warning, success, error } = Modal;

function AddressCard({ addressId, address, instructions, isPrimary }) {
  const [showAddressUpdate, setShowAddressUpdate] = useState(false);
  const [showInstructionsUpdate, setShowInstructionsUpdate] = useState(false);

  const dispatch = useDispatch();

  const deleteAddressModal = {
    title: "Delete Address",
    content: <>Are you sure that you want to delete the address?</>,
    centered: true,
    maskClosable: true,
    onOk: (close) => {
      dispatch(setLoading(true));
      dispatch(deleteAddress(addressId)).then((result) => {
        if (result?.status === "success") close();
        dispatch(setLoading(false));
      });
    },
  };

  return (
    <>
      <Row gutter={[16, 16]} className="addressCardContainer">
        <Col span={16}>
          <p className="addressCardDetails">{address}</p>
          {isPrimary && <PrimaryCheckMark />}
          {/* <p className="addressCardDetails">
            {state}, {city}, {zipCode}
          </p> */}
        </Col>
        <Col span={8} className="flex justifyEnd">
          <Space size={14}>
            {!isPrimary && (
              <Button
                type="text"
                onClick={() => {
                  dispatch(setPrimaryAddress(addressId, "Primary"));
                }}
              >
                Set as primary
              </Button>
            )}
            {!isPrimary && (
              <Button
                type="text"
                onClick={() => {
                  confirm(deleteAddressModal);
                }}
              >
                Delete
              </Button>
            )}
            <Button type="text" onClick={() => setShowAddressUpdate(true)}>
              Edit
            </Button>
          </Space>
        </Col>
        <Col>
          {instructions ?
            <div>
              <p style={{ fontFamily: 'tradeGothic', fontSize: 16 }}><b>Instructions:</b> {instructions}</p>
              <Button type="text" onClick={() => setShowInstructionsUpdate(true)}>
                Edit Instructions
              </Button>
            </div> : <Button type="text" onClick={() => setShowInstructionsUpdate(true)}>
              Add Address Instructions
            </Button>}
        </Col>
      </Row>
      {
        <AddInstructionsModal
          visible={showInstructionsUpdate}
          handleSave={() => setShowInstructionsUpdate(false)}
          onCancel={() => setShowInstructionsUpdate(false)}
          data={{
            addressId,
            instructions,
          }}
        />
      }
      {
        <AddAddressModal
          type="edit"
          title="Edit Address"
          visible={showAddressUpdate}
          handleSave={() => setShowAddressUpdate(false)}
          onCancel={() => setShowAddressUpdate(false)}
          data={{
            addressId,
            address,
            instructions,
            isPrimary,
          }}
        />
      }
    </>
  );
}

AddressCard.propTypes = {};

export default AddressCard;
