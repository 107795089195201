import { actions } from "redux/actions/facilities.action";

const INITIAL_STATE = {
  facilities: [],
  isFetchingFacilities: false,
};

const reducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case actions.SET_FACILITIES:
      return { ...state, facilities: action.data };
    case actions.SET_FETCHING_FACILITIES:
      return { ...state, isFetchingFacilities: action.isFetchingFacilities };
    default:
      return state;
  }
};
export default reducer;
