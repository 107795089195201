export const actions = {
  SET_PAYMENT_METHODS: "SET_PAYMENT_METHODS",
  ADD_PAYMENT_METHOD: "ADD_PAYMENT_METHOD",
  UPDATE_PAYMENT_METHOD: "UPDATE_PAYMENT_METHOD",
  DELETE_PAYMENT_METHOD: "DELETE_PAYMENT_METHOD",
  RESET_PAYMENT_METHODS: "RESET_PAYMENT_METHODS",
  SET_PRIMARY_METHOD: "SET_PRIMARY_METHOD",
  SET_CREDIT: "SET_CREDIT",
};

export const setPaymentMethods = (data) => ({
  type: actions.SET_PAYMENT_METHODS,
  data,
});

export const addPaymentMethod = (data) => ({
  type: actions.ADD_PAYMENT_METHOD,
  data,
});

export const updatePaymentMethod = (data) => ({
  type: actions.UPDATE_PAYMENT_METHOD,
  data,
});

export const deletePaymentMethod = (paymentMethodId) => ({
  type: actions.DELETE_PAYMENT_METHOD,
  paymentMethodId,
});

export const resetPaymentMethods = () => ({
  type: actions.RESET_PAYMENT_METHODS,
});

export const setPrimaryMethod = (paymentMethodId, prevPaymentMethodId) => ({
  type: actions.SET_PRIMARY_METHOD,
  paymentMethodId,
  prevPaymentMethodId,
});

export const setCredit = (data) => ({
  type: actions.SET_CREDIT,
  data,
});
