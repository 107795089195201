import { actions } from "../actions/booking.action";

const INITIAL_STATE = {
  bookings: [],
  currentBooking: null,
};

const reducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case actions.SET_CURRENT_BOOKING:
      return { ...state, currentBooking: action.data };
    case actions.SET_BOOKINGS:
      return { ...state, bookings: action.data };
    case actions.ADD_BOOKING:
      return { ...state, bookings: [...state.bookings, action.data] };
    case actions.UPDATE_BOOKING:
      const updatedBookings = state.bookings.map((booking) => {
        if (booking.id === action.data?.id) {
          return { ...booking, ...action.data };
        }
        return booking;
      });
      return { ...state, bookings: updatedBookings };
    case actions.DELETE_BOOKING:
      const filteredBookings = state.bookings.filter(
        (booking) => booking.orderId !== action?.orderId
      );
      return { ...state, bookings: filteredBookings };
    case actions.RESET:
      return INITIAL_STATE;
    default:
      return state;
  }
};
export default reducer;

// orders schema
//   {
//     "status": "pending" | "completed",  // for frontend only
//     "serviceAddress": "string",
//     "voucherCode": "string",
//     "serviceDate": "2022-05-19T14:41:42.686Z",
//     "facilityId": 0,
//     "operationTypeId": 0,
//     "emailReminderRequired": true,
//     "smsReminderRequired": true,
//     "userProfileId": 0,
//     "lineItems": [
//       {
//         "productId": 0,
//         "addOns": [
//           0
//         ]
//       }
//     ]
