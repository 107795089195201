import { ReactComponent as AccountSettingsIco } from "assets/icons/accountSettingsIco.svg";
import { ReactComponent as MyAppointmentsIco } from "assets/icons/calenderIco.svg";
import { ReactComponent as AddressesIco } from "assets/icons/addressesIco.svg";
import { ReactComponent as IntakeFormsIco } from "assets/icons/intakeFormsIco.svg";
import { ReactComponent as DocumentsIco } from "assets/icons/documentsIco.svg";
import { ReactComponent as MemebershipIco } from "assets/icons/membershipsIco.svg";
import { ReactComponent as PaymentsIco } from "assets/icons/paymetsIco.svg";
import { ReactComponent as FamilyIco } from "assets/icons/familyIco.svg";
import { ReactComponent as GiftcardAndPromotionsIco } from "assets/icons/giftCardAndPromotionsIco.svg";
import { ReactComponent as ReferalAndEarnIco } from "assets/icons/referandEarnIco.svg";

import AccountSettings from "containers/Account/Screens/AccountSettings/AccountSettings";
import Appointments from "containers/Account/Screens/Appointments/Appointments";
import Addresses from "containers/Account/Screens/Addresses/Addresses";
import IntakeForms from "containers/Account/Screens/IntakeForms/IntakeForms";
import Documents from "containers/Account/Screens/Documents/Documents";
import Payments from "containers/Account/Screens/Payments/Payments";
import GiftcardsAndPromotions from "containers/Account/Screens/GiftcardsAndPromotions/GiftcardsAndPromotions";
import ReferAndEarn from "containers/Account/Screens/ReferAndEarn/ReferAndEarn";
import FamilyMembers from "containers/Account/Screens/FamilyMembers/FamilyMembers";
import Memberships from "containers/Account/Screens/Memberships/Memberships";

const Test = () => {
  return <>Subpage</>;
};

const accountRoutes = [
  {
    label: "My Appointments",
    icon: <MyAppointmentsIco />,
    urlName: "appointments",
    path: "/account/appointments",
    component: Appointments,
  },
  {
    label: "Addresses",
    icon: <AddressesIco />,
    urlName: "addresses",
    path: "/account/addresses",
    component: Addresses,
  },
  {
    label: "Intake Forms",
    icon: <IntakeFormsIco />,
    urlName: "intake_forms",
    path: "/account/intake_forms",
    component: IntakeForms,
  },
  {
    label: "Documents",
    icon: <DocumentsIco />,
    urlName: "documents",
    path: "/account/documents",
    component: Documents,
  },
  {
    label: "Memberships and Programs",
    icon: <MemebershipIco />,
    urlName: "memberships",
    path: "/account/memberships",
    component: Memberships,
  },
  {
    label: "Payments",
    icon: <PaymentsIco />,
    urlName: "payments",
    path: "/account/payments",
    component: Payments,
  },
  {
    label: "Family Members",
    icon: <FamilyIco />,
    urlName: "family",
    path: "/account/family",
    component: FamilyMembers,
  },
  {
    label: "Gift Card & Promotions",
    icon: <GiftcardAndPromotionsIco />,
    urlName: "giftcards_and_promotions",
    path: "/account/giftcards_and_promotions",
    component: GiftcardsAndPromotions,
  },
  {
    label: "Refer & Earn",
    icon: <ReferalAndEarnIco />,
    urlName: "refer",
    path: "/account/refer",
    component: ReferAndEarn,
  },
  // {
  //   label: "Gift Card & Promotions",
  //   icon: <GiftcardAndPromotionsIco />,
  //   urlName: "giftcards_and_promotions",
  //   path: "/account/giftcards_and_promotions",
  //   component: GiftcardsAndPromotions,
  // },
  // {
  //   label: "Refer & Earn",
  //   icon: <ReferalAndEarnIco />,
  //   urlName: "refer",
  //   path: "/account/refer",
  //   component: ReferAndEarn,
  // },
  {
    label: "Account Settings",
    icon: <AccountSettingsIco />,
    urlName: "information",
    path: "/account/(information)?",
    component: AccountSettings,
    order: 0,
  },
];

export default accountRoutes;
