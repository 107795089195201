import React from "react";
import { Route, Redirect } from "react-router-dom";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";
import queryString from "query-string";
import { useLocation } from "react-router-dom";
// import { setExistingUserOnLogRocket } from '../../actions/user';

export const PublicRoute = ({ component: Component, ...rest }) => {
  const { user } = useSelector((state) => {
    return { user: state.user };
  });
  const { currentUser, isTokenVerified } = user;

  const location = useLocation();
  const qs = queryString.parse(location.search);
  console.log('login hit')

  return (
    <Route
      {...rest}
      render={(props) => {
        if (currentUser && isTokenVerified) {
          // setExistingUserOnLogRocket();
          // TODO: create a list of public routes and check if redirect isnt a private route

          return (
            <Redirect
              to={{
                pathname: qs.redirect ? `/${qs.redirect}` : "/",
                state: { from: props.location },
              }}
            />
          );
        }
        return <Component {...props} />;
      }}
    />
  );
};
PublicRoute.propTypes = {
  location: PropTypes.object,
  component: PropTypes.any,
};
