import React, { useState, useRef, useEffect } from "react";
import PropTypes from "prop-types";

import { useDispatch, useSelector } from "react-redux";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import { CardNumberElement, CardExpiryElement } from "@stripe/react-stripe-js";
import { useStripe, useElements } from "@stripe/react-stripe-js";

import { Row, Col, Form, Typography, Divider, Space, Button } from "antd";
import {
  FormInput,
  FormButton,
  FormCheckbox,
} from "components/FormItems/FlatFormItems";

import Stripe from "components/Stripe/Stripe";
import InfoText from "components/InfoText/InfoText";

import {
  changePrimaryPaymentMethod,
  getAllPaymentMethods,
  getSetupIntent,
  postPaymentMethodId,
} from "services/paymentMethod.service";
import { openNotificationWithIcon } from "utils/Notification";
import { setLoading, setSubmitting } from "redux/actions/loading.actions";

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PK);

function StripePayment({
  saveText = "Add & Order",
  onSuccess,
  submitting,
  isRequired = true,
}) {
  const stripe = useStripe();
  const elements = useElements();
  const [form] = Form.useForm();
  const [isCardEmpty, setisCardEmpty] = useState(true);

  const dispatch = useDispatch();

  const submitBtnRef = useRef();
  const [setupIntent, setSetupIntent] = useState(undefined);

  const { currentUser } = useSelector((state) => {
    return {
      currentUser: state.user.currentUser,
    };
  });

  useEffect(() => {
    dispatch(getSetupIntent()).then((token) => {
      setSetupIntent(token);
    });
  }, []);

  const stripeErrorListener = (event) => {
    setisCardEmpty(event.empty);
  };
  const handleSubmit = async (data) => {
    // We don't want to let default form submission happen here,
    // which would refresh the page.
    // event.preventDefault();

    if (!stripe || !elements) {
      console.log("Stripe or elements not loaded");
      // Stripe.js has not yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      return;
    }

    dispatch(setLoading(true));

    let cardholderName = form.getFieldValue("name");
    if (!cardholderName && isCardEmpty && isRequired === false) {
      // no need to proceed with payment
      onSuccess({
        optNewsletter: data.optNewsletter ?? false,
      });
      return;
    }

    const result = await stripe.confirmCardSetup(setupIntent, {
      payment_method: {
        card: elements.getElement(CardNumberElement),
        billing_details: {
          name: data.name,
          email: currentUser?.email,
        },
      },
    });
    if (result?.error) {
      // Show error to your customer (for example, insufficient funds)
      openNotificationWithIcon(
        "error",
        "Error!",
        result?.error?.message || "Network error has occured"
      );
      dispatch(setLoading(false));
    } else {
      // The payment has been processed!
      if (result.setupIntent.status === "succeeded") {
        let backendResponse = await dispatch(
          postPaymentMethodId(result.setupIntent.payment_method)
        );
        if (backendResponse?.status === "success") {
          await dispatch(
            changePrimaryPaymentMethod(
              backendResponse?.response?.paymentMethodId,
              undefined,
              false
            )
          );
        }

        if (onSuccess) {
          onSuccess({
            optNewsletter: data.optNewsletter ?? false,
          });
        }
        // Show a success message to your customer
        // There's a risk of the customer closing the window before callback
        // execution. Set up a webhook or plugin to listen for the
        // payment_intent.succeeded event that handles any business critical
        // post-payment actions.
      }
    }
  };

  return (
    <Form
      form={form}
      onFinish={handleSubmit}
      layout="vertical"
      requiredMark={false}
      className="stripeForm"
      initialValues={{ optNewsletter: false }}
    >
      <Row gutter={[24, 16]}>
        <Col xs={{ span: 24 }} md={11}>
          <Stripe isRequired={!isCardEmpty} onChange={stripeErrorListener}>
            <Col xs={24} md={0}>
              <InfoText style={{ marginBottom: 16 }}>
                Your card will not be charged until after the service is
                complete.
              </InfoText>
            </Col>
          </Stripe>
        </Col>
        <Col xs={{ span: 0 }} md={13}>
          <InfoText style={{ marginTop: 36 }}>
            Your card will not be charged until after the service is complete.
          </InfoText>
        </Col>
      </Row>
      <FormCheckbox name="optNewsletter" className="stripeFormCheckbox">
        I agree to receive customer support, appointment, newsletter,
        educational and promotional messages. Typically messages are sent by
        Liquid with each appointment, in addition to a couple of times per month
        if you don’t have an appointment. Messages and data rates may apply.
        Reply STOP to no longer receive.{" "}
        <Typography.Link target="_blank" href="https://liquidmobileiv.com/privacy-policy/">
          Privacy Policy
        </Typography.Link>
        .
      </FormCheckbox>

      <div className="flex alignCenter" style={{ marginTop: 16 }}>
        <FormButton
          disabled={!stripe}
          text={saveText}
          type="submit"
          loading={submitting}
          formItemStyles={{ marginBottom: 0 }}
          // onClick={e=>{
          //   e.preventDefault();
          //   onSuccess()
          // }}
          style={{
            margin: "0px 16px 0px 0px",
            padding: " 16px 50px",
            borderRadius: 50,
            fontFamily: "tradeGothicBold",
          }}
        />
        {/* <Button type="text">Or Proceed</Button> */}
      </div>
    </Form>
  );
}

StripePayment.propTypes = {};

const StripePaymentWrapper = (props) => {
  return (
    <Elements stripe={stripePromise}>
      <StripePayment {...props} />
    </Elements>
  );
};

export default StripePaymentWrapper;
