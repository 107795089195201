import React, { useMemo } from "react";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";

function useCurrentProfile(props) {
  const { currentProfileId, profiles } = useSelector((state) => state.user);

  const currentProfile = useMemo(() => {
    if (currentProfileId) {
      return profiles.find((profile) => profile.profileId === currentProfileId);
    } else return profiles[0];
  }, [currentProfileId, profiles]);

  return currentProfile;
}

export default useCurrentProfile;
