import React, { useCallback } from "react";
import PropTypes from "prop-types";

import { Tag, Typography } from "antd";
import Modal from "components/Modal/Modal";
import { Button } from "components/Buttons/Buttons";
import { useDispatch } from "react-redux";
import { purchaseReward } from "services/rewards.service";
import { BenefitType, RewardType } from "utils/enums";
import moment from "moment";
const { Title, Text, Link } = Typography;

function MembershipModal({
  visible,
  data,
  onCancel,
  onSave,
  onMembershipCancel,
}) {
  const {
    programId,
    programName,
    content,
    subText,
    notes,
    pricing,
    annualSavings,
    programType,
    isPurchased,
    isCancelled,
    cancellationDate,
    fromDate,
    tillDate,
    sellingPrice,
    rewardsRemaining,
    purchasedDate,
  } = data;
  const canPurchase = !isPurchased;
  const showPurchased = isPurchased && !isCancelled;
  const showCancelled = isPurchased && isCancelled;
  const canRenew = !isPurchased;
  const canCancel = programType === RewardType.Membership && !isCancelled;

  const dispatch = useDispatch();
  const programText = Object.entries(RewardType).find(
    ([key, value]) => value === programType
  )?.[0];

  const renderFooter = () => {
    if (
      programType === RewardType.Membership ||
      (!isPurchased && programType === RewardType.Program)
    ) {
      return (
        <div className="membershipModalFooter">
          {isPurchased && programType === RewardType.Membership && (
            <Text
              style={{
                fontFamily: "tradeGothic",
                marginBottom: 16,
                fontSize: 17,
                textAlign: "left",
              }}
            >
              <b style={{ fontFamily: "tradeGothic" }}>Charged:</b> $
              {Number(sellingPrice ?? 0)?.toFixed?.(2)} <br />
              {isCancelled && (
                <>
                  <b style={{ fontFamily: "tradeGothic" }}>Cancelled on:</b>` $
                  {moment(cancellationDate).format("DD MMM YYYY")}`<br />
                </>
              )}
              <b style={{ fontFamily: "tradeGothic" }}>Billing Cycle:</b>{" "}
              {moment(fromDate).format("DD MMM YYYY")}-
              {moment(tillDate).format("DD MMM YYYY")}
              {/* <br />
              <b style={{ fontFamily: "tradeGothic" }}>Remaining Items:</b>{" "}
              {rewardsRemaining} */}
            </Text>
          )}

          {!isPurchased && programType === RewardType.Membership && (
            <Text
              style={{
                fontFamily: "tradeGothic",
                marginBottom: 16,
                fontSize: 17,
              }}
            >
              You will be charged every month.
            </Text>
          )}

          {showCancelled && (
            <Text
              style={{
                fontFamily: "tradeGothic",
                marginBottom: 16,
                fontSize: 17,
                textAlign: "left",
              }}
            >
              Your membership rewards are valid till the end of the billing
              period.
            </Text>
          )}
          <div
            className="flex justifySpaceBetween alignCenter"
            style={{ width: "100%" }}
          >
            <Button
              onClick={() => {
                if (!isPurchased) onSave();
                else onMembershipCancel();
              }}
              disabled={
                (programType !== RewardType.Membership && isPurchased) ||
                showCancelled
              }
              rounded={true}
              className="membershipSaveButton"
            >
              {!isPurchased
                ? "Confirm Now"
                : canRenew
                ? "Renew Membership"
                : "Cancel Membership"}
            </Button>
            <Link underline onClick={onCancel}>
              Cancel
            </Link>
          </div>
        </div>
      );
    } else if (isPurchased && programType === RewardType.Program) {
      return (
        <div className="membershipModalFooter">
          <Text
            style={{
              fontFamily: "tradeGothic",
              marginBottom: 16,
              fontSize: 17,
              textAlign: "left",
            }}
          >
            <b style={{ fontFamily: "tradeGothic" }}>Purchased On:</b> 
            {moment(purchasedDate)?.format("DD MMM YYYY")} <br />{" "}
            <b style={{ fontFamily: "tradeGothic" }}>Remaining Items: </b>
            {rewardsRemaining} <br />
          </Text>
        </div>
      );
    } else {
      if (!programId) return <></>;
      else {
        return <></>;
      }
    }
  };

  return (
    <Modal
      className="membershipModal"
      visible={visible}
      onCancel={onCancel}
      title={!isPurchased ? "Confirmation" : `${programText} Details`}
      footer={renderFooter()}
    >
      <div className="membershipModalContent">
        <div className="content">
          <div className="header">
            <Title
              level={4}
              className="tradeGothic"
              style={{ color: "#4A6AAF", margin: 0 }}
            >
              {programName}
            </Title>
            {showPurchased && <Tag color="green">Purchased</Tag>}
            {showCancelled && <Tag color="orange">Cancelled</Tag>}
          </div>
          <div className="innerContent">{content}</div>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
            }}
          >
            {notes &&
              notes.split("\n").map((ele) => {
                return (
                  <Text
                    style={{
                      fontFamily: "tradeGothic",
                    }}
                  >
                    {ele}
                  </Text>
                );
              })}
          </div>
        </div>
        {pricing && (
          <div style={{ marginBottom: 20 }}>
            <Title
              level={5}
              className="tradeGothic"
              style={{ marginBottom: 0 }}
            >
              ${pricing}/Month
            </Title>
          </div>
        )}
      </div>
    </Modal>
  );
}

MembershipModal.propTypes = {
  visible: PropTypes.bool.isRequired,
  onSubmit: PropTypes.any,
  onCancel: PropTypes.any,
  membership: PropTypes.any.isRequired,
};

export default MembershipModal;
