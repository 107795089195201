import React from "react";
import { Route, Redirect } from "react-router-dom";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";
import queryString from "query-string";
import { useLocation } from "react-router-dom";
// import { setExistingUserOnLogRocket } from '../../actions/user';

export const PrivateRoute = ({ component: Component, ...rest }) => {
  const { user } = useSelector((state) => {
    return { user: state.user };
  });
  const { currentUser, isTokenVerified } = user;

  const location = useLocation();
  const qs = queryString.parse(location.search);

  let redirect = "/login";
  let state = {};
  let pathname = location.pathname?.toLowerCase() || ""
  if (pathname.includes("account/intake_forms")) {
    redirect = "/login";
  } else if (qs.redirect !== "giftcards_and_promotions") {
    redirect = qs.redirect;
  } else {
    redirect = `/login`;
    state = {
      goTo: `/account/giftcards_and_promotions?redirect=${qs.redirect}&id=${qs.id}`,
    };
  }

  return (
    <Route
      {...rest}
      render={(props) => {
        if (currentUser && isTokenVerified) {
          // setExistingUserOnLogRocket();
          return <Component {...props} />;
        }
        // TODO: create a list of private routes and check if redirect isnt a private route
        return (
          <Redirect
            to={{
              pathname: redirect,
              state: { from: props.location, ...state },
            }}
          />
        );
      }}
    />
  );
};
PrivateRoute.propTypes = {
  location: PropTypes.object,
  component: PropTypes.any,
};
