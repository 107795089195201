import { CloseOutlined } from "@ant-design/icons";
import React, { useEffect, useState } from "react";

import { Button as AntdButton, Drawer, Skeleton } from "antd";
import { Button } from "components/Buttons/Buttons";
import GiftCardItem from "./GiftCardItem";
import GiftCardModal from "./GiftCardModal";
import Modal from "components/Modal/Modal";
import { getAllGiftCards } from "services/booking.service";
import { useDispatch } from "react-redux";

export default function GiftCardsList({ visible, giftCards = [], onCancel }) {
  const [data, setData] = useState({
    data: [],
    isLoading: true,
  });

  const dispatch = useDispatch();

  const loadData = () => {
    setData((state) => ({
      ...state,
      isLoading: true,
    }));
    dispatch(getAllGiftCards())
      .then((res) => {
        if (res?.status === "success")
          setData((state) => ({
            ...state,
            data: res?.data ?? [],
          }));
      })
      .finally(() => {
        setData((state) => ({
          ...state,
          isLoading: false,
        }));
      });
  };

  useEffect(() => {
    if (visible) {
      loadData();
    }
  }, [visible]);

  return (
    <Modal
      destroyOnClose
      headerStyle={{ border: "none" }}
      className="serviceDrawer"
      width={"min(100vw, 420px)"}
      title="Gift Cards"
      placement="right"
      onCancel={onCancel}
      visible={visible}
      closable={false}
      footer={<></>}
    >
      <div className="giftCardsList">
        {data?.isLoading ? (
          <>
            <Skeleton paragraph active />
          </>
        ) : (
          data?.data.map((giftCard) => {
            return (
              <GiftCardItem
                key={giftCard.giftCardId}
                giftCard={giftCard}
                refreshData={loadData}
              />
            );
          })
        )}
      </div>
    </Modal>
  );
}
