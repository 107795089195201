import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";

import { Row, Col, Typography, Spin } from "antd";
import MembershipCard from "./MembershipCard";
import MembershipModal from "./MembershipModal";
import StripeAddCardModal from "components/StripeAddCard/StripeAddCardModal";


import { useDispatch, useSelector } from "react-redux";
import {
  getCustomerPrograms,
  getPurchaseableRewards,
  getRewards,
  purchaseReward,
} from "services/rewards.service";
import { findWindows } from "windows-iana";
import moment from "moment";
import { setLoading } from "redux/actions/loading.actions";
import Spinner from "components/Spinner/Spinner";
import { cancelMembership } from "services/booking.service";

const { Title, Text } = Typography;

const LOADING_TYPE = {
  FETCHING: 1,
  PURCHASING: 2,
  CANCELLING: 3,
};

function Memberships(props) {
  const dispatch = useDispatch();
  const [showAddCard, setShowAddCard] = useState(false);
  const [customerPurchases, setCustomerPurchases] = useState({
    data: [],
    isLoading: true,
  });

  const { allPrograms, paymentMethods } = useSelector((state) => {
    return {
      allPrograms: state.rewards.purchaseableRewards,
      paymentMethods: state.paymentMethod.paymentMethods,
    };
  });
  const primaryPaymentMethod =
    paymentMethods.find((card) => card?.isPrimary === true) ??
    paymentMethods[0];

  const [loadingCustomerPrograms, setLoadingCustomerPrograms] = useState(
    LOADING_TYPE.FETCHING
  );

  const hasMembershipDummy = false;
  const [showMembershipModal, setShowMembershipModal] = useState(false); // false or 0+ number

  const loadCustomerPurchases = () => {
    dispatch(setLoading(true));
    setCustomerPurchases((state) => ({
      ...state,
      isLoading: true,
    }));
    dispatch(getCustomerPrograms()).then((res) => {
      if (res?.status === "success") {
        setCustomerPurchases((state) => ({
          data: res.data,
          isLoading: false,
        }));
      } else {
        dispatch(setLoading(false));
        setCustomerPurchases((state) => ({
          ...state,
          isLoading: false,
        }));
      }
    });
  };



  useEffect(() => {
    if (loadingCustomerPrograms === LOADING_TYPE.FETCHING) {
      Promise.all([loadCustomerPurchases()])
        .then((res) => {
          // setShowMembershipModal(false);
        })
        .finally(() => {
          setLoadingCustomerPrograms(false);
        });
    } else if (loadingCustomerPrograms === LOADING_TYPE.PURCHASING) {
      setTimeout(() => {
        const body = document.querySelector(".accountsContainer");
        body.scrollIntoView(
          {
            behavior: "smooth",
          },
          0
        );
      }, 200);
      setTimeout(() => {
        Promise.all([dispatch(getRewards()), loadCustomerPurchases()])
          .then((res) => {})
          .finally(() => {
            setLoadingCustomerPrograms(false);
          });
      }, 10000);
    }
  }, [loadingCustomerPrograms]);

  return (
    <div style={{ paddingTop: 20 }}>
      {/* TODO: UNCOMMENT */}
      {/* Membership Status Start */}
      {/* <div
        className={`availableMembershipsContainer ${
          hasMembershipDummy ? "hasMembership" : ""
        }`}
      >
        {hasMembershipDummy ? (
          <MembershipCard
            key={1}
            title={MembershipOptions[0].title}
            content={MembershipOptions[0].content}
            subText={MembershipOptions[0].subText}
            pricing={MembershipOptions[0].pricing}
            annualSavings={MembershipOptions[0].annualSavings}
          />
        ) : (
          <Text className="tradeGothic" style={{ fontSize: 17, lineHeight: 1 }}>
            You dont have any active Memberships or Programs.
          </Text>
        )}
      </div> */}
      {/* Membership Status End */}

      {/* Membership Options Start */}
      {/* TODO: UNCOMMENT */}
      <Row gutter={[32, 32]}>
        {loadingCustomerPrograms ? (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              flexDirection: "column",
              height: 200,
              width: "100%",
            }}
          >
            <Spin />
            {loadingCustomerPrograms === LOADING_TYPE.PURCHASING && (
              <>
                <br />
                <Text>
                  Please wait 10 seconds, while we confirm your memberships
                  status.
                </Text>
              </>
            )}
          </div>
        ) : (
          customerPurchases?.data?.map((it, index) => {
            return (
              <Col>
                <MembershipCard
                  key={it.programId}
                  data={it}
                  onClick={() => setShowMembershipModal(it)}
                />
              </Col>
            );
          })
        )}
      </Row>

      <MembershipModal
        // membershipStatus={showMembershipModal?.customerProgramId ? true : false}
        paymentMethod={primaryPaymentMethod}
        data={showMembershipModal}
        visible={showMembershipModal}
        membership={false}
        onSave={() => {
          if (!primaryPaymentMethod) setShowAddCard(true);
          else {
            dispatch(
              purchaseReward({
                paymentMethodId: primaryPaymentMethod?.paymentMethodId,
                timeZone: findWindows(moment?.tz?.guess?.() || "")?.[0],
                product: {
                  productId: showMembershipModal?.productId,
                  paymentGatewayPriceId:
                    showMembershipModal?.paymentGatewayPriceId,
                },
              })
            ).then((res) => {
              if (res?.status === "success") {
                setLoadingCustomerPrograms(LOADING_TYPE.PURCHASING);
                setShowMembershipModal(false);
              }
            });
          }
          // dispatch(setCurrentMembership(type));
        }}
        onCancel={() => {
          setShowMembershipModal(false);
        }}
        onMembershipCancel={() => {
          dispatch(cancelMembership(showMembershipModal?.customerProgramId))
            .then((res) => {
              if(res?.status==='success'){
                const body = document.querySelector("#root");
                setLoadingCustomerPrograms(LOADING_TYPE.PURCHASING);
                setShowMembershipModal(false);
              }
            
            })
            .finally(() => {});
        }}
      />
      <StripeAddCardModal
        message="Payment method is mandatory for purchasing a program or membership"
        saveText={"Save and Order"}
        closable={false}
        forcePrimary={true}
        visible={showAddCard}
        handleSave={(data = []) => {
          setShowAddCard(false);
        }}
        onCancel={() => setShowAddCard(false)}
      />
      {/* Membership Options End */}
    </div>
  );
}

Memberships.propTypes = {};

export default Memberships;
