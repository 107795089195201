import React from "react";
import PropTypes from "prop-types";

import { Button, Space, Typography } from "antd";
import {
  CheckCircleOutlined,
  ExclamationCircleFilled,
} from "@ant-design/icons";
import { FormType } from "utils/enums";
import moment from "moment";

const { Text, Title } = Typography;

function AuditListItem({ data = {}, title, onClick }) {
  const { isExpired, createdOn, isDesignOutdated } = data;
  const isFilled = true;
  return (
    <div className="auditListItem">
      <div className="auditListItemLeft">
        <Space align="start">
          {isExpired ? (
            <ExclamationCircleFilled style={{ color: "#faad14" }} />
          ) : (
            <CheckCircleOutlined
              style={{ color: isFilled ? " #27CA2D" : "#dddddd" }}
            />
          )}
          <Title level={5} style={{ margin: 0 }}>
            {title}
          </Title>
        </Space>

        <Text>
          {createdOn ? (
            <>
              {moment.utc(createdOn).local().format("MMM DD, YYYY h:mm A")} (
              {moment.tz.guess()})
            </>
          ) : (
            "NA"
          )}
        </Text>
      </div>
      <Space>
        {/* {!isDesignOutdated && (
          <Button
            type="text"
            style={{ padding: 4 }}
            onClick={() => onClick(false)}
          >
            Edit
          </Button>
        )} */}

        <Button
          type="text"
          style={{ padding: 4 }}
          onClick={() => onClick(true)}
        >
          View
        </Button>
      </Space>
    </div>
  );
}

AuditListItem.propTypes = {};

export default AuditListItem;
