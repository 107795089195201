import React from "react";
import PropTypes from "prop-types";

import { Row, Col, Form, Typography, Divider } from "antd";

import {
  CardNumberElement,
  CardExpiryElement,
  CardCvcElement,
} from "@stripe/react-stripe-js";
import "./Stripe.scss";

import { FormInput } from "components/FormItems/FlatFormItems";

const CARD_ELEMENT_OPTIONS = {
  style: {
    base: {
      color: "#32325d",
      fontFamily: "Roboto, sans-serif",
      fontSmoothing: "antialiased",
      fontSize: "14px",
      "::placeholder": {
        fontSize: 14,
        color: "#A8ACB3",
      },
    },
    invalid: {
      color: "#fa755a",
      iconColor: "#fa755a",
    },
  },
};

function Stripe({ children, isRequired = true, onChange }) {
  return (
    <Row gutter={[24, 16]}>
      <Col span={24}>
        <Typography className="stripeElementsLabel">Card Number</Typography>
        <CardNumberElement
          options={{ ...CARD_ELEMENT_OPTIONS, showIcon: true }}
          onChange={onChange}
        />
      </Col>
      <Col span={24}>
        <FormInput
          name="name"
          placeholder="Card Holder Name"
          label="Card Holder Name"
          variant="underlined"
          rules={[
            {
              message: "Please enter a valid name.",
              validator: (_, value) => {
                if (isRequired && !value) {
                  return Promise.reject();
                }
                return Promise.resolve();
              },
            },
          ]}
          formItemStyles={{ marginBottom: 0 }}
        />
      </Col>

      <Col span={11}>
        <Typography className="stripeElementsLabel">Expiration Date</Typography>
        <CardExpiryElement options={CARD_ELEMENT_OPTIONS} />
      </Col>
      <Col span={10}>
        <Typography className="stripeElementsLabel">CVC</Typography>
        <CardCvcElement options={CARD_ELEMENT_OPTIONS} />
      </Col>
      <Col span={24}>
        {children}
        <Divider
          style={{
            margin: "10px 0px",
            backgroundColor: "#BDC6DD",
          }}
        />
      </Col>
    </Row>
  );
}

Stripe.propTypes = {};

export default Stripe;
