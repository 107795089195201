import React, { useEffect, useMemo, useRef, useState } from "react";
import PropTypes from "prop-types";
import Modal from "components/Modal/Modal";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";

import {
  FormInput,
  FormInputArea,
  FormButton,
  FormCheckbox,
} from "components/FormItems/FlatFormItems";
import {
  Radio,
  Input,
  Form,
  Typography,
  Row,
  Col,
  Spin,
  Skeleton,
  Alert,
} from "antd";
import { FormDatePicker } from "components/FormItems/FlatFormItems";
import {
  getGiftCardById,
  getGiftCardDesign,
  purchaseGiftCard,
  updateGiftCard,
  validateGuestAccount,
} from "services/booking.service";
import { validateEmail } from "utils/common";
import { LoadingOutlined, UserOutlined } from "@ant-design/icons";
import UsernamePopup from "containers/Dashboard/UsernamePopup";
import { GIFTCARD_CONFIG_KEY } from "utils/constants";
import Spinner from "components/Spinner/Spinner";

// Hardcoded ID provided by the backend to be used as productID for giftcard purchase
const GIFT_CARD_ID = 1148;

const { Title } = Typography;

function GiftCardModal({
  visible,
  onCancel,
  onSave,
  primaryPaymentMethod,
  design,
  miscLoading,
}) {
  const [form] = Form.useForm();
  const value = Form.useWatch("amount", form);
  const isRecipient = Form.useWatch("isRecipient", form);

  const recipientFirstName = Form.useWatch("recipientFirstName", form);
  const recipientLastName = Form.useWatch("recipientLastName", form);
  const email = Form.useWatch("recipientEmail", form);
  const isValidated = Form.useWatch("isValidated", form);
  const username = Form.useWatch("username", form);
  const hasMultipleGuests = Form.useWatch("hasMultipleGuests", form);
  const count = Form.useWatch("count", form);

  const [showUsernamePopup, setShowUsernamePopup] = useState(false);
  const [isValidating, setIsValidating] = useState(false);
  const [lastCheckedInfo, setLastCheckedInfo] = useState({
    email: "",
    recipientFirstName: "",
    recipientLastName: "",
  });

  const [formInfo, setFormInfo] = useState({
    data: null,
    isLoading: false,
  });

  const { user } = useSelector((state) => ({
    user: state.user.currentUser,
  }));
  const abortConRef = useRef();
  const dispatch = useDispatch();

  const isEdit = !!visible?.giftCardId;

  // const [design, setdesign] = useState({
  //   data: null,
  //   isLoading: true,
  // });
  const [showTCs, setShowTCs] = useState(false);

  useEffect(() => {
    if (!visible) {
      form.resetFields();
      setFormInfo({
        data: null,
        isLoading: false,
      });
    } else if (visible?.giftCardId) {
      setFormInfo((state) => ({ ...state, isLoading: true }));

      dispatch(getGiftCardById(visible.giftCardId))
        .then((res) => {
          if (res?.status === "success") {
            const data = res?.data ?? {};
            setFormInfo((state) => ({ ...state, data }));
            form.setFieldsValue({
              ...data,
              scheduledSendDate: data.scheduledSendDate
                ? moment(res.scheduledSendDate)
                : null,
            });
          }
        })
        .finally(() => {
          setFormInfo((state) => ({ ...state, isLoading: false }));
        });
    }
  }, [visible]);

  const checkInformation = () => {
    if (!validateEmail(email?.trim?.() || "")) return;

    setIsValidating(true);
    if (abortConRef.current) abortConRef.current.abort();
    abortConRef.current = new AbortController();
    dispatch(
      validateGuestAccount({
        recipientFirstName,
        recipientLastName,
        emailAddress: email,
      })
    )
      .then((res) => {
        if (res?.status !== "success") {
          return;
        }

        if (res?.count > 1) {
          setShowUsernamePopup(true);
        } else if (res?.count === 1) {
          form.setFields([
            {
              name: "recipientEmail",
              errors: [],
            },
            {
              name: "count",
              value: 1,
            },
            {
              name: "isValidated",
              value: true,
            },
            {
              name: "username",
              value: undefined,
            },
            {
              name: "hasMultipleGuests",
              value: "no",
            },
          ]);
          setLastCheckedInfo({
            email,
            recipientFirstName,
            recipientLastName,
          });
        } else {
          form.setFields([
            {
              name: "recipientEmail",
              errors: [],
            },
            {
              name: "count",
              value: 0,
            },
            {
              name: "isValidated",
              value: true,
            },
            {
              name: "username",
              value: undefined,
            },
            {
              name: "hasMultipleGuests",
              value: "no",
            },
          ]);
          setLastCheckedInfo({
            email,
            recipientFirstName,
            recipientLastName,
          });
        }
      })
      .finally(() => {
        setIsValidating(false);
      });
  };

  const renderVerificationStatus = () => {
    const hasAllFields =
      email === lastCheckedInfo?.email &&
      recipientFirstName === lastCheckedInfo.recipientFirstName &&
      recipientLastName === lastCheckedInfo.recipientLastName;

    if (isValidating)
      return (
        <Spin
          indicator={
            <LoadingOutlined
              style={{ fontSize: 20, lineHeight: 0, color: "#486baf" }}
              spin
            />
          }
        />
      );
    if (count === 0 && hasAllFields)
      return (
        <p
          className="verifiedText firstLetterUppercase"
          style={{
            lineHeight: 1,
            margin: 0,
          }}
        >
          NEW GUEST
        </p>
      );

    return (
      <>
        {hasAllFields ? (
          <div
            style={{
              display: "flex",
              gap: 8,
            }}
          >
            <UserOutlined onClick={() => setShowUsernamePopup(true)} />
            <p
              className="verifiedText firstLetterUppercase"
              style={{
                lineHeight: 1,
                margin: 0,
              }}
            >
              VERIFIED
            </p>
          </div>
        ) : (
          <p
            className="verifyBtn firstLetterUppercase"
            onClick={checkInformation}
            disabled={isRecipient}
            style={{
              lineHeight: 1,
              margin: 0,
            }}
          >
            VERIFY
          </p>
        )}
      </>
    );
  };

  const renderForm = () => {
    const {
      sellingPrices = [50, 100, 150],
      customValueEnabled = true,
      notes,
      minimumValue = 200,
    } = design ?? {};
    return (
      <>
        {formInfo?.data?.hasInvalidEmail && (
          <Alert
            message="Invalid Receiver Email Provided."
            type="error"
            className="giftcardFeedbackAlert"
            closable
          />
        )}
        <Title level={4} className="tradeGothic">
          Gift Card Amount
        </Title>

        {isEdit ? (
          <>
            {formInfo?.isLoading ? (
              <Spinner />
            ) : (
              <Typography.Text>${formInfo?.data?.amount}</Typography.Text>
            )}
          </>
        ) : (
          <Form.Item
            disabled={isEdit}
            name="amount"
            minimumValue={minimumValue}
            style={{ marginBottom: 20 }}
            rules={[
              {
                required: true,
                message: "amount required",
              },
            ]}
          >
            <Radio.Group
              disabled={isEdit}
              className="giftCardValues"
              // onChange={(e, value) => setValue(e.target.value)}
            >
              {sellingPrices?.map((price) => {
                return <Radio value={price}>${price}</Radio>;
              })}
              {customValueEnabled && (
                <Radio value={"custom"}>
                  {value === "custom" ? (
                    <Form.Item
                      className="no-error-field"
                      style={{ margin: 0 }}
                      rules={[
                        {
                          required: true,
                        },
                        {
                          min: 1,
                        },
                      ]}
                      name="customValue"
                      required
                    >
                      <Input
                        min={1}
                        prefix="$"
                        type="number"

                        // onChange={(e) => setCustomValue(Number(e.target.value))}
                        // value={customValue}
                      />
                    </Form.Item>
                  ) : (
                    <> Custom</>
                  )}
                </Radio>
              )}
            </Radio.Group>
          </Form.Item>
        )}
        <Title level={4} className="tradeGothic" style={{ marginBottom: 6 }}>
          To
        </Title>
        {/* 
        <FormInput
          label="Reciepient Name"
          variant="underlined"
          name="reciepientFullName"
          placeholder={"Full name..."}
          formItemStyles={{ marginBottom: 14 }}
          required={true}
        /> */}

        <Row gutter={16}>
          <Col xs={12}>
            <FormInput
              label="First Name"
              variant="underlined"
              name="recipientFirstName"
              placeholder={"First name"}
              formItemStyles={{ marginBottom: 12 }}
              required={!isRecipient}
              disabled={isRecipient}
              onChange={(e) => {
                if (e.target.value !== lastCheckedInfo.recipientFirstName)
                  form.setFields([
                    {
                      name: "isValidated",
                      value: false,
                    },
                  ]);
              }}
            />
          </Col>
          <Col xs={12}>
            <FormInput
              label="Last Name"
              variant="underlined"
              name="recipientLastName"
              placeholder={"Last name"}
              formItemStyles={{ marginBottom: 12 }}
              required={!isRecipient}
              disabled={isRecipient}
              onChange={(e) => {
                if (e.target.value !== lastCheckedInfo.recipientFirstName)
                  form.setFields([
                    {
                      name: "isValidated",
                      value: false,
                    },
                  ]);
              }}
            />
          </Col>
          <Col xs={24}>
            <FormInput
              validateFirst={true}
              label="Reciepient Email Address"
              variant="underlined"
              name="recipientEmail"
              placeholder={"name@domain.com"}
              formItemStyles={{ marginBottom: 14 }}
              disabled={isRecipient}
              addonAfter={<>{renderVerificationStatus()}</>}
              rules={[
                {
                  required: !isRecipient,
                  message: "The name is required.",
                },
                {
                  type: "email",
                  message: "The input is not valid E-mail!",
                },
                {
                  message: "User not validated",
                  validator: (_, value) => {
                    if (isRecipient) return Promise.resolve();
                    if (
                      email === lastCheckedInfo?.email &&
                      recipientFirstName ===
                        lastCheckedInfo.recipientFirstName &&
                      recipientLastName === lastCheckedInfo.recipientLastName
                    )
                      return Promise.resolve();
                    else return Promise.reject("Username not validated");
                  },
                },
              ]}
              onChange={(e) => {
                if (e.target.value !== lastCheckedInfo.email)
                  form.setFields([
                    {
                      name: "isValidated",
                      value: false,
                    },
                  ]);
              }}
              onBlur={() => {
                console.log("blurrr");
                if (
                  email &&
                  recipientFirstName &&
                  recipientLastName &&
                  !(
                    email === lastCheckedInfo?.email &&
                    recipientFirstName === lastCheckedInfo.recipientFirstName &&
                    recipientLastName === lastCheckedInfo.recipientLastName
                  )
                ) {
                  checkInformation();
                }
                // dispatch();
              }}
            />
          </Col>
        </Row>
        <FormInput hidden={true} rules={[]} name={"count"} />
        <FormInput hidden={true} rules={[]} name={"username"} />
        <FormInput hidden={true} rules={[]} name={"hasMultipleGuests"} />
        <FormInput hidden={true} rules={[]} name={"isValidated"} />

        <Row>
          <Col xs={24}>
            <FormCheckbox
              name="isRecipient"
              label="I am the recipient"
              formItemStyles={{ marginBottom: 14 }}
              className="tradeGothic"
            >
              I am the recipient{" "}
            </FormCheckbox>
          </Col>
        </Row>
        <FormInputArea
          label="Personal Notes"
          name="personalNotes"
          variant="underlined"
          disabled={isEdit}
        />

        {/* <Title level={4} className="tradeGothic">
          From
        </Title> */}

        <FormDatePicker
          form={form}
          placeholder={"Send Date"}
          label={"Send Date"}

          name="scheduledSendDate"
          variant="underlined"
          formItemStyles={{ marginBottom: 14 }}
          required
          validateFirst
          // defaultValue={moment("01-01-1994", "DD-MM-YYYY")}
          rules={[
            {
              required: true,
              message: "*Required",
            },
            // {
            //   message:
            //     "Your age should be between 18 and 85. Kindly contact our support.",
            //   validator: (_, value) => {
            //     if (
            //       value.isSameOrBefore(moment().subtract(18, "years")) &&
            //       value.isAfter(moment().subtract(85, "years"))
            //     )
            //       return Promise.resolve();
            //     else return Promise.reject();
            //   },
            // },
          ]}
          disabledDate={(current) =>
            current.isSameOrBefore(
              moment().set({ hour: 0, minute: 0, second: 0, millisecond: 0 })
            )
          }
          disabled={isEdit}
        />
        <FormButton
          type="submit"
          disabled={
            (isEdit && !visible?.hasInvalidEmail) || visible?.isRedeemed
          }
          text={isEdit ? "Resend Email" : "Make Payment"}
          rounded={true}
          formItemStyles={{ marginBottom: 12 }}
        />
        <Typography.Link
          underline
          disabled={!design?.notes}
          onClick={() => setShowTCs(true)}
        >
          Gift Card Terms & Conditions
        </Typography.Link>
      </>
    );
  };

  const renderLoader = () => {
    return (
      <div>
        <Skeleton
          active
          paragraph={{
            rows: 6,
          }}
        />
      </div>
    );
  };

  return (
    <Modal
      className="giftCardModal"
      width="500px"
      title={isEdit ? "Update Gift Card" : "Send a Gift Card"}
      footer={null}
      onCancel={() => onCancel()}
      visible={visible}
      //   onOk={() => console.log("ok")}
      //   okText="Add"
      centered={true}

      //   footer={[<Button rounded={true}>Save</Button>]}
      //   cancelText="No"
    >
      <Form
        form={form}
        requiredMark={false}
        layout="vertical"
        onFinishFailed={({ values, errorFields, outOfDate }) => {
          form.scrollToField(errorFields[0].name, {
            scrollMode: "if-needed",
            block: "center",
            behavior: "smooth",
          });
        }}
        onFinish={(formValues) => {
          const { scheduledSendDate, amount, customValue, ...values } =
            formValues;
          let data = {
            ...values,
          };
          data.scheduledSendDate = moment(scheduledSendDate).format();
          if (amount === "custom") {
            data.giftCardPrice = formValues.customValue;
          } else {
            data.giftCardPrice = formValues.amount;
          }
          data.paymentMethodId = primaryPaymentMethod?.paymentMethodId;

          data.recipientUsername = data.username;

          if (isRecipient) {
            delete data.username;
            data.recipientFirstName = user.firstName;
            data.recipientLastName = user.lastName;
            data.recipientEmail = user.email;
            data.recipientUserName = user.userName;
          }

          const cb = isEdit
            ? () => updateGiftCard(visible?.giftCardId, data)
            : () => purchaseGiftCard(data);

          dispatch(cb()).then((res) => {
            if (res?.status === "success") {
              onSave();
            }
          });
        }}
      >
        {miscLoading ? renderLoader() : renderForm()}
      </Form>
      <UsernamePopup
        username={username}
        visible={showUsernamePopup}
        onCancel={() => setShowUsernamePopup(false)}
        continueWithoutUsername={() => {
          form.setFields([
            {
              name: "recipientEmail",
              errors: [],
            },
            {
              name: "isValidated",
              value: true,
            },
            {
              name: "username",
              value: "",
            },
            {
              name: "backendStatus",
              value: "",
            },
            {
              name: "hasMultipleGuests",
              value: "no",
            },
          ]);
          setLastCheckedInfo({
            email,
            recipientFirstName,
            recipientLastName,
          });
          setShowUsernamePopup(false);
        }}
        handleSave={(username) => {
          form.setFields([
            {
              name: "recipientEmail",
              errors: [],
            },
            {
              name: "count",
              value: 2,
            },
            {
              name: "isValidated",
              value: true,
            },
            {
              name: "username",
              value: username,
            },
            {
              name: "hasMultipleGuests",
              value: "yes",
            },
          ]);
          setLastCheckedInfo({
            email,
            recipientFirstName,
            recipientLastName,
          });
          setShowUsernamePopup(false);
        }}
      />
      <Modal
        title="Gift Card Terms & Conditions"
        visible={showTCs}
        onCancel={() => setShowTCs(false)}
        footer={null}
      >
        <div>
          <Typography.Text level={4} className="tradeGothic whiteSpacePreWrap" >
            {design?.notes}
          </Typography.Text>
        </div>
      </Modal>
    </Modal>
  );
}

GiftCardModal.propTypes = {};

export default GiftCardModal;
