const getEnv = () => {
  const appEnv = window.location.host.split(".")[0];
  switch (appEnv) {
    case "gentle-grass-09eed8a10":
      return "predev";
    case "purple-sand-066809e10":
      return "test";
    case "nice-meadow-08f429810":
      return "uat";
    case "black-pebble-06b1a7010":
      return "prod";
    case "elevate":
      return "prod";
    default:
      return "local";
  }
};

const apiUrl = () => {
  switch (getEnv()) {
    case "uat":
      return "https://uat-liquidmobile-api.azurewebsites.net/api/";
    case "prod":
      return "https://prod-liquidmobileiv-guest-api.azurewebsites.net/api/";
    case "predev":
      return "https://pre-dev-liquidmobile-api.azurewebsites.net/api/";
    default:
      return "https://dev-liquidmobile-api.azurewebsites.net/api/";
  }
};

const api = {
  url: apiUrl(),
};

export default api;
