export const actions = {
  SET_FACILITIES: "SET_FACILITIES",
  SET_FETCHING_FACILITIES: "SET_FETCHING_FACILITIES",
};

export const setFacilities = (data) => ({
  type: actions.SET_FACILITIES,
  data,
});

export const setFetchingFacilities = (isFetchingFacilities) => {
  return {
    type: actions.SET_FETCHING_FACILITIES,
    isFetchingFacilities,
  };
};
