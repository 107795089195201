import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import PropTypes from "prop-types";

import FamilyMemberCard from "./FamilyMemberCard";
import "./FamilyMembers.scss";

import { List, Typography } from "antd";
import FamilyMemberModal from "./FamilyMemberModal";

import { getAllFamilyMembers } from "services/family.service";

const { Title } = Typography;

const DUMMY_FAMILY_MEMBERS = [
  { name: "Mike Lammers", permissionToBill: [] },
  { name: "Person 2", permissionToBill: [] },
];

function FamilyMembers(props) {
  const [showFamilyMemberModal, setShowFamilyMemberModal] = useState(false);

  const family = useSelector((state) => state.family.family);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getAllFamilyMembers());
  }, []);

  return (
    <>
      <List
        locale={{ emptyText: "No Family Members" }}
        className="familyMembersList"
        itemLayout="vertical"
        size="large"
        dataSource={family}
        renderItem={(member, index) => (
          <List.Item key={index}>
            <FamilyMemberCard
              key={member.invitedMemberId}
              invitedMemberId={member.invitedMemberId}
              firstName={member.firstName}
              lastName={member.lastName}
              email={member.email}
              mobileNo={member.mobileNo}
              permissionToBill={member.permissionToBill}
            />
          </List.Item>
        )}
      />
      <div
        className="addNewMemberContainer"
        onClick={() => setShowFamilyMemberModal(true)}
      >
        <Title level={5} className="tradeGothic">
          + Add/Invite Family Member
        </Title>
      </div>
      <FamilyMemberModal
        type="add"
        title="Add Family Member"
        handleSave={() => {
          setShowFamilyMemberModal(false);
        }}
        visible={showFamilyMemberModal}
        onCancel={() => setShowFamilyMemberModal(false)}
      />
    </>
  );
}

FamilyMembers.propTypes = {};

export default FamilyMembers;
