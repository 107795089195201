import React, { useMemo, useState } from "react";
import { Layout } from "antd";
import logo from "../../assets/images/logo.png";
import { Link, useHistory } from "react-router-dom";

import "./AppHeader.scss";
import { push } from "connected-react-router";
import { useDispatch, useSelector } from "react-redux";
import { Space, Menu, Dropdown, Row, Col, Button, Drawer } from "antd";
import { ReactComponent as DropdownIcon } from "assets/icons/dropdownIco.svg";
import { ReactComponent as HamburderIcon } from "assets/icons/hamburgerIco.svg";
import Avatar from "components/Avatar/Avatar";
import { logoutUser } from "services/auth.services";
import {
  setCurrentProfileId,
  setShowProfileScreen,
} from "redux/actions/user.actions";

import useCurrentProfile from "hooks/useCurrentProfile";
import Timer from "components/Timer/Timer";
import queryString from "query-string";
import { useLocation } from "react-router-dom";
import { LogoutOutlined } from "@ant-design/icons";

const { Header } = Layout;

// additional props can be
// box shadow: example check login screen

const AppHeader = ({ collapsed, toggle, logout, props }) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();
  const qs = queryString.parse(location.search);

  const profile = useCurrentProfile();

  const { user, isTokenVerified } = useSelector((state) => ({
    user: state.user.currentUser,
    isTokenVerified: state.user.isTokenVerified,
  }));
  const [showDrawer, setShowDrawer] = useState(false);

  const dropdownMenu = (
    <Menu>
      <Menu.Item
        onClick={() => {
          // logoutUser(dispatch)
          dispatch(logoutUser());
        }}
      >
        Log Out
      </Menu.Item>
      {/* <Menu.Item
        onClick={() => {
          dispatch(setShowProfileScreen(true));
        }}
      >
        Select Profile
      </Menu.Item> */}
    </Menu>
  );

  const menus = useMemo(() => {
    if (user && isTokenVerified)
      return [
        { title: "My Account", url: "/account" },
        { title: "BOOK AN APPOINTMENT", url: "/" },
      ];
    else
      return [
        {
          title: "GIFT CARDS",
          url: `/giftcards`,
        },
        {
          title: "SIGNUP",
          url: `/signup${qs.redirect ? `?redirect=${qs.redirect}` : ""}`,
        },
        {
          title: "LOGIN",
          url: "/login",
        },
      ];
  }, [isTokenVerified, user]);

  const selectedMenu = useMemo(() => {
    return menus
      .filter((item) => history.location.pathname === item.url)
      .map((item) => item.url);
  }, [history.location.pathname, menus]);

  return (
    <Header className='appHeader'>
      <div className='headerTopRight'>
        <Timer />
        {/* <a href="https://liquidmobileiv.com/contact">CONTACT US</a> */}
        <a href='tel:+18559547843'>(855) 954-7843</a>
      </div>
      <div className='headerContent'>
        <div>
          <a href='https://liquidmobileiv.com/'>
            <img src={logo} width={160} alt='logo' className='logoImage' />
          </a>
        </div>

        <Row>
          <Col xs={0} md={24}>
            <div style={{ display: "flex" }}>
              <Space align='center' className='appHeaderUserNav'>
                <Space style={{ paddingTop: 4 }}>
                  {menus.map((menu, index) => {
                    return (
                      <p
                        key={menu.url}
                        onClick={() => dispatch(push(menu.url))}
                        style={{ lineHeight: "50px" }}
                      >
                        {menu.title}
                      </p>
                    );
                  })}
                </Space>

                {isTokenVerified && user && (
                  <Dropdown overlay={dropdownMenu} trigger={["click"]}>
                    <Space style={{ cursor: "pointer", paddingRight: 12 }}>
                      <Avatar>
                        {profile?.firstName?.[0]} {profile?.lastName?.[0]}
                      </Avatar>
                      <DropdownIcon />
                    </Space>
                  </Dropdown>
                )}
              </Space>
            </div>
          </Col>
          <Col xs={24} md={0}>
            <Button
              ghost
              shape='circle'
              className='hamburderIcon'
              onClick={() => setShowDrawer(true)}
            >
              <HamburderIcon />
            </Button>
            <Drawer
              // title="Basic Drawer"
              placement={"right"}
              closable={true}
              onClose={() => setShowDrawer(false)}
              visible={showDrawer}
            >
              <Menu className='sidebarWrapper' selectedKeys={selectedMenu}>
                {menus.map((menu, index) => {
                  return (
                    <Menu.Item
                      tabIndex={0}
                      // icon={item.icon}
                      key={menu.url}
                      onClick={() => {
                        dispatch(push(menu.url));
                        setShowDrawer(false);
                      }}
                    >
                      {menu.title}
                    </Menu.Item>
                  );
                })}
              </Menu>

              {isTokenVerified && user && (
                <Button
                  className='sideMenuButton'
                  onClick={() => dispatch(logoutUser())}
                >
                  <LogoutOutlined style={{ fontSize: 16 }} />
                  <span>Log Out</span>
                </Button>
              )}
            </Drawer>
          </Col>
        </Row>
      </div>
    </Header>
  );
};
export default AppHeader;
