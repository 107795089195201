import React, { useCallback, useEffect, useRef, useState } from "react";
import { useSelector, useDispatch } from "react-redux";

import Modal from "components/Modal/Modal";
import { Button } from "components/Buttons/Buttons";

import {
  Form,
  Typography,
  Checkbox,
  Skeleton,
  Button as AntdButton,
} from "antd";

import Signature, { useSignature } from "components/Signature/Signature";
import { openNotificationWithIcon } from "utils/Notification";
import FormParsedUI from "components/FormParseUI/FormParseUi.index";
import moment from "moment";
import {
  getForm,
  getFormByValueId,
  putForm,
} from "services/intakeForm.service";
import { setLoading } from "redux/actions/loading.actions";
import { FormType } from "utils/enums";
import { FormHiddenSubmitButton } from "components/FormItems/RoundedFormItems";

const { Title, Text } = Typography;

const DATE_INDICATOR = "{{DATE}}";

export default function IntakeFormModal({
  type,
  visible,
  title = "",
  onSubmit,
  onClose,
  initialValues = {},
  formId,
  isSubmitted = false,
  disabled,
}) {
  const [form] = Form.useForm();

  const [formInformation, setformInformation] = useState();
  const [error, setError] = useState(false);
  const submitBtnRef = useRef();

  const dispatch = useDispatch();

  const [signature, getSignatureValue, clearSignatureValue, setSignatureValue] =
    useSignature("intakeModal", form);

  const [agreeAllConsensts, setAgreeAllConsents] = useState(isSubmitted);
  const { user, intakeForms } = useSelector((state) => {
    return { user: state.user, intakeForms: state.intakeForms };
  });
  const { isSubmitting } = intakeForms;

  const loadData = (renderDesign = true) => {
    // get data
    dispatch(getFormByValueId(formId))
      .then((res) => {
        const data = res?.data;
        const {
          designJSON,
          valueJSON,
          formVersionId,
          formValueId,
          formType,
          formId,
        } = data ?? {};
        const design = JSON.parse(designJSON || "{}");
        const values = JSON.parse(valueJSON || "{}");

        setformInformation({
          design,
          formVersionId,
          formValueId,
          formType,
          formId,
          values,
        });

        if (renderDesign) {
          let transformedValues = {};
          const keys = Object.keys(values);
          for (let i = 0; i < keys.length; i++) {
            const key = keys[i];
            const value = values[key];
            if (value?.startsWith?.(DATE_INDICATOR)) {
              const dateString = value.split(DATE_INDICATOR).pop();
              const date = moment(dateString);
              transformedValues[key] = date;
            } else transformedValues[key] = value;
          }

          form.setFieldsValue(transformedValues);

          if (values?.customerSignature && disabled)
            setSignatureValue(values?.customerSignature);
        }
      })
      .catch(() => {
        setError(true);
      })
      .finally(() => dispatch(setLoading(false)));
  };

  useEffect(() => {
    if (visible && isSubmitted) {
      loadData();
    } else {
      setError();
      form.resetFields();
      clearSignatureValue();
      setformInformation();
    }
  }, [visible, isSubmitted]);

  //   useEffect(() => {
  //     const customerSignature = record?.values?.customerSignature;
  //     if (customerSignature && visible) {
  //       clearSignatureValue();
  //       setSignatureValue(customerSignature);
  //     } else {
  //       clearSignatureValue();
  //     }
  //   }, [visible, record]);

  /** handleSave just updates the formValuesJson */
  const getSubmitableData = (data) => {
    const transformedValues = {};
    const keys = Object.keys(data);
    for (let i = 0; i < keys.length; i++) {
      const key = keys[i];
      const value = data[key];
      if (moment.isMoment(value))
        transformedValues[key] = DATE_INDICATOR + value?.toString();
      else transformedValues[key] = value;
    }
    return transformedValues;
  };

  const handleSubmit = (values) => {
    const signature = getSignatureValue();
    if (signature === "") {
      openNotificationWithIcon(
        "error",
        "Error!",
        "Please sign the consent form"
      );
      return;
    }
    dispatch(setLoading(true));

    values.customerSignature = signature;

    const { formId, formVersionId, formValueId } = formInformation;

    const transformedValues = getSubmitableData(values);
    if (getSignatureValue)
      transformedValues.customerSignature = getSignatureValue();

    dispatch(
      putForm(
        formId,
        formVersionId,
        formValueId,
        transformedValues,
        type === FormType?.consent ? "consent" : "physicalHistory"
      )
    ).then((res) => {
      if (res?.status === "success") onSubmit?.();
    });
  };

  // useEffect(() => {
  //   if (visible) {
  //     setTimeout(() => {
  //       const form = document.querySelector(".intakeModal form");
  //       console.log("Form", form.clientHeight);
  //       form?.scrollIntoView({
  //         top: 0,
  //         left: 0,
  //         behavior: "smooth",
  //       });
  //     }, 500);
  //   }
  // }, [visible]);

  return (
    <Modal
      key={title}
      destroyOnClose={false}
      className="intakeForm intakeModal"
      title={title}
      visible={visible}
      width={812}
      onCancel={onClose}
      footer={
        type !== FormType.consent ? (
          <></>
        ) : (
          <div
            className="flex "
            style={{ justifyContent: "space-between", alignItems: "center" }}
          >
            <Checkbox
              className="checkboxRegular flatFormItemCheckbox"
              checked={
                agreeAllConsensts
                // (consentOfAge === true || consentOfAge === false) &&
                // (photographyConsent === true || photographyConsent === false)
              }
              disabled={disabled}
              formItemStyles={{ marginBottom: 0 }}
              onChange={(e) => {
                setAgreeAllConsents((state) => {
                  return !state;
                });
                if (e.target.checked === false && clearSignatureValue) {
                  clearSignatureValue();
                }
              }}
            >
              I agree to all consents
            </Checkbox>
            <Button
              onClick={() => submitBtnRef?.current?.click()}
              disabled={!agreeAllConsensts || disabled}
              // disabled={
              //   // // !(consentOfAge === true || consentOfAge === false) ||
              //   !agreeAllConsensts
              // }
              loading={isSubmitting}
              rounded={true}
              type="submit"
              style={{ paddingLeft: 60, paddingRight: 60 }}
            >
              Save
            </Button>
          </div>
        )
      }
    >
      {error && (
        <div style={{ marginBottom: 16 }}>
          <Text>
            Error has occured while loading form.
            <AntdButton
              type="text"
              onClick={() => {
                loadData();
              }}
              disabled={false}
            >
              Retry
            </AntdButton>
          </Text>
        </div>
      )}
      <Form
        disabled={disabled}
        form={form}
        layout="vertical"
        className="intakeFormWrapper consentForm"
        onFinishFailed={({ values, errorFields, outOfDate }) => {
          form.scrollToField(errorFields[0].name, {
            scrollMode: "if-needed",
            block: "center",
            behavior: "smooth",
          });
        }}
        onFinish={handleSubmit}
      >
        {error ? (
          <></>
        ) : formInformation?.design ? (
          <FormParsedUI
            disabled={disabled}
            data={formInformation?.design}
            form={form}
            signature={signature}
            getSignatureValue={getSignatureValue}
            clearSignatureValue={clearSignatureValue}
            setSignatureValue={setSignatureValue}
            isSignatureDisabled={disabled}
          />
        ) : (
          <Skeleton active />
        )}
        <FormHiddenSubmitButton submitRef={submitBtnRef} />
        {type === FormType.historyOrPhysical && (
          <Button
            disabled={disabled || error}
            rounded={true}
            type="submit"
            style={{ paddingLeft: 60, paddingRight: 60 }}
            loading={isSubmitting}
          >
            Save
          </Button>
        )}
      </Form>
    </Modal>
  );
}

IntakeFormModal.prototype = {};
