import React, { useEffect, useRef, useState } from "react";
import { useSelector, useDispatch } from "react-redux";

import Modal from "components/Modal/Modal";
import { Button } from "components/Buttons/Buttons";

import {
  Form,
  Typography,
  Checkbox,
  Skeleton,
  Button as AntdButton,
} from "antd";
import {
  FormCheckbox,
  FormRadio,
  FormRadioGroup,
  FormHiddenSubmitButton,
} from "components/FormItems/FlatFormItems";

import "./ConsentForm.scss";
import {
  postForm,
  getForm,
  getFormByType,
  putForm,
} from "services/intakeForm.service";
import Signature, { useSignature } from "components/Signature/Signature";
import { openNotificationWithIcon } from "utils/Notification";
import FormParsedUI from "components/FormParseUI/FormParseUi.index";

import moment from "moment";
import { setLoading } from "redux/actions/loading.actions";

const { Title, Text } = Typography;

const FORM_NAME = "Consent";
const DATE_INDICATOR = "{{DATE}}";

export default function ConsentForm({
  visible,
  onSubmit,
  onClose,
  initialValues = {},
  disabled,
  isOutdated,
  viewOnly,
}) {
  const [form] = Form.useForm();
  const submitBtnRef = useRef();
  const consentOfAge = Form.useWatch("consentOfRecipientAge", form);

  const [error, setError] = useState(false);

  const dispatch = useDispatch();
  const { user, intakeForms } = useSelector((state) => {
    return { user: state.user, intakeForms: state.intakeForms };
  });
  const { currentUser, profiles } = user;
  const { intakeFormSubmitted } = currentUser;

  const { isSubmitting, consent } = intakeForms;
  const [signature, getSignatureValue, clearSignatureValue, setSignatureValue] =
    useSignature("consentModal", form);
  const isFormSubmitted =
    profiles?.[0]?.consentId !== undefined &&
    profiles?.[0]?.consentId !== null &&
    profiles?.[0]?.consentId !== 0;
  const [agreeAllConsensts, setAgreeAllConsents] = useState(isFormSubmitted);

  const [formInformation, setformInformation] = useState();

  const loadData = (renderDesign = true) => {
    // get data
    if ((isFormSubmitted && !isOutdated) || viewOnly) {
      dispatch(setLoading(true));
      dispatch(getForm(profiles[0].profileId, FORM_NAME))
        .then((res) => {
          if (res?.status === "success") {
            setError(false);
            const data = res?.data;
            const {
              formId,
              formVersionId,
              formValueId,
              designJSON,
              valueJSON,
            } = data;

            const json = JSON.parse(designJSON || "{}");
            const values = JSON.parse(valueJSON || "{}");
            setformInformation({
              formId,
              formVersionId,
              formValueId,
              design: json,
              values,
            });
            let customerSignature = "";
            let transformedValues = {};
            const keys = Object.keys(values);
            for (let i = 0; i < keys.length; i++) {
              const key = keys[i];
              const value = values[key];
              if (value?.startsWith?.(DATE_INDICATOR)) {
                const dateString = value.split(DATE_INDICATOR).pop();
                const date = moment(dateString);
                transformedValues[key] = date;
              } else transformedValues[key] = value;
              if (key === "customerSignature") {
                customerSignature = value;
                transformedValues[key] = value;
              }
            }
            console.log(
              "customerSignature",
              setSignatureValue,
              customerSignature
            );
            if (setSignatureValue && disabled) {
              setSignatureValue(customerSignature);
            }
            form.setFieldsValue(transformedValues);
          } else {
            setError(true);
          }
        })
        .catch((error) => {
          setError(true);
        })
        .finally(() => dispatch(setLoading(false)));
    } else {
      dispatch(getFormByType(3, true))
        .then((res) => {
          if (res?.status === "success") {
            setError(false);
            const data = res?.data;
            const formData = data?.formVersions?.[0];
            const { formId, formJSON, formVersionId, versionNo } = formData;

            const design = JSON.parse(formJSON || "{}");
            setformInformation({
              formId,
              formVersionId,
              versionNo,
              design,
              values: null,
            });
          } else {
            setError(true);
          }
        })
        .catch((error) => {
          setError(true);
        })
        .finally(() => dispatch(setLoading(false)));
    }
  };

  useEffect(() => {
    if (visible) loadData();
    else {
      form.resetFields();
      setformInformation();
      clearSignatureValue();
    }
  }, [profiles?.profileId, visible]);

  useEffect(() => {
    if (isFormSubmitted) {
      form.setFieldsValue({
        consentOfRecipientAge: isFormSubmitted
          ? consent.consentOfRecipientAge
          : false,
      });
    }
  }, [consent]);
  const getSubmitableData = (data) => {
    const transformedValues = {};
    const keys = Object.keys(data);
    for (let i = 0; i < keys.length; i++) {
      const key = keys[i];
      const value = data[key];
      if (moment.isMoment(value))
        transformedValues[key] = DATE_INDICATOR + value?.toString();
      else transformedValues[key] = value;
    }
    return transformedValues;
  };

  const handleSubmit = (values) => {
    const signature = getSignatureValue();
    if (signature === "") {
      openNotificationWithIcon(
        "error",
        "Error!",
        "Please sign the consent form"
      );
      return;
    }
    dispatch(setLoading(true));

    values.customerSignature = signature;

    const { formId, formVersionId, formValueId } = formInformation;

    const transformedValues = getSubmitableData(values);
    if (getSignatureValue)
      transformedValues.customerSignature = getSignatureValue();

    // we no longer need put flow
    if (isFormSubmitted && !isOutdated && false) {
      // put
      dispatch(
        putForm(
          formId,
          formVersionId,
          formValueId,
          transformedValues,
          "consent"
        )
      ).then(() => {
        if (onSubmit) onSubmit();
      });
    } else {
      // post
      dispatch(
        postForm(formId, formVersionId, transformedValues, "consent")
      ).then(() => {
        if (onSubmit) onSubmit();
      });
    }
  };

  // useEffect(() => {
  //   if (visible) {
  //     setTimeout(() => {
  //       const form = document.querySelector(".consentModal form");
  //       console.log("Form", form.clientHeight);
  //       form?.scrollIntoView({
  //         top: 0,
  //         left: 0,
  //         behavior: "smooth",
  //       });
  //     }, 500);
  //   }
  // }, [visible]);

  return (
    <Modal
      destroyOnClose={false}
      className="intakeForm consentModal"
      title="Consents"
      visible={visible}
      width={812}
      onCancel={onClose}
      footer={
        <div
          className="flex "
          style={{ justifyContent: "space-between", alignItems: "center" }}
        >
          <Checkbox
            disabled={disabled}
            className="checkboxRegular flatFormItemCheckbox"
            checked={
              agreeAllConsensts
              // (consentOfAge === true || consentOfAge === false) &&
              // (photographyConsent === true || photographyConsent === false)
            }
            formItemStyles={{ marginBottom: 0 }}
            onChange={(e) => {
              setAgreeAllConsents((state) => {
                return !state;
              });
              if (e.target.checked === false && clearSignatureValue) {
                clearSignatureValue();
              }
            }}
          >
            I agree to all consents
          </Checkbox>
          <Button
            onClick={() => submitBtnRef?.current?.click()}
            disabled={
              // !(consentOfAge === true || consentOfAge === false) ||
              !agreeAllConsensts || disabled
            }
            loading={isSubmitting}
            rounded={true}
            type="submit"
            style={{ paddingLeft: 60, paddingRight: 60 }}
          >
            Save
          </Button>
        </div>
      }
    >
      {error && (
        <div style={{ marginBottom: 16 }}>
          <Text>
            Error has occured while loading form.
            <AntdButton
              type="text"
              onClick={() => {
                loadData();
              }}
              disabled={false}
            >
              Retry
            </AntdButton>
          </Text>
        </div>
      )}
      <Form
        form={form}
        layout="vertical"
        disabled={disabled || error}
        className="intakeFormWrapper "
        initialValues={{
          consentOfRecipientAge: undefined,
        }}
        onFinishFailed={({ values, errorFields, outOfDate }) => {
          form.scrollToField(errorFields[0].name, {
            scrollMode: "if-needed",
            block: "center",
            behavior: "smooth",
          });
        }}
        onFinish={handleSubmit}
      >
        {error ? (
          <></>
        ) : formInformation?.design ? (
          <FormParsedUI
            disabled={disabled}
            data={formInformation?.design}
            form={form}
            signature={signature}
            getSignatureValue={getSignatureValue}
            clearSignatureValue={clearSignatureValue}
            setSignatureValue={setSignatureValue}
            isSignatureDisabled={disabled}
            // isSignatureDisabled={true}
          />
        ) : (
          <Skeleton active />
        )}

        <FormHiddenSubmitButton submitRef={submitBtnRef} />
      </Form>
    </Modal>
  );
}

ConsentForm.prototype = {};
